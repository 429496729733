import Logo from "../img/logo.svg";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { CompanyListing } from "@pentacode/core/src/model";
import { shared, mixins } from "../styles";
import { app } from "../init";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { alert } from "./alert-dialog";
import "./scroller";

@customElement("ptc-select-company")
export class SelectCompany extends Routing(StateMixin(LitElement)) {
    routePattern = /^select_company/;

    @state()
    private _loading = false;

    @state()
    private _companies: CompanyListing[] = [];

    @state()
    private _filter = "";

    handleRoute() {
        this._load();
    }

    private async _load() {
        this._loading = true;

        try {
            this._companies = await app.api.listCompanies();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _selectCompany(companyId: number) {
        this._loading = true;

        try {
            await app.selectCompany(companyId);
            const { next, ...params } = this.router.params;
            this.go(next || "", params);
            window.location.reload();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        shared,
        css`
            :host {
                ${mixins.fullbleed()};
                ${mixins.scroll()};
                z-index: 100;
                background: var(--color-bg);
                padding: 2em;
            }

            .container {
                min-height: 100%;
                text-align: center;
            }

            .card {
                width: 300px;
                background: var(--color-bg);
                border-radius: calc(2 * var(--border-radius));
                color: var(--color-fg);
                margin: 2em;
                box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px -5px;
                position: relative;
                overflow: hidden;
                max-height: 30em;
                padding-top: 1em;
            }

            .card > input,
            .companies > * {
                margin: 0 1em 1em 1em;
            }

            .logo {
                height: 50px;
            }

            .greeting {
                margin-top: 1em;
                width: 350px;
                text-align: center;
            }

            .message {
                margin: 0 0 0.8em 0;
            }
        `,
    ];

    render() {
        if (!app.account) {
            return;
        }

        const fs = this._filter.toLowerCase();

        return html`
            <div class="container vertical center-aligning center-justifying layout">
                <img src="${Logo}" class="logo" />

                <div class="greeting">Bitte wählen Sie ein Unternehmen:</div>

                <div class="card vertical layout">
                    <input
                        type="search"
                        placeholder="Filtern..."
                        @input=${(e: Event) => (this._filter = (e.target as HTMLInputElement).value)}
                        ?hidden=${this._companies.length < 5}
                    />

                    <ptc-scroller class="stretch">
                        <div class="vertical layout companies">
                            ${this._companies
                                .filter(
                                    (c) =>
                                        !fs ||
                                        c.name.toLowerCase().includes(fs) ||
                                        c.venues.some((v) => v.name.toLowerCase().includes(fs)) ||
                                        c.id.toString() === fs
                                )
                                .map(
                                    (company) => html`
                                        <button class="ghost" @click=${() => this._selectCompany(company.id)}>
                                            ${company.name}
                                        </button>
                                    `
                                )}
                        </div>
                    </ptc-scroller>
                </div>

                <button class="transparent" ?hidden=${!app.company} @click=${() => this.go("")}>Abbrechen</button>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
