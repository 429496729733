import { LitElement, html, css, PropertyValues } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { RevenueGroup, revenueTypeIcon, taxKeyLabel } from "@pentacode/core/src/model";
import { formatDate } from "@pentacode/core/src/util";
import { shared } from "../styles";
import "./popover";
import { LitVirtualizer } from "@lit-labs/virtualizer";

@customElement("ptc-revenues-autocomplete-input")
export class RevenuesAutocompleteInput extends LitElement {
    @property()
    placeholder: string = "";

    @property({ attribute: false })
    templates: RevenueGroup[] = [];

    @state()
    private _suggestions: RevenueGroup[] = [];

    @state()
    private _highlightedSuggestion: number = -1;

    @state()
    private _showSuggestions: boolean = false;

    @query("lit-virtualizer")
    private _virtualizer: LitVirtualizer;

    private get _nameInput(): HTMLInputElement {
        return this.querySelector("input[name='name']") as HTMLInputElement;
    }

    firstUpdated() {
        this._nameInput.addEventListener("input", () => this._updateSuggestions());
        this._nameInput.addEventListener("focusin", () => this._nameInputFocus());
        this._nameInput.addEventListener("focusout", () => this._nameInputBlur());
        this.addEventListener("keydown", (e: KeyboardEvent) => this._keydown(e));
    }

    async updated(changes: PropertyValues) {
        // If navigating with arrow keys, scroll the highlighted suggestion into view
        if (changes.has("_highlightedSuggestion") && this._highlightedSuggestion !== -1) {
            this._virtualizer.scrollToIndex(this._highlightedSuggestion, "center");
        }
    }

    private async _updateSuggestions() {
        const filterString = this._nameInput.value;
        this._suggestions = this.templates.filter((e) => e.name.toLowerCase().includes(filterString.toLowerCase()));
        this._highlightedSuggestion = -1;
        await this.updateComplete;
        this.renderRoot.querySelector(".suggestions")?.scrollIntoView({ block: "center" });
    }

    private async _selectSuggestion(entry: RevenueGroup) {
        this.dispatchEvent(new CustomEvent<RevenueGroup>("template-selected", { detail: entry }));
    }

    private async _nameInputFocus() {
        // e.stopPropagation();
        if (!this._nameInput.readOnly) {
            await this._updateSuggestions();
            this._showSuggestions = true;
        }
    }

    private _nameInputBlur() {
        // e.stopPropagation();
        setTimeout(() => (this._showSuggestions = false), 200);
    }

    private async _keydown(e: KeyboardEvent) {
        switch (e.key) {
            case "ArrowDown":
                this._highlightedSuggestion = Math.min(this._suggestions.length - 1, this._highlightedSuggestion + 1);
                break;
            case "ArrowUp":
                this._highlightedSuggestion = Math.max(-1, this._highlightedSuggestion - 1);
                break;
            case "Enter": {
                const selected = this._suggestions[this._highlightedSuggestion];
                if (selected) {
                    await this._selectSuggestion(selected);
                    e.preventDefault();
                }
            }
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
                font-size: inherit;
            }

            .suggestions {
                width: 100%;
                position: absolute;
                left: 0;
                top: 100%;
                background: var(--color-bg);
                border-radius: var(--border-radius);
                box-shadow: rgba(0, 0, 0, 0.3) 0 1px 5px -2px;
                box-sizing: border-box;
                z-index: 1;
                overflow: auto;
                max-height: var(--suggestions-max-height, 25em);
                font-size: var(--suggestions-size, inherit);
            }

            .suggestion {
                padding: 0.4em 0.6em 0.5em 0.6em;
                background: transparent;
                border-radius: 0;
                text-align: left;
                width: 100%;
            }

            .suggestion:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }

            .suggestion .pills {
                margin-top: 0.5em;
            }

            .suggestion > i {
                font-size: var(--font-size-big);
                margin-right: 0.4em;
            }
        `,
    ];

    render() {
        return html`
            <slot></slot>

            <div class="suggestions" ?hidden=${!this._showSuggestions || !this._suggestions.length}>
                <lit-virtualizer
                    .items=${this._suggestions}
                    .renderItem=${(entry: RevenueGroup, idx: number) => this._renderSuggestion(entry, idx)}
                ></lit-virtualizer>
            </div>
        `;
    }

    private _renderSuggestion(entry: RevenueGroup, idx: number) {
        return html`
            <button
                type="button"
                tabindex="-1"
                class="suggestion horizontal center-aligning layout"
                @click=${() => this._selectSuggestion(entry)}
                ?active=${idx === this._highlightedSuggestion}
            >
                <i class="${entry.id ? revenueTypeIcon(entry.type) : "history"}"></i>

                <div class="stretch collapse">
                    <div class="ellipsis">${entry.name}</div>

                    <div class="tiny pills">
                        ${entry.ledger
                            ? html`
                                  <div class="pill">
                                      <strong>Knt:</strong>
                                      ${entry.ledger}
                                  </div>
                              `
                            : ""}
                        ${entry.taxKey
                            ? html`
                                  <div class="pill">
                                      <strong>St.:</strong>
                                      ${taxKeyLabel(entry.taxKey)}
                                  </div>
                              `
                            : ""}
                        ${entry.costCenter
                            ? html`
                                  <div class="pill">
                                      <strong>KoSt.:</strong>
                                      ${entry.costCenter}
                                  </div>
                              `
                            : ""}
                        ${entry.lastUsed
                            ? html`
                                  <div class="pill">
                                      <i class="history"></i>
                                      ${formatDate(entry.lastUsed)}
                                  </div>
                              `
                            : ""}
                    </div>
                </div>
            </button>
        `;
    }
}
