import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { Employee, Gender, genderLabel } from "@pentacode/core/src/model";
import { app } from "../init";
import { shared } from "../styles";
import { DateInput } from "./date-input";
import "./popover";
import { countries } from "@pentacode/core/src/countries";
import { patterns } from "@pentacode/core/src/validation";
import { DateString } from "@pentacode/openapi";
import { EmployeeTagsInput } from "./employee-tags-input";

@customElement("ptc-profile-form")
export class ProfileForm extends LitElement {
    @property({ type: Boolean })
    displayHighestStaffNumber = false;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='timePin']")
    private _pinInput: HTMLInputElement;

    @query("input[name='staffNumber']")
    private _staffNumberInput: HTMLInputElement;

    @query("input[name='email']")
    private _emailInput: HTMLInputElement;

    @query("ptc-employee-tags-input")
    private _tagsInput: EmployeeTagsInput;

    @state()
    private _employee: Employee;

    get data() {
        if (!this._form) {
            return {};
        }
        const formData = new FormData(this._form);
        const staffNumber = parseInt(formData.get("staffNumber") as string);
        return {
            firstName: (formData.get("firstName") as string) || "",
            lastName: (formData.get("lastName") as string) || "",
            title: (formData.get("title") as string) || "",
            callName: (formData.get("callName") as string) || "",
            address: (formData.get("address") as string) || "",
            postalCode: (formData.get("postalCode") as string) || "",
            city: (formData.get("city") as string) || "",
            email: (formData.get("email") as string) || "",
            phone: (formData.get("phone") as string) || "",
            phone2: (formData.get("phone2") as string) || "",
            staffNumber: isNaN(staffNumber) ? null : staffNumber,
            birthday: (formData.get("birthday") as DateString) || null,
            birthName: (formData.get("birthName") as string) || "",
            birthCity: (formData.get("birthCity") as string) || "",
            birthCountry: (formData.get("birthCountry") as string) || "",
            nationality: (formData.get("nationality") as string) || "",
            gender: (formData.get("gender") as Gender) || null,
            taxId: (formData.get("taxId") as string) || "",
            socialSecurityNumber: (formData.get("socialSecurityNumber") as string) || "",
            notes: (formData.get("notes") as string) || "",
            timePin: formData.get("timePin") as string,
            tags: this._tagsInput.tags,
        };
    }

    get hasChanged() {
        const { tags, ...rest } = this.data;
        return (
            Object.entries(rest).some(([key, value]) => this._employee?.[key as keyof Employee] !== value) ||
            tags?.length !== this._employee.tags.length ||
            tags?.some((tag) => !this._employee.tags.some((t) => t.id === tag.id))
        );
    }

    private get _highestStaffNumber() {
        return app.employees.reduce((highest, employee) => Math.max(highest, employee.staffNumber || 0), 0);
    }

    async init(employee: Employee) {
        this._employee = employee;
        if (!this._form) {
            await this.updateComplete;
        }
        const inputs = this._form.querySelectorAll(
            ".field > input, .field > textarea, .field > ptc-date-input, .field > select"
        ) as NodeListOf<HTMLInputElement>;
        for (const input of inputs) {
            input.value = (employee[input.name as keyof Employee] as string) || "";
        }
        this._pinInput.value = employee.timePin;
        this._tagsInput.tags = employee.tags ? [...employee.tags] : [];
    }

    reportValidity() {
        return this._form?.reportValidity();
    }

    async generatePin() {
        if (!this._pinInput) {
            await this.updateComplete;
        }
        this._pinInput.value = await app.generatePin();
        this._validatePin();

        this.dispatchEvent(new CustomEvent("change"));
        this.dispatchEvent(new CustomEvent("input"));
    }

    private _validatePin() {
        const pin = this._pinInput.value;
        if (!pin) {
            this._pinInput.setCustomValidity("");
            return;
        }

        const isValid = /^\d\d\d\d$/.test(pin);
        const isUnique = !app.company!.employees.some((emp) => emp.id !== this._employee.id && emp.timePin === pin);
        this._pinInput.setCustomValidity(
            !isValid ? "Bitte geben Sie eine vierstellige PIN ein!" : !isUnique ? "Diese PIN ist bereits vergeben!" : ""
        );
    }

    private _validateStaffNumber() {
        const input = this._staffNumberInput;

        if (!input.value) {
            input.setCustomValidity("");
            return;
        }

        const value = Number(input.value);

        if (isNaN(value) || value < 1 || value >= 1e10) {
            input.setCustomValidity("Bitte geben Sie eine Zahl zwischen 1 und 999999999 ein!");
            return;
        }

        if (app.employees.some((e) => e.id !== this._employee.id && e.staffNumber === value)) {
            input.setCustomValidity("Diese Personalnummer ist bereits vergeben!");
            return;
        }

        input.setCustomValidity("");
    }

    private _validateEmail() {
        const input = this._emailInput;
        const dup = input.value && app.employees.find((e) => e.id !== this._employee.id && e.email === input.value);

        if (dup) {
            input.setCustomValidity(`Es existiert bereits ein Mitarbeiter mit dieser Emailadresse: ${dup.name}`);
        } else {
            input.setCustomValidity("");
        }
    }

    private _changeHandler() {
        this._validatePin();
        this._validateStaffNumber();
        this._validateEmail();
        this.dispatchEvent(new CustomEvent("change", { composed: true, bubbles: true }));
    }

    private _submit(e: Event) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit", { composed: true, bubbles: true }));
    }

    static styles = [
        shared,
        DateInput.styles,
        css`
            :host {
                display: block;
            }
        `,
    ];

    render() {
        if (!this._employee) {
            return;
        }
        const isSelf = !!this._employee.id && this._employee.id === app.profile?.id;
        const countrySpecificPatterns = patterns[app.company?.country ?? "DE"] ?? patterns.DE;
        return html`
            <form id="profileForm" @change=${this._changeHandler} @submit=${this._submit}>
                <h2>Allgemein</h2>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Titel</label>
                        <input type="text" name="title" placeholder="Titel" />
                    </div>

                    <div class="stretch"></div>
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Vorname</label>
                        <input type="text" name="firstName" placeholder="Vorname" required />
                    </div>

                    <div class="field">
                        <label>Nachname</label>
                        <input type="text" name="lastName" placeholder="Nachname" required />
                    </div>
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Rufname</label>
                        <input type="text" name="callName" placeholder="Rufname" />
                    </div>
                    <div class="field">
                        <label>Geburtstag</label>
                        <ptc-date-input name="birthday"></ptc-date-input>
                    </div>
                </div>

                <div class="field">
                    <label>Adresse</label>
                    <input type="text" name="address" placeholder="Strasse / Hausnummer" />
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Postleitzahl</label>
                        <input type="number" pattern="d*" name="postalCode" placeholder="PLZ" />
                    </div>

                    <div class="field">
                        <label>Stadt</label>
                        <input type="text" name="city" placeholder="Stadt" />
                    </div>
                </div>

                <div class="field">
                    <label class="center-aligning horizontal layout">
                        <div class="stretch">Personalnummer</div>
                        ${this.displayHighestStaffNumber && this._highestStaffNumber
                            ? html`
                                  <div class="subtle small">
                                      Aktuell höchste Pnr.: <strong>${this._highestStaffNumber}</strong>
                                  </div>
                              `
                            : ""}
                    </label>
                    <input type="number" name="staffNumber" placeholder="Personalnummer" />
                </div>

                <div class="field">
                    <label>Fähigkeiten & Merkmale</label>
                    <ptc-employee-tags-input @change=${() => this._changeHandler()}></ptc-employee-tags-input>
                </div>

                <div class="field">
                    <label>Bemerkungen</label>
                    <textarea name="notes"></textarea>
                </div>

                <h2>Kontakt</h2>

                <div class="field">
                    <label>Emailadresse</label>
                    <input type="email" name="email" placeholder="Emailadresse" ?readonly=${isSelf} />
                    ${isSelf
                        ? html`
                              <ptc-popover class="tooltip" trigger="hover">
                                  Ihre eigene Emailadresse können Sie nur unter dem Punkt
                                  <strong>Einstellungen / Benutzerkonto</strong> ändern.
                              </ptc-popover>
                          `
                        : ""}
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Telefon</label>
                        <input type="tel" name="phone" placeholder="Telefon" />
                    </div>

                    <div class="field">
                        <label>Telefon 2</label>
                        <input type="tel" name="phone2" placeholder="Telefon 2" />
                    </div>
                </div>

                <h2>Geburtsort & Nationalität</h2>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Geburtsname</label>
                        <input type="text" name="birthName" placeholder="" />
                    </div>
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Geburtsort</label>
                        <input type="text" name="birthCity" placeholder="" />
                    </div>

                    <div class="field">
                        <label>Geburtsland</label>
                        <select name="birthCountry">
                            <option value="">-- Keine Angabe --</option>
                            ${countries.map(
                                (country) => html` <option .value=${country.iso}>${country.name}</option> `
                            )}
                        </select>
                    </div>
                </div>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Nationalität</label>
                        <select name="nationality">
                            <option value="">-- Keine Angabe --</option>
                            ${countries.map(
                                (country) => html` <option .value=${country.iso}>${country.name}</option> `
                            )}
                        </select>
                    </div>

                    <div class="field">
                        <label>Geschlecht</label>
                        <select name="gender">
                            <option value="">-- Keine Angabe --</option>
                            ${Object.values(Gender).map(
                                (gender) => html` <option .value=${gender}>${genderLabel(gender)}</option> `
                            )}
                        </select>
                    </div>
                </div>

                <h2>Steuerliche Angaben</h2>

                <div class="horizontal spacing evenly stretching layout">
                    <div class="field">
                        <label>Sozialversicherungsnummer</label>
                        <input
                            type="text"
                            name="socialSecurityNumber"
                            placeholder="${countrySpecificPatterns.socialSecurityNumber.placeholder}"
                            pattern=${countrySpecificPatterns.socialSecurityNumber.pattern}
                        />
                    </div>

                    <div class="field">
                        <label>Steuer-ID</label>
                        <input
                            type="tel"
                            name="taxId"
                            placeholder="${countrySpecificPatterns.taxId.placeholder}"
                            pattern=${countrySpecificPatterns.taxId.pattern}
                        />
                    </div>
                </div>

                <h2>Zeiterfassung</h2>

                <div class="field">
                    <label>PIN</label>
                    <div class="right icon input">
                        <input type="number" name="timePin" maxlength="4" />
                        <button class="skinny transparent" type="button" @click=${this.generatePin}>
                            <i class="redo"></i>
                        </button>
                    </div>
                </div>

                <button invisible></button>
            </form>
        `;
    }
}
