import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { PayrollItemType, PayrollReport } from "@pentacode/core/src/payroll";
import { formatNumber } from "@pentacode/core/src/util";
import { router } from "../init";
import { shared } from "../styles";

@customElement("ptc-payroll-report")
export class PayrollReportEl extends LitElement {
    @property({ attribute: false })
    report: PayrollReport;

    @property({ type: Boolean })
    hideEmployeeInfo: boolean = false;

    static styles = [
        shared,
        css`
            tr > :nth-child(2) {
                width: 4em;
            }

            tr > :nth-child(3) {
                width: 3em;
            }

            tr > :nth-child(4) {
                width: 4em;
            }

            tr > :nth-child(5) {
                width: 5em;
            }

            tr > :nth-child(6) {
                width: 5em;
            }

            th.description {
                text-align: left;
            }

            td.number {
                text-align: right;
            }

            .employee-header {
                cursor: pointer;
            }

            .employee-header:hover {
                color: var(--color-primary);
            }

            .employee-name {
                margin-bottom: 0.25em;
            }
        `,
    ];

    render() {
        if (!this.report) {
            return;
        }

        const items = this.report.items.filter((item) => !item.exportOnly);
        const total = items.reduce((total, item) => total + (item.result || 0), 0);
        const employee = this.report.employee;
        return html`
            <table class="simple">
                <thead>
                    <tr>
                        <th>
                            <div
                                class="horizontal center-aligning spacing layout employee-header"
                                @click=${() => router.go(`employees/${employee.id}/payroll`)}
                                ?hidden=${this.hideEmployeeInfo}
                            >
                                <ptc-avatar class="tiny" .employee=${employee}></ptc-avatar>
                                <div class="semibold">${employee.lastName}, ${employee.firstName}</div>
                                ${employee.staffNumber
                                    ? html` <div class="subtle">#${employee.staffNumber}</div> `
                                    : ""}
                            </div>
                        </th>
                        <th scope="col" class="number" hidden>Lohnart</th>
                        <th scope="col" class="number">Kst.</th>
                        <th scope="col" class="number">Menge</th>
                        <th scope="col" class="number">Einh.</th>
                        <th scope="col" class="number"><span class="nowrap">€ / Einh.</span></th>
                        <th scope="col" class="number"><span class="nowrap">Faktor (%)</span></th>
                        <th scope="col" class="number"><span class="nowrap">Betrag (€)</span></th>
                    </tr>
                </thead>
                <tbody>
                    ${items.map(
                        ({
                            description,
                            icon,
                            color,
                            unit,
                            amount,
                            wagePerUnit,
                            factor,
                            result,
                            type,
                            wageType,
                            costCenter,
                        }) => {
                            return html`
                                <tr>
                                    <td class="description" style="--color-highlight: ${color}">
                                        <i class="${icon || "dollar-badge"}"></i>
                                        ${type === PayrollItemType.Commission ? html`Umsatzbeteiligung: ` : ""}
                                        ${description}
                                        ${type === PayrollItemType.BonusTaxed
                                            ? html`<sup class="smaller orange colored-text">*beitragspfl.</sup>`
                                            : ""}
                                    </td>
                                    <td class="number" hidden>${wageType}</td>
                                    <td class="number">${costCenter || html`<span class="subtle">N/A</span>`}</td>
                                    <td class="number">${formatNumber(amount)}</td>
                                    <td>${{ days: "T", hours: "Std.", euros: "€", pieces: "St." }[unit]}</td>
                                    <td class="number">${wagePerUnit ? formatNumber(wagePerUnit) : ""}</td>
                                    <td class="number">${factor ? formatNumber(factor) : ""}</td>
                                    <td class="number">${result ? formatNumber(result) : ""}</td>
                                </tr>
                            `;
                        }
                    )}
                    <tr class="bold">
                        <td><i class="sack-dollar"></i> Gesamt</td>
                        <td hidden></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="number">${formatNumber(total)}</td>
                    </tr>
                </tbody>
            </table>
        `;
    }
}
