import "./scroller";
import "./avatar";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { Department, Employee, Venue } from "@pentacode/core/src/model";
import "./progress";
import { live } from "lit/directives/live.js";
import { app } from "../init";
import { Checkbox } from "./checkbox";
import { colors } from "@pentacode/core/src/colors";
import { Popover } from "./popover";
import { shared } from "../styles";

@customElement("ptc-select-audience")
export class SelectAudience extends LitElement {
    get selected(): Employee[] {
        return app.employees.filter(
            (e) =>
                !!e.active &&
                (this.all ||
                    [...this.venues.flatMap((v) => v.departments), ...this.departments].some((d) =>
                        e.positions.some((p) => p.departmentId === d.id)
                    ) ||
                    this.employees.some((emp) => emp.id === e.id)) &&
                app.hasPermissionForEmployee(e)
        );
    }

    readonly preventDismiss = true;

    @query(".filter-input")
    private _filterInput: HTMLInputElement;

    @query(".assignments-popover")
    private _assignmentsPopover: Popover;

    @state()
    private _filterString = "";

    @state()
    private _displayEmployeesLimit = 100;

    @property({ attribute: false })
    all = false;

    @property({ attribute: false })
    venues: Venue[] = [];

    @property({ attribute: false })
    departments: Department[] = [];

    @property({ attribute: false })
    employees: Employee[] = [];

    static styles = [
        shared,
        Checkbox.styles,
        css`
            .assignments ptc-checkbox-button ptc-checkbox {
                font-size: 0.7em;
            }

            .assignments ptc-checkbox-button button {
                padding: 0.5em;
            }

            .assignments ptc-avatar {
                margin: -0.2em 0.5em -0.2em 0;
            }

            .assignments .filter-input {
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                width: 10em;
            }

            .assignments-popover {
                padding: 0;
                --popover-hover-buffer: 0;
            }

            .assignments-popover .scroller {
                max-height: 30em;
                border-radius: 1em;
            }
        `,
    ];

    private _fireChange() {
        this.dispatchEvent(
            new CustomEvent("change", {
                detail: { selected: this.selected },
                bubbles: true,
                composed: true,
            })
        );
    }

    private _deselectGlobal() {
        setTimeout(() => {
            this.all = false;
            this._fireChange();
        }, 200);
    }

    private _deselectVenue(venue: Venue) {
        setTimeout(() => {
            this.venues = this.venues.filter((v) => v.id !== venue.id);
            this._fireChange();
        }, 200);
    }

    private _deselectDepartment(dep: Department) {
        setTimeout(() => {
            this.departments = this.departments.filter((d) => d.id !== dep.id);
            this._fireChange();
        }, 200);
    }

    private _deselectEmployee(emp: Employee) {
        setTimeout(() => {
            this.employees = this.employees.filter((e) => e.id !== emp.id);
            this._fireChange();
        }, 200);
    }

    private async _selectGlobal() {
        setTimeout(() => {
            this.all = true;
            this._fireChange();
        }, 200);
    }

    private async _selectVenue(v: Venue) {
        this.venues.push(v);
        setTimeout(() => {
            this._filterString = this._filterInput.value = "";
            this._filterInput.blur();
            this._assignmentsPopover.hide();
            this.requestUpdate("venues");
            this._fireChange();
        }, 200);
    }

    private async _selectDepartment(d: Department) {
        this.departments.push(d);
        setTimeout(() => {
            this._filterString = this._filterInput.value = "";
            this._filterInput.blur();
            this._assignmentsPopover.hide();
            this.requestUpdate("departments");
            this._fireChange();
        }, 200);
    }

    private async _selectEmployee(e: Employee) {
        this.employees.push(e);
        setTimeout(() => {
            this._filterString = this._filterInput.value = "";
            this._filterInput.blur();
            this._assignmentsPopover.hide();
            this.requestUpdate("employees");
            this._fireChange();
        }, 200);
    }

    render() {
        if (!app.company) {
            return html``;
        }

        const fs = this._filterString.toLowerCase();

        const availableVenues = app.accessibleVenues.filter(
            (venue) => !this.venues.some((ven) => ven.id === venue.id) && venue.name.toLowerCase().includes(fs)
        );

        const availableDepartments = app.accessibleDepartments.filter(
            (department) =>
                !this.departments.some((dep) => dep.id === department.id) && department.name.toLowerCase().includes(fs)
        );

        const availableEmployees = app.accessibleEmployees.filter(
            (e) =>
                e.active &&
                !!e.email &&
                !this.employees.some((emp) => emp.id === e.id) &&
                e.name.toLowerCase().includes(fs)
        );

        return html`
            <div class="small spacing wrapping horizontal layout assignments">
                ${this.all
                    ? html`
                          <ptc-checkbox-button
                              class="box"
                              buttonClass="transparent"
                              .label=${html`<i class="globe"></i> Alle`}
                              .checked=${live(true)}
                              @change=${this._deselectGlobal}
                          ></ptc-checkbox-button>
                      `
                    : ""}
                ${this.venues.map(
                    (v) => html`
                        <ptc-checkbox-button
                            class="box"
                            buttonClass="transparent"
                            .label=${html`<div class="ellipsis" style="max-width: 10em;">
                                <i class="building"></i> ${v.name}
                            </div>`}
                            .checked=${live(true)}
                            @change=${() => this._deselectVenue(v)}
                            title=${v.name}
                        ></ptc-checkbox-button>
                    `
                )}
                ${this.departments.map(
                    (d) => html`
                        <ptc-checkbox-button
                            class="box"
                            buttonClass="transparent"
                            .label=${html`<div class="ellipsis" style="max-width: 10em">
                                <i class="users-class"></i> ${d.name}
                            </div>`}
                            .checked=${live(true)}
                            style="--color-highlight: ${colors[d.color] || d.color}"
                            @change=${() => this._deselectDepartment(d)}
                            title=${d.name}
                        ></ptc-checkbox-button>
                    `
                )}
                ${this.employees.map(
                    (e) => html`
                        <ptc-checkbox-button
                            class="box"
                            buttonClass="transparent"
                            .label=${html` <div class="centering horizontal layout">
                                <ptc-avatar class="tinier inline-block" .employee=${e}></ptc-avatar>
                                <div class="ellipsis" style="max-width: 10em;">${e.name}</div>
                            </div>`}
                            .checked=${live(true)}
                            @change=${() => this._deselectEmployee(e)}
                            title=${e.name}
                        ></ptc-checkbox-button>
                    `
                )}

                <div class="left icon input">
                    <i class="plus"></i>
                    <input
                        placeholder="hinzufügen..."
                        class="filter-input"
                        @input=${(e: Event) => (this._filterString = (e.target as HTMLInputElement).value)}
                    />
                    <ptc-popover trigger="focus" class="assignments-popover">
                        <div class="scroller">
                            ${!this.all && !this._filterString
                                ? html`
                                      <div class="margined">
                                          <ptc-checkbox-button
                                              class="box"
                                              buttonClass="transparent"
                                              .label=${html`<i class="globe"></i> Alle`}
                                              .checked=${live(false)}
                                              @change=${this._selectGlobal}
                                          ></ptc-checkbox-button>
                                      </div>
                                  `
                                : ""}

                            <div class="margined spacing vertical layout" ?hidden=${!availableVenues.length}>
                                <div class="sticky background padded blue colored-text">Standorte</div>

                                ${availableVenues.map(
                                    (v) => html`
                                        <ptc-checkbox-button
                                            class="box"
                                            buttonClass="transparent"
                                            .label=${html`<i class="building"></i> ${v.name}`}
                                            .checked=${live(false)}
                                            @change=${() => this._selectVenue(v)}
                                        ></ptc-checkbox-button>
                                    `
                                )}
                            </div>

                            <div class="margined spacing vertical layout" ?hidden=${!availableDepartments.length}>
                                <div class="sticky background padded blue colored-text">Abteilungen</div>
                                ${availableDepartments.map(
                                    (d) => html`
                                        <ptc-checkbox-button
                                            class="box"
                                            buttonClass="transparent"
                                            .label=${html`<i class="users-class"></i> ${d.name}`}
                                            .checked=${live(false)}
                                            style="--color-highlight: ${colors[d.color] || d.color}"
                                            @change=${() => this._selectDepartment(d)}
                                        ></ptc-checkbox-button>
                                    `
                                )}
                            </div>

                            <div class="margined spacing vertical layout" ?hidden=${!availableEmployees.length}>
                                <div class="sticky background padded blue colored-text">Mitarbeiter</div>

                                ${availableEmployees.slice(0, this._displayEmployeesLimit).map(
                                    (e) =>
                                        html` <ptc-checkbox-button
                                            class="box"
                                            buttonClass="transparent"
                                            .label=${html` <div class="center-alinging horizontal layout">
                                                <ptc-avatar class="tinier inline-block" .employee=${e}></ptc-avatar>
                                                <div>${e.name}</div>
                                            </div>`}
                                            .checked=${live(false)}
                                            @change=${() => this._selectEmployee(e)}
                                        ></ptc-checkbox-button>`
                                )}
                                ${availableEmployees.length > this._displayEmployeesLimit
                                    ? html`
                                          <button class="subtle" @click=${() => (this._displayEmployeesLimit += 100)}>
                                              Mehr Anzeigen...
                                          </button>
                                      `
                                    : ""}
                            </div>
                        </div>
                    </ptc-popover>
                </div>
            </div>
        `;
    }
}
