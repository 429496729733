import { css, html } from "lit";
import { state, customElement } from "lit/decorators.js";
import { Employee, JobApplication, JobPosting } from "@pentacode/core/src/model";
import { formatDate } from "@pentacode/core/src/util";
import { Dialog } from "./dialog";

@customElement("ptc-job-application-dialog")
export class JobApplicationDialog extends Dialog<
    { application: JobApplication; posting: JobPosting },
    { action: "approve" | "reject" }
> {
    @state()
    private _application: JobApplication;

    @state()
    private _posting: JobPosting;

    show({ application, posting }: { application: JobApplication; posting: JobPosting }) {
        this._application = application;
        this._posting = posting;
        return super.show();
    }

    private _approve() {
        this.done({ action: "approve" });
    }

    private _reject() {
        this.done({ action: "reject" });
    }

    private _sendMessage() {
        window.open(`mailto:${this._application.email}?subject=${encodeURIComponent(this._posting.title)}`);
    }

    static styles = [
        ...Dialog.styles,
        css`
            label {
                padding: 0;
                margin: 0.5em;
                color: var(--color-primary);
            }

            ptc-avatar {
                margin: 0.3em;
            }
        `,
    ];

    renderContent() {
        const {
            email,
            firstName,
            lastName,
            headShot,
            birthday,
            availableFrom,
            address,
            postalCode,
            city,
            phone,
            created,
            message,
        } = this._application;

        return html`
            <div class="vertical layout fit-vertically">
                <div class="padded horizontal layout">
                    <div class="padded stretch subtle">Bewerbung vom ${formatDate(created)}</div>
                    <button class="slim transparent" @click=${() => this.done()}><i class="times"></i></button>
                </div>
                <ptc-scroller class="stretch">
                    <div class="padded">
                        <div class="horizontal center-aligning spacing layout">
                            <ptc-avatar
                                class="enormous"
                                .employee=${new Employee({
                                    firstName,
                                    lastName,
                                    avatar: headShot,
                                })}
                            ></ptc-avatar>
                            <div class="stretch">
                                <div class="field">
                                    <label>Vorname</label>
                                    <div class="margined">${firstName}</div>
                                </div>
                                <div class="field">
                                    <label>Nachname</label>
                                    <div class="margined">${lastName}</div>
                                </div>
                            </div>
                        </div>
                        <div class="horizontal spacing evenly stretching layout">
                            <div class="field">
                                <label> Geburtstag </label>
                                <div class="margined">${birthday ? formatDate(birthday) : "Keine Angabe"}</div>
                            </div>
                            <div class="field">
                                <label> Verfügbar Ab </label>
                                <div class="margined">${availableFrom ? formatDate(availableFrom) : "sofort"}</div>
                            </div>
                        </div>
                        <div class="horizontal spacing evenly stretching layout">
                            <div class="field">
                                <label> Email </label>
                                <div class="margined">${email}</div>
                            </div>
                            <div class="field">
                                <label> Telefon </label>
                                <div class="margined">${phone || html`<span class="subtle">Keine Angabe</span>`}</div>
                            </div>
                        </div>
                        <div class="field">
                            <label> Adresse </label>
                            <div class="margined">${address}</div>
                        </div>
                        <div class="horizontal spacing evenly stretching layout">
                            <div class="field">
                                <label> Postleitzahl </label>
                                <div class="margined">${postalCode}</div>
                            </div>
                            <div class="field">
                                <label> Stadt </label>
                                <div class="margined">${city}</div>
                            </div>
                        </div>
                        <div class="field">
                            <label> Nachricht </label>
                            <div class="margined">
                                ${message
                                    ? html`<pre>${message}</pre>`
                                    : html`<span class="subtle"> Keine Nachricht hinterlassen.</span>`}
                            </div>
                        </div>
                    </div>
                </ptc-scroller>
                <div class="padded">
                    <div class="horizontal spacing evenly stretching layout">
                        <button class="ghost" @click=${this._sendMessage}>
                            <i class="envelope"></i> Nachricht Senden
                        </button>
                    </div>
                    <div class="horizontal top-margined spacing evenly stretching layout">
                        <button class="primary" @click=${this._approve}>
                            <i class="handshake-simple"></i> Einstellen
                        </button>
                        <button class="negative" @click=${this._reject}>
                            <i class="handshake-simple-slash"></i> Ablehnen
                        </button>
                    </div>
                </div>
            </div>
        `;
    }
}
