import { SetPasswordParams } from "@pentacode/core/src/api";
import { html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";

@customElement("ptc-change-password-dialog")
export class ChangePasswordDialog extends Dialog<void, void> {
    @query("input[name='repeatPassword']")
    private _repeatPasswordInput: HTMLInputElement;

    @query("form")
    private _form: HTMLFormElement;

    async show() {
        [...this.renderRoot.querySelectorAll("#changePasswordForm input")].forEach(
            (el: HTMLInputElement) => (el.value = "")
        );
        return super.show();
    }

    private _checkRepeatedPassword() {
        const formData = new FormData(this._form);
        this._repeatPasswordInput.setCustomValidity(
            formData.get("newPassword") === formData.get("repeatPassword")
                ? ""
                : "Sie haben das Passwort nicht richtig wiederholt. " + "Bitte versuchen Sie es erneut!"
        );
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        if (this.loading) {
            return;
        }

        this.loading = true;

        const formData = new FormData(this._form);
        const oldPassword = formData.get("oldPassword") as string;
        const password = formData.get("newPassword") as string;

        try {
            await app.api.setPassword(
                new SetPasswordParams({
                    id: app.account!.id,
                    password,
                    oldPassword,
                })
            );
            await app.fetchAccount();
            this.done();
            void alert("Passwort erfolgreich geändert!", { type: "success" });
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this._show();
        }
        this.loading = false;
    }

    renderContent() {
        return html`
            <form
                class="double-padded"
                id="changePasswordForm"
                @input=${this._checkRepeatedPassword}
                @submit=${this._submit}
                class="padded"
            >
                <h2>Passwort Ändern</h2>

                <div class="field">
                    <label>Altes Passwort</label>
                    <input type="password" name="oldPassword" required />
                </div>

                <div class="field">
                    <label>Neues Passwort</label>
                    <input type="password" name="newPassword" required />
                </div>

                <div class="field">
                    <label>Passwort Wiederholen</label>
                    <input type="password" name="repeatPassword" required />
                </div>

                <div class="top-padded horizontal spacing evenly stretching layout">
                    <button class="primary">Passwort Ändern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
