import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Department, RosterTargets, TimeEntry } from "@pentacode/core/src/model";
import { toDateString, getDateArray, parseDateString, toDurationString } from "@pentacode/core/src/util";
import { app, router } from "../init";
import { DateString } from "@pentacode/openapi";
import { getAutomaticBreak } from "@pentacode/core/src/time";

@customElement("ptc-roster-targets")
export class RosterTargetsElement extends LitElement {
    @property({ attribute: false })
    targets: RosterTargets[] = [];

    @property({ attribute: false })
    department: Department;

    @property({ attribute: false })
    entries: TimeEntry[] = [];

    @property({ type: Boolean })
    editable: boolean;

    @property({ attribute: false })
    from: DateString;

    @property({ attribute: false })
    to: DateString;

    createRenderRoot() {
        return this;
    }

    static styles = css`
        .department-targets {
            position: sticky;
            bottom: 0;
            z-index: 6;
            background: rgba(255, 255, 255, 0.95);
            border-top: solid 1px var(--shade-1);
        }

        .department-targets-header {
            position: sticky;
            background: var(--color-bg);
            z-index: 7;
            left: 0;
        }

        .department-targets button {
            padding: 0.3em 0.5em;
            margin: 0.4em;
        }

        .department-targets-day {
            padding: 0.3em 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .department-day-quota {
            background: #fafafa;
            border-radius: var(--border-radius);
            overflow: hidden;
            position: relative;
            text-align: center;
            font-size: 0.85em;
            width: 100px;
            height: 24px;
            line-height: 24px;
            font-weight: bold;
            color: var(--color-highlight);
            border: solid 2px;
        }

        .department-day-numbers {
            width: 100px;
            box-sizing: border-box;
        }

        .department-day-progress {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
        }

        .department-day-progress-bar {
            background: var(--color-highlight);
            color: var(--color-bg);
            text-shadow: rgba(0, 0, 0, 0.2) 0 1px 1px;
        }
    `;

    render() {
        if (!this.department) {
            return;
        }

        if (!this.targets.some((t) => t.departmentId === this.department.id)) {
            return this.editable
                ? html`
                      <div class="row department-targets">
                          <div class="department-targets-header horizontal center-justifying layout">
                              <button class="small subtle" @click=${() => router.go("planning/roster")}>
                                  <i class="tasks-alt"></i>
                                  Stundenvorgaben
                              </button>
                          </div>
                      </div>
                  `
                : "";
        }

        const today = toDateString(new Date());
        const dates = getDateArray(this.from, this.to);
        return html`
            <div class="row department-targets">
                <div class="department-targets-header horizontal center-justifying layout">
                    <button class="small subtle" @click=${() => router.go("planning/roster")} ?hidden=${!this.editable}>
                        <i class="pencil-alt"></i>
                        Stundenvorgaben
                    </button>
                </div>
                <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                    <div><i class="plus-minus"></i></div>
                </div>

                ${dates.map((date) => {
                    const weekDay = parseDateString(date)!.getDay();
                    const targets = this.targets.find(
                        (t) => t.weekDay === weekDay && t.departmentId === this.department.id
                    );

                    if (!targets || !targets.hours) {
                        return html` <div class="department-targets-day"></div> `;
                    }

                    const { hours: nominal } = targets;
                    let actual = 0;
                    const entries = this.entries.filter(
                        (e) =>
                            !e.deleted &&
                            e.date === date &&
                            e.position &&
                            e.position.departmentId === this.department.id
                    );
                    for (const entry of entries) {
                        actual +=
                            entry.durationPlanned -
                            getAutomaticBreak(entry.durationPlanned, app.getTimeSettings({ timeEntry: entry }));
                    }
                    const diff = (actual - nominal) / nominal;

                    return html`
                        <div class="department-targets-day ${date === today ? "today" : ""}">
                            ${targets
                                ? html`
                                      <div
                                          class="department-day-quota ${diff > 0 ? "red" : ""}"
                                          ?disabled=${date < today}
                                      >
                                          <div
                                              class="department-day-progress"
                                              style="width: ${Math.min(1, actual / nominal) * 100}%"
                                          >
                                              <div class="department-day-numbers department-day-progress-bar">
                                                  ${toDurationString(actual, true)} / ${toDurationString(nominal, true)}
                                              </div>
                                          </div>

                                          <div class="department-day-numbers">
                                              ${toDurationString(actual, true)} / ${toDurationString(nominal, true)}
                                          </div>
                                      </div>
                                  `
                                : ""}
                        </div>
                    `;
                })}
                <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                    <div><i class="plus-minus"></i></div>
                </div>
            </div>
        `;
    }
}
