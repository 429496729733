import { Absence, AbsenceStatus, TimeEntryType, timeEntryTypeColor } from "@pentacode/core/src/model";
import {
    getRange,
    getDateArray,
    dateAdd,
    parseDateString,
    toDateString,
    formatDate,
    formatWeekDayShort,
} from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty, RouteArgs } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app, router } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import { AbsenceDialog } from "./absence-dialog";

@customElement("ptc-employees-absences-single")
export class EmployeesAbsencesSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<id>\d+)\/absences/;

    @routeProperty({ arg: "id", type: Number })
    employeeId: number;

    get routeTitle() {
        return `Abwesenheiten: ${this._employee && this._employee.name}`;
    }

    async handleRoute(_args: RouteArgs, { display_absence, ...rest }: { [prop: string]: string }) {
        await this._load();
        if (display_absence) {
            const absence = this._absences.find((a) => a.id === Number(display_absence));
            if (absence) {
                void this._absenceDialog.show(absence);
            }
            router.params = rest;
        }
    }

    @state()
    private _loading = false;

    @state()
    private _absences: Absence[] = [];

    @state()
    private _displayPastAbsences = false;

    @singleton("ptc-absence-dialog")
    private _absenceDialog: AbsenceDialog;

    private get _employee() {
        return (this.employeeId && app.getEmployee(this.employeeId)) || null;
    }

    private async _load() {
        if (!this._employee) {
            return;
        }

        this._loading = true;
        this._displayPastAbsences = false;

        try {
            this._absences = await app.api.getAbsences({ employee: this.employeeId });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newAbsence(type: TimeEntryType) {
        const created = await this._absenceDialog.show(
            new Absence({
                employeeId: this.employeeId,
                type,
            })
        );
        if (created) {
            void this._load();
        }
    }

    private async _editAbsence(absence: Absence) {
        const edited = await this._absenceDialog.show(absence);
        if (edited) {
            void this._load();
        }
    }

    static styles = [
        shared,
        css`
            .absence {
                border: solid 1px var(--shade-2);
                border-radius: 1em;
                padding-top: 0.5em;
            }

            .days-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: 0.5em 0;
            }

            .day {
                text-align: center;
                height: 2em;
                box-sizing: border-box;
                position: relative;
            }

            .day.start,
            .day.middle,
            .day.end {
                border-top: solid 1px var(--color-highlight);
                border-bottom: solid 1px var(--color-highlight);
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            .day.start {
                border-left: solid 1px var(--color-highlight);
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
            }

            .day.end {
                border-right: solid 1px var(--color-highlight);
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
            }

            .absence.denied .day,
            .absence.requested .day {
                color: var(--color-highlight);
                background: transparent;
            }

            .absence.denied .day:not(.before):not(.after)::after {
                content: "";
                display: block;
                width: 110%;
                height: 1px;
                background: currentColor;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -5%;
            }

            .today {
                background: var(--color-highlight);
                color: var(--color-bg);
                border-radius: 100%;
                border: solid 1px;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                font-weight: bold;
            }
        `,
    ];

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }
        const today = toDateString(new Date());

        const absences = this._displayPastAbsences ? this._absences : this._absences.filter((a) => a.end > today);

        return html`
            <div class="fullbleed vertical layout">
                <div class="padded horizontal layout border-bottom">
                    <div class="stretch"></div>
                    <button class="slim transparent"><i class="plus"></i></button>
                    <ptc-popover class="larger tooltip" hide-on-click>
                        <div class="vertical spacing layout">
                            ${[
                                TimeEntryType.Vacation,
                                TimeEntryType.Sick,
                                TimeEntryType.ChildSick,
                                TimeEntryType.SickInKUG,
                            ].map(
                                (type) => html`
                                    <button
                                        class="slim ghost"
                                        @click=${() => this._newAbsence(type)}
                                        style="--color-highlight: ${timeEntryTypeColor(type)}"
                                    >
                                        <i class="${app.localized.timeEntryTypeIcon(type)}"></i>
                                        ${app.localized.timeEntryTypeLabel(type)}
                                    </button>
                                `
                            )}
                        </div>
                    </ptc-popover>
                </div>
                ${!absences.length
                    ? html`
                          <div class="stretch centering vertical layout">
                              <i class="giant faded light island-tropical"></i>
                              <div>Es liegen aktuell keine geplanten Abwesenheiten vor.</div>
                              <button
                                  class="subtle double-margined"
                                  @click=${() => (this._displayPastAbsences = true)}
                                  ?hidden=${this._displayPastAbsences}
                              >
                                  Vergangene Abwesenheiten Anzeigen
                              </button>
                          </div>
                      `
                    : html`
                          <ptc-scroller class="stretch">
                              <div class="centering double-margined layout" ?hidden=${this._displayPastAbsences}>
                                  <button
                                      class="subtle"
                                      @click=${() => (this._displayPastAbsences = true)}
                                      ?hidden=${this._displayPastAbsences}
                                  >
                                      Vergangene Abwesenheiten Anzeigen
                                  </button>
                              </div>
                              ${absences.map((absence) => {
                                  const { start, end } = absence;
                                  const inclusiveEnd = dateAdd(end, { days: -1 });
                                  const renderFrom = getRange(start, "week").from;
                                  const renderTo = getRange(inclusiveEnd, "week").to;
                                  const dates = getDateArray(renderFrom, renderTo);

                                  return html`
                                      <div
                                          class="double-padded double-margined absence click ${absence.status}"
                                          style="--color-highlight: ${timeEntryTypeColor(absence.type)}"
                                          @click=${() => this._editAbsence(absence)}
                                      >
                                          <div class="medium vertically-padded horizontal layout">
                                              <div class="stretch">
                                                  <strong class="colored-text">
                                                      <i class="${app.localized.timeEntryTypeIcon(absence.type)}"></i>
                                                      ${app.localized.timeEntryTypeLabel(absence.type)}:
                                                  </strong>
                                                  ${formatWeekDayShort(start)}, <strong>${formatDate(start)}</strong> -
                                                  ${formatWeekDayShort(inclusiveEnd)},
                                                  <strong>${formatDate(inclusiveEnd)}</strong>
                                              </div>
                                              <div class="smaller">
                                                  ${absence.status === AbsenceStatus.Requested
                                                      ? html` <div class="orange pill">beantragt</div> `
                                                      : absence.status === AbsenceStatus.Denied
                                                        ? html` <div class="red pill">abgelehnt</div> `
                                                        : html`
                                                              <div class="pill">
                                                                  <strong>${absence.dayCount}</strong>
                                                                  ${absence.dayCount === 1 ? "Fehltag" : "Fehltage"}
                                                              </div>
                                                          `}
                                              </div>
                                          </div>

                                          ${absence.request
                                              ? html`
                                                    <div class="padded vertically-margined text-centering orange box">
                                                        <strong>
                                                            <i class="comment-question"></i> Beantragt am
                                                            ${formatDate(absence.request.time)}
                                                        </strong>
                                                        ${absence.request.message
                                                            ? html` - <span>"${absence.request.message}"</span> `
                                                            : ""}
                                                    </div>
                                                `
                                              : ""}
                                          ${absence.response
                                              ? html`
                                                    ${absence.status === AbsenceStatus.Denied
                                                        ? html`
                                                              <div
                                                                  class="padded vertically-margined text-centering red box"
                                                              >
                                                                  <strong>
                                                                      <i class="thumbs-down"></i> Abgelehnt von
                                                                      ${absence.response.employeeName || "Unbekannt"} am
                                                                      ${formatDate(absence.response.time)}
                                                                  </strong>
                                                                  ${absence.response.message
                                                                      ? html`
                                                                            - <span>"${absence.response.message}"</span>
                                                                        `
                                                                      : ""}
                                                              </div>
                                                          `
                                                        : html`
                                                              <div
                                                                  class="padded vertically-margined text-centering green box"
                                                              >
                                                                  <strong>
                                                                      <i class="thumbs-up"></i> Genehmigt von
                                                                      ${absence.response.employeeName || "Unbekannt"} am
                                                                      ${formatDate(absence.response.time)}
                                                                  </strong>
                                                                  ${absence.response.message
                                                                      ? html`
                                                                            - <span>"${absence.response.message}"</span>
                                                                        `
                                                                      : ""}
                                                              </div>
                                                          `}
                                                `
                                              : ""}
                                          ${absence.notes
                                              ? html`
                                                    <div class="padded vertically-margined text-centering grey box">
                                                        <i class="comment"></i> ${absence.notes}
                                                    </div>
                                                `
                                              : ""}

                                          <div class="days-grid">
                                              <div class="text-centering bold">Mo</div>
                                              <div class="text-centering bold">Di</div>
                                              <div class="text-centering bold">Mi</div>
                                              <div class="text-centering bold">Do</div>
                                              <div class="text-centering bold">Fr</div>
                                              <div class="text-centering bold">Sa</div>
                                              <div class="text-centering bold">So</div>

                                              ${dates.map(
                                                  (date) => html`
                                                      <div
                                                          class="day centering layout ${classMap({
                                                              before: date < start,
                                                              start: date === start,
                                                              middle: date > start && date < inclusiveEnd,
                                                              end: date === inclusiveEnd,
                                                              after: date > inclusiveEnd,
                                                          })}"
                                                      >
                                                          <div class="${date === today ? "today" : ""}">
                                                              ${parseDateString(date)!.getDate()}
                                                          </div>
                                                      </div>
                                                  `
                                              )}
                                          </div>
                                      </div>
                                  `;
                              })}
                          </ptc-scroller>
                      `}
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
