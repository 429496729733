import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { VersionInfo } from "@pentacode/core/src/version";
import { html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Dialog } from "./dialog";
import "./scroller";

@customElement("ptc-update-installed-dialog")
export class UpdateInstalledDialog extends Dialog<Partial<VersionInfo>, boolean> {
    @property()
    private _versionInfo: VersionInfo;

    async show(info: VersionInfo) {
        this._versionInfo = info;
        return super.show();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                display: flex;
                flex-direction: column;
                max-height: 500px;
                padding: 0.8em 0.5em 0.5em 0.5em;
            }

            .title {
                font-size: var(--font-size-large);
                margin: 0.2em 0;
                font-weight: 600;
            }

            .subtitle {
                margin-bottom: 0.5em;
            }

            ptc-scroller {
                flex: 1;
            }

            .changelog {
                padding: 0 1em;
            }
        `,
    ];

    renderContent() {
        return html`
            <div class="horizontal layout header">
                <i class="arrow-alt-circle-up" style="font-size: 3rem;"></i>

                <div class="stretch">
                    <div class="title">Update Installiert</div>

                    <div class="subtitle">
                        Pentacode <strong>Version ${this._versionInfo.version}</strong> wurde erfolgreich installiert.
                    </div>
                </div>
            </div>

            <ptc-scroller>
                <div class="changelog">${unsafeHTML(this._versionInfo.changes)}</div>
            </ptc-scroller>

            <div class="top-padded stretching horizontal layout">
                <button class="transparent stretch" @click=${() => this.done(true)}>
                    Fortfahren <i class="arrow-right"></i>
                </button>
            </div>
        `;
    }
}
