import { html, TemplateResult } from "lit-html";

export function truncate(str: string, len: number, suffix: string | TemplateResult = "…") {
    return str.length > len ? html`${str.slice(0, len) + suffix}` : str;
}

export function formatFraction(num: number, maxDenominator = 5) {
    if (!num) {
        return 0;
    }

    const denominators: [number, number][] = [];
    for (let div = 1; div <= maxDenominator; div++) {
        denominators.push([div, (num * div) % 1]);
    }
    denominators.sort((a, b) => a[1] - b[1]);
    const denominator = denominators[0][0];
    let wholes = Math.floor(num);
    let numerator = Math.round((num % 1) * denominator);
    if (numerator === denominator) {
        wholes++;
        numerator = 0;
    }

    return numerator === 0 ? html`${wholes}` : html`${wholes ? `${wholes} ` : ""}${numerator}&frasl;${denominator}`;
}
