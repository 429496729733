import { html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { Dialog } from "./dialog";
import "./scroller";
import { toDateString } from "@pentacode/core/src/util";
import { DateInput } from "./date-input";
import { app } from "../init";
import { Employee, EmployeeStatus } from "@pentacode/core/src/model";
import { alert, confirm } from "./alert-dialog";
import { ErrorCode } from "@pentacode/core/src/error";
import { clone } from "@pentacode/core/src/encoding";

@customElement("ptc-retire-employee-dialog")
export class RetireEmployeeDialog extends Dialog<Employee, void> {
    readonly preventDismiss = true;

    @state()
    private _employee: Employee;

    @query("ptc-date-input[name='date']")
    private _dateInput: DateInput;

    async show(employee: Employee) {
        this._employee = employee;
        const promise = super.show();
        await this.updateComplete;
        this._dateInput.value = toDateString(new Date());
        return promise;
    }

    private async _submit(e?: FocusEvent, deleteDanglingEntries = false): Promise<void> {
        e?.preventDefault();

        const date = this._dateInput.value;

        if (!date) {
            return;
        }

        const retireImmediately = date <= toDateString(new Date());
        const existingContract = this._employee.getContractForDate(date);
        const contract = existingContract ? clone(existingContract) : undefined;

        if (this._employee.contracts.some((c) => c.start >= date)) {
            this.done();
            void alert(
                "Für diesen Mitarbeiter existieren ein oder mehrere Verträge mit einem Vertragsbeginn der nach dem angegeben Ausscheidungsdatum liegt. Bitte löschen Sie diese Verträge und wiederholen den Vorgang erneut.",
                { type: "warning", title: "Vorgang Nicht Möglich" }
            );
            return;
        }

        this.loading = true;

        try {
            if (contract) {
                contract.end = date;
                await app.updateEmployee(this._employee, {
                    contract,
                    deleteDanglingEntries,
                });
            }

            await app.updateEmployee(this._employee, {
                status: retireImmediately ? EmployeeStatus.Retired : this._employee.status,
                scheduledStatusChange: retireImmediately
                    ? null
                    : {
                          status: EmployeeStatus.Retired,
                          date,
                      },
            });
            this.done();
        } catch (e) {
            if (e.code === ErrorCode.CONFLICT) {
                const confirmed = await confirm(e.message, "Datensätze Löschen", "Abbrechen", {
                    type: "destructive",
                });

                if (confirmed) {
                    return await this._submit(undefined, true);
                }
            } else {
                void alert(e.message, { type: "warning" });
            }
        }

        this.loading = false;
    }

    static styles = [...Dialog.styles, DateInput.styles, css``];

    renderContent() {
        return html`
            <form @submit=${this._submit}>
                <div class="padded spacing vertical layout">
                    <div class="padded spacing horizontal layout">
                        <i class="person-to-door enormous"></i>
                        <div class="stretch">
                            <label>Mitarbeiter entlassen ab...</label>
                            <ptc-date-input name="date" required datePicker="popover"></ptc-date-input>
                        </div>
                    </div>

                    <div class="horizontal spacing evenly stretching layout">
                        <button class="primary">Entlassen</button>
                        <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                    </div>
                </div>
            </form>
        `;
    }
}
