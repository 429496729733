import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { shared } from "../styles";
import { Checkbox } from "./checkbox";
import { TimeEntry, TimeEntryType } from "@pentacode/core/src/model";
import { app } from "../init";
import { formatDate, formatDateShort, toTimeString } from "@pentacode/core/src/util";
import "./avatar";

@customElement("ptc-roster-changes")
export class RosterChanges extends LitElement {
    @property({ attribute: false })
    timeEntries: TimeEntry[] = [];

    @query("form")
    private _form: HTMLFormElement;

    @query("#allCheckbox")
    private _allCheckbox: HTMLFormElement;

    private get _selectedIds() {
        const formData = this._form && new FormData(this._form);
        const ids = (formData?.getAll("entries") || []) as string[];
        return ids;
    }

    private get _selectedEntries() {
        return this._selectedIds.map((id) => this.timeEntries.find((e) => e.id === id)!);
    }

    private _publish() {
        this.dispatchEvent(new CustomEvent("publish", { detail: { timeEntries: this._selectedEntries } }));
    }

    private _undo() {
        this.dispatchEvent(new CustomEvent("undo", { detail: { timeEntries: this._selectedEntries } }));
    }

    private _toggleAll() {
        const checkboxes = this.renderRoot.querySelectorAll("ptc-checkbox") as NodeListOf<Checkbox>;
        for (const checkbox of checkboxes) {
            checkbox.checked = this._allCheckbox.checked;
        }
        this.requestUpdate();
    }

    private _change() {
        this._allCheckbox.checked = this._selectedIds.length === this.timeEntries.length;
        this.requestUpdate();
    }

    private _toggleEntry(entry: TimeEntry) {
        const checkbox = this.renderRoot.querySelector(`ptc-checkbox[data-entry="${entry.id}"]`) as Checkbox;
        if (checkbox) {
            checkbox.checked = !checkbox.checked;
            this._change();
        }
    }

    updated(changes: Map<keyof typeof this, unknown>) {
        if (changes.has("timeEntries")) {
            this._allCheckbox.checked = true;
            this._toggleAll();
        }
    }

    static styles = [shared, Checkbox.styles];

    render() {
        const selectedCount = this._selectedIds.length;
        return html`
            <div class="center-aligning horizontal layout padded border-bottom">
                <div class="small centering horizontal layout" style="width: 2em; height: 2em;">
                    <ptc-checkbox @change=${this._toggleAll} id="allCheckbox"></ptc-checkbox>
                </div>
                <div class="padded stretch text-centering">
                    <strong>${selectedCount}</strong> von <strong>${this.timeEntries.length}</strong> Änderungen gewählt
                </div>
            </div>
            <ptc-scroller style="max-height: 70vh">
                <form
                    class="padded vertical spacing layout"
                    @submit=${(e: Event) => e.preventDefault()}
                    @change=${this._change}
                >
                    ${this.timeEntries.map((entry) => {
                        const employee = app.getEmployee(entry.employeeId);
                        const prevEmployee =
                            (entry.published?.employeeId && app.getEmployee(entry.published.employeeId)) || null;
                        const prevPosition =
                            (entry.published?.positionId && app.getPosition(entry.published.positionId)?.position) ||
                            null;
                        const changeType =
                            entry.deleted && !entry.published?.deleted
                                ? "deleted"
                                : !entry.published
                                  ? "added"
                                  : "edited";
                        const changes = changeType === "edited" ? entry.getChangedProps() : null;
                        return html`
                            <div
                                class="small start-aligning spacing horizontal layout change-row"
                                style="cursor: pointer"
                                @click=${() => this._toggleEntry(entry)}
                            >
                                <div class="centering horizontal layout" style="width: 2em; height: 2em;">
                                    <ptc-checkbox
                                        name="entries"
                                        .value=${entry.id}
                                        data-entry=${entry.id}
                                        @click=${(e: Event) => e.stopPropagation()}
                                    ></ptc-checkbox>
                                </div>
                                ${
                                    changes?.size && entry.published
                                        ? html`
                                              <div
                                                  class="padded box stretch"
                                                  style="--color-highlight: ${app.getTimeEntryColor(
                                                      entry
                                                  )}; padding-bottom: 0.3em;"
                                              >
                                                  <div class="smaller start-aligning spacing horizontal layout">
                                                      <div class="stretch">
                                                          <span
                                                              class="${changes.has("date")
                                                                  ? "bold line-through"
                                                                  : "subtle"}"
                                                          >
                                                              ${formatDate(entry.published.date)}
                                                          </span>
                                                          -
                                                          <span
                                                              class="${changes.has("positionId")
                                                                  ? "bold line-through"
                                                                  : "subtle"}"
                                                          >
                                                              ${prevPosition?.name}
                                                          </span>
                                                      </div>
                                                      <div class="center-aligning spacing horizontal layout">
                                                          <ptc-avatar
                                                              style="font-size: 0.5em"
                                                              .employee=${prevEmployee}
                                                          ></ptc-avatar>
                                                          <div
                                                              class="stretch ellipsis ${changes.has("employeeId")
                                                                  ? "bold line-through"
                                                                  : "subtle"}"
                                                              style="max-width: 10em"
                                                          >
                                                              ${prevEmployee?.name || "Nicht Zugewiesen"}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="larger">
                                                      ${entry.type === TimeEntryType.Work
                                                          ? html`
                                                                <span
                                                                    class="${changes?.has("startPlanned") &&
                                                                    !changes.has("date")
                                                                        ? "bold"
                                                                        : ""}"
                                                                >
                                                                    ${toTimeString(entry.published.startPlanned) ||
                                                                    "offen"}
                                                                </span>
                                                                -
                                                                <span
                                                                    class="${changes?.has("endPlanned") &&
                                                                    !changes.has("date")
                                                                        ? "bold"
                                                                        : ""}"
                                                                >
                                                                    ${toTimeString(entry.published.endPlanned) ||
                                                                    "offen"}
                                                                </span>
                                                            `
                                                          : html`
                                                                <i
                                                                    class="${app.localized.timeEntryTypeIcon(
                                                                        entry.type
                                                                    )}"
                                                                ></i>
                                                                ${app.localized.timeEntryTypeLabel(entry.type)}
                                                            `}
                                                  </div>
                                              </div>

                                              <i class="arrow-right" style="align-self: center"></i>
                                          `
                                        : ""
                                }

                                <div
                                    class="padded box stretch ${changeType === "deleted" ? "line-through" : ""}"
                                    style="--color-highlight: ${app.getTimeEntryColor(entry)}; padding-bottom: 0.3em;"
                                >
                                    <div class="smaller start-aligning spacing horizontal layout">
                                        <div class="stretch">
                                            <span class="${changes?.has("date") ? "bold" : ""}">
                                                ${formatDate(entry.date)}
                                            </span>
                                            -
                                            <span class="${changes?.has("positionId") ? "bold" : ""}">
                                                ${entry.position?.name}
                                            </span>
                                        </div>
                                        <div class="center-aligning spacing horizontal layout">
                                            <ptc-avatar style="font-size: 0.5em" .employee=${employee}></ptc-avatar>
                                            <div
                                                class="stretch ellipsis ${changes?.has("employeeId") ? "bold" : ""}"
                                                style="max-width: 10em"
                                            >
                                                ${employee?.name || "Nicht Zugewiesen"}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="larger horizontal end-aligning layout">
                                        <div class="stretch">
                                            ${
                                                entry.type === TimeEntryType.Work
                                                    ? html`
                                                          <span
                                                              class="${changes?.has("startPlanned") &&
                                                              !changes.has("date")
                                                                  ? "bold"
                                                                  : ""}"
                                                          >
                                                              ${toTimeString(entry.startPlanned) || "offen"}
                                                          </span>
                                                          -
                                                          <span
                                                              class="${changes?.has("endPlanned") &&
                                                              !changes.has("date")
                                                                  ? "bold"
                                                                  : ""}"
                                                          >
                                                              ${toTimeString(entry.endPlanned) || "offen"}
                                                          </span>
                                                      `
                                                    : html`
                                                          <i class="${app.localized.timeEntryTypeIcon(entry.type)}"></i>
                                                          ${app.localized.timeEntryTypeLabel(entry.type)}
                                                      `
                                            }
                                        </div>
                                        
                                        ${
                                            changeType === "deleted"
                                                ? html` <div
                                                      class="smaller red colored-text"
                                                      style="text-decoration: none; float: right;"
                                                  >
                                                      <i class="minus-circle"></i> ${formatDateShort(
                                                          entry.deleted!,
                                                          true
                                                      )}
                                                      ${toTimeString(entry.deleted!)}
                                                  </div>`
                                                : changeType === "added"
                                                  ? html` <div class="smaller green colored-text">
                                                        <i class="plus-circle"></i> ${formatDateShort(
                                                            entry.created,
                                                            true
                                                        )}
                                                        ${toTimeString(entry.created)}
                                                    </div>`
                                                  : html` <div class="smaller orange colored-text">
                                                        <i class="pen-circle"></i> ${formatDateShort(
                                                            entry.updated,
                                                            true
                                                        )}
                                                        ${toTimeString(entry.updated)}
                                                    </div>`
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `;
                    })}
                </form>
            </ptc-scroller>
            <div class="half-padded evenly stretching spacing horizontal layout border-top">
                <button class="transparent" @click=${this._undo} ?disabled=${!selectedCount}>
                    <i class="rotate-left"></i> Rückgängig Machen
                </button>
                <button class="transparent" @click=${this._publish} ?disabled=${!selectedCount}>
                    <i class="paper-plane"></i> Veröffentlichen
                </button>
            </div>
        `;
    }
}
