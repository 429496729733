import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { TimeLogDevice } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import { app } from "../init";
import { CreateOrUpdateTimeLogDeviceParams } from "@pentacode/core/src/api";
import { EntityFiltersEl } from "./entity-filters";
import { alert } from "./alert-dialog";
import { Checkbox } from "./checkbox";
import "./help";

@customElement("ptc-time-log-device-dialog")
export class TimeLogDeviceDialog extends Dialog<TimeLogDevice, TimeLogDevice> {
    @state()
    private _device: TimeLogDevice;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='description']")
    private _descriptionInput: HTMLInputElement;

    @query("ptc-checkbox-button[name='displaySchedule']")
    private _displayScheduleCheckbox: Checkbox;

    @query("ptc-checkbox-button[name='displayUpcoming']")
    private _displayUpcomingCheckbox: Checkbox;

    @query("ptc-checkbox-button[name='displayActive']")
    private _displayActiveCheckbox: Checkbox;

    @query("ptc-entity-filters")
    private _filtersInput: EntityFiltersEl;

    async show(device: TimeLogDevice) {
        this._device = device;
        const promise = super.show();

        await this.updateComplete;

        this._descriptionInput.value = device.description || "";
        this._filtersInput.filters = device.filters || [];
        this._displayActiveCheckbox.checked = device.displayActive;
        this._displayUpcomingCheckbox.checked = device.displayUpcoming;
        this._displayScheduleCheckbox.checked = device.displaySchedule;

        if (!this._descriptionInput.value) {
            this._descriptionInput.focus();
        }

        return promise;
    }

    private async _submit(e: Event) {
        e.preventDefault();

        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);

        const description = data.get("description") as string;
        const filters = this._filtersInput.filters;
        const displaySchedule = data.has("displaySchedule");
        const displayUpcoming = data.has("displayUpcoming");
        const displayActive = data.has("displayActive");

        this.loading = true;

        try {
            const device = await app.api.createOrUpdateTimeLogDevice(
                new CreateOrUpdateTimeLogDeviceParams({
                    id: this._device.id,
                    description,
                    filters,
                    displaySchedule,
                    displayUpcoming,
                    displayActive,
                })
            );

            this.done(device);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    static styles = [...Dialog.styles, Checkbox.styles, css``];

    renderContent() {
        return html`
            <form @submit=${this._submit} autocomplete="off" class="double-padded spacing vertical layout">
                <div class="small margined subtle blue colored-text">
                    ${this._device.id
                        ? html`<i class="pencil-alt"></i> Stempeluhr Bearbeiten`
                        : html`<i class="plus"></i> Neue Stempeluhr`}
                </div>

                <input name="description" required placeholder="Bezeichnung" class="fill-horizontally" />

                <div class="field" ?disabled=${this._device.legacy}>
                    <label>Arbeitsbereiche</label>
                    <ptc-entity-filters
                        class="focus-container"
                        .filterTypes=${["venue", "department", "position"] as const}
                    ></ptc-entity-filters>
                </div>

                <div class="field" ?disabled=${this._device.legacy}>
                    <label>
                        Funktionen

                        <ptc-help-anchor page="handbuch/zeiterfassung/#funktionen">
                            <i class="faded question-circle"></i>
                        </ptc-help-anchor>
                    </label>
                    <div class="vertical spacing layout">
                        <ptc-checkbox-button buttonClass="slim ghost" label="Dienstplan" name="displaySchedule">
                        </ptc-checkbox-button>
                        <ptc-checkbox-button
                            buttonClass="slim ghost"
                            label="Ausstehende Schichten"
                            name="displayUpcoming"
                        ></ptc-checkbox-button>
                        <ptc-checkbox-button
                            buttonClass="slim ghost"
                            label="Aktive Schichten"
                            name="displayActive"
                        ></ptc-checkbox-button>
                    </div>
                </div>

                <div class="horizontal top-padded spacing evenly stretching layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
