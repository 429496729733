import { html, css } from "lit";
import { customElement, property, query, queryAll } from "lit/decorators.js";
import {
    RevenueGroup,
    RevenueEntry,
    RevenueType,
    TaxKey,
    taxKeyLabel,
    taxKeyValue,
    revenueTypeIcon,
} from "@pentacode/core/src/model";
import { formatDate, formatNumber } from "@pentacode/core/src/util";
import { Dialog } from "./dialog";
import "./popover";
import "./revenues-autocomplete-input";
import { Euros } from "@pentacode/openapi";

interface Input {
    entry: RevenueEntry;
    templates: RevenueGroup[];
}

@customElement("ptc-revenues-split-dialog")
export class RevenuesSplitDialog extends Dialog<Input, RevenueEntry[]> {
    readonly preventDismiss = true;

    @property()
    private _originalEntry: RevenueEntry;

    @property()
    private _entries: RevenueEntry[] = [];

    @property()
    private _templates: RevenueGroup[] = [];

    @query("#sumInput")
    private _sumInput: HTMLInputElement;

    @query("#receiptInput")
    private _receiptInput: HTMLInputElement;

    @queryAll("form")
    private _forms: NodeListOf<HTMLFormElement>;

    private get _sum() {
        return Math.round(100 * this._entries.reduce((total, entry) => total + entry.amount, 0)) / 100;
    }

    async show({ entry, templates }: Input) {
        this._originalEntry = entry;
        this._entries = [];
        this._addEntry();
        this._addEntry();
        this._templates = templates;
        return super.show();
    }

    private async _templateSelected(entry: RevenueEntry, { detail: group }: CustomEvent<RevenueGroup>) {
        const { name, type, ledger, costCenter, taxKey } = group;
        Object.assign(entry, { name, type, ledger, costCenter, taxKey, group: group.id ? group : null });
        this.requestUpdate();
    }

    private _updateEntry(entry: RevenueEntry, e: Event) {
        const input = e.target as HTMLInputElement;
        const data = new FormData(input.form!);
        entry.name = data.get("name") as string;
        entry.ledger = data.get("ledger") as string;
        entry.costCenter = data.get("costCenter") as string;
        const taxKey = Number(data.get("taxKey") as string);
        entry.taxKey = taxKey in TaxKey ? taxKey : TaxKey.None;
        const amountNet = Number(data.get("amountNet"));
        const tax = taxKeyValue(entry.taxKey, entry.date) / 100;
        const amount =
            input.name === "amountNet" && !isNaN(amountNet)
                ? Math.round(amountNet * (1 + tax) * 100) / 100
                : Number(data.get("amount"));

        entry.amount = (isNaN(amount) ? 0 : entry.type === RevenueType.Expense ? -amount : amount) as Euros;

        this.requestUpdate();
    }

    private _addEntry(amount: Euros = 0 as Euros) {
        const { type, date, venueId, daily } = this._originalEntry;
        const entry = new RevenueEntry({
            type,
            date,
            amount,
            daily,
            venueId,
        });
        this._entries.push(entry);
        this.requestUpdate();
    }

    private _submit(e: Event) {
        e.preventDefault();

        this._sumInput.setCustomValidity(
            this._sum !== this._originalEntry.amount
                ? "Der Gesamtbetrag muss mit dem ursprünglichen Betrag übereinstimmen!"
                : ""
        );

        for (const form of this._forms) {
            if (!form.reportValidity()) {
                return;
            }
        }

        for (const entry of this._entries) {
            entry.receipt = this._receiptInput.value;
        }

        this.done(this._entries);
    }

    static styles = [
        ...Dialog.styles,
        css`
            :host {
                --dialog-max-width: 50em;
            }

            h1 {
                text-align: center;
                margin: 0.5em;
            }

            .original-name {
                font-size: var(--font-size-large);
                font-weight: 600;
                margin: 0.5em 2em;
                text-align: center;
            }

            .original-fields {
                margin: 0.5em auto;
                width: 35em;
                text-align: center;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 1em;
            }

            .original-fields .field {
                padding: 0.5m;
            }

            .original-fields .field .label {
                font-weight: bold;
                margin: 0.2em;
            }

            .original-fields .field .value {
                font-size: var(--font-size-medium);
                margin-top: 0.5em;
            }

            .entries {
                margin: 1em;
            }

            .row {
                display: grid;
                grid-template-columns: 1fr repeat(5, 6.5em);
            }

            .row.header-row > * {
                font-weight: bold;
                text-align: center;
                padding: 0.5em;
                font-size: var(--font-size-small);
            }

            .row.entry-row {
                border-top: dashed 1px var(--shade-3);
            }

            .row > * {
                min-width: 0;
                position: relative;
            }

            .row.entry-row > :not(:last-child) {
                border-right: dashed 1px var(--shade-2);
            }

            .amount-gross {
                font-weight: bold;
            }

            .row.sum-row {
                border-top: solid 2px var(--shade-2);
            }

            input[readonly] {
                pointer-events: none;
            }

            .row input,
            .row select {
                width: 100%;
                border: none;
                border-radius: 0;
            }

            .row input:hover,
            .row select:hover {
                background: var(--shade-1);
            }

            .row input:focus,
            .row select:focus {
                background: var(--blue-bg);
            }

            input[type="number"] {
                text-align: right;
            }

            input[readonly],
            ptc-date-input[readonly] {
                border: none;
            }

            .name-input {
                --suggestions-size: var(--font-size-tiny);
                --suggestions-max-height: 12em;
            }

            #sumInput {
                pointer-events: none;
            }
        `,
    ];

    private _renderEntry(entry: RevenueEntry) {
        const taxPercent = taxKeyValue(entry.taxKey, entry.date);

        return html`
            <form
                class="row entry-row"
                autocomplete="off"
                @change=${(e: Event) => this._updateEntry(entry, e)}
                @submit=${this._submit}
            >
                <div class="name">
                    <ptc-revenues-autocomplete-input
                        class="input stretch name-input"
                        .templates=${this._templates}
                        @template-selected=${(e: CustomEvent<RevenueGroup>) => this._templateSelected(entry, e)}
                    >
                        <input type="string" name="name" required .value=${entry.name} />
                    </ptc-revenues-autocomplete-input>
                </div>

                <div class="ledger">
                    <input type="text" name="ledger" .value=${entry.ledger} />
                </div>

                <div class="costCenter">
                    <input type="text" name="costCenter" .value=${entry.costCenter} />
                </div>

                <div class="taxKey">
                    <select name="taxKey" class="plain" required>
                        ${Object.values(TaxKey)
                            .filter((val) => typeof val === "number")
                            .map(
                                (key: TaxKey) => html`
                                    <option .value=${key} ?selected=${key === entry.taxKey}>
                                        ${taxKeyLabel(key, this._originalEntry.date)}
                                    </option>
                                `
                            )}
                    </select>
                </div>

                <div class="amount-net">
                    <div class="right icon input">
                        <input
                            type="number"
                            step="0.01"
                            min="0"
                            name="amountNet"
                            .value=${Math.abs(entry.amount / (1 + taxPercent / 100)).toFixed(2)}
                            required
                        />
                        <i class="euro-sign"></i>
                    </div>
                </div>

                <div class="amount-gross">
                    <div class="right icon input">
                        <input
                            type="number"
                            step="0.01"
                            min="0"
                            name="amount"
                            .value=${Math.abs(entry.amount).toFixed(2)}
                            required
                        />
                        <i class="euro-sign"></i>
                    </div>
                </div>
            </form>
        `;
    }

    renderContent() {
        const original = this._originalEntry;
        const sum = this._sum;

        return html`
            <h1>Splitbuchung</h1>

            <div class="original-name">
                <i class="${revenueTypeIcon(original.type)}"></i>
                ${original.name}
            </div>

            <div class="original-fields">
                <div class="field">
                    <div class="label">Datum</div>
                    <div class="value">${formatDate(original.date)}</div>
                </div>

                <div class="field">
                    <div class="label">Betrag</div>
                    <div class="value">${formatNumber(Math.abs(original.amount))} €</div>
                </div>

                <div class="field">
                    <div class="label">Belegnummer</div>
                    <input type="text" .value=${original.receipt} id="receiptInput" />
                </div>
            </div>

            <div class="vertical center-aligning layout">
                <i class="arrow-down"></i>
            </div>

            <div class="entries">
                <div class="row header-row">
                    <div class="name">Text</div>
                    <div class="ledger">Konto</div>
                    <div class="costCenter">KoSt.</div>
                    <div class="tax">Steuer</div>
                    <div class="amount-net">Betr. Netto</div>
                    <div class="amount-gross">Betr. Brutto</div>
                </div>

                ${this._entries.map((entry) => this._renderEntry(entry))}

                <form class="row sum-row" @submit=${this._submit}>
                    <div class="name">
                        <button type="button" class="small borderless subtle" @click=${() => this._addEntry()}>
                            <i class="plus"></i>
                            Posten Hinzufügen
                        </button>
                    </div>
                    <div class="ledger"></div>
                    <div class="costCenter">.</div>
                    <div class="tax"></div>
                    <div class="amount-net"></div>
                    <div class="amount-gross">
                        <div class="right icon input ${sum !== original.amount ? "negative" : "positive"}">
                            <input
                                type="number"
                                name="sum"
                                step="0.01"
                                .value=${Math.abs(sum).toFixed(2)}
                                id="sumInput"
                                tabindex="-1"
                            />
                            <i class="euro-sign"></i>
                        </div>
                    </div>
                </form>
            </div>

            <div class="horizontal layout padded-medium pad-children">
                <div class="stretch"></div>
                <button class="primary" @click=${this._submit}>Speichern</button>

                <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
            </div>
        `;
    }
}
