import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { Scroller } from "./scroller";
import { formatDate, formatNumber, toDateString, toDurationString } from "@pentacode/core/src/util";
import { compareContracts, ContractChange, getContractChanges } from "@pentacode/core/src/contract";
import "./contract-change";
import { Contract, employmentTypeLabel, NominalHoursMode } from "@pentacode/core/src/model";
import { alert, confirm } from "./alert-dialog";
import { ContractForm } from "./contract-form";
import { clone } from "@pentacode/core/src/encoding";
import { Dialog } from "./dialog";
import { ErrorCode } from "@pentacode/core/src/error";
import { UpdateEmployeeParams } from "@pentacode/core/src/api";
import { cache } from "lit/directives/cache.js";
import { DateInput } from "./date-input";
import { Checkbox } from "./checkbox";
import "./popover";
import { Hours, Days, Euros, DateString } from "@pentacode/openapi";

@customElement("ptc-employees-contracts-single")
export class EmployeesContractsSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<employeeId>\d+)\/contracts(?:\/(?<page>[^/]+)(?:\/(?<contractId>\d+))?)?/;

    @routeProperty({ arg: "employeeId", type: Number })
    employeeId: number;

    @routeProperty({ arg: "page" })
    page: "view" | "edit" | "new" | "changes";

    @routeProperty({ arg: "contractId", type: Number })
    contractId: number;

    get routeTitle() {
        return `Verträge: ${this._employee && this._employee.name}`;
    }

    @state()
    private _loading = false;

    @state()
    private _newContract: Contract | null = null;

    @query("ptc-contract-form#editContractForm")
    private _editContractForm: ContractForm;

    @query("ptc-contract-form#viewContractForm")
    private _viewContractForm: ContractForm;

    @query("#interruptContractDialog")
    private _interruptContractDialog: Dialog<void, void>;

    @query("#interruptContractDialog input[name='end']")
    private _interruptContractEndInput: HTMLInputElement;

    @query("#changesScroller")
    private _changesScroller: Scroller;

    private get _employee() {
        return (this.employeeId && app.getEmployee(this.employeeId)) || null;
    }

    async handleRoute() {
        if (!this.page) {
            this._goTo("view", undefined, true);
            return;
        }

        if (this.page === "view" && !this._selectedContract && this._employee?.currentContract) {
            this._goTo(`view/${this._employee.currentContract.id}`, undefined, true);
            return;
        }

        if (this.page === "new" && !this._newContract) {
            this._openNewContract();
        }

        setTimeout(() => {
            if (this.page === "view") {
                this._viewContractForm?.openAtSection("general");
            } else if (this.page === "edit" || this.page === "new") {
                this._editContractForm?.openAtSection("general");
            } else if (this.page === "changes") {
                this._changesScroller.scrollTop = this._changesScroller.scrollHeight;
            }
        }, 100);
    }

    private _goTo(path: string, params?: any, replace = false) {
        if (!this._employee) {
            return;
        }
        this.go(`employees/${this._employee.id}/contracts/${path}`, params, replace);
    }

    private get _selectedContract() {
        return this._employee?.contracts.find((c) => c.id === this.contractId);
    }

    private async _update(data: Partial<UpdateEmployeeParams>, rethrowErrors = false, refresh = true) {
        this._loading = true;
        try {
            await app.updateEmployee(this._employee!, data, refresh);
            this._loading = false;
        } catch (e) {
            this._loading = false;
            if (rethrowErrors) {
                throw e;
            } else {
                void alert(e.message, { type: "warning" });
            }
        }
    }

    private async _editContract() {
        const choice = await confirm(
            "Sind Sie sicher dass Sie diesen bestehenden Vertrag bearbeiten möchten? " +
                "Beachten Sie dass das Bearbeiten von bestehenden Vertägen sich rückwirkend auf die " +
                "Berechnung von Stundenbilanzen, Urlaubsbilanzen und Personalkosten auswirkt. " +
                "Bei regulären Vertragsänderungen wird deshalb das Erstellen eines neuen Vertrages empfohlen.",
            "Fortfahren",
            "Abbrechen",
            {
                title: "Vertragsänderung",
                icon: "file-contract",
                optionsLayout: "horizontal",
            }
        );

        if (choice) {
            this._goTo(`edit/${this._selectedContract!.id}`);
        }
    }

    private _openContract(contract: Contract) {
        this._goTo(`view/${contract.id}`);
    }

    private _openNewContract(contract = this._selectedContract || this._employee!.latestContract || new Contract()) {
        contract = clone(contract);
        // @ts-expect-error deleting non optional property
        delete contract.id;
        // @ts-expect-error deleting non optional property
        delete contract.ignoreIssues;
        for (const salary of contract.salaries || []) {
            // @ts-expect-error deleting non optional property
            delete salary.id;
        }
        for (const bonus of contract.bonuses || []) {
            // @ts-expect-error deleting non optional property
            delete bonus.id;
        }
        for (const benefit of contract.benefits || []) {
            // @ts-expect-error deleting non optional property
            delete benefit.id;
        }
        contract.start = toDateString(new Date());
        contract.end = null;
        this._newContract = contract;
    }

    private async _deleteContract(contract: Contract, e?: MouseEvent) {
        e && e.stopPropagation();

        if (
            !(await confirm(
                "Sind Sie sicher dass Sie diesen Vertrag löschen möchten? Diese Änderung kann nicht rückgängig gemacht werden!",
                "Löschen",
                "Abbrechen",
                {
                    title: "Vertrag Löschen",
                    type: "destructive",
                }
            ))
        ) {
            return;
        }

        try {
            await this._update({ contract, deleteContract: true }, true);
        } catch (e) {
            if (e.code === ErrorCode.CONFLICT) {
                const confirmed = await confirm(e.message, "Datensätze Löschen", "Abbrechen", {
                    type: "destructive",
                    icon: "exclamation-triangle",
                    title: "Datensätze Löschen",
                    optionsLayout: "horizontal",
                });

                if (confirmed) {
                    await this._update({ contract, deleteContract: true, deleteDanglingEntries: true }, true);
                }
            } else {
                void alert(e.message, { type: "warning" });
            }
        }
    }

    private async _interruptContract() {
        return this._interruptContractDialog.show();
    }

    private async _submitInterruptContract(e: Event) {
        e.preventDefault();

        this._interruptContractDialog.dismiss();
        const data = new FormData(e.target as HTMLFormElement);

        const inter = clone(this._selectedContract)!;

        // @ts-expect-error deleting non optional property
        delete inter.id;
        inter.start = data.get("start") as DateString;
        inter.inclusiveEnd = data.get("end") as DateString;
        inter.hoursPerWeek = 0 as Hours;
        inter.hoursPerDay = [0, 0, 0, 0, 0, 0, 0] as Hours[];
        inter.comment = data.get("comment") as string;
        inter.salaries = [];
        inter.ignoreIssues = [];
        inter.bonuses = [];
        inter.benefits = [];
        inter.blocked = true;

        if (!data.has("vacation")) {
            inter.vacationDays = 0 as Days;
        }

        if (data.has("cutbonus")) {
            inter.sfnAdvance = 0 as Euros;
        }

        const newContract = clone(this._selectedContract)!;
        // @ts-expect-error deleting non optional property
        delete newContract.ignoreIssues;
        // @ts-expect-error deleting non optional property
        delete newContract.id;
        for (const salary of newContract.salaries) {
            // @ts-expect-error deleting non optional property
            delete salary.id;
        }
        for (const bonus of newContract.bonuses || []) {
            // @ts-expect-error deleting non optional property
            delete bonus.id;
        }
        for (const benefit of newContract.benefits || []) {
            // @ts-expect-error deleting non optional property
            delete benefit.id;
        }
        newContract.start = inter.end!;

        try {
            await this._update({ contract: inter }, true, false);
            await this._update({ contract: newContract }, true);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
    }

    private async _submitContract() {
        if (!this._editContractForm.reportValidity()) {
            return;
        }

        const updated = new Contract(this._editContractForm.data);
        let change: ContractChange | undefined = undefined;

        if (this._newContract) {
            let latestContract = this._employee?.latestContract;
            if (latestContract) {
                latestContract = clone(latestContract);
                latestContract.end = updated.start;
            }
            change = latestContract && compareContracts(app.company!, latestContract, updated);
        } else if (this._selectedContract) {
            const currentContract = clone(this._selectedContract);
            currentContract.end = updated.start;
            change = compareContracts(app.company!, currentContract, updated);
        }

        const confirmed =
            !change ||
            !change.fields.length ||
            (await confirm(
                html`
                    <div class="horizontal start-aligning layout">
                        <i class="enormous file-pen"></i>
                        <div class="stretch horizontally-margined">
                            <div class="large semibold">Vertragsänderung</div>
                            <div class="vertically-margined">
                                Sind Sie sicher, dass Sie die folgenden Änderungen vornehmen möchten?
                            </div>
                            ${updated.start < toDateString(new Date())
                                ? html`
                                      <div class="vertically-margined orange padded box">
                                          <strong><i class="exclamation-triangle"></i> Achtung:</strong> Diese
                                          Vertragsänderungen
                                          <strong>gelten rückwirkend ab dem ${formatDate(updated.start)}</strong>. Alle
                                          Lohnabrechnungen sowie die Urlaubs- und Arbeitszeitkonten dieses Mitarbeiters
                                          werden ab diesem Datum neu berechnet!
                                      </div>
                                  `
                                : html`
                                      <div class="vertically-margined">
                                          Diese Vertragsänderungen gelten
                                          <strong>ab dem ${formatDate(updated.start)}</strong>.
                                      </div>
                                  `}
                        </div>
                    </div>
                    <ptc-scroller style="max-height: 60vh;">
                        <ptc-contract-change .change=${change}></ptc-contract-change>
                    </ptc-scroller>
                `,
                "Bestätigen",
                "Abbrechen",
                {
                    hideIcon: true,
                    maxWidth: "40em",
                    optionsLayout: "horizontal",
                }
            ));

        if (!confirmed) {
            return;
        }

        try {
            await this._update({ contract: Object.assign(new Contract(), this._editContractForm.data) }, true);
            this._newContract = null;
            this._goTo(`view/${this._selectedContract?.id || ""}`);
        } catch (e) {
            if (e.code === ErrorCode.CONFLICT) {
                const confirmed = await confirm(e.message, "Datensätze Löschen", "Abbrechen", {
                    type: "destructive",
                    icon: "exclamation-triangle",
                    title: "Datensätze Löschen",
                    optionsLayout: "horizontal",
                });

                if (confirmed) {
                    await this._update({
                        contract: Object.assign(new Contract(), this._editContractForm.data),
                        deleteDanglingEntries: true,
                    });
                    this._newContract = null;
                    this._goTo(`view/${this._selectedContract?.id || ""}`);
                }
            } else {
                void alert(e.message, { type: "warning" });
            }
        }
    }

    private async _cancelEditContract() {
        this._newContract = null;
        this._openContract(this._selectedContract || this._employee!.latestContract);
    }

    static styles = [
        shared,
        DateInput.styles,
        Checkbox.styles,
        css`
            .sub-menu {
                padding: 0;
                width: 20em;
            }

            .interrupt-contract-dialog form {
                padding: 0.7em;
            }

            .interrupt-contract-dialog h1 {
                text-align: center;
                margin-bottom: 0;
                margin-top: 0.3em;
            }

            .interrupt-contract-dialog button:not(:last-child) {
                margin-right: 0.7em;
            }

            @media print {
                :host,
                .fullbleed {
                    display: block;
                    position: static !important;
                }
            }
        `,
    ];

    private _renderEditContract(contract: Contract) {
        return html`
            <div class="fullbleed vertical layout">
                <ptc-scroller class="stretch">
                    <ptc-contract-form
                        id="editContractForm"
                        .employee=${this._employee}
                        .contract=${contract}
                        @submit=${this._submitContract}
                        @cancel=${this._cancelEditContract}
                    ></ptc-contract-form>
                </ptc-scroller>

                <div class="padded spacing evenly stretching horizontal layout">
                    <button class="primary" @click=${this._submitContract}>Speichern</button>
                    <button type="button" class="transparent" @click=${this._cancelEditContract}>Abbrechen</button>
                </div>
            </div>
        `;
    }

    private _renderViewContract(contract: Contract) {
        return html`
            <div class="fullbleed vertical layout">
                <div class="padded center-aligning horizontal layout border-bottom">
                    <div class="stretch"></div>

                    <button title="Vertrag Bearbeiten" class="slim transparent" ?hidden=${!this._selectedContract}>
                        <i class="pencil-alt"></i>
                    </button>

                    <ptc-popover class="popover-menu" hide-on-click style="width: 14em">
                        <button @click=${this._editContract}>
                            <i class="edit"></i>
                            Vertrag Bearbeiten
                        </button>
                        <button @click=${() => this._goTo("new")}>
                            <i class="plus"></i>
                            Neuer Vertrag
                        </button>
                        <button @click=${this._interruptContract}>
                            <i class="pause-circle"></i>Vertragsunterbrechung
                        </button>
                        <button @click=${() => this._deleteContract(contract)}>
                            <i class="trash"></i>Vertrag Löschen
                        </button>
                    </ptc-popover>
                </div>
                <ptc-scroller class="stretch">
                    <ptc-contract-form
                        id="viewContractForm"
                        .employee=${this._employee}
                        .contract=${contract}
                        @submit=${this._submitContract}
                        @cancel=${this._cancelEditContract}
                        readonly
                    ></ptc-contract-form>
                </ptc-scroller>
            </div>
        `;
    }

    private _renderContactChanges() {
        const changes = getContractChanges(app.company!, this._employee!);

        return changes.length
            ? html`
                  <div>
                      ${changes.map((change) => html` <ptc-contract-change .change=${change}></ptc-contract-change> `)}

                      <div style="height: 10em;"></div>
                  </div>
              `
            : html`
                  <div class="fullbleed faded double-padded centering vertical layout">
                      <i class="giant list-timeline"></i>
                      <div>Es liegen keine Vertragsänderungen für diesen Mitarbeiter vor.</div>
                  </div>
              `;
    }

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }

        const currentContract = emp.currentContract;

        return html`
            <div class="fullbleed horizontal layout noprint" ?hidden=${!["view", "edit", "new"].includes(this.page)}>
                <div class="sub-menu vertical layout">
                    <div class="padded transparent center-aligning horizontal layout border-bottom">
                        <button
                            class="padded transparent ${this.page === "new" ? "bold blue colored-text" : ""}"
                            @click=${() => this._goTo("new")}
                        >
                            <i class="plus"></i> Neuer Vertrag
                        </button>
                        <div class="stretch"></div>

                        ${app.settings.contractsSortDirection === "ascending"
                            ? html`
                                  <button
                                      class="padded transparent"
                                      @click=${() => app.updateSettings({ contractsSortDirection: "descending" })}
                                      title="Aufsteigend Sortieren (Klicken zum Ändern)"
                                  >
                                      <i class="arrow-down-short-wide"></i>
                                  </button>
                              `
                            : html`
                                  <button
                                      class="padded transparent"
                                      @click=${() => app.updateSettings({ contractsSortDirection: "ascending" })}
                                      title="Absteigend sortieren (Klicken zum Ändern)"
                                  >
                                      <i class="arrow-down-wide-short"></i>
                                  </button>
                              `}
                    </div>
                    <ptc-scroller class="stretch">
                        ${emp.contracts
                            .sort(
                                app.settings.contractsSortDirection === "ascending"
                                    ? (a, b) => (a.start < b.start ? -1 : 1)
                                    : (a, b) => (a.start > b.start ? -1 : 1)
                            )
                            .map(
                                ({
                                    start,
                                    inclusiveEnd,
                                    id,
                                    employmentType,
                                    defaultSalary,
                                    hoursPerWeek,
                                    vacationDays,
                                    hoursPerDay,
                                    nominalHoursMode,
                                }) => html`
                                    <div
                                        class="double-padded border-bottom click ${this.contractId === id
                                            ? "bold blue colored-text"
                                            : ""}"
                                        @click=${() => this._goTo(`view/${id}`)}
                                    >
                                        <div class="bottom-margined horizontal layout">
                                            <div class="stretch">
                                                ${formatDate(start)} -
                                                ${inclusiveEnd ? formatDate(inclusiveEnd) : "offen"}
                                            </div>
                                            ${id === currentContract?.id
                                                ? html` <div class="small orange colored-text">aktiv</div> `
                                                : ""}
                                        </div>
                                        <div class="tiny pills">
                                            <div class="slim pill">
                                                <i class="file-contract"></i> ${employmentTypeLabel(employmentType)}
                                            </div>

                                            <div class="slim pill">
                                                <i class="hourglass"></i>
                                                ${[
                                                    NominalHoursMode.FixedDays,
                                                    NominalHoursMode.FixedDaysWithoutHolidays,
                                                ].includes(nominalHoursMode)
                                                    ? hoursPerDay?.map((h, i) =>
                                                          !h
                                                              ? ""
                                                              : html`${i ? "," : ""}
                                                                    ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"][i]}
                                                                    <strong>${toDurationString(h, true)}</strong>`
                                                      )
                                                    : html` ${formatNumber(hoursPerWeek, 1)} Std / Wo `}
                                            </div>

                                            <div class="slim pill">
                                                <i class="island-tropical"></i>
                                                ${formatNumber(vacationDays, 1)} Tage / Jahr
                                            </div>
                                            ${!defaultSalary
                                                ? ""
                                                : defaultSalary.type === "monthly"
                                                  ? html`<div class="slim pill">
                                                        <i class="euro-sign"></i>
                                                        ${formatNumber(defaultSalary.amount)}€ / Mon
                                                    </div>`
                                                  : html`<div class="slim pill">
                                                        <i class="euro-sign"></i>${formatNumber(defaultSalary.amount)}€
                                                        / Std
                                                    </div>`}
                                        </div>
                                    </div>
                                `
                            )}
                    </ptc-scroller>
                    <div class="padded vertical layout">
                        <button class="small subtle" @click=${() => this._goTo("changes")}>
                            <i class="list-timeline"></i>
                            Vertragsänderungen
                        </button>
                    </div>
                </div>

                <div class="relative stretch">
                    ${cache(
                        this.page === "new" && this._newContract
                            ? this._renderEditContract(this._newContract)
                            : !this._selectedContract
                              ? html`
                                    <div class="fullbleed faded double-padded centering vertical layout">
                                        <i class="giant file-contract"></i>
                                        <div>Kein Vertrag gewählt.</div>
                                    </div>
                                `
                              : this.page === "edit"
                                ? this._renderEditContract(this._selectedContract)
                                : this._renderViewContract(this._selectedContract)
                    )}
                </div>
            </div>

            <div class="fullbleed vertical layout" ?hidden=${this.page !== "changes"}>
                <div class="padded center-aligning horizontal layout border-bottom noprint">
                    <button class="transparent slim" @click=${() => this._goTo("view")}>
                        <i class="chevron-left"></i> Verträge
                    </button>
                    <div class="stretch"></div>
                    <button class="transparent slim" @click=${() => print()}>
                        <i class="print"></i>
                    </button>
                </div>

                <div class="padded center-aligning horizontal layout bottom-margined printonly">
                    <div class="stretch">
                        Vetragsänderungen - <strong>${this._employee!.name}</strong>
                        ${this._employee!.staffNumber ? `(Pnr: ${this._employee?.staffNumber})` : ""} (Stand
                        ${formatDate(new Date())})
                    </div>
                    <div class="subtle small-caps">${app.company!.name}</div>
                </div>

                <ptc-scroller class="stretch" id="changesScroller"> ${this._renderContactChanges()} </ptc-scroller>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>

            <ptc-dialog id="interruptContractDialog" class="interrupt-contract-dialog">
                <form @submit=${this._submitInterruptContract}>
                    <h1>Vertragsunterbrechung</h1>

                    <div class="horizontal spacing layout">
                        <div class="field stretch">
                            <label>Von</label>
                            <ptc-date-input
                                name="start"
                                required
                                .min=${this._selectedContract?.start}
                                @change=${(e: Event) =>
                                    (this._interruptContractEndInput.min = (e.target as HTMLInputElement).value)}
                                datePicker="popover"
                            ></ptc-date-input>
                        </div>

                        <div class="field stretch">
                            <label>Bis</label>
                            <ptc-date-input
                                name="end"
                                required
                                .max=${this._selectedContract?.inclusiveEnd || undefined}
                                datePicker="popover"
                            ></ptc-date-input>
                        </div>
                    </div>

                    <div class="field">
                        <label>Grund</label>
                        <textarea name="comment" placeholder='z.B. "Elternzeit"'></textarea>
                    </div>

                    <div class="field">
                        <ptc-checkbox-button
                            name="vacation"
                            label="Urlaubsanspruch Läuft Weiter"
                            buttonClass="ghost"
                        ></ptc-checkbox-button>
                    </div>

                    <div class="field">
                        <ptc-checkbox-button
                            name="cutbonus"
                            label="SFN-Pauschale Anteilig Kürzen"
                            buttonClass="ghost"
                        ></ptc-checkbox-button>
                    </div>

                    <div class="horizontal layout">
                        <button class="primary stretch">Speichern</button>
                        <button
                            class="stretch transparent"
                            type="button"
                            @click=${() => this._interruptContractDialog.dismiss()}
                        >
                            Abbrechen
                        </button>
                    </div>
                </form>
            </ptc-dialog>
        `;
    }
}
