import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { BenefitType } from "@pentacode/core/src/model";
import { live } from "lit/directives/live.js";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";

@customElement("ptc-benefit-type-dialog")
export class BenefitTypeDialog extends Dialog<BenefitType, boolean> {
    @state()
    private _benefitType: BenefitType;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    async show(type: BenefitType) {
        this._benefitType = type;
        const promise = super.show();

        await this.updateComplete;

        if (!this._nameInput.value) {
            this._nameInput.focus();
        }

        return promise;
    }

    private _formInput() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);

        this._benefitType.name = data.get("name") as string;
        this._benefitType.taxFree = data.has("taxFree");
        this._benefitType.includeInBonusPayments = data.has("includeInBonusPayments");
        this._benefitType.includeInMinimumWageComparison = data.has("includeInMinimumWageComparison");
    }

    static styles = [...Dialog.styles, Checkbox.styles, css``];

    renderContent() {
        return html`
            <form
                @input=${this._formInput}
                @submit=${(e: Event) => {
                    e.preventDefault();
                    this.done(true);
                }}
                autocomplete="off"
                class="double-padded spacing vertical layout"
            >
                <div class="small margined subtle blue colored-text">
                    ${this._benefitType.id
                        ? html`<i class="pencil-alt"></i> Sonderleistung Bearbeiten`
                        : html`<i class="plus"></i> Neue Sonderleistung`}
                </div>

                <input
                    name="name"
                    required
                    placeholder="Name der Sonderleistung"
                    class="fill-horizontally"
                    .value=${live(this._benefitType.name)}
                />

                <div class="field">
                    <ptc-checkbox-button
                        name="taxFree"
                        label="Beitragsfrei"
                        buttonClass="semislim ghost"
                        .checked=${live(this._benefitType.taxFree)}
                    ></ptc-checkbox-button>
                </div>
                <div class="field">
                    <ptc-checkbox-button
                        name="includeInMinimumWageComparison"
                        label="Auf Mindestlohn Anrechenbar"
                        buttonClass="semislim ghost"
                        .checked=${live(this._benefitType.includeInMinimumWageComparison)}
                    ></ptc-checkbox-button>
                </div>
                <div class="field">
                    <ptc-checkbox-button
                        name="includeInBonusPayments"
                        label="Bei Zuschlagszahlungen Berücksichtigen"
                        buttonClass="semislim ghost"
                        .checked=${live(this._benefitType.includeInBonusPayments)}
                    ></ptc-checkbox-button>
                </div>

                <div class="horizontal top-padded spacing evenly stretching layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
