import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { formatDateShort, parseDurationString, toDurationString } from "@pentacode/core/src/util";
import { shared } from "../styles";
import "./drawer";
import { BonusType, BonusTypeMode } from "@pentacode/core/src/model";
import "./popover";

@customElement("ptc-bonus-type-item")
export class BonusTypeEl extends LitElement {
    @property({ attribute: false })
    bonusType: BonusType;

    private get _intervals(): [number, number][] {
        return (
            this.bonusType?.intervals.map(([start, end]) => [parseDurationString(start), parseDurationString(end)]) ||
            []
        );
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                padding: 0.8em;
            }
        `,
    ];

    render() {
        const bonusType = this.bonusType;
        return html`
            <div class="larger bottom-margined">
                ${bonusType.name || html`<span class="subtle">Neue Zuschlagsart</span>`}
            </div>
            <div class="smaller pills">
                ${bonusType.mode === BonusTypeMode.Daily
                    ? html`<div class="pill"><i class="clock-rotate-left"></i> Täglich</div>`
                    : bonusType.mode === BonusTypeMode.WeekDays
                      ? bonusType.weekDays?.map(
                            (day) =>
                                html`<div class="pill">
                                    <i class="calendar"></i> ${["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][day]}
                                </div>`
                        )
                      : bonusType.mode === BonusTypeMode.Holidays
                        ? html`<div class="pill"><i class="wreath"></i> Gesetzliche Feiertage</div>`
                        : bonusType.mode === BonusTypeMode.Date
                          ? html`<div class="pill">
                                <i class="calendar"></i> ${formatDateShort("2001-" + bonusType.date!)}
                            </div>`
                          : ""}
                ${this._intervals.map(
                    ([start, end]) =>
                        html`<div class="pill">
                            <i class="clock"></i> ${toDurationString(start % 24)} ${start >= 24 ? "(Folgetag)" : ""} -
                            ${toDurationString(end % 24)} ${end >= 24 ? "(Folgetag)" : ""}
                        </div>`
                )}
                ${bonusType.shiftMustStartBefore
                    ? html`
                          <div class="pill" title="Schichtbeginn vor">
                              <i class="circle-play"></i> ${bonusType.shiftMustStartBefore.slice(0, 5)}
                          </div>
                      `
                    : ""}
            </div>
        `;
    }
}
