import { LitElement, html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { shared } from "../styles";
import "./time-entry-form";
import "./scroller";
import "./avatar";
import "./drawer";
import "./roster-entry";
import "./progress";
import { autoAssign, AutoAssignContext, AutoAssignCriteria } from "@pentacode/core/src/auto-assign";
import { dateAdd, debounce } from "@pentacode/core/src/util";
import { Checkbox } from "./checkbox";
import { EmployeeData } from "./roster";
import { app } from "../init";

@customElement("ptc-auto-assign-menu")
export class AutoAssignMenu extends LitElement {
    private _context: AutoAssignContext;

    @query("form")
    private _weightsForm: HTMLFormElement;

    private _originalAssignments = new Map<string, number | null>();

    private _originalEmployeeData = new Map<number, EmployeeData>();

    init(context: AutoAssignContext) {
        this._context = context;
        this._originalAssignments.clear();
        this._originalEmployeeData.clear();
        context.entries = context.entries.filter(
            (e) => !e.deleted && e.date >= dateAdd(context.range.from, { days: -1 }) && e.date <= context.range.to
        );
        context.entries.forEach((e) => this._originalAssignments.set(e.id, e.employeeId));
        context.employees.forEach((e) => this._originalEmployeeData.set(e.employee.id, e));
        this.updatePreview();
    }

    debouncedUpdatePreview = debounce(() => this.updatePreview(), 200);

    updatePreview() {
        const form = this._weightsForm;
        const data = new FormData(form);
        const weights: AutoAssignCriteria = {
            avoidProblems: -1 * Number(data.get("avoidProblems")),
            assignAll: -1 * Number(data.get("assignAll")),
            fillQuota: Number(data.get("fillQuota")),
            avoidOvertime: -1 * Number(data.get("avoidOvertime")),
            reduceAccumulatedOvertime: Number(data.get("reduceAccumulatedOvertime")),
            minimizeCosts: -1 * Number(data.get("minimizeCosts")),
            considerAvailabilities: Number(data.get("considerAvailabilities")),
        };

        this._context.entries.forEach((e) => (e.employeeId = this._originalAssignments.get(e.id)!));
        this._context.employees.forEach((e) => Object.assign(e, { ...this._originalEmployeeData.get(e.employee.id) }));
        this._context.weights = weights;
        this._context.reassignEntries = data.has("reassignEntries");

        autoAssign(this._context);
        this.dispatchEvent(new CustomEvent("updated"));
    }

    close() {
        this._context.entries.forEach((e) => (e.employeeId = this._originalAssignments.get(e.id)!));
        this._context.employees.forEach((e) => Object.assign(e, this._originalEmployeeData.get(e.employee.id)));
        this.dispatchEvent(new CustomEvent("close"));
    }

    private async _submit(e: Event) {
        e.preventDefault();
        await app.createOrUpdateTimeEntries(this._context.entries);
        this.dispatchEvent(new CustomEvent("close"));
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: block;
                position: relative;
            }
        `,
    ];

    render() {
        return html`
            <div class="fullbleed vertical layout">
                <div class="small vertical layout border-bottom">
                    <button class="slim transparent text-left-aligning" @click=${this.close}>
                        <div class="horizontal layout">
                            <div class="stretch"><i class="chevron-left"></i> Zurück</div>
                            <div class="key">Esc</div>
                        </div>
                    </button>
                </div>

                <ptc-scroller class="stretch">
                    <form class="padded small" @input=${this.debouncedUpdatePreview} @submit=${this._submit}>
                        <div class="large margined text-centering">
                            <i class="robot"></i> Schichten Automatisch Zuweisen
                        </div>
                        <div class="margined">
                            <div class="field">
                                <ptc-checkbox-button
                                    buttonClass="ghost"
                                    label="Bereits zugewiesene Schichten neu verteilen"
                                    name="reassignEntries"
                                ></ptc-checkbox-button>
                            </div>
                            <div class="field">
                                <label>Probleme vermeiden</label>
                                <input type="range" step="1" min="0" max="10" name="avoidProblems" />
                            </div>
                            <div class="field">
                                <label>Schichten vollständig verteilen</label>
                                <input type="range" step="1" min="0" max="10" name="assignAll" />
                            </div>
                            <div class="field">
                                <label>Sollstunden erfüllen</label>
                                <input type="range" step="1" min="0" max="10" name="fillQuota" />
                            </div>
                            <div class="field">
                                <label>Überstunden vermeiden</label>
                                <input type="range" step="1" min="0" max="10" name="avoidOvertime" />
                            </div>
                            <div class="field">
                                <label>Überstunden abbauen</label>
                                <input type="range" step="1" min="0" max="10" name="reduceAccumulatedOvertime" />
                            </div>
                            <div class="field">
                                <label>Kosten minimieren</label>
                                <input type="range" step="1" min="0" max="10" name="minimizeCosts" />
                            </div>
                            <div class="field">
                                <label>Verfügbarkeiten berücksichtigen</label>
                                <input type="range" step="1" min="0" max="10" name="considerAvailabilities" />
                            </div>
                        </div>
                        <div class="padded vertical layout">
                            <button class="primary">Anwenden</button>
                        </div>
                    </form>
                </ptc-scroller>
            </div>
        `;
    }
}
