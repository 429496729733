import { createContext } from "@lit/context";

export interface EnabledFeatures {
    rosterAutoAssign: boolean;
}

export const enabledFeatures = createContext<EnabledFeatures>(Symbol("enabledFeatures"));

export function loadEnabledFeatures() {
    const enabledFeatures = (process.env.PTC_ENABLED_FEATURES || "").split(",");

    return {
        rosterAutoAssign: enabledFeatures.includes("rosterAutoAssign"),
    };
}
