import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./spinner";
import "./popover";
import "./scroller";
import "./help";
import { ChangePasswordDialog } from "./change-password-dialog";
import { Account } from "@pentacode/core/src/model";

@customElement("ptc-settings-account")
export class SettingsAccount extends Routing(StateMixin(LitElement)) {
    routePattern = /^settings\/account/;

    get routeTitle() {
        return "Benutzerkonto | Einstellungen";
    }

    get helpPage() {
        return "handbuch/einstellungen/benutzerkonto/";
    }

    @state()
    private _loading: boolean = false;

    @singleton("ptc-change-password-dialog")
    private _changePasswordDialog: ChangePasswordDialog;

    private get _accountInfoData() {
        const form = this.renderRoot.querySelector("#accountInfoForm") as HTMLFormElement | null;

        if (!form) {
            return {};
        }

        const data = new FormData(form);

        return {
            firstName: data.get("firstName") as string,
            lastName: data.get("lastName") as string,
            email: data.get("email") as string,
        };
    }

    private get _hasAccountInfoChanged() {
        return (
            app.account &&
            Object.entries(this._accountInfoData).some(([key, value]) => app.account![key as keyof Account] !== value)
        );
    }

    private async _submitAccountInfo(e: FocusEvent) {
        e.preventDefault();

        this._loading = true;
        try {
            await app.updateAccount({ ...this._accountInfoData });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    static styles = [shared, css``];

    render() {
        if (!app.company) {
            return html``;
        }

        return html`
            <ptc-scroller class="fullbleed">
                <div style="max-width: 50em; margin: 0 auto;">
                    <form
                        id="accountInfoForm"
                        class="padded border-bottom"
                        @submit=${this._submitAccountInfo}
                        @change=${() => this.requestUpdate()}
                    >
                        <h2>Benutzer</h2>

                        <div class="horizontal spacing layout">
                            <div class="field stretch">
                                <label>Vorname</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    .value=${app.account!.firstName}
                                    placeholder="Vorname"
                                />
                            </div>
                            <div class="field stretch">
                                <label>Nachname</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    .value=${app.account!.lastName}
                                    placeholder="Nachname"
                                />
                            </div>
                        </div>

                        <div class="field">
                            <label>Benutzername / Emailadresse</label>
                            <input
                                type="email"
                                name="email"
                                .value=${app.account?.email || ""}
                                required
                                placeholder="Emailadresse"
                            />
                        </div>

                        <button class="primary" ?hidden=${!this._hasAccountInfoChanged}>Speichern</button>
                    </form>

                    <div class="double-padded">
                        <button class="ghost" @click=${() => this._changePasswordDialog.show()}>Passwort Ändern</button>
                    </div>
                </div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
