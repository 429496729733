import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { formatDate } from "@pentacode/core/src/util";
import { app } from "../init";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import "./employees-filter";
import { Checkbox } from "./checkbox";
import "./popover";
import "./spinner";
import "./avatar";
import { Employee } from "@pentacode/core/src/model";
import { EmployeeSortProperty } from "@pentacode/core";

@customElement("ptc-employees-profile-all")
export class EmployeesProfileAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/core_data/;

    get routeTitle() {
        return "Stammdaten: Alle Mitarbeiter";
    }

    private static _columns: { property: keyof Employee; label: string; format?: (val: unknown) => string }[] = [
        { property: "lastName", label: "Nachname" },
        { property: "firstName", label: "Vorname" },
        { property: "callName", label: "Rufname" },
        { property: "staffNumber", label: "Pers-Nr" },
        { property: "birthday", label: "Geburtstag", format: formatDate },
        { property: "address", label: "Adresse" },
        { property: "postalCode", label: "PLZ" },
        { property: "city", label: "Stadt" },
        { property: "email", label: "Email" },
        { property: "phone", label: "Telefon" },
        { property: "phone2", label: "Telefon 2" },
        { property: "timePin", label: "Zeit-PIN" },
    ];

    @state()
    private _loading: boolean = false;

    @query("#columnsForm")
    private _columnsForm: HTMLFormElement;

    private get _displayColumns() {
        if (!this._columnsForm) {
            return new Set(EmployeesProfileAll._columns.map((c) => c.property));
        }
        const columns = new FormData(this._columnsForm).getAll("columns");
        return new Set(columns);
    }

    private get _employees() {
        return app.getFilteredEmployees();
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            .row {
                display: flex;
                page-break-inside: avoid;
            }

            .row:not(.header):hover {
                cursor: pointer;
                color: var(--color-primary);
            }

            .row.header {
                font-weight: bold;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            .row > * {
                width: 10em;
                padding: 0.5em;
                flex: none;
                word-break: break-word;
                border-bottom: solid 1px var(--shade-2);
                background: var(--color-bg);
            }

            .row :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .row > .lastName {
                position: sticky;
                left: 0;
                z-index: 1;
            }

            .row > .firstName {
                width: 7em;
            }

            .row > .callName {
                width: 5em;
            }

            .row > .staffNumber {
                width: 5em;
            }

            .row > .timePin {
                width: 5em;
            }

            .row > .postalCode {
                width: 5em;
            }

            .row > .birthday {
                width: 6em;
            }

            .row > .phone,
            .row > .phone2 {
                width: 8em;
            }

            .row > .email {
                width: 15em;
            }

            .row > .address {
                width: 15em;
            }

            .row > .avatar {
                width: 2em;
                border: none;
                padding: 0.3em;
            }

            @media print {
                .row {
                    font-size: var(--font-size-tiny);
                }

                .wrapper {
                    position: static;
                    display: block;
                }
            }
        `,
    ];

    render() {
        const displayColumns = this._displayColumns;
        const columns = EmployeesProfileAll._columns.filter((c) => displayColumns.has(c.property));
        return html`
            <div class="fullbleed vertical layout wrapper">
                <div class="padded spacing center-aligning horizontal layout border-bottom noprint">
                    <div class="stretch"></div>

                    <button class="slim transparent">
                        <i class="toggle-on large"></i>
                    </button>

                    <ptc-popover style="min-width: 10em;">
                        <form class="small" id="columnsForm" @change=${() => this.requestUpdate()}>
                            ${EmployeesProfileAll._columns.map(
                                ({ property, label }) => html`
                                    <ptc-checkbox-button
                                        .label=${label}
                                        name="columns"
                                        .value=${property}
                                        checked
                                        buttonClass="transparent slim"
                                    ></ptc-checkbox-button>
                                `
                            )}
                        </form>
                    </ptc-popover>

                    <button class="slim transparent" @click=${() => print()}>
                        <i class="print"></i>
                    </button>
                </div>

                <div class="padded horizontal layout small-caps bottom-margined printonly">
                    <div class="stretch">Personal-Stammdaten (Stand ${formatDate(new Date())})</div>
                    <div class="subtle">${app.company?.name}</div>
                </div>

                <ptc-employees-filter
                    class="border-bottom"
                    .sortableProps=${[
                        "firstName",
                        "lastName",
                        "staffNumber",
                        "birthday",
                        "birthdayDate",
                        "email",
                    ] as EmployeeSortProperty[]}
                ></ptc-employees-filter>

                <div class="scroller stretch">
                    <div class="header row">
                        <div class="avatar noprint"></div>
                        ${columns.map(({ property, label }) => html`<div class="${property}">${label}</div>`)}
                    </div>

                    ${this._employees.map((employee) => {
                        return html`
                            <div class="employee row" @click=${() => this.go(`employees/${employee.id}/core_data`)}>
                                <div class="avatar left-padded centering layout noprint">
                                    <ptc-avatar class="tiny" .employee=${employee}></ptc-avatar>
                                </div>
                                ${columns.map(
                                    ({ property, format }) =>
                                        html`<div class="${property}">
                                            ${format && employee[property]
                                                ? format(employee[property])
                                                : employee[property]}
                                        </div>`
                                )}
                            </div>
                        `;
                    })}
                </div>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
