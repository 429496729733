import { WageType } from "./model";

export const BMD_WAGE_TYPES: WageType[] = [
    { number: "1000", description: "in Euro", name: "Gehalt", unit: "euros" },
    { number: "1020", description: "in Euro", name: "Geschäftsführerbezug", unit: "euros" },
    { number: "1030", description: "in Euro", name: "Geschäftsführerbezug nur DB/DZ/Kommst", unit: "euros" },
    { number: "1100", description: "in Euro", name: "Honorar 20% USt pfl. (Eingabe)", unit: "euros" },
    { number: "1110", description: "in Euro", name: "Honorar", unit: "euros" },
    { number: "1111", description: "in Euro", name: "Honorar 20% USt", unit: "euros" },
    { number: "1200", description: "in Euro", name: "Monatslohn", unit: "euros" },
    { number: "1300", description: "Menge", name: "Stundenlohn", unit: "hours" },
    { number: "1400", description: "in Euro", name: "Lehrlingseinkommen", unit: "euros" },
    { number: "1500", description: "in Euro", name: "Fixprämie 12x", unit: "euros" },
    { number: "1505", description: "in Euro", name: "Fixprämie 14x", unit: "euros" },
    { number: "1510", description: "in Euro", name: "Prämie", unit: "euros" },
    { number: "1520", description: "in Euro", name: "Fixprovision 12x", unit: "euros" },
    { number: "1525", description: "in Euro", name: "Fixprovision 14x", unit: "euros" },
    { number: "1530", description: "in Euro", name: "Provision", unit: "euros" },
    { number: "1600", description: "in Euro", name: "Sachbezug Pkw", unit: "euros" },
    { number: "1610", description: "in Euro", name: "Sachbezug Pkw 50%", unit: "euros" },
    { number: "1635", description: "in Euro", name: "Sachbezug Garage", unit: "euros" },
    { number: "1640", description: "in Euro", name: "Sachbezug Wohnung", unit: "euros" },
    { number: "1650", description: "in Euro", name: "Sachbezug sonstiger", unit: "euros" },
    { number: "1670", description: "in Euro", name: "Sachbezug Elektroauto", unit: "euros" },
    { number: "1675", description: "in Euro", name: "Sachbezug Pkw Ruhend", unit: "euros" },
    { number: "1700", description: "in Euro", name: "Trinkgeldpauschale", unit: "euros" },
    { number: "1710", description: "in Euro", name: "Kleiderpauschale pfl.", unit: "euros" },
    { number: "1720", description: "in Euro", name: "MA-Rabatt (Eingabe)", unit: "euros" },
    { number: "1730", description: "in Euro", name: "Fehlgeld", unit: "euros" },
    // Excluded for now because they need the number of km as input
    // { number: "2600", description: "in Anzahl KM", name: "KM Geld", unit: "pieces" },
    { number: "2800", description: "in Euro", name: "Fahrtersatz SV frei", unit: "euros" },
    { number: "2810", description: "in Euro", name: "Fahrtersatz SV pfl.", unit: "euros" },
    { number: "2820", description: "in Euro", name: "Öffi-Ticket (Auszahlung)", unit: "euros" },
    { number: "2822", description: "in Euro", name: "Öffi-Ticket (Info für L16)", unit: "euros" },
    { number: "2900", description: "in Euro", name: "Barauslagen", unit: "euros" },
    { number: "2905", description: "in Euro", name: "Barauslagen 5%", unit: "euros" },
    { number: "2910", description: "in Euro", name: "Barauslagen 10%", unit: "euros" },
    { number: "2920", description: "in Euro", name: "Barauslagen 20%", unit: "euros" },
    { number: "3000", description: "in Stunden", name: "Mehrstunden", unit: "hours" },
    { number: "3010", description: "in Stunden", name: "MStd. Zuschlag 50% §68 (2) frei", unit: "hours" },
    { number: "3020", description: "in Stunden", name: "MStd. Zuschlag 50% pfl.", unit: "hours" },
    { number: "3030", description: "in Stunden", name: "MStd. Teilzeit bis NAZ", unit: "hours" },
    { number: "3040", description: "in Stunden", name: "MStd. Teilzeit über NAZ", unit: "hours" },
    { number: "3050", description: "in Stunden", name: "MStd. Teilzeit ZS 25%", unit: "hours" },
    { number: "3100", description: "in Stunden", name: "ÜStd. Grundlohn", unit: "hours" },
    { number: "3110", description: "in Stunden", name: "ÜStd. Zuschlag 50% §68 (2) frei", unit: "hours" },
    { number: "3120", description: "in Stunden", name: "ÜStd. Zuschlag 50% pfl.", unit: "hours" },
    { number: "3140", description: "in Stunden", name: "ÜStd. Zuschlag 50% frei", unit: "hours" },
    { number: "3150", description: "in Stunden", name: "ÜStd. Zuschlag 100% pfl.", unit: "hours" },
    { number: "3160", description: "in Stunden", name: "ÜStd. Zuschlag 100% frei", unit: "hours" },
    { number: "3700", description: "in Stunden", name: "SFN Grundlohn pfl.", unit: "hours" },
    { number: "3710", description: "in Stunden", name: "SFN Zuschlag frei", unit: "hours" },
    { number: "3720", description: "in Stunden", name: "Feiertag Arbeitsentgelt frei", unit: "hours" },
    { number: "4080", description: "in Stunden", name: "Nachtzulage frei Menge", unit: "hours" },
    { number: "4090", description: "in Stunden", name: "Sonstige Zulage pfl. Menge", unit: "hours" },
    { number: "4580", description: "in Euro", name: "Nachtzulage frei in Euro", unit: "euros" },
    { number: "4590", description: "in Euro", name: "Sonstige Zulage pfl. in Euro", unit: "euros" },
    { number: "5090", description: "in Euro", name: "Einmalprämie SV lfd / LSt SZ", unit: "euros" },
    { number: "5100", description: "in Euro", name: "Wiederkehrende Prämie SV SZ / LSt SZ", unit: "euros" },
    { number: "7000", description: "in Euro", name: "Akonto", unit: "euros" },
    { number: "7100", description: "in Euro", name: "Betriebsratsumlage fix", unit: "euros" },
    { number: "7105", description: "in Euro", name: "Gewerkschaft fix", unit: "euros" },
];
