// import { Balances, TimeEntry, TimeEntryType } from "@pentacode/core/src/model";
import { dateAdd, formatNumber, toDateString, toDurationString } from "@pentacode/core/src/util";
import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";
import "./scroller";
import { DateInput } from "./date-input";
import { GetDailyResultsParams, ResetBalancesParams } from "@pentacode/core/src/api";
import { DailyResults, EmployeeStatus, EntityFilter } from "@pentacode/core/src/model";
import { DateString, Days, Euros } from "@pentacode/openapi";
import { EntityFiltersEl } from "./entity-filters";
import { live } from "lit/directives/live.js";
import { DurationInput } from "./duration-input";
import "./avatar";
// import { DateString, Days, Euros, Hours } from "@pentacode/openapi";

@customElement("ptc-reset-balances-dialog")
export class ResetBalancesDialog extends Dialog<{ filters: EntityFilter[] } | undefined, boolean> {
    private get _employees() {
        return this._filtersInput?.filteredEmployees || app.accessibleEmployees;
    }

    preventDismiss = true;

    @query("ptc-date-input")
    private _dateInput: DateInput;

    @query("ptc-entity-filters")
    private _filtersInput: EntityFiltersEl;

    @state()
    private _dailyResults: DailyResults[] = [];

    private async _loadBalances() {
        const date = this._dateInput?.value;

        if (!date) {
            return;
        }

        const from = date;
        const to = dateAdd(from, { days: 1 });

        this.loading = true;

        try {
            this._dailyResults = await app.api.getDailyResults(
                new GetDailyResultsParams({
                    from,
                    to,
                })
            );
            await this.updateComplete;
            this._resetInputValues();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    private async _submit(e: Event) {
        e.preventDefault();

        const date = this._dateInput.value;
        if (!date) {
            this._dateInput.setCustomValidity("Bitte wählen Sie ein Datum aus.");
            this._dateInput.reportValidity();
            return;
        }

        const params = new ResetBalancesParams({
            date,
            balances: [],
        });

        for (const employee of this._employees) {
            const row = this.renderRoot.querySelector(`tr[data-employee-id="${employee.id}"]`) as HTMLTableRowElement;

            if (!row) {
                continue;
            }
            const timeInput = row.querySelector("ptc-duration-input[name='time']") as DurationInput;
            const vacationInput = row.querySelector("input[name='vacation']") as HTMLInputElement;
            const bonusesInput = row.querySelector("input[name='bonuses']") as HTMLInputElement;

            const time = timeInput.value;
            const vacation = vacationInput.value ? (Number(vacationInput.value) as Days) : null;
            const bonuses = bonusesInput.value ? (Number(bonusesInput.value) as Euros) : null;

            params.balances.push({
                employeeId: employee.id,
                time,
                vacation,
                bonuses,
            });
        }

        this.loading = true;

        try {
            await app.api.resetBalances(params);
            this.done(true);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    private _resetInputValues() {
        for (const dailyResults of this._dailyResults) {
            const row = this.renderRoot.querySelector(
                `tr[data-employee-id="${dailyResults.employeeId}"]`
            ) as HTMLTableRowElement;
            if (!row) {
                continue;
            }
            const timeInput = row.querySelector("ptc-duration-input[name='time']") as DurationInput;
            const vacationInput = row.querySelector("input[name='vacation']") as HTMLInputElement;
            const bonusesInput = row.querySelector("input[name='bonuses']") as HTMLInputElement;
            timeInput.value =
                typeof dailyResults.timeBalance.reset === "number" ? dailyResults.timeBalance.reset : null;
            vacationInput.value =
                typeof dailyResults.vacationBalance.reset === "number"
                    ? dailyResults.vacationBalance.reset.toString()
                    : "";
            bonusesInput.value =
                typeof dailyResults.bonusesBalance.reset === "number"
                    ? dailyResults.bonusesBalance.reset.toString()
                    : "";
        }
    }

    private async _filtersChanged() {
        this.requestUpdate();
        await this.updateComplete;
        this._resetInputValues();
    }

    async show({
        filters = [{ type: "employeeStatus", value: EmployeeStatus.Active }],
        date = toDateString(new Date()),
    }: { date?: DateString; filters?: EntityFilter[] } = {}) {
        const promise = super.show();
        await this.updateComplete;
        this._dateInput.value = date;
        this._filtersInput.filters = filters;
        await this._loadBalances();
        return promise;
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        DurationInput.styles,
        css`
            .inner {
                --dialog-max-width: 100%;
                --dialog-width: fit-content;
            }

            table {
                border-collapse: separate;
                display: block;
            }

            thead {
                display: block;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            thead th {
                text-align: center;
            }

            tbody {
                display: block;
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                display: grid;
                grid-template-columns: 1fr 4em 2em 7em 4em 2em 6em 4em 2em 6em;
                align-items: center;
                width: 100%;
                background: var(--color-bg);
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th {
                font-weight: 600;
            }

            th,
            td {
                padding: 0.3em;
                vertical-align: middle;
                position: relative;
            }

            td {
                text-align: right;
            }

            td input {
                width: 100%;
            }
        `,
    ];

    renderContent() {
        const employees = this._employees;
        return html`
            <form class="vertical layout fit-vertically">
                <div class="double-padded border-bottom semibold"><i class="undo"></i> Konten Zurücksetzen</div>

                <ptc-scroller class="stretch">
                    <div class="double-padded">
                        <div class="fields spacing horizontal layout">
                            <div class="field stretch">
                                <label>Datum</label>
                                <ptc-date-input
                                    name="date"
                                    @change=${this._loadBalances}
                                    class="slim"
                                    required
                                ></ptc-date-input>
                            </div>
                            <div class="field stretch">
                                <label>Mitarbeiter</label>
                                <ptc-entity-filters
                                    class="larger focus-container"
                                    @change=${() => this._filtersChanged()}
                                ></ptc-entity-filters>
                            </div>
                        </div>
                        ${employees.length > 0
                            ? html`<table>
                                  <thead>
                                      <th></th>
                                      <th style="grid-column: span 3;">
                                          <i class="hourglass-half"></i> Arbeitszeit (Std.)
                                      </th>
                                      <th style="grid-column: span 3;">
                                          <i class="island-tropical"></i> Urlaub (Tage)
                                      </th>
                                      <th style="grid-column: span 3;"><i class="badge-percent"></i> SFN (€)</th>
                                  </thead>
                                  <tbody>
                                      ${employees.map((employee) => {
                                          if (!employee.id) {
                                              return;
                                          }
                                          const dailyResults = this._dailyResults.find(
                                              (b) => b.employeeId === employee.id
                                          );

                                          if (!dailyResults) {
                                              return;
                                          }

                                          return html`
                                              <tr data-employee-id=${employee.id}>
                                                  <th class="center-aligning spacing horizontal layout">
                                                      <input
                                                          type="hidden"
                                                          name="employeeId"
                                                          .value=${live(employee.id?.toString())}
                                                      />
                                                      <ptc-avatar .employee=${employee} class="tiny"></ptc-avatar>
                                                      <div class="stretch ellipsis">
                                                          ${employee.lastName}, ${employee.firstName}
                                                      </div>
                                                  </th>
                                                  <td>${toDurationString(dailyResults.timeBalance.carry, true)}</td>
                                                  <td>
                                                      <i class="arrow-right"></i>
                                                  </td>
                                                  <td>
                                                      <ptc-duration-input
                                                          name="time"
                                                          class="skinny"
                                                          allowNegative
                                                      ></ptc-duration-input>
                                                  </td>
                                                  <td>${formatNumber(dailyResults.vacationBalance.carry)}</td>
                                                  <td>
                                                      <i class="arrow-right"></i>
                                                  </td>
                                                  <td>
                                                      <input type="number" name="vacation" step="0.01" class="skinny" />
                                                  </td>
                                                  <td>
                                                      ${typeof dailyResults.bonusesBalance.carry === "number"
                                                          ? formatNumber(dailyResults.bonusesBalance.carry)
                                                          : "-"}
                                                  </td>
                                                  <td>
                                                      <i class="arrow-right"></i>
                                                  </td>
                                                  <td>
                                                      <input
                                                          type="number"
                                                          name="bonuses"
                                                          step="0.01"
                                                          class="skinny"
                                                          ?disabled=${typeof dailyResults.bonusesBalance.carry !==
                                                          "number"}
                                                      />
                                                  </td>
                                              </tr>
                                          `;
                                      })}
                                  </tbody>
                              </table>`
                            : html`<div class="subtle text-centering top-margined">
                                  Die gewählten Filter treffen auf keine Mitarbeiter zu.
                              </div>`}
                    </div>
                </ptc-scroller>

                <div class="horizontal spacing layout padded-medium">
                    <div class="stretch"></div>
                    <button
                        ?disabled=${employees.length === 0}
                        class="primary"
                        @click=${this._submit}
                        style="width: 15em;"
                    >
                        Übernehmen
                    </button>
                    <button type="button" class="transparent" @click=${() => this.done(false)} style="width: 15em;">
                        Abbrechen
                    </button>
                </div>
            </form>
        `;
    }
}
