import { UpdateEmployeesParams } from "@pentacode/core/src/api";
import { debounce } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { shared, colors } from "../styles";
import { app, router } from "../init";
import { alert } from "./alert-dialog";
import { cache } from "lit/directives/cache.js";
import { live } from "lit/directives/live.js";
import "./avatar";
import "./employees-filter";
import { EmployeeSortProperty } from "@pentacode/core";

@customElement("ptc-employees-positions-row")
export class EmployeesPosisionsRow extends LitElement {
    @property({ type: Number })
    employeeId: number;

    @property({ attribute: false })
    positions: number[];

    @property({ type: Boolean })
    isVisible = false;

    shouldUpdate(changes: Map<string, unknown>) {
        return changes.has("isVisible") || this.isVisible;
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }

    private _render() {
        const emp = app.getEmployee(this.employeeId);
        if (!emp) {
            return;
        }
        return html`
            <div
                class="row-header employee-header horizontal center-aligning layout"
                @click=${() => router.go(`employees/${emp.id}/positions`)}
            >
                <ptc-avatar .employee=${emp}></ptc-avatar>
                <div class="employee-name stretch ellipsis">${emp.name}</div>
            </div>

            ${app.accessibleVenues.map(
                (venue) => html`
                    ${venue.departments
                        .filter((department) => app.hasAccess({ department }))
                        .map(
                            (dep) => html`
                                <div
                                    class="department-positions"
                                    style="--color-highlight: ${colors[dep.color] || dep.color}"
                                >
                                    ${dep.positions.map(
                                        (pos) => html`
                                            <div
                                                class="department-positions-checkbox click"
                                                style="--color-highlight: ${app.getPositionColor(pos)}"
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="positions.${emp.id}"
                                                    value="${pos.id}"
                                                    id="checkbox_${emp.id}.${pos.id}"
                                                    .checked=${live(this.positions.includes(pos.id))}
                                                    @change=${(e: Event) =>
                                                        this.dispatchEvent(
                                                            new CustomEvent("position-toggled", {
                                                                detail: {
                                                                    employeeId: this.employeeId,
                                                                    position: pos.id,
                                                                    checked: (e.target as HTMLInputElement).checked,
                                                                },
                                                            })
                                                        )}
                                                />
                                                <label for="checkbox_${emp.id}.${pos.id}">
                                                    <i class="check"></i>
                                                </label>
                                            </div>
                                        `
                                    )}
                                </div>
                            `
                        )}
                `
            )}
        `;
    }
}

@customElement("ptc-employees-positions-all")
export class EmployeesPositionsAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/positions/;

    get routeTitle() {
        return "Positionen: Alle Mitarbeiter";
    }

    @state()
    private _data: { id: number; positions: number[] }[] = [];

    @query(".inner")
    private _inner: HTMLDivElement;

    @query(".header.row")
    private _headerRow: HTMLDivElement;

    handleRoute() {
        this._data = app.getFilteredEmployees().map((e) => ({
            id: e.id,
            positions: e.positions.map((p) => p.id),
        }));
    }

    private _intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => this._intersectionHandler(entries),
        { root: this, rootMargin: "100%" }
    );

    private _intersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => ((e.target as EmployeesPosisionsRow).isVisible = e.isIntersecting));
    }

    updated() {
        this._inner.style.width = `${this._headerRow.scrollWidth}px`;
        const elements = this.renderRoot.querySelectorAll("ptc-employees-positions-row");
        for (const el of elements) {
            this._intersectionObserver.observe(el);
        }
    }

    private _positionToggled({
        target,
        detail: { employeeId, position, checked },
    }: CustomEvent<{ employeeId: number; position: number; checked: boolean }>) {
        const item = this._data.find((d) => d.id === employeeId)!;
        item.positions = (target as EmployeesPosisionsRow).positions = checked
            ? [...new Set([...item.positions, position])]
            : item.positions.filter((p) => p !== position);
        this._update();
    }

    private _getUpdates() {
        return this._data.filter(({ id, positions }) => {
            const employee = app.getEmployee(id)!;

            return (
                positions.length !== employee.positions.length ||
                positions.some((val) => !employee.positions.some((p) => p.id === val))
            );
        });
    }

    private _update = debounce(async () => {
        const update = this._getUpdates();

        if (!update.length) {
            return;
        }

        try {
            await app.api.updateEmployees(new UpdateEmployeesParams({ update }));
            await app.fetchCompany();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
    }, 2000);

    static styles = [
        shared,
        css`
            .row {
                display: flex;
                height: 2.5em;
                border-bottom: solid 1px var(--shade-2);
            }

            .header.row {
                position: sticky;
                top: 0;
                background: var(--color-bg);
                z-index: 2;
                height: auto;
            }

            .header.row > * {
                background: var(--color-bg);
            }

            .header.row .row-header {
                height: auto;
            }

            .row-header {
                width: 14em;
                flex: none;
                position: sticky;
                left: 0;
                border-right: solid 1px var(--shade-2);
                background: var(--color-bg);
                padding: 0 0.5em;
                height: 2.5em;
                z-index: 1;
            }

            .row-header ptc-avatar {
                font-size: var(--font-size-tiny);
                margin-right: 0.8em;
            }

            .employee-header {
                cursor: pointer;
            }

            .employee-header:hover {
                color: var(--color-primary);
            }

            .row .department {
                text-align: center;
            }

            .venue-header {
                position: relative;
                padding-top: 2em;
                border-right: solid 1px var(--shade-2);
                background: var(--color-bg);
            }

            .venue-name {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: center;
                line-height: 2em;
                font-weight: bold;
                padding: 0 0.2em;
            }

            .venue-departments {
                display: flex;
                min-height: 100%;
            }

            .department-header {
                position: relative;
                padding-top: 2em;
                color: var(--color-highlight);
            }

            .department-header:not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .department-name {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                text-align: center;
                line-height: 2em;
                font-weight: 600;
                padding: 0 0.5em;
            }

            .department-positions {
                display: flex;
                align-items: flex-end;
                min-height: 100%;
            }

            .department-positions-name {
                max-height: 10em;
                width: 2.5em;
                line-height: 2.5em;
                writing-mode: vertical-rl;
                text-orientation: mixed;
                padding: 0.5em 0;
                color: var(--color-highlight);
            }

            .department-positions-checkbox {
                width: 2.5em;
                text-align: center;
                position: relative;
            }

            .department-positions-checkbox input {
                position: absolute;
                opacity: 0;
            }

            .department-positions-checkbox label {
                display: block;
                text-align: center;
                width: 100%;
                line-height: 2.5em;
                cursor: pointer;
                color: var(--color-highlight);
                padding: 0;
            }

            .department-positions-checkbox input:not(:checked) + label {
                opacity: 0;
            }

            .department-positions > * {
                border-right: solid 1px var(--shade-2);
            }

            .department-header .department-positions > :last-child {
                border-right: none;
            }
        `,
    ];

    render() {
        const totalPositionsCount = app.accessibleDepartments
            .flatMap((d) => d.positions.filter((position) => app.hasAccess({ position })).length)
            .reduce((total, each) => total + each, 0);
        const rowWidth = `calc(${14 + 2.5 * totalPositionsCount}em + ${totalPositionsCount + 1}px)`;
        return html`
            <div class="fullbleed vertical layout">
                <ptc-employees-filter
                    class="border-bottom"
                    @change=${() => this.handleRoute()}
                    .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
                ></ptc-employees-filter>
                <div class="stretch collapse scrolling">
                    <div class="inner">
                        <div class="header row" style="width: ${rowWidth}">
                            <div class="row-header"></div>

                            ${app.accessibleVenues.map(
                                (venue) => html`
                                    <div class="venue-header">
                                        <div class="venue-name ellipsis">${venue.name}</div>

                                        <div class="venue-departments">
                                            ${venue.departments
                                                .filter((department) => app.hasAccess({ department }))
                                                .map(
                                                    (dep) => html`
                                                        <div
                                                            class="department-header"
                                                            style="--color-highlight: ${colors[dep.color] || dep.color}"
                                                        >
                                                            <div class="department-name ellipsis">${dep.name}</div>

                                                            <div class="department-positions ellipsis">
                                                                ${dep.positions
                                                                    .filter((position) => app.hasAccess({ position }))
                                                                    .map(
                                                                        (pos) => html`
                                                                            <div
                                                                                class="department-positions-name ellipsis"
                                                                                style="--color-highlight: ${app.getPositionColor(
                                                                                    pos
                                                                                )}"
                                                                            >
                                                                                ${pos.name}
                                                                            </div>
                                                                        `
                                                                    )}
                                                            </div>
                                                        </div>
                                                    `
                                                )}
                                        </div>
                                    </div>
                                `
                            )}
                        </div>

                        ${this._data.map(
                            ({ id, positions }) => html`
                                <ptc-employees-positions-row
                                    class="row"
                                    style="width: ${rowWidth}"
                                    .employeeId=${id}
                                    .positions=${positions}
                                    @position-toggled=${this._positionToggled}
                                ></ptc-employees-positions-row>
                            `
                        )}
                    </div>
                </div>
            </div>
        `;
    }
}
