import { dateAdd, toDateString } from "@pentacode/core/src/util";
import { css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";
import "./scroller";
import { DateInput } from "./date-input";
import { EmployeeStatus, EntityFilter } from "@pentacode/core/src/model";
import { DateString } from "@pentacode/openapi";
import { EntityFiltersEl } from "./entity-filters";
import "./avatar";
import { app } from "../init";
import { GetDailyResultsParams } from "@pentacode/core/src/api";

@customElement("ptc-recalculate-results-dialog")
export class RecalculateResultsDialog extends Dialog<{ filters: EntityFilter[] } | undefined, boolean> {
    preventDismiss = true;

    @query("ptc-date-input")
    private _dateInput: DateInput;

    @query("ptc-entity-filters")
    private _filtersInput: EntityFiltersEl;

    private async _submit(e: Event) {
        e.preventDefault();

        const from = this._dateInput.value!;
        const to = dateAdd(from, { days: 1 });
        const filters = this._filtersInput.filters;

        this.loading = true;

        try {
            await app.api.getDailyResults(new GetDailyResultsParams({ from, to, filters, recalculate: true }));
            this.done();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    async show({
        filters = [{ type: "employeeStatus", value: EmployeeStatus.Active }],
        date,
    }: { date?: DateString; filters?: EntityFilter[] } = {}) {
        if (!date) {
            date =
                app.company!.settings.commitTimeEntriesBefore ||
                app.company!.settings.startLedgers ||
                toDateString(app.company!.created);
        }

        const promise = super.show();
        await this.updateComplete;
        this._dateInput.value = date;
        this._filtersInput.filters = filters;
        return promise;
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        css`
            .inner {
                --dialog-max-width: 40em;
            }
        `,
    ];

    renderContent() {
        return html`
            <form class="vertical layout fit-vertically">
                <div class="double-padded border-bottom semibold">
                    <i class="calculator-simple"></i> Konten Neu Berechnen
                </div>

                <ptc-scroller class="stretch">
                    <div class="double-padded">
                        <div class="fields spacing horizontal layout">
                            <div class="field stretch">
                                <label>Ab</label>
                                <ptc-date-input name="date" class="slim" required></ptc-date-input>
                            </div>
                            <div class="field stretch">
                                <label>Mitarbeiter</label>
                                <ptc-entity-filters
                                    class="larger focus-container"
                                    @change=${() => this.requestUpdate()}
                                ></ptc-entity-filters>
                            </div>
                        </div>
                    </div>
                </ptc-scroller>

                <div class="horizontal spacing evenly stretching layout padded-medium">
                    <button class="primary" @click=${this._submit}>Übernehmen</button>
                    <button type="button" class="transparent" @click=${() => this.done(false)}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
