import { Department, Position, Venue } from "@pentacode/core/src/model";
import { CreateDepartmentParams, UpdateDepartmentParams } from "@pentacode/core/src/api";
import { html, css } from "lit";
import { customElement, property, query, queryAll, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";
import "./popover";
import { confirm, alert } from "./alert-dialog";
import { ColorInput } from "./color-input";
import "./sortable-list";

interface Params {
    venue: Venue;
    department?: Department;
    hideTimeSettings?: boolean;
}

@customElement("ptc-department-dialog")
export class DepartmentDialog extends Dialog<Params, void> {
    readonly preventDismiss = true;

    @property({ type: Boolean })
    hideTimeSettings = false;

    @state()
    private _params: CreateDepartmentParams | UpdateDepartmentParams = new CreateDepartmentParams();

    @property()
    private _error = "";

    @query("form")
    private _form: HTMLFormElement;

    @queryAll("input[name='positions']")
    private _positionInputs: Iterable<HTMLInputElement>;

    private get _isNew() {
        return this._params instanceof CreateDepartmentParams;
    }

    show({ department, venue, hideTimeSettings }: Params): Promise<void> {
        this._error = "";

        this.hideTimeSettings = !!hideTimeSettings;

        this._params = department
            ? new UpdateDepartmentParams({
                  id: department.id,
                  name: department.name,
                  color: department.color,
                  positions: department.positions
                      .filter((r) => r.active)
                      .map(({ id, name, color }) => ({ id, name, color })),
                  bonusesAreTaxed: department.bonusesAreTaxed,
              })
            : new CreateDepartmentParams({
                  venue: venue.id,
                  name: "",
                  color: "",
                  positions: [{ name: "", color: "" }],
                  bonusesAreTaxed: false,
                  // timeTracking: true
              });

        return super.show();
    }

    private async _addPosition() {
        if (!this._params.positions!.some((r) => !r.name)) {
            this._params.positions!.push({ name: "", color: "" });
            this.requestUpdate();
            await this.updateComplete;
        }
        const input = [...this._positionInputs].find((input) => !input.value);
        input && input.focus();
    }

    private _removePosition(index: number) {
        this._params.positions!.splice(index, 1);
        this.requestUpdate();
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        if (!this._params.positions!.length) {
            this._error = "Bitte fügen Sie mindestens eine Position hinzu!";
            return;
        }

        if (!this._form.reportValidity()) {
            return;
        }

        this.loading = true;

        try {
            this._isNew ? await app.createDepartment(this._params) : await app.updateDepartment(this._params);
            this.done();
        } catch (e) {
            this._error = e.message;
        }

        this.loading = false;
    }

    private async _archive() {
        this.hide();
        const confirmed = await confirm(
            "Wollen Sie diese Abteilung wirklich archivieren?",
            "Archivieren",
            "Abbrechen",
            { type: "destructive", title: "Abteilung Archivieren", icon: "archive" }
        );
        this._show();
        if (confirmed) {
            this.loading = true;
            await app.archiveDepartment((this._params as UpdateDepartmentParams).id);
            this.loading = false;
            this.done();
            void alert(
                "Die Abteilung wurde erfolgreich archiviert. Falls Sie sie wiederherstellen " +
                    "möchten, melden Sie sich bitte bei unserem Supportteam!",
                { type: "success" }
            );
        }
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        ColorInput.styles,
        css`
            .inner {
                display: flex;
                flex-direction: column;
                max-width: 500px;
            }

            .position-item > input {
                padding: 0.7em 2em 0.7em 3em;
                width: 100%;
            }

            .position-item > input:focus-visible {
                box-shadow: inset var(--color-primary) 0 0 0 1px;
            }

            .position-item > ptc-color-input {
                position: absolute;
                left: 0.5em;
                top: 0.5em;
            }

            .position-item > button {
                position: absolute;
                right: 0.5em;
                top: 0.4em;
            }

            .position-item:not(:hover) > button {
                opacity: 0;
            }

            .name-input-wrapper > ptc-color-input {
                position: absolute;
                left: 0.5em;
                top: 0.5em;
            }

            .name-input-wrapper > input {
                width: 100%;
                padding-left: 3em;
                padding-right: 2em;
            }

            .name-input-wrapper > button {
                position: absolute;
                right: 0.5em;
                top: 0.5em;
            }
        `,
    ];

    renderContent() {
        const dep = this._params;
        const positions = dep.positions;

        return html`
            <form class="vertical layout fit-vertically" @submit=${this._submit}>
                <div class="double-padded relative">
                    <div class="vertical spacing layout">
                        <div class="small margined subtle blue colored-text">
                            ${dep instanceof UpdateDepartmentParams
                                ? html`<i class="pencil-alt"></i> Abteilung Bearbeiten`
                                : html`<i class="plus"></i> Neue Abteilung`}
                        </div>
                        <div class="large name-input-wrapper relative">
                            <ptc-color-input
                                name="color"
                                .value=${dep.color || ""}
                                required
                                @change=${(e: Event) => (dep.color = (e.target as HTMLInputElement).value)}
                            ></ptc-color-input>

                            <input
                                type="text"
                                name="name"
                                .value=${dep.name || ""}
                                placeholder="z.B. Küche"
                                required
                                @change=${(e: Event) => (dep.name = (e.target as HTMLInputElement).value)}
                            />

                            <button
                                type="button"
                                class="smaller transparent slim absolute"
                                style="top: 0.5em; right: 0.5em;"
                                ?hidden=${this._isNew}
                            >
                                <i class="ellipsis-h"></i>
                            </button>

                            <ptc-popover class="popover-menu" .preferAlignment=${["bottom"]} hide-on-click>
                                <button @click=${this._archive} type="button">
                                    <i class="archive"></i>
                                    Abteilung Archivieren
                                </button>
                            </ptc-popover>
                        </div>
                    </div>
                </div>

                <ptc-scroller class="stretch">
                    <div class="horizontally-padded margined">
                        <label>Positionen</label>

                        <div class="box">
                            <ptc-sortable-list
                                .items=${positions}
                                .renderItem=${(position: Position, i: number) => html`
                                    <div class="position-item relative border-bottom">
                                        <ptc-color-input
                                            .value=${position.color}
                                            @change=${(e: Event) =>
                                                (position.color = (e.target as HTMLInputElement).value)}
                                        ></ptc-color-input>

                                        <input
                                            type="text"
                                            name="positions"
                                            .value=${position.name}
                                            placeholder="z.B. Küchenchef"
                                            required
                                            @change=${(e: Event) =>
                                                (position.name = (e.target as HTMLInputElement).value)}
                                            class="plain"
                                        />

                                        <button
                                            type="button"
                                            class="transparent skinny"
                                            @click=${() => this._removePosition(i)}
                                        >
                                            <i class="trash"></i>
                                        </button>
                                    </div>
                                `}
                            >
                            </ptc-sortable-list>

                            <div class="vertical layout">
                                <button class="small transparent" type="button" @click=${this._addPosition}>
                                    <i class="plus"></i>
                                    Neue Position
                                </button>
                            </div>
                        </div>

                        <ptc-checkbox-button
                            buttonClass="ghost"
                            name="bonusesAreTaxed"
                            .checked=${!!dep.bonusesAreTaxed}
                            label="SFN-Zuschläge sind Beitragspflichtig"
                            class="top-margined"
                            @change=${(e: Event) => (dep.bonusesAreTaxed = (e.target as Checkbox).checked)}
                            hidden
                        ></ptc-checkbox-button>

                        <div class="negative margined message" ?hidden=${!this._error}>${this._error}</div>
                    </div>
                </ptc-scroller>

                <div class="padded horizontal spacing evenly stretching layout">
                    <button class="primary">Speichern</button>

                    <button type="button" @click=${() => this.done()} class="transparent">Abbrechen</button>
                </div>
            </form>
        `;
    }
}
