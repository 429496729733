import { Venue } from "@pentacode/core/src/model";
import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { HolidayName, HOLIDAYS } from "@pentacode/core/src/holidays";
import { shared } from "../styles";
import { Checkbox } from "./checkbox";
import "./popover";
import { app } from "../init";
import { StateCode } from "@pentacode/core/src/localized";

@customElement("ptc-venue-form")
export class VenueForm extends LitElement {
    @property({ type: Boolean })
    hideTimeSettings = false;

    @property()
    usedVenueNumbers: number[] = [];

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='venueNumber']")
    private _venueNumberInput: HTMLInputElement;

    get data() {
        const formData = this._form && new FormData(this._form);

        if (!formData) {
            return null;
        }

        const venueNumberRaw = formData.get("venueNumber");

        return {
            name: formData.get("name") as string,
            address: formData.get("address") as string,
            postalCode: formData.get("postalCode") as string,
            city: formData.get("city") as string,
            state: formData.get("state") as StateCode,
            holidays: formData.getAll("holidays") as string[],
            venueNumber: venueNumberRaw ? Number(venueNumberRaw) : undefined,
        };
    }

    firstUpdated() {
        this.init(new Venue());
    }

    init(venue: Venue) {
        const { holidays, ...rest } = venue;

        for (const [prop, value] of Object.entries(rest)) {
            const inp = this.renderRoot.querySelector(`[name=${prop}]`) as HTMLInputElement | null;
            inp && (inp.value = value?.toString() || "");
        }

        this._setHolidays(holidays);
    }

    private _setHolidays(holidays?: HolidayName[] | null) {
        if (!this.data) {
            return;
        }

        if (!holidays) {
            holidays = Object.values(HOLIDAYS)
                .flat()
                .filter((holiday) => holiday.states.includes(this.data!.state))
                .map((holiday) => holiday.name);
        }

        for (const checkbox of this.renderRoot.querySelectorAll(`[name="holidays"]`) as NodeListOf<Checkbox>) {
            checkbox.checked = holidays.includes(checkbox.value);
        }

        this.requestUpdate();
    }

    private _submit(e: FocusEvent) {
        e.preventDefault();
        this.dispatchEvent(new CustomEvent("submit", { detail: { data: this.data } }));
    }

    public validateVenueNumber() {
        if (!this.data?.venueNumber) {
            this._venueNumberInput.setCustomValidity("");
            this._form.reportValidity();
            return true;
        }

        if (this.usedVenueNumbers.indexOf(this.data?.venueNumber) !== -1) {
            this._venueNumberInput.setCustomValidity("Diese Standortnummer ist bereits vergeben.");
            this._form.reportValidity();
            return false;
        }

        this._venueNumberInput.setCustomValidity("");
        this._form.reportValidity();
        return true;
    }

    static styles = [shared, Checkbox.styles];

    render() {
        return html`
            <form @submit=${this._submit}>
                <div class="horizontal spacing layout">
                    <div class="field stretch">
                        <label>Standortname</label>
                        <input type="text" name="name" placeholder="Standortname" required />
                    </div>

                    <div class="field">
                        <label>Standortnummer</label>
                        <input type="number" name="venueNumber" placeholder="Standortnummer" />
                    </div>
                </div>

                <div class="field">
                    <label>Adresse</label>
                    <input type="text" name="address" placeholder="Strasse / Hausnummer" required />
                </div>

                <div class="horizontal spacing layout">
                    <div class="field stretch">
                        <input type="number" pattern="d*" name="postalCode" placeholder="PLZ" required />
                    </div>

                    <div class="field stretch">
                        <input type="text" name="city" placeholder="Stadt" required />
                    </div>
                </div>

                <div class="field">
                    <label>Bundesland</label>

                    <select name="state" required @change=${() => this._setHolidays()}>
                        ${app.localized.states.map(
                            (state) => html` <option value=${state.code}>${state.name}</option> `
                        )}
                    </select>
                </div>

                <div class="field">
                    <label>Feiertage</label>
                    <button class="slim ghost" type="button">
                        <div class="normal-weight text-left-aligning horizontal center-aligning layout">
                            <div class="stretch">
                                ${this.data?.holidays?.length
                                    ? html`<span class="bold">${this.data.holidays.length}</span> Feiertage gewählt`
                                    : html`<span class="subtle">Feiertage Wählen</span>`}
                            </div>
                            <i class="angle-down"></i>
                        </div>
                    </button>
                    <ptc-popover style="padding: 0">
                        <ptc-scroller style="max-height: 40em">
                            <div class="padded spacing vertical layout">
                                ${HOLIDAYS[app.company?.country || "DE"]
                                    .filter((hol) => !hol.noRealHoliday)
                                    .map(
                                        (holiday) => html`
                                            <ptc-checkbox-button
                                                name="holidays"
                                                buttonClass="small slim ghost"
                                                .value=${holiday.name}
                                                .label=${holiday.name}
                                                @change=${() => this.requestUpdate()}
                                            ></ptc-checkbox-button>
                                        `
                                    )}
                            </div>
                        </ptc-scroller>
                    </ptc-popover>
                </div>
            </form>
        `;
    }
}
