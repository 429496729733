import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import "./popover";
import "./scroller";
import { app } from "../init";
import { live } from "lit/directives/live.js";
import { singleton } from "../lib/singleton";
import { WageTypeDialog } from "./wage-type-dialog";

@customElement("ptc-select-wage-type")
export class SelectWageType extends LitElement {
    @property()
    value: string;

    @property()
    name: string;

    @state()
    private _filterString = "";

    @singleton("ptc-wage-type-dialog")
    private _wageTypeDialog: WageTypeDialog;

    @query("input[type=search]")
    private _filterInput: HTMLInputElement;

    protected createRenderRoot() {
        return this;
    }

    private async _addWageType() {
        if (!app.company) {
            return;
        }

        const wageType = await this._wageTypeDialog.show();
        if (wageType) {
            await app.updateCompany({ wageTypes: [...app.company.wageTypes, wageType] });
            this.value = wageType.number;
        }
    }

    render() {
        const wageTypes = app.company?.wageTypes || [];
        const selected =
            this.value &&
            (wageTypes.find((wageType) => wageType.number === this.value) || {
                name: "Unbekannte Lohnart",
                number: this.value,
            });

        return html`
            <button class="fill-horizontally text-left-aligning ghost" type="button">
                <div class="bold center-aligning spacing horizontal layout">
                    ${selected
                        ? html`
                              <div class="stretch ellipsis">${selected.name}</div>
                              <div class="subtle">#${selected.number}</div>
                          `
                        : html`<div class="faded stretch">[Keine Lohnart gewählt]</div>`}
                    <i class="caret-down"></i>
                </div>
            </button>

            <ptc-popover
                style="min-width: 18em;"
                class="small"
                @popover-show=${() => {
                    this._filterString = this._filterInput.value = "";
                    this._filterInput.focus();
                    this.requestUpdate();
                }}
            >
                <div class="vertical layout">
                    <div class="left icon input">
                        <i class="search"></i>
                        <input
                            type="search"
                            placeholder="Filtern..."
                            @input=${() => (this._filterString = this._filterInput.value)}
                        />
                    </div>
                </div>
                <ptc-scroller style="max-height: 50vh">
                    <div class="top-padded half-spacing vertical layout">
                        ${this.value !== ""
                            ? html`
                                  <button
                                      type="button"
                                      class="text-left-aligning slim transparent"
                                      @click=${() => (this.value = "")}
                                  >
                                      <i class="times"></i> Lohnart entfernen
                                  </button>
                                  <div class="border-bottom"></div>
                              `
                            : ""}
                        ${!wageTypes.length
                            ? html` <div class="padded subtle">Keine Lohnarten verfügbar</div> `
                            : wageTypes
                                  .filter(({ name, description, number }) =>
                                      `${name} ${description} ${number}`
                                          .toLowerCase()
                                          .includes(this._filterString.toLowerCase())
                                  )
                                  .map(
                                      (wageType) => html`
                                          <button
                                              type="button"
                                              class="text-left-aligning slim transparent"
                                              @click=${() => (this.value = wageType.number)}
                                          >
                                              <div class="bold center-aligning spacing horizontal layout">
                                                  <div class="stretch">${wageType.name}</div>
                                                  <div class="subtle smaller">#${wageType.number}</div>
                                              </div>
                                              ${wageType.description
                                                  ? html`<div class="smaller subtle">${wageType.description}</div>`
                                                  : ""}
                                          </button>
                                      `
                                  )}
                        ${app.hasPermission("manage.settings.accounting")
                            ? html`
                                  <div class="border-top"></div>
                                  <button
                                      type="button"
                                      class="text-left-aligning slim transparent"
                                      @click=${this._addWageType}
                                  >
                                      <i class="plus"></i> Neue Lohnart
                                  </button>
                              `
                            : html``}
                    </div></ptc-scroller
                >
            </ptc-popover>

            <input type="hidden" .name=${this.name} .value=${live(this.value)} />
        `;
    }
}
