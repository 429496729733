import { LitElement, css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";

import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import "./scroller";
import "./revenue-item";
import "./spinner";
import {
    formatDate,
    getMonthRange,
    GetRevenueEntriesParams,
    RevenueType,
    revenueTypeIcon,
    revenueTypeLabel,
    taxKeyLabel,
} from "@pentacode/core";
import { alert } from "./alert-dialog";

import { shared } from "../styles";
import { app } from "../init";
import { Task } from "@lit/task";
import "./entity-multi-select";
import type { EntityMultiSelect } from "./entity-multi-select";
import { Balance } from "./balance";

const allRevenueTypes = [
    RevenueType.Adjustment,
    RevenueType.BankDeposit,
    RevenueType.Cashless,
    RevenueType.Debt,
    RevenueType.Deposit,
    RevenueType.Expense,
    RevenueType.Other,
    RevenueType.PayAdvance,
    RevenueType.Sales,
].sort((a, b) => revenueTypeLabel(a)?.localeCompare(revenueTypeLabel(b) || "") || 0);

@customElement("ptc-revenues-all")
export class RevenuesAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^revenues\/all/;

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .scroller {
                flex: 1;
                overflow: auto;
            }

            thead {
                position: sticky;
                top: 0;
                z-index: 2;
            }

            tbody {
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                align-items: center;
                width: 100%;
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th,
            td {
                padding: 0.5em;
                vertical-align: middle;
            }

            thead {
                font-weight: bold;
                background: var(--color-bg);
                text-align: left;
            }

            tr > td,
            tr > th {
                text-align: left;
            }

            tr > td:last-child,
            tr > th:last-child {
                text-align: right;
            }

            .no-border {
                border: none;
            }

            .positive {
                color: var(--color-positive);
            }

            .negative {
                color: var(--color-negative);
            }
        `,
    ];

    public get routeTitle() {
        return (this.venue && `Alle Umsätze: ${this.venue.name}`) || undefined;
    }

    protected handleRoute() {
        void this._entriesTask.run();
    }

    @query("#typeSelector")
    private _typesSelector: EntityMultiSelect<RevenueType>;

    @state()
    private _revenueTypes: RevenueType[] = allRevenueTypes;

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @state()
    private _filterString = "";

    private _updateFilters() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _updateTypes() {
        this._revenueTypes = this._typesSelector.selected;
        if (!this._revenueTypes.length) {
            this._revenueTypes = allRevenueTypes;
        }
        this.requestUpdate();
    }

    private _entriesTask = new Task(this, {
        task: async ([venueId, date]) => {
            if (!venueId || !date) {
                return [];
            }

            try {
                return await app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        from: getMonthRange(this.date).from,
                        to: getMonthRange(this.date).to,
                        venue: venueId,
                    })
                );
            } catch (e) {
                void alert(e.message, { type: "warning" });
                return [];
            }
        },
        args: () => [this.venue?.id, this.date] as const,
    });

    protected render(): unknown {
        return html`
            <div class="center-aligning horizontal layout padded border-top border-bottom">
                <i class="filter"></i>
                <ptc-entity-multi-select
                    id="typeSelector"
                    addLabel="Filter Hinzufügen..."
                    .existing=${allRevenueTypes}
                    .getLabel=${(type: RevenueType) => revenueTypeLabel(type)}
                    .getIcon=${(type: RevenueType) => revenueTypeIcon(type)}
                    @change=${() => this._updateTypes()}
                    class="smaller no-border  stretch"
                ></ptc-entity-multi-select>
                <div class="filter-input right icon input smaller">
                    <input
                        id="filterInput"
                        type="text"
                        placeholder="Suchen..."
                        @input=${() => this._updateFilters()}
                        autocomplete="off"
                    />
                    <i class="${this._filterString ? "times click" : "search"} icon" @click=${this._clearFilter}></i>
                </div>
            </div>
            <div class="scroller">
                <div class="double-padded">
                    <table class="fill-horizontally">
                        <thead>
                            <tr>
                                <th>Art</th>
                                <th>Datum</th>
                                <th>Text</th>
                                <th>Belegnummer</th>
                                <th>Steuersatz</th>
                                <th>Konto</th>
                                <th>Kostenstelle</th>
                                <th>Betrag</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${this._entriesTask.render({
                                pending: () => this._renderLoading(),
                                complete: (entries) =>
                                    repeat(
                                        entries
                                            .filter((entry) => this._revenueTypes.includes(entry.type))
                                            .filter(
                                                (entry) =>
                                                    this._filterString.trim() === "" ||
                                                    entry.text.toLowerCase().includes(this._filterString)
                                            ),
                                        (entry) => entry.id,
                                        (entry) =>
                                            html`<tr class="row">
                                                <td title="${revenueTypeLabel(entry.type) || ""}">
                                                    <i class="${revenueTypeIcon(entry.type) || ""}"></i>
                                                </td>
                                                <td>${formatDate(entry.date)}</td>
                                                <td>${entry.text}</td>
                                                <td>${entry.receipt}</td>
                                                <td>${taxKeyLabel(entry.taxKey)}</td>
                                                <td>${entry.ledger}</td>
                                                <td>${entry.costCenter}</td>
                                                <td>
                                                    <ptc-balance
                                                        icons="plus-minus"
                                                        .value="${entry.amount}"
                                                    ></ptc-balance>
                                                </td>
                                            </tr>`
                                    ),
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        `;
    }

    private _renderLoading() {
        return html`<div class="fullbleed center-aligning center-justifying vertical layout scrim">
            <ptc-spinner active></ptc-spinner>
        </div>`;
    }
}
