import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { RevenueGroup, RevenueType, taxKeyLabel, Department } from "@pentacode/core/src/model";
import { GetRevenueGroupsParams, UpdateRevenueGroupParams } from "@pentacode/core/src/api";
import { debounce } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared, colors } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import { isSafari } from "../lib/util";

@customElement("ptc-revenues-attribution")
export class RevenueTemplates extends Routing(StateMixin(LitElement)) {
    routePattern = /^revenues\/attribution/;

    get routeTitle() {
        return this.venue ? `Erlöszuordnung: ${this.venue.name}` : undefined;
    }

    @state()
    private _loading = false;

    @state()
    private _groups: RevenueGroup[] = [];

    @state()
    private _draggingDepartment: Department | null = null;

    handleRoute() {
        this._load();
    }

    private async _load() {
        if (this._loading || !this.venue) {
            return;
        }

        this._loading = true;

        try {
            this._groups = await app.api.getRevenueGroups(
                new GetRevenueGroupsParams({
                    venue: this.venue.id,
                    type: [RevenueType.Sales],
                    reporting: true,
                    excludeSuggestions: true,
                })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _dragstart(_e: DragEvent, dep: Department) {
        // const dragEl = document.createElement("ptc-revenues-templates-dragelement") as DragElement;
        // dragEl.group = group;
        // this.renderRoot!.appendChild(dragEl);
        this.classList.add("dragging");
        this._draggingDepartment = dep;
    }

    private async _dragend(e: DragEvent) {
        e.preventDefault();
        for (const row of this.renderRoot!.querySelectorAll(".group")) {
            row.classList.remove("dragover");
        }
        this.classList.remove("dragging");
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = "link";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _drop(e: DragEvent, group: RevenueGroup) {
        e.preventDefault();
        if (this._draggingDepartment) {
            this._addAttribution(group, this._draggingDepartment);
        }
        this._draggingDepartment = null;
        for (const row of this.renderRoot!.querySelectorAll(".group")) {
            row.classList.remove("dragover");
        }
    }

    private _addAttribution(group: RevenueGroup, dep: Department) {
        if (!group.attributions.some((a) => a.department === dep.id)) {
            group.attributions.push({ department: dep.id, ratio: group.attributions.length ? 0 : 100 });
        }
        const ratio = 100 / group.attributions.length;
        group.attributions.forEach((a) => (a.ratio = ratio));
        this.requestUpdate();
        this._updateGroup(group);
    }

    private _removeAttribution(group: RevenueGroup, dep: Department) {
        group.attributions = group.attributions.filter((a) => a.department !== dep.id);
        const ratio = 100 / group.attributions.length;
        group.attributions.forEach((a) => (a.ratio = ratio));
        this.requestUpdate();
        this._updateGroup(group);
    }

    private _focusAttributionRatio(group: RevenueGroup, dep: Department) {
        const input = this.renderRoot!.querySelector(
            `input[data-group="${group.id}"][data-department="${dep.id}"]`
        ) as HTMLInputElement;
        input && input.select();
    }

    private _updateAttributionRatio(group: RevenueGroup, dep: Department, value: number) {
        const currIndex = group.attributions.findIndex((a) => a.department === dep.id);
        const att = group.attributions[currIndex];

        if (!att) {
            return;
        }

        att.ratio = isNaN(value) ? 0 : value;

        let diff: number;
        let i = group.attributions.length - 1;

        while ((diff = 100 - group.attributions.reduce((total, a) => total + a.ratio, 0)) && i >= 0) {
            if (i !== currIndex) {
                group.attributions[i].ratio = Math.max(0, Math.min(100, group.attributions[i].ratio + diff));
            }
            i--;
        }

        this.requestUpdate();

        this._updateGroup(group);
    }

    private _updateGroup = debounce(
        (group: RevenueGroup) => {
            app.api.updateRevenueGroup(new UpdateRevenueGroupParams({ group }));
        },
        2000,
        (group) => group.id
    );

    static styles = [
        shared,
        css`
            .group {
                margin: 1em;
                border-radius: calc(2 * var(--border-radius));
                border: solid 1px var(--shade-2);
            }

            .group-name {
                font-size: var(--font-size-medium);
                margin: 1em;
                font-weight: bold;
            }

            .group .pills {
                margin: 1em 1.2em;
            }

            .attributions {
                margin: 1em;
                border-radius: calc(2 * var(--border-radius));
                border: dashed 1px var(--shade-2);
                min-height: 3em;
                position: relative;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
                padding: 1em 0.5em 0.5em 0.5em;
                grid-gap: 0.5em;
            }

            .attributions::before {
                content: "Zuordnung";
                display: block;
                position: absolute;
                top: -1em;
                left: 0;
                right: 0;
                width: 6em;
                margin: auto;
                background: var(--color-bg);
                padding: 0.2em;
                border-radius: var(--border-radius);
                text-align: center;
            }

            .group.dragover .attributions {
                background: var(--blue-bg);
            }

            .attribution-department {
                color: var(--color-highlight);
                border: solid 1px;
                padding: 0.3em;
                border-radius: var(--border-radius);
                font-weight: bold;
                text-align: left;
                position: relative;
                z-index: 1;
            }

            .attribution-department-bar {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background: var(--color-highlight);
                z-index: -1;
                border-radius: var(--border-radius);
                opacity: 0.3;
            }

            .attribution-department input {
                padding: 0.3em 0.8em 0.3em 0.3em;
                width: 5em;
                text-align: right;
                background: transparent;
            }

            .attribution-department-name {
                padding: 0 0.5em;
                cursor: pointer;
            }

            .attribution-department input:not(:hover):not(:focus) {
                border-color: transparent;
            }

            .departments {
                width: 20em;
            }

            .department {
                border-radius: calc(2 * var(--border-radius));
                background: var(--color-highlight-bg);
                color: var(--color-highlight);
                border: solid 1px;
                padding: 1em;
                margin: 1em;
                font-weight: bold;
                opacity: 0.999;
            }

            :host(.dragging) .group > * {
                pointer-events: none;
            }

            [draggable="true"] {
                cursor: grab;
                transition: box-shadow 0.2s;
            }

            [draggable="true"]:hover {
                position: relative;
                z-index: 1;
                box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
                background: var(--color-bg);
            }

            [draggable="true"]:active {
                cursor: grabbing;
            }

            .attribution-department:not(:hover) .remove-attribution {
                display: none;
            }

            button.remove-attribution {
                position: absolute;
                right: -0.8em;
                top: -0.8em;
                font-size: var(--font-size-tiny);
                padding: 0.3em;
            }

            .no-groups {
                margin: 4em 2em;
                text-align: center;
            }

            .no-groups > button {
                margin-top: 1em;
            }
        `,
    ];

    render() {
        if (!this.venue) {
            return;
        }

        return html`
            <div class="fullbleed horizontal layout">
                <ptc-scroller class="revenue stretch collapse border-right">
                    ${this._groups.length
                        ? this._groups.map((group) => {
                              const { name, ledger, costCenter, taxKey } = group;
                              return html`
                                  <div
                                      class="group"
                                      @drop=${(evt: DragEvent) => this._drop(evt, group)}
                                      @dragover=${(evt: DragEvent) => this._dragover(evt)}
                                      @dragenter=${(evt: DragEvent) => this._dragenter(evt)}
                                      @dragleave=${(evt: DragEvent) => this._dragleave(evt)}
                                  >
                                      <div class="group-name">${name}</div>
                                      <div class="small pills">
                                          ${ledger
                                              ? html` <div class="pill"><strong>Konto: </strong> ${ledger}</div> `
                                              : ""}
                                          ${taxKey
                                              ? html`
                                                    <div class="pill"><strong>St: </strong> ${taxKeyLabel(taxKey)}</div>
                                                `
                                              : ""}
                                          ${costCenter
                                              ? html` <div class="pill"><strong>KoSt: </strong> ${costCenter}</div> `
                                              : ""}
                                      </div>

                                      <div class="attributions">
                                          ${repeat(
                                              group.attributions,
                                              (a) => a.department,
                                              (a) => {
                                                  const { department } = app.getDepartment(a.department);
                                                  if (!department) {
                                                      return;
                                                  }

                                                  return html`
                                                      <div
                                                          class="attribution-department horizontal center-aligning layout"
                                                          style="--color-highlight: ${colors[department.color] ||
                                                          department.color}"
                                                      >
                                                          <button
                                                              class="icon remove-attribution"
                                                              @click=${() => this._removeAttribution(group, department)}
                                                          >
                                                              <i class="times"></i>
                                                          </button>

                                                          <div
                                                              class="attribution-department-bar"
                                                              style="width: ${a.ratio}%"
                                                          ></div>

                                                          <label
                                                              class="attribution-department-name stretch ellipsis"
                                                              for="attribution-${department.id}"
                                                              @click=${() =>
                                                                  this._focusAttributionRatio(group, department)}
                                                          >
                                                              ${department.name}
                                                          </label>
                                                          <div class="right icon input">
                                                              <input
                                                                  type="number"
                                                                  step="1"
                                                                  min="0"
                                                                  max="100"
                                                                  required
                                                                  .value=${a.ratio}
                                                                  data-group="${group.id}"
                                                                  data-department="${department.id}"
                                                                  @input=${(e: Event) =>
                                                                      this._updateAttributionRatio(
                                                                          group,
                                                                          department,
                                                                          Number((e.target! as HTMLInputElement).value)
                                                                      )}
                                                              />
                                                              <i class="percent"></i>
                                                          </div>
                                                      </div>
                                                  `;
                                              }
                                          )}
                                      </div>
                                  </div>
                              `;
                          })
                        : html`
                              <div class="no-groups">
                                  <div>Sie haben noch keine Umsatzgruppen definiert.</div>

                                  <button @click=${() => this.go("revenues/groups")}>
                                      Jetzt Anlegen <i class="arrow-right"></i>
                                  </button>
                              </div>
                          `}
                </ptc-scroller>

                <ptc-scroller class="departments">
                    ${this.venue.departments.map(
                        (dep) => html`
                            <div
                                class="department"
                                draggable="true"
                                @dragstart=${(evt: DragEvent) => this._dragstart(evt, dep)}
                                @dragend=${(evt: DragEvent) => this._dragend(evt)}
                                style="--color-highlight: ${colors[dep.color] || dep.color}"
                            >
                                ${dep.name}
                            </div>
                        `
                    )}
                </ptc-scroller>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
