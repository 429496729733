import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { shared } from "../styles";
import "./popover";

@customElement("ptc-entity-multi-select")
export class EntityMultiSelect<T> extends LitElement {
    @property({ attribute: false })
    existing: T[] = [];

    @property({ attribute: false })
    selected: T[] = [];

    @property()
    emptyLabel: string = "";

    @property()
    emptyIcon: string = "";

    @property()
    addLabel: string = "Hinzufügen...";

    @property()
    noOptionsLabel = "Tippen zum Filtern / neue Kategorie anlegen";

    @property({ attribute: false })
    getId: (e: T) => string | number = (e: T) => String(e);

    @property({ attribute: false })
    getLabel: (e: T) => string = (e: T) => String(e);

    @property({ attribute: false })
    getColor?: (e: T) => string;

    @property({ attribute: false })
    getIcon?: (e: T) => string;

    @property({ attribute: false })
    isMatch: (e: T, str: string) => boolean = (e: T, str: string) => this.getLabel(e).toLowerCase().includes(str);

    @property({ attribute: false })
    makeNew?: (val: string) => T;

    @query("input")
    private _input: HTMLInputElement;

    private _keydown(e: KeyboardEvent) {
        if (this.makeNew && e.key === "Enter") {
            this._addEntity(this.makeNew(this._input.value));
        }
    }

    private async _addEntity(entity: T) {
        if (!entity || this.selected.some((e) => this.getId(e) === this.getId(entity))) {
            return;
        }
        this.selected.push(entity);
        this._input.value = "";
        this.requestUpdate();
        await this.updateComplete;
        this._input.focus();
        this.dispatchEvent(new CustomEvent("change"));
    }

    private _entityClicked(entity: T) {
        this.selected = this.selected.filter((e) => this.getId(e) !== this.getId(entity));
        this.dispatchEvent(new CustomEvent("change"));
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
                flex-direction: row;
                gap: 0.3em;
                flex-wrap: wrap;
                border: solid 1px var(--shade-2);
                border-radius: 0.5em;
                position: relative;
                overflow: visible;
                padding: 0.3em;
                font-size: inherit;
            }

            :host(.slim) {
                padding: 0.3em;
            }

            input {
                padding: 0.3em;
                border: none;
                background: none;
                width: 100%;
            }

            :host(.focused) {
                border-color: var(--color-primary);
            }

            :host(:not(.focused)) i.plus {
                opacity: 0.5;
            }

            .filters-popover {
                padding: 0.5em;
                width: 20em;
                z-index: 10;
            }

            .add-tag {
                overflow: visible;
                width: 10em;
            }

            .pill,
            .add-tag {
                font-size: 0.9em;
            }

            .pill:not(:hover) > .remove-icon {
                display: none;
            }
        `,
    ];

    render() {
        const existing = this.existing;
        const value = this._input?.value || "";
        const results = existing.filter(
            (e) => !this.selected.some((e2) => this.getId(e2) === this.getId(e)) && this.isMatch(e, value.toLowerCase())
        );
        if (this.makeNew && value) {
            results.push(this.makeNew(value));
        }

        return html`
            ${!this.selected.length && this.emptyLabel
                ? html`
                      <div class="pill">
                          ${this.emptyIcon ? html`<i class="${this.emptyIcon}"></i> ` : ""}${this.emptyLabel}
                      </div>
                  `
                : ""}
            ${this.selected.map(
                (e) => html`
                    <div
                        class="pill click"
                        @click=${() => this._entityClicked(e)}
                        style="--color-highlight: ${this.getColor?.(e)}"
                    >
                        ${this.getIcon ? html` <i class="${this.getIcon(e)}"></i> ` : ""} ${this.getLabel(e)}
                        <i class="times remove-icon"></i>
                    </div>
                `
            )}

            <div class="add-tag">
                <div class="center-aligning horizontal layout">
                    <i class="plus"></i>

                    <input
                        class="stretch"
                        placeholder="${this.addLabel}"
                        @keydown=${this._keydown}
                        @input=${() => this.requestUpdate()}
                    />

                    <ptc-popover
                        class="filters-popover"
                        trigger="focus"
                        style="padding: 0.5em; --popover-hover-buffer: 0;"
                    >
                        <ptc-scroller style="max-height: 50vh;">
                            <div class="vertical layout">
                                ${results.length
                                    ? results.map(
                                          (res) => html`
                                              <button
                                                  class="text-left-aligning slim transparent ellipsis"
                                                  @click=${() => this._addEntity(res)}
                                                  style="--color-highlight: ${this.getColor?.(res)}"
                                              >
                                                  ${this.getIcon ? html` <i class="${this.getIcon(res)}"></i> ` : ""}
                                                  ${this.getLabel(res)}
                                              </button>
                                          `
                                      )
                                    : html` <div class="padded faded text-centering">${this.noOptionsLabel}</div> `}
                            </div>
                        </ptc-scroller>
                    </ptc-popover>
                </div>
            </div>
        `;
    }
}
