import { LitElement, TemplateResult, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { live } from "lit/directives/live.js";
import "./popover";
import "./scroller";

@customElement("ptc-select")
export class Select<T> extends LitElement {
    @property({ attribute: false })
    options: T[] = [];

    @property({ attribute: false })
    selected: T | null = null;

    @property()
    name: string;

    @property({ type: Boolean })
    readonly: boolean = false;

    @property({ attribute: false })
    renderOption: (option: T | null) => TemplateResult | string = (option) =>
        option === null ? "[ Nichts Gewählt ]" : String(option);

    @property({ attribute: false })
    renderLabel: (option: T | null) => TemplateResult | string = (option) => this.renderOption(option);

    @property()
    getValue: (option: T) => string = (option) => String(option);

    @property()
    buttonClass = "ghost";

    @property()
    get value(): string {
        return (this.selected && this.getValue(this.selected)) || "";
    }
    set value(val: string) {
        this.selected = this.options.find((option) => this.getValue(option) === val) || null;
    }

    createRenderRoot() {
        return this;
    }

    private _selectOption(option: T) {
        this.selected = option;
        this.dispatchEvent(
            new CustomEvent("change", {
                detail: { option },
            })
        );
    }

    render() {
        return html`
            <button
                type="button"
                class="fill-horizontally text-left-aligning ${this.buttonClass}"
                style="padding: 0;${this.readonly ? " pointer-events: none;" : ""}"
            >
                <div class="center-aligning horizontal layout">
                    <div class="stretch">${this.renderLabel(this.selected)}</div>
                    <i class="caret-down horizontally-margined"></i>
                </div>
            </button>

            <ptc-popover hide-on-click style="padding: 0; max-width: var(--select-max-width, 20em);">
                <ptc-scroller style="max-height: var(--select-max-height, 50vh);">
                    ${this.options.map(
                        (option, i) => html`
                            <div
                                class="click ${i !== 0 ? "border-top" : ""} ${this.selected &&
                                this.getValue(option) === this.getValue(this.selected)
                                    ? "blue colored-text"
                                    : ""}"
                                @click=${() => this._selectOption(option)}
                            >
                                ${this.renderOption(option)}
                            </div>
                        `
                    )}
                </ptc-scroller>
            </ptc-popover>

            <input type="hidden" .value=${live(this.value)} name=${this.name} />
        `;
    }
}
