import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { TimeEntry, Department, Venue } from "@pentacode/core/src/model";
import { formatDateShort, formatNumber, getDateArray } from "@pentacode/core/src/util";
import { app } from "../init";
import { colors } from "../styles";
import { ChartConfig } from "./chart";
import { DateString } from "packages/openapi/src/units";

@customElement("ptc-roster-costs")
export class RosterCosts extends LitElement {
    @property({ attribute: false })
    from: DateString;

    @property({ attribute: false })
    to: DateString;

    @property({ attribute: false })
    venue: Venue;

    @property({ attribute: false })
    departments: Department[] = [];

    @property({ attribute: false })
    entries: TimeEntry[] = [];

    private get _dates() {
        return getDateArray(this.from, this.to);
    }

    @state()
    private _costsPlanned = 0;

    @state()
    private _costsFinal = 0;

    @state()
    private _chartConfig: ChartConfig;

    updated(changes: Map<string, unknown>) {
        if (
            changes.has("venue") ||
            changes.has("from") ||
            changes.has("to") ||
            changes.has("entries") ||
            changes.has("statements") ||
            changes.has("departments")
        ) {
            this.updateConfig();
        }
    }

    createRenderRoot() {
        return this;
    }

    updateConfig() {
        const dates = this._dates;

        const dailyCosts = new Map<string, { planned: number; final: number }>();

        for (const date of dates) {
            const entries = this.entries.filter((e) => {
                const position = e.position || app.getEmployee(e.employeeId)?.positions?.[0];
                return (
                    position &&
                    e.date === date &&
                    !e.deleted &&
                    this.departments.some((d) => d.positions.some((p) => p.id === position.id))
                );
            });

            dailyCosts.set(date, {
                planned: entries.reduce((total, e) => total + (e.resultPlanned?.totalCosts || 0), 0),
                final: entries.reduce((total, e) => total + (e.result?.totalCosts || 0), 0),
            });
        }

        const seriesPlanned = {
            name: "Geplant",
            data: [...dailyCosts.entries()].map(([, { planned }]) => planned),
        };
        const seriesFinal = {
            name: "Geleistet",
            data: [...dailyCosts.entries()].map(([, { final }]) => final),
        };

        this._costsPlanned = [...dailyCosts.values()].reduce((total, { planned }) => total + planned, 0);
        this._costsFinal = [...dailyCosts.values()].reduce((total, { final }) => total + final, 0);

        const headerWidth = (this.querySelector(".costs-total") as HTMLDivElement)?.offsetWidth || 0;
        const balancesWidth = (this.querySelector(".balance-before") as HTMLDivElement)?.offsetWidth || 0;
        const cellWidth = (this.querySelector(".employee-day") as HTMLDivElement)?.offsetWidth || 0;

        this._chartConfig = {
            chart: {
                type: "line",
                height: 60,
                sparkline: {
                    enabled: true,
                },
            },
            colors: [colors.blue, colors.orange],
            legend: {
                show: false,
                floating: true,
                position: "top",
                horizontalAlign: "left",
            },
            series: [seriesPlanned, seriesFinal],
            xaxis: {
                type: "category",
            },
            grid: {
                padding: {
                    left: headerWidth + balancesWidth + cellWidth / 2,
                    right: balancesWidth + cellWidth / 2,
                    top: 10,
                    bottom: 10,
                },
            },
            dataLabels: {
                enabled: app.settings.rosterDisplayMode !== "minimal",
                formatter: (val: number) => formatNumber(val, 0) + " €",
            },
            tooltip: {
                enabled: true,
                x: { show: true, formatter: (i: number) => formatDateShort(dates[i - 1]) },
                y: { formatter: (val: number) => formatNumber(val, 0) + " €" },
                followCursor: true,
            },
            stroke: {
                curve: "smooth",
            },
        };
    }

    static styles = css`
        .row.costs {
            height: 60px;
        }

        .costs-total {
            position: sticky;
            z-index: 9;
            background: rgba(255, 255, 255, 0.95);
            text-align: center;
            left: 0;
        }

        .costs-total-title {
            font-weight: 600;
        }

        .costs-total-value {
            font-size: var(--font-size-large);
            color: var(--color-highlight);
        }

        .row.costs ptc-chart {
            position: absolute;
            inset: 0;
            width: auto;
        }
    `;

    render() {
        return html`
            <div class="row costs">
                <div class="costs-total">
                    <div class="fullbleed center-aligning evenly stretching horizontal layout">
                        <div class="blue">
                            <div class="costs-total-title">Geplant</div>
                            <div class="costs-total-value">${formatNumber(this._costsPlanned, 0)} €</div>
                        </div>

                        <div class="orange">
                            <div class="costs-total-title">Geleistet</div>
                            <div class="costs-total-value">${formatNumber(this._costsFinal, 0)} €</div>
                        </div>
                    </div>
                </div>
                <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                    <div><i class="plus-minus"></i></div>
                </div>
                ${this._dates.map(() => html` <div class="employee-day"></div> `)}
                <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                    <div><i class="plus-minus"></i></div>
                </div>
                <ptc-chart .config=${this._chartConfig}></ptc-chart>
            </div>
        `;
    }
}
