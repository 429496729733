import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { shared } from "../styles";
import type { EmojiClickEvent } from "./emoji-picker";
import "./emoji-picker";

@customElement("ptc-rich-text-editor")
export class RichTextEditor extends LitElement {
    get content() {
        return this._editor.getHTML();
    }

    set content(content: string) {
        this._editor.commands.clearContent();
        this._editor.commands.insertContent(content);
    }

    private _editor = new Editor({
        extensions: [StarterKit],
        onTransaction: () => this.requestUpdate(),
        onFocus: () => this.classList.add("focused"),
        onBlur: () => this.classList.remove("focused"),
    });

    firstUpdated() {
        this.renderRoot.querySelector(".container")!.append(this._editor.options.element);
        this.addEventListener("click", () => this._editor.commands.focus());
    }

    private _emojiSelected(e: EmojiClickEvent) {
        if (e.detail.unicode) {
            this._editor.chain().focus().insertContent(e.detail.unicode).run();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                position: relative;
                cursor: text;
                border: solid 1px var(--shade-2);
                border-radius: 0.5em;
                display: flex;
                flex-direction: column;
            }

            :host(.focused) {
                border-color: var(--color-primary);
            }

            ul {
                list-style: disc outside;
                padding-left: 1em;
            }

            h1,
            h2,
            h3,
            h4 {
                margin: 0 0 0.5em 0;
            }

            h1:not(:first-child),
            h2:not(:first-child),
            h3:not(:first-child),
            h4:not(:first-child) {
                margin-top: 0.5em;
            }

            p {
                margin: 0.5em 0;
            }

            p:first-child {
                margin-top: 0;
            }

            p:last-child {
                margin-bottom: 0;
            }

            em {
                font-style: italic;
            }
        `,
    ];

    render() {
        return html`
            <div class="small padded spacing horizontal center-aligning layout border-bottom">
                <button
                    class="${this._editor?.isActive("heading") ? "primary" : "transparent"} slim"
                    @click=${() => this._editor.chain().focus().setHeading({ level: 2 }).run()}
                >
                    <i class="heading"></i>
                </button>

                <button
                    class="${this._editor?.isActive("paragraph") ? "primary" : "transparent"} slim"
                    @click=${() => this._editor.chain().focus().setParagraph().run()}
                >
                    <i class="text"></i>
                </button>

                <div class="spacer"></div>

                <button
                    class="${this._editor?.isActive("bold") ? "primary" : "transparent"} slim"
                    @click=${() => this._editor.chain().focus().toggleBold().run()}
                >
                    <i class="bold"></i>
                </button>

                <button
                    class="${this._editor?.isActive("italic") ? "primary" : "transparent"} slim"
                    @click=${() => this._editor.chain().focus().toggleItalic().run()}
                >
                    <i class="italic"></i>
                </button>

                <button
                    class="${this._editor?.isActive("bulletList") ? "primary" : "transparent"} slim"
                    @click=${() => this._editor.chain().focus().toggleBulletList().run()}
                >
                    <i class="list"></i>
                </button>

                <div class="stretch"></div>

                <button class="${this._editor?.isActive("bulletList") ? "primary" : "transparent"} slim">
                    <i class="smile-wink"></i>
                </button>

                <ptc-emoji-picker
                    .preferAlignment=${["bottom", "bottom-right", "bottom-left"]}
                    @emoji-click=${this._emojiSelected}
                >
                </ptc-emoji-picker>
            </div>
            <div class="double-padded container scroller stretch" @click=${(e: Event) => e.stopPropagation()}></div>
        `;
    }
}
