import { Venue, Role, Department } from "@pentacode/core/src/model";
import { LitElement, html, css, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { colors, shared } from "../styles";
import { VenueDialog } from "./venue-dialog";
import { TransferVenueDialog } from "./transfer-venue-dialog";
import { alert, confirm } from "./alert-dialog";
import "./spinner";
import "./venue-departments";
import "./popover";
import "./scroller";
import "./help";
import "./sortable-list";
import { DepartmentDialog } from "./department-dialog";
import { UpdateVenueParams } from "@pentacode/core/src/api";
import { moveClamped } from "../lib/util";

@customElement("ptc-settings-venues")
export class SettingsVenues extends Routing(StateMixin(LitElement)) {
    routePattern = /^settings\/venues/;

    get routeTitle() {
        return "Arbeitsbereiche | Einstellungen";
    }

    get helpPage() {
        return "handbuch/einstellungen/arbeitsbereiche/";
    }

    @state()
    private _loading: boolean = false;

    @singleton("ptc-venue-dialog")
    private _venueDialog: VenueDialog;

    @singleton("ptc-transfer-venue-dialog")
    private _transferVenueDialog: TransferVenueDialog;

    private _newVenue() {
        void this._venueDialog.show(new Venue());
    }

    private _editVenue(venue: Venue) {
        void this._venueDialog.show(venue);
    }

    private async _archiveVenue(venue: Venue) {
        if (
            !(await confirm("Wollen Sie diesen Standort wirklich archivieren?", "Archivieren", "Abbrechen", {
                type: "destructive",
                title: "Standort Archivieren",
                icon: "archive",
            }))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.archiveVenue({ id: venue.id });
            void alert(
                "Der Standort wurde erfolgreich archiviert. Falls Sie ihn wiederherstellen " +
                    "möchten, melden Sie sich bitte bei unserem Supportteam!"
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _moveVenue(venue: Venue) {
        const company = await this._transferVenueDialog.show({
            venueName: venue.name,
        });

        if (
            !company ||
            !(await confirm(
                `Sind Sie sicher, dass Sie diesen Standort in das Unternehmen ${company.name} umziehen möchten?`,
                "Standort Umziehen"
            ))
        ) {
            return;
        }

        this._loading = true;
        try {
            await app.api.updateVenue({
                id: venue.id,
                company: company.id,
            });

            if (
                await confirm(
                    `Standort erfolgreich umgezogen! Möchten Sie nun in das Unternehmen ${company.name} wechseln?`,
                    "Ja",
                    "Nein",
                    {
                        type: "success",
                    }
                )
            ) {
                await app.selectCompany(company.id);
                window.location.reload();
            }
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _changeOrder(venue: Venue, direction: "up" | "down") {
        const index = app.venues.findIndex((v) => v.id === venue.id);
        if (index === -1) return; // could not find venue

        this._loading = true;

        try {
            const reorderedVenues = moveClamped(app.venues, index, direction === "up" ? index - 1 : index + 1);
            await Promise.all(
                reorderedVenues
                    .map((venue, index) => {
                        if (venue.order !== index) {
                            return app.api.updateVenue(new UpdateVenueParams({ id: venue.id, order: index }));
                        }
                    })
                    .filter(Boolean)
            );
            await app.fetchCompany();
        } catch (error) {
            void alert(error.message, { type: "warning" });
        }

        this._loading = false;
    }

    @singleton("ptc-department-dialog")
    private _departmentDialog: DepartmentDialog;

    private async _editDepartment(venue: Venue, department: Department) {
        void this._departmentDialog.show({ venue, department });
    }

    private async _newDepartment(venue: Venue) {
        void this._departmentDialog.show({ venue });
    }

    private async _departmentMoved(dep: Department, toIndex: number) {
        this._loading = true;
        try {
            await app.api.updateDepartment({
                id: dep.id,
                order: toIndex,
            });
            await app.fetchCompany();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    static styles = [
        shared,
        css`
            .department-color {
                width: 1.3em;
                height: 1.3em;
                border-radius: 1.3em;
                background: var(--color-highlight);
                margin: 0.1em 1.25em 0 0.35em;
                border: solid 1px var(--shade-1);
                box-sizing: border-box;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        // Only allow reordering venues if user has access to all venues.
        const allowReordering = app.venues.length === app.accessibleVenues.length;

        return html`
            <ptc-scroller class="fullbleed">
                <div class="double-padded" style="max-width: 50em; margin: 0 auto;">
                    ${app.accessibleVenues.map(
                        (venue, i) => html`
                            <div class="double-margined box">
                                <div class="padded horizontal start-aligning layout">
                                    <i class="half-margined huger people-roof"></i>

                                    <div class="padded stretch">
                                        <div class="horizontal middle-aligning layout">
                                            <div class="large bold small-caps">${venue.name}</div>
                                            ${venue.venueNumber
                                                ? html`<div class="padded smaller subtle">#${venue.venueNumber}</div>`
                                                : nothing}
                                        </div>
                                        <div class="subtle" style="margin-top: 0.2em">
                                            ${venue.address}, ${venue.postalCode} ${venue.city}, ${venue.stateName}
                                        </div>
                                    </div>

                                    <button class="medium transparent slim">
                                        <i class="ellipsis-h"></i>
                                    </button>

                                    <ptc-popover class="popover-menu" .preferAlignment=${["bottom"]} hide-on-click>
                                        <button @click=${() => this._editVenue(venue)}>
                                            <i class="pencil-alt"></i>
                                            Standort Bearbeiten
                                        </button>
                                        <button @click=${() => this._moveVenue(venue)} ?hidden=${!app.account?.admin}>
                                            <i class="truck-moving"></i>
                                            Standort Umziehen
                                        </button>
                                        <button @click=${() => this._archiveVenue(venue)}>
                                            <i class="archive"></i>
                                            Standort Archivieren
                                        </button>
                                        ${allowReordering
                                            ? html`
                                                  <button
                                                      @click=${() => this._changeOrder(venue, "up")}
                                                      ?disabled=${i === 0}
                                                  >
                                                      <i class="arrow-up"></i>
                                                      Nach Vorne Verschieben
                                                  </button>
                                                  <button
                                                      @click=${() => this._changeOrder(venue, "down")}
                                                      ?disabled=${i >= app.accessibleVenues.length - 1}
                                                  >
                                                      <i class="arrow-down"></i>
                                                      Nach Hinten Verschieben
                                                  </button>
                                              `
                                            : ""}
                                    </ptc-popover>
                                </div>

                                <ptc-sortable-list
                                    .items=${venue.departments}
                                    .renderItem=${(dep: Department) => html`
                                        <div
                                            class="double-padded horizontal layout click border-top"
                                            style="--color-highlight: ${colors[dep.color] || dep.color};"
                                            @click=${() => this._editDepartment(venue, dep)}
                                        >
                                            <div class="department-color"></div>

                                            <div class="stretch">
                                                <div class="larger bottom-margined">${dep.name}</div>

                                                <div class="smaller pills">
                                                    ${dep.positions
                                                        .filter((r) => r.active)
                                                        .sort((a, b) => a.order - b.order)
                                                        .map((position) => {
                                                            const positionCount = app.company!.employees.filter(
                                                                (emp) =>
                                                                    emp.active &&
                                                                    emp.positions.some((r) => r.id === position.id)
                                                            ).length;
                                                            return html`
                                                                <div
                                                                    class="pill"
                                                                    style="--color-highlight: ${position.color ||
                                                                    "inherit"}"
                                                                >
                                                                    ${position.name}

                                                                    <div class="detail">${positionCount}</div>
                                                                </div>
                                                            `;
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    `}
                                    @item-moved=${(e: CustomEvent<{ item: Department; toIndex: number }>) =>
                                        this._departmentMoved(e.detail.item, e.detail.toIndex)}
                                >
                                </ptc-sortable-list>

                                <div class="padded vertical layout border-top">
                                    <button @click=${() => this._newDepartment(venue)} class="transparent">
                                        <i class="plus"></i>
                                        Neue Abteilung
                                    </button>
                                </div>
                            </div>
                        `
                    )}

                    <div class="double-margined vertical layout">
                        <button @click=${this._newVenue} class="transparent">
                            <i class="plus"></i>
                            Neuer Standort
                        </button>
                    </div>
                </div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
