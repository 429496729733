import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { Unit, WageType } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";
import { app } from "../init";

@customElement("ptc-wage-type-dialog")
export class WageTypeDialog extends Dialog<WageType, WageType> {
    readonly preventDismiss = true;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    @query("input[name='number']")
    private _numberInput: HTMLInputElement;

    @query("select[name='unit']")
    private _unitSelect: HTMLSelectElement;

    @query("textarea[name='description']")
    private _descriptionInput: HTMLInputElement;

    @state()
    private _wageType?: WageType;

    async show(wageType?: WageType) {
        const promise = super.show();
        this._wageType = wageType;

        await this.updateComplete;

        this._nameInput.value = wageType?.name || "";
        this._numberInput.value = wageType?.number || "";
        this._unitSelect.value = wageType?.unit || "";
        this._descriptionInput.value = wageType?.description || "";

        if (!this._nameInput.value) {
            this._nameInput.focus();
        }

        return promise;
    }

    private get _data() {
        if (!this._form) {
            return undefined;
        }

        const data = new FormData(this._form);

        return {
            name: data.get("name") as string,
            number: data.get("number") as string,
            unit: data.get("unit") as Unit,
            description: data.get("description") as string,
        };
    }

    private _validate() {
        const { number, name } = this._data!;
        if (app.company?.wageTypes.some((wt) => wt !== this._wageType && wt.number === number)) {
            this._numberInput.setCustomValidity("Es existiert bereits eine Lohnart mit dieser Nummer!");
        } else {
            this._numberInput.setCustomValidity("");
        }
        if (app.company?.wageTypes.some((wt) => wt !== this._wageType && wt.name === name)) {
            this._nameInput.setCustomValidity("Es existiert bereits eine Lohnart mit diesem Namen!");
        } else {
            this._nameInput.setCustomValidity("");
        }
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        css`
            :host {
                z-index: 101;
            }
        `,
    ];

    renderContent() {
        return html`
            <form
                @submit=${(e: Event) => {
                    e.preventDefault();
                    this.done(this._data);
                }}
                autocomplete="off"
                class="double-padded spacing vertical layout"
                @change=${this._validate}
            >
                <div class="small margined subtle blue colored-text">
                    <i class="pencil-alt"></i> Lohnart Hinzufügen / Bearbeiten
                </div>

                <input name="name" required placeholder="Name der Lohnart" class="fill-horizontally" />

                <div class="field">
                    <label>Lohnartnummer</label>
                    <input type="number" name="number" required />
                </div>

                <div class="field">
                    <label>Einheit</label>
                    <select name="unit" required>
                        <option value="euros">Euro</option>
                        <option value="hours">Stunden</option>
                        <option value="days">Tage</option>
                        <option value="pieces">Stück</option>
                    </select>
                </div>

                <div class="field">
                    <label>Beschreibung</label>
                    <textarea name="description"></textarea>
                </div>

                <div class="horizontal top-padded spacing evenly stretching layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
