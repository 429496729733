import { html, css } from "lit";
import { customElement, query, queryAll, state } from "lit/decorators.js";
import {
    Absence,
    AbsenceStatus,
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
    VacationBalance,
    DailyResults,
} from "@pentacode/core/src/model";
import {
    getRange,
    dateAdd,
    parseDateString,
    formatDate,
    toDateString,
    dateSub,
    formatRange,
} from "@pentacode/core/src/util";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert, confirm } from "./alert-dialog";
import { prompt } from "./prompt";
import { DateInput } from "./date-input";
import "./progress";
import "./scroller";
import "./avatar";
import { Textarea } from "./textarea";
import { DateRange, getCommonWorkDays, getVacationEntitlement } from "@pentacode/core/src/time";
import { DateString } from "@pentacode/openapi";
import { GetBalancesParams, GetDailyResultsParams } from "@pentacode/core/src/api";

@customElement("ptc-absence-dialog")
export class AbsenceDialog extends Dialog<Absence, boolean> {
    @query("form")
    private _form: HTMLFormElement;

    @query("[name='start']")
    private _startInput: DateInput;

    @query("[name='end']")
    private _endInput: DateInput;

    @query("[name='notes']")
    private _notesInput: Textarea;

    @state()
    private _absence: Absence;

    @state()
    private _entries: TimeEntry[] = [];

    @state()
    private _commonWorkDays: number[] = [];

    @state()
    private _vacationBalances: VacationBalance[] = [];

    @queryAll("input[name='days']")
    private _dayInputs: HTMLInputElement[];

    @state()
    private _validationError = "";

    private get _employee() {
        return app.getEmployee(this._absence.employeeId)!;
    }

    private get _data() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);
        return {
            start: data.get("start") as DateString,
            end: data.get("end") ? dateAdd(data.get("end") as DateString, { days: 1 }) : null,
            days: data.getAll("days") as DateString[],
            notes: data.get("notes") as string,
        };
    }

    private get _days() {
        if (!this._data) {
            return;
        }
        const { start, end } = this._data;

        if (!start || !end) {
            return;
        }

        const { from } = getRange(start, "week");
        const { to } = getRange(dateAdd(end, { days: -1 }), "week");

        const days: string[] = [];
        let date = from;

        while (date < to) {
            days.push(date);
            date = dateAdd(date, { days: 1 });
        }

        return days;
    }

    private get _includedEntries() {
        return this._entries.filter(
            (e) => e.date >= this._absence.start && e.date < this._absence.end && e.type === this._absence.type
        );
    }

    private get _removedEntries() {
        const data = this._data;
        return this._includedEntries.filter((e) => !data?.days.includes(e.date));
    }

    private get _updatedEntries() {
        const data = this._data;

        if (!data) {
            return [];
        }

        const existing = this._includedEntries.filter((e) => data?.days.includes(e.date));

        const added = data.days
            .filter((date) => !existing.some((e) => e.date === date))
            .map(
                (date) =>
                    new TimeEntry({
                        type: this._absence.type,
                        employeeId: this._absence.employeeId,
                        date,
                    })
            );

        return [...existing, ...added];
    }

    private get _conflictingEntries() {
        const data = this._data;
        if (!data || !data.end) {
            return [];
        }
        return this._entries.filter((e) => e.date >= data.start && e.date < data.end! && e.type !== this._absence.type);
    }

    async show(absence: Absence) {
        const promise = super.show();
        this._lastRangeLoaded = undefined;
        this._absence = absence;
        await this.updateComplete;
        this._fill();
        void this._load();

        setTimeout(() => {
            if (!this._startInput.value) {
                void this._startInput.focus();
            } else if (!this._endInput.value) {
                void this._endInput.focus();
            } else {
                void this._notesInput.focus();
            }
        }, 500);

        return promise;
    }

    private _getCommonWorkDays(dailyResults: DailyResults[]) {
        return getCommonWorkDays(dailyResults);
    }

    private async _loadCommonWorkDays(before: DateString, earliestDate: DateString): Promise<number[]> {
        const today = toDateString(new Date());

        // Make sure the "before" date is not in the future
        if (before > today) {
            before = today;
        }

        // Don't check further back than the earliest date
        if (before < earliestDate) {
            return [];
        }

        const to = getRange(before, "week").from;
        const from = dateAdd(to, { weeks: -13 });

        const dailyResults = await app.api.getDailyResults(
            new GetDailyResultsParams({
                from,
                to,
                filters: [{ type: "employeeId", value: this._absence.employeeId }],
            })
        );
        const lastDayWorked = [...dailyResults].reverse().find((res) => res.results.work.base.duration > 0);

        // If there are no work or absence days in the loaded time range, load the previous one
        if (!lastDayWorked) {
            return this._loadCommonWorkDays(from, earliestDate);
        }

        // If the last day worked or absent is not in the same week as the "to" date,
        // adjust the time range to make sure the last loaded week contains a work or absence day.
        if (dateSub(lastDayWorked.date, to) > 7) {
            return this._loadCommonWorkDays(getRange(lastDayWorked.date, "week").from, earliestDate);
        }

        return this._getCommonWorkDays(dailyResults);
    }

    private _lastRangeLoaded?: DateRange;

    private async _load() {
        this._validationError = "";

        if (!this._data || !this._data.start || !this._data.end) {
            return;
        }

        const { start, end } = this._data;

        if (end <= start) {
            this._validationError = "Das Abwesenheitsende darf nicht vor dem Abwesenheitsbeginn liegen!";
            return;
        }

        if (start > dateAdd(toDateString(new Date()), { days: 365 })) {
            this._validationError = "Der Abwesenheitsbeginn darf nicht länger als ein Jahr in der Zukunft liegen.";
            return;
        }

        if (start < dateAdd(toDateString(new Date()), { days: -365 })) {
            this._validationError =
                "Der Abwesenheitsbeginn darf nicht weiter als ein Jahr in der Vergangenheit liegen.";
            return;
        }

        if (dateSub(start, end) > 180) {
            this._validationError = "Die Abwesenheitsdauer darf nicht länger als 180 Tage betragen!";
            return;
        }

        const from = getRange(start, "month").from;
        const to = getRange(end, "month").to;

        if (this._lastRangeLoaded && from >= this._lastRangeLoaded.from && to <= this._lastRangeLoaded.to) {
            this.requestUpdate();
            await this.updateComplete;
            this._fillDays();
            this.requestUpdate();
            return;
        }

        this.loading = true;
        try {
            const [vacationBalances, entries, commonWorkDays] = await Promise.all([
                app.api.getVacationBalances(
                    new GetBalancesParams({
                        filters: [{ type: "employeeId", value: this._absence.employeeId }],
                        resolution: "year",
                        from: getRange(start, "year").from,
                        to: getRange(end, "year").to,
                    })
                ),
                app.getTimeEntries({
                    employee: this._absence.employeeId,
                    from,
                    to,
                    type: [
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.SickInKUG,
                        TimeEntryType.ChildSick,
                        TimeEntryType.Free,
                        TimeEntryType.CompDay,
                    ],
                }),
                this._loadCommonWorkDays(start, dateAdd(start, { years: -1 })), // Don't go further than a year into the past
            ]);
            this._vacationBalances = vacationBalances;
            this._entries = entries;
            this._commonWorkDays = commonWorkDays;
            this._lastRangeLoaded = { from, to };
        } catch (e) {
            void alert(`Fehler beim Laden des Abwesenheitendialogs; ${e.message}`, { type: "warning" });
        }
        this.loading = false;

        this._fillDays();
        this.requestUpdate();
    }

    private _fill() {
        this._startInput.value = this._absence.start;
        this._endInput.value = this._absence.end ? dateAdd(this._absence.end, { days: -1 }) : null;
        this._notesInput.value = this._absence.notes;
    }

    private _fillDays() {
        const prefill =
            (!this._absence.id || this._absence.status === AbsenceStatus.Requested) &&
            !this._entries.some(
                (e) => e.type === this._absence.type && e.date >= this._absence.start && e.date < this._absence.end
            );
        for (const input of this._dayInputs) {
            input.checked =
                !input.disabled && prefill
                    ? this._isCommonDay(input.value)
                    : this._entries.some((e) => e.type === this._absence.type && e.date === input.value);
        }
    }

    private _isCommonDay(date: string) {
        const d = parseDateString(date)!;
        const contract = this._employee.getContractForDate(date);
        if (contract && contract.fixedWorkDays) {
            return !!contract.hoursPerDay && contract.hoursPerDay[(d.getDay() + 6) % 7] !== 0;
        }
        return this._commonWorkDays.includes(d.getDay());
    }

    private async _submit(e?: FocusEvent, vals: Partial<Absence> = {}) {
        e && e.preventDefault();
        const { start, end, notes } = this._data!;

        const absence = new Absence({
            ...this._absence,
            ...vals,
            start,
            end: end!,
            notes,
        });

        if (!absence.status) {
            absence.status = AbsenceStatus.Approved;
            absence.response = {
                employeeId: app.profile!.id,
                time: new Date(),
                message: "",
            };
        }

        if (absence.status === AbsenceStatus.Inferred) {
            absence.status = AbsenceStatus.Approved;
        }

        const updateEntries = this._updatedEntries;
        const deleteEntries = this._removedEntries;
        const conflictingEntries = this._conflictingEntries;

        updateEntries.forEach((e) => (e.comment = notes));

        if (absence.status === AbsenceStatus.Approved) {
            if (deleteEntries.length) {
                this.hide();
                const confirmed = await confirm(
                    `Wollen Sie diese Änderung wirklich vornehmen? Es werden ${deleteEntries.length} Fehltage gelöscht.`,
                    "Bestätigen",
                    "Abbrechen",
                    {
                        title: "Änderung Speichern",
                    }
                );
                this._show();
                if (!confirmed) {
                    return;
                }
            }

            if (
                conflictingEntries.some(
                    (entry) => entry.isPast && !entry.deleted && (entry.startFinal || entry.endFinal)
                )
            ) {
                this.hide();
                await alert(
                    "Es liegen bereits erfasste Arbeitszeiten in diesem Zeitraum vor! Wenn Sie die Abweseneheit dennoch erstellen möchten, löschen Sie bitte zunächst alle erfassten Arbeitszeiten im gewünschten Abwesenheitszeitraum!",
                    {
                        title: "Konflikte mit Arbeitszeiten",
                        type: "warning",
                    }
                );
                this._show();
                return;
            }

            if (conflictingEntries.length) {
                this.hide();
                const confirmed = await confirm(
                    html`Im dem gewählten Zeitraum liegen
                        <strong>${conflictingEntries.length} bestehende Dienstplaneinträge</strong> für diesen
                        Mitarbeiter vor. Möchten Sie diese Einträge löschen?`,
                    "Bestätigen",
                    "Abbrechen",
                    {
                        title: "Bestehende Einträge Löschen",
                        type: "destructive",
                        icon: "exclamation-triangle",
                    }
                );
                this._show();
                if (!confirmed) {
                    return;
                }
            }
        }

        this.loading = true;

        try {
            if (absence.id) {
                await app.api.updateAbsence(absence);
            } else {
                await app.api.createAbsence(absence);
            }

            if (absence.status === AbsenceStatus.Approved) {
                if (deleteEntries.length || conflictingEntries.length) {
                    await app.removeTimeEntries([...deleteEntries, ...conflictingEntries], true, true);
                }
                if (updateEntries.length) {
                    await app.createOrUpdateTimeEntries(updateEntries, { wait: true, publish: true });
                }
            }

            this.done(true);
        } catch (e) {
            void alert(`Fehler beim Abschicken der Änderungen: ${e.message}`, { type: "warning" });
        }
        this.loading = false;
    }

    private async _approve() {
        const v = this._absence.type === TimeEntryType.Vacation;
        const message = await prompt(
            `Wollen Sie ${v ? "diesen Urlaubsantrag" : "diese Krankmeldung"} wirklich genehmigen?`,
            "Absenden",
            "Abbrechen",
            {
                title: `${v ? "Urlaubsantrag" : "Krankmeldung"} Genehmigen`,
                icon: "thumbs-up",
                placeholder: "Geben Sie hier Ihre optionale Nachricht an den Mitarbeiter ein.",
            }
        );

        if (typeof message !== "undefined") {
            return this._submit(undefined, {
                status: AbsenceStatus.Approved,
                response: {
                    employeeId: app.profile!.id,
                    time: new Date(),
                    message,
                },
            });
        }
    }

    private async _deny() {
        this.hide();
        const v = this._absence.type === TimeEntryType.Vacation;
        const message = await prompt(
            `Wollen Sie ${v ? "diesen Urlaubsantrag" : "diese Krankmeldung"} wirklich ablehnen?`,
            "Absenden",
            "Abbrechen",
            {
                title: `${v ? "Urlaubsantrag" : "Krankmeldung"} Ablehnen`,
                icon: "thumbs-down",
                placeholder: "Geben Sie hier Ihre optionale Nachricht an den Mitarbeiter ein.",
            }
        );
        this._show();
        if (typeof message !== "undefined") {
            return this._submit(undefined, {
                status: AbsenceStatus.Denied,
                response: {
                    employeeId: app.profile!.id,
                    time: new Date(),
                    message,
                },
            });
        }
    }

    private async _deleteAbsence() {
        if (!this._absence) {
            return;
        }
        const deleteEntries = this._entries.filter(
            (e) => e.type === this._absence.type && e.date >= this._absence.start && e.date < this._absence.end
        );
        this.hide();
        const confirmed = await confirm(
            `Wollen Sie diese Abwesenheit wirklich entfernen? Es werden ${deleteEntries.length} Fehltage gelöscht.`,
            "Löschen",
            "Abbrechen",
            {
                title: "Abwesenheit Löschen",
                type: "destructive",
            }
        );
        if (confirmed) {
            try {
                await Promise.all([
                    deleteEntries.length ? app.removeTimeEntries(deleteEntries, true, true) : Promise.resolve(),
                    this._absence.id ? app.api.deleteAbsence(this._absence.id) : Promise.resolve(),
                ]);
                this.done(true);
            } catch (e) {
                await alert(e.message, { type: "warning" });
                this._show();
            }
        } else {
            this._show();
        }
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        Textarea.styles,
        css`
            .inner {
                max-width: 30em;
            }

            .day-grid {
                display: grid;
                grid-template-columns: repeat(7, 3.5em);
                justify-content: center;
                grid-gap: 0.5em;
            }

            .day-header {
                font-weight: bold;
                text-align: center;
            }

            .day {
                box-sizing: border-box;
                height: 3.5em;
                border-radius: 0.5em;
                border: solid 1px var(--shade-2);
                overflow: hidden;
            }

            .day:not([disabled]) {
                color: var(--color-highlight);
                border-color: var(--color-highlight);
            }

            .day-date {
                position: absolute;
                right: 0.4em;
                top: 0.4em;
                line-height: 1em;
                font-weight: bold;
                z-index: 1;
                pointer-events: none;
            }

            .day label {
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            .day label .selected-icon {
                position: absolute;
                left: 0.2em;
                top: 0.2em;
            }

            .day input:not(:checked) + label {
                opacity: 0;
            }

            .day-is-common,
            .conflict {
                color: var(--color-highlight);
                font-size: var(--font-size-smaller);
            }

            .day label .day-is-common,
            .day label .conflict {
                color: var(--color-bg);
            }

            .day .day-is-common {
                position: absolute;
                left: 0.2em;
                bottom: 0.2em;
            }

            .day .conflict {
                position: absolute;
                right: 0.2em;
                bottom: 0.2em;
            }

            ptc-progress {
                width: 8em;
            }
        `,
    ];

    renderContent() {
        const absence = this._absence;
        const employee = this._employee;
        const data = this._data;
        const days = this._days;
        const type = this._absence.type;
        const editing = this._absence.id || this._absence.status === AbsenceStatus.Inferred;
        const conflictingEntries = this._conflictingEntries;
        const commitBefore = app.company?.settings.commitTimeEntriesBefore;

        return html`
            <form
                @submit=${this._submit}
                class="padded vertical layout fit-vertically"
                @input=${() => this.requestUpdate()}
            >
                <div class="padded horizontal start-aligning layout">
                    <div class="stretch">
                        <div class="faded bottom-margined">
                            ${editing ? "Abwesenheit Bearbeiten" : "Neue Abwesenheit"}:
                        </div>
                        <div class="big colored-text stretch" style="--color-highlight: ${timeEntryTypeColor(type)}">
                            <i class="${app.localized.timeEntryTypeIcon(type)}"></i>
                            ${app.localized.timeEntryTypeLabel(type)}
                        </div>
                    </div>
                    <div ?hidden=${!editing}>
                        <button type="button" class="subtle transparent icon" @click=${this._deleteAbsence}>
                            <i class="trash-alt"></i>
                        </button>
                    </div>
                </div>

                <div class="margined spacing centering horizontal layout">
                    <ptc-avatar class="smaller" .employee=${employee}></ptc-avatar>
                    <div class="larger">${employee.name}</div>
                </div>

                <div class="horizontal evenly stretching spacing layout">
                    <div class="vertical layout">
                        <label>Von</label>
                        <ptc-date-input
                            name="start"
                            class="large"
                            required
                            @input=${async () => {
                                await this._load();
                            }}
                            .readonly=${[AbsenceStatus.Requested, AbsenceStatus.Denied].includes(absence.status)}
                            datePicker="popover"
                        ></ptc-date-input>
                    </div>
                    <div class="vertical layout">
                        <label>Bis</label>
                        <ptc-date-input
                            name="end"
                            class="large"
                            required
                            @input=${async () => {
                                await this._load();
                            }}
                            .min=${data && data.start}
                            .readonly=${[AbsenceStatus.Requested, AbsenceStatus.Denied].includes(absence.status)}
                            datePicker="popover"
                        ></ptc-date-input>
                    </div>
                </div>

                ${absence.request
                    ? html`
                          <div class="padded vertically-margined text-centering orange box">
                              <strong>
                                  <i class="comment-question"></i> Beantragt am ${formatDate(absence.request.time)}:
                              </strong>
                              ${absence.request.message ? html` <span>"${absence.request.message}"</span> ` : ""}
                          </div>
                      `
                    : ""}
                ${absence.response
                    ? html`
                          ${absence.status === AbsenceStatus.Denied
                              ? html`
                                    <div class="padded vertically-margined text-centering red box">
                                        <strong>
                                            <i class="thumbs-down"></i> Abgelehnt von
                                            ${absence.response.employeeName || "Unbekannt"} am
                                            ${formatDate(absence.response.time)}:
                                        </strong>
                                        ${absence.response.message
                                            ? html` <span>"${absence.response.message}"</span> `
                                            : ""}
                                    </div>
                                `
                              : html`
                                    <div class="padded vertically-margined text-centering green box">
                                        <strong>
                                            <i class="thumbs-up"></i> Genehmigt von
                                            ${absence.response.employeeName || "Unbekannt"} am
                                            ${formatDate(absence.response.time)}:
                                        </strong>
                                        ${absence.response.message
                                            ? html` <span>"${absence.response.message}"</span> `
                                            : ""}
                                    </div>
                                `}
                      `
                    : ""}
                ${this._validationError
                    ? html`<div class="double-margined double-padded red colored-text text-centering">
                          <i class="exclamation-triangle"></i> ${this._validationError}
                      </div>`
                    : ""}
                ${!data || !data.start || !data.end
                    ? html`<div class="double-margined double-padded text-centering">
                          <i class="calendar"></i> Bitte geben Sie den Abwesenheitszeitraum ein!
                      </div>`
                    : ""}
                ${absence.status !== AbsenceStatus.Denied && !this._validationError && data && days
                    ? html`
                          <div class="double-margined text-centering">Bitten wählen Sie die zu buchenden Fehltage:</div>

                          <div class="day-grid">
                              <div class="day-header">Mo</div>
                              <div class="day-header">Di</div>
                              <div class="day-header">Mi</div>
                              <div class="day-header">Do</div>
                              <div class="day-header">Fr</div>
                              <div class="day-header">Sa</div>
                              <div class="day-header">So</div>
                          </div>

                          <ptc-scroller class="stretch vertically-margined">
                              <div class="day-grid" style="--color-highlight: ${timeEntryTypeColor(type)}">
                                  ${days.map((date) => {
                                      const conflicting = conflictingEntries.some((e) => e.date === date);
                                      const commited = commitBefore && date < commitBefore;
                                      return html`<div
                                          class="day relative"
                                          ?disabled=${!data.start ||
                                          !data.end ||
                                          date < data.start ||
                                          date >= data.end ||
                                          commited}
                                      >
                                          <div class="day-date">${parseDateString(date)!.getDate()}</div>

                                          <i
                                              class="bullseye day-is-common ${absence.type === TimeEntryType.Vacation
                                                  ? "orange"
                                                  : "blue"}"
                                              ?hidden=${!this._isCommonDay(date)}
                                          ></i>

                                          <i
                                              class="exclamation-triangle conflict ${absence.type ===
                                              TimeEntryType.Vacation
                                                  ? "orange"
                                                  : "blue"}"
                                              ?hidden=${!conflicting}
                                          ></i>

                                          <input
                                              class="invisible"
                                              type="checkbox"
                                              name="days"
                                              .value=${date}
                                              id="checkbox_${date}"
                                              ?disabled=${!data.start ||
                                              !data.end ||
                                              date < data.start ||
                                              date >= data.end}
                                          />
                                          <label for="checkbox_${date}" class="fullbleed centering layout click">
                                              <i
                                                  class="selected-icon ${app.localized.timeEntryTypeIcon(absence.type)}"
                                              ></i>

                                              <div class="day-date">${parseDateString(date)!.getDate()}</div>

                                              <i class="bullseye day-is-common" ?hidden=${!this._isCommonDay(date)}></i>

                                              <i class="exclamation-triangle conflict" ?hidden=${!conflicting}></i>
                                          </label>
                                      </div>`;
                                  })}
                              </div>
                          </ptc-scroller>

                          <div class="top-margined stats centering spacing vertical layout text-centering">
                              ${absence.type === TimeEntryType.Vacation
                                  ? this._vacationBalances
                                        .flatMap((balance) => balance.subBalances ?? [balance])
                                        .map((balance) => {
                                            const { taken, available } = getVacationEntitlement(balance);
                                            const before = this._includedEntries.filter(
                                                (e) => e.date >= balance.from && e.date < balance.to
                                            ).length;
                                            const after = this._data!.days.filter(
                                                (date) => date >= balance.from && date < balance.to
                                            ).length;
                                            const diff = after - before;
                                            return html`
                                                <div class="center-aligning wrapping spacing horizontal layout">
                                                    <div class="semibold blue colored-text stats-label">
                                                        Urlaub ${formatRange(balance)}:
                                                    </div>
                                                    <ptc-progress
                                                        .nominal=${available}
                                                        .actual=${taken}
                                                        class="vacation"
                                                        .threshold=${0.01}
                                                    ></ptc-progress>
                                                    ${diff
                                                        ? html`
                                                              <i class="smaller arrow-right"></i>
                                                              <ptc-progress
                                                                  .nominal=${available}
                                                                  .actual=${taken + diff}
                                                                  class="vacation"
                                                                  .threshold=${0.01}
                                                              ></ptc-progress>
                                                          `
                                                        : ""}
                                                </div>
                                            `;
                                        })
                                  : ""}
                          </div>

                          <div class="double-margined text-centering small subtle">
                              <div>
                                  <i
                                      class="bullseye day-is-common ${absence.type === TimeEntryType.Vacation
                                          ? "orange"
                                          : "blue"}"
                                  ></i>
                                  Übliche Arbeitstage, ermittelt anhand der Vertragsdaten und Arbeitszeiten der letzten
                                  13 Wochen.
                              </div>
                              <div>
                                  <i
                                      class="exclamation-triangle day-is-common ${absence.type ===
                                      TimeEntryType.Vacation
                                          ? "orange"
                                          : "blue"}"
                                  ></i>
                                  Konflikte mit bestehenden Dienstplaneinträgen
                              </div>
                          </div>
                      `
                    : ""}

                <div class="field">
                    <label>Bemerkungen</label>
                    <ptc-textarea name="notes"></ptc-textarea>
                </div>

                ${absence.status === AbsenceStatus.Denied
                    ? html`
                          <div class="spacing evenly stretching horizontal layout">
                              <button class="transparent" type="button" @click=${() => this.dismiss()}>
                                  Schließen
                              </button>
                          </div>
                      `
                    : absence.status === AbsenceStatus.Requested
                      ? html`
                            <div class="spacing evenly stretching horizontal layout">
                                <button
                                    class="green ghost"
                                    type="button"
                                    @click=${this._approve}
                                    ?disabled=${!!this._validationError}
                                >
                                    <i class="thumbs-up"></i> Genehmigen
                                </button>
                                <button class="red ghost" type="button" @click=${this._deny}>
                                    <i class="thumbs-down"></i> Ablehnen
                                </button>
                            </div>
                        `
                      : html`
                            <div class="spacing evenly stretching horizontal layout">
                                <button class="primary" ?disabled=${!!this._validationError}>Speichern</button>
                                <button class="transparent" type="button" @click=${() => this.dismiss()}>
                                    Abbrechen
                                </button>
                            </div>
                        `}
            </form>
        `;
    }
}
