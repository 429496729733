import { html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { AccountingSalaryConfig } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import { AccountingSalaryConfigForm } from "./accounting-salary-config-form";
import "./scroller";

@customElement("ptc-accounting-salary-config-dialog")
export class AccountingSalaryConfigDialog extends Dialog<AccountingSalaryConfig, AccountingSalaryConfig> {
    @query("ptc-accounting-salary-config-form")
    private _form: AccountingSalaryConfigForm;

    async show(config: AccountingSalaryConfig) {
        const promise = super.show();
        await this.updateComplete;
        this._form.config = config;
        this._form.isNew = !config.id;
        return promise;
    }

    private _save() {
        if (this._form.reportValidity()) {
            this.done(this._form.config);
        }
    }

    private _cancel() {
        this.done();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                max-width: 70em;
                display: flex;
                flex-direction: column;
            }

            ptc-accounting-salary-config-form {
                margin: 1em 1em 0 1em;
            }
        `,
    ];

    renderContent() {
        return html`
            <ptc-scroller class="stretch">
                <ptc-accounting-salary-config-form @submit=${this._save}></ptc-accounting-salary-config-form>
            </ptc-scroller>

            <div class="horizontal center-aligning evenly stretching layout padded-medium pad-children">
                <button class="primary" @click=${this._save}>Speichern</button>
                <button class="transparent" @click=${this._cancel}>Abbrechen</button>
            </div>
        `;
    }
}
