import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";
import "./scroller";
import { alert } from "./alert-dialog";
import "./avatar";
import { CreateInvitesParams } from "@pentacode/core/src/api";
import { Employee } from "@pentacode/core/src/model";

@customElement("ptc-invite-dialog-single")
export class InviteDialogSingle extends Dialog<Employee, boolean> {
    @state()
    private _employee: Employee;

    @query("form")
    private _form: HTMLFormElement;

    async show(employee: Employee) {
        this._employee = employee;
        return super.show();
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const data = new FormData(this._form);

        this.loading = true;

        try {
            await app.api.createInvites(
                new CreateInvitesParams({
                    recipients: [{ id: this._employee.id, email: data.get("email") as string }],
                    message: data.get("message") as string,
                })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
        this.done(true);
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        css`
            .inner {
                padding: 0;
            }
        `,
    ];

    renderContent() {
        if (!this._employee) {
            return html``;
        }
        return html`
            <form
                class="vertical layout"
                @submit=${this._submit}
                @input=${() => this.requestUpdate()}
                autocomplete="off"
            >
                <div class="double-margined text-centering">
                    <div class="large">Einladung Versenden</div>
                </div>

                <div class="margined field">
                    <label>Emailadresse</label>
                    <input
                        .value=${this._employee.email}
                        placeholder="Emailadresse"
                        class="small email-input"
                        name="email"
                        required
                        type="email"
                        autocomplete="off"
                    />
                </div>

                <div class="margined field">
                    <label>Nachricht (Optional)</label>
                    <textarea name="message"></textarea>
                </div>

                <div class="margined horizontal spacing stretching layout">
                    <button class="primary" type="submit">Versenden</button>
                    <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
