import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { CostCenter } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";
import { EntityFiltersEl } from "./entity-filters";
import { app } from "../init";

@customElement("ptc-cost-center-dialog")
export class CostCenterDialog extends Dialog<CostCenter, CostCenter> {
    readonly preventDismiss = true;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    @query("input[name='number']")
    private _numberInput: HTMLInputElement;

    @query("ptc-entity-filters")
    private _entityFilters: EntityFiltersEl;

    @state()
    private _costCenter?: CostCenter;

    async show(costCenter?: CostCenter) {
        const promise = super.show();
        this._costCenter = costCenter;

        await this.updateComplete;

        this._nameInput.value = costCenter?.name || "";
        this._numberInput.value = costCenter?.number || "";
        this._entityFilters.filters = costCenter?.entities || [];

        if (!this._nameInput.value) {
            this._nameInput.focus();
        }

        return promise;
    }

    private get _data() {
        if (!this._form) {
            return undefined;
        }

        const data = new FormData(this._form);

        return {
            name: data.get("name") as string,
            number: data.get("number") as string,
            entities: this._entityFilters.filters,
        };
    }

    private _validate() {
        const { number, name } = this._data!;
        if (app.company?.costCenters.some((cs) => cs !== this._costCenter && cs.number === number)) {
            this._numberInput.setCustomValidity("Es existiert bereits eine Kostenstelle mit dieser Nummer!");
        } else {
            this._numberInput.setCustomValidity("");
        }
        if (app.company?.costCenters.some((cs) => cs !== this._costCenter && cs.name === name)) {
            this._nameInput.setCustomValidity("Es existiert bereits eine Kostenstelle mit diesem Namen!");
        } else {
            this._nameInput.setCustomValidity("");
        }
    }

    static styles = [...Dialog.styles, Checkbox.styles, css``];

    renderContent() {
        return html`
            <form
                @submit=${(e: Event) => {
                    e.preventDefault();
                    this.done(this._data);
                }}
                autocomplete="off"
                class="double-padded spacing vertical layout"
                @change=${this._validate}
            >
                <div class="small margined subtle blue colored-text">
                    <i class="pencil-alt"></i> Kostenstelle Hinzufügen / Bearbeiten
                </div>

                <input name="name" required placeholder="Name der Kostenstelle" class="fill-horizontally" />

                <div class="field">
                    <label>Kostenstellennummer</label>
                    <input type="number" name="number" required />
                </div>

                <div class="field">
                    <label>Arbeitsbereiche / Mitarbeiter</label>
                    <ptc-entity-filters
                        class="focus-container"
                        .filterTypes=${["employeeId", "employmentType", "venue", "department", "position"]}
                        hideFilterIcon
                    ></ptc-entity-filters>
                </div>

                <div class="horizontal top-padded spacing evenly stretching layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
