import { EmploymentType, TimeEntry, TimeEntryType } from "@pentacode/core/src/model";
import { MonthlyStatement } from "@pentacode/core/src/statement";
import { getRange, toDateString, monthNames, formatNumber as fmtNum, parseDateString } from "@pentacode/core/src/util";
import { TimeResult, calcAllHours, calcTotalHours } from "@pentacode/core/src/hours";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { colors } from "../styles/config";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Chart } from "./chart";
import { DateString } from "@pentacode/openapi";

const formatNumber = (val: number) => fmtNum(val, 0);

@customElement("ptc-reports-savings")
export class Reports extends Routing(StateMixin(LitElement)) {
    routePattern = /^reports\/savings/;

    get routeTitle() {
        return "Ersparnisbericht";
    }

    @state()
    private _loading = false;

    @state()
    private _statements: MonthlyStatement[] = [];

    @state()
    private _timeEntries: TimeEntry[] = [];

    @query(".chart-savings-monthly")
    private _chartSavingsMonthly: Chart;

    @query(".chart-savings-daily")
    private _chartSavingsDaily: Chart;

    @query(".chart-savings-gauge")
    private _chartSavingsGauge: Chart;

    handleRoute() {
        this._load();
    }

    private async _load() {
        const date = (this.router.params.date as DateString) || new Date();
        const currYear = getRange(date, "year");
        const currMonth = getRange(date, "month");

        if (currYear.to > currMonth.to) {
            currYear.to = currMonth.to;
        }

        this._loading = true;

        try {
            const [statements, timeEntries] = await Promise.all([
                app.getMonthlyStatements(currYear),
                app.getTimeEntries(currMonth),
            ]);

            this._statements = statements;
            this._timeEntries = timeEntries.filter((e) => !!e.employeeId);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
        this._updateCharts();
    }

    private _getCosts({
        status = "total",
        month,
        employee,
        employment,
        department: depId,
    }: {
        status?: "work" | "breaks" | "commission" | "vacation" | "sick" | "bonus" | "total";
        month?: number;
        employee?: number;
        employment?: EmploymentType;
        department?: number;
    }) {
        let statements = this._statements;
        if (typeof month === "number") {
            statements = statements.filter((s) => s.month === month);
        }
        if (typeof employee === "number") {
            statements = statements.filter((s) => s.employeeId === employee);
        }
        if (typeof employment === "number") {
            statements = statements.filter((s) => {
                const employee = app.getEmployee(s.employeeId);
                const contract = employee && employee.getContractForMonth(s.year, s.month);
                return contract && employment === contract.employmentType;
            });
        }
        if (typeof depId === "number") {
            function reduceCost(hours: TimeResult[]) {
                return hours.reduce((total, h) => total + h.salaryCost + h.commissionCost + h.bonusCost, 0);
            }
            return statements.reduce((total, s) => {
                const emp = app.getEmployee(s.employeeId)!;
                const empDepartments = new Set(emp.positions.map((p) => p.departmentId));
                const totalWorkCost = reduceCost(s.time.distinct.filter((h) => h.type === TimeEntryType.Work));
                const totalDepCost = reduceCost(
                    s.time.distinct.filter((h) => {
                        const { department } = (h.position && app.getPosition(h.position)) || { department: null };
                        return department && department.id === depId;
                    })
                );

                const depRatio = totalWorkCost
                    ? totalDepCost / totalWorkCost
                    : empDepartments.has(depId)
                      ? 1 / empDepartments.size
                      : 0;

                return total + s.cost.total * depRatio;
            }, 0);
        } else {
            return statements.reduce((total, s) => total + s.cost[status], 0);
        }
    }

    private _getChartConfigSavingsMonthly() {
        const savings = monthNames.map((_n, month) => {
            const statements = this._statements.filter((s) => s.month === month);
            return statements.reduce((total, s) => total + s.cost.savings, 0);
        });
        const series = [
            {
                name: "Ersparnis (€)",
                data: savings,
                type: "bar",
            },
            {
                type: "line",
                name: "Ersparnis (%)",
                data: savings.map((s, month) => {
                    const costs = this._getCosts({ status: "total", month });
                    return costs ? (s / costs) * 100 : 0;
                }),
            },
        ];

        return {
            chart: {
                type: "line",
                height: 300,
                selection: { enabled: false },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            series: series,
            labels: monthNames.map((n) => n.slice(0, 3)),
            xaxis: {
                type: "category",
            },
            colors: [colors.blue, colors.green],
            yaxis: [
                {
                    opposite: true,
                    title: {
                        text: "Ersparnis (€)",
                        style: {
                            color: colors.blue,
                        },
                    },
                    labels: {
                        formatter: (val: number) => formatNumber(val),
                        style: {
                            colors: colors.blue,
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: colors.blue,
                    },
                    axisTicks: {
                        show: true,
                        color: colors.blue,
                    },
                },
                {
                    seriesName: "Ersparnis (%)",
                    opposite: true,
                    title: {
                        text: "Ersparnis (%)",
                        style: {
                            color: colors.green,
                        },
                    },
                    labels: {
                        formatter: (val: number) => fmtNum(val, 1),
                        style: {
                            colors: colors.green,
                        },
                    },
                    axisTicks: {
                        show: true,
                        color: colors.green,
                    },
                    axisBorder: {
                        show: true,
                        color: colors.green,
                    },
                },
            ],
            tooltip: {
                y: [
                    {
                        formatter: (val: number) => formatNumber(val) + " €",
                    },
                    {
                        formatter: (val: number) => fmtNum(val, 1) + " %",
                    },
                ],
            },
            plotOptions: {
                bar: {
                    columnWidth: "60%",
                },
            },
            stroke: {
                curve: "smooth",
                width: [1, 4],
            },
        };
    }

    private _getChartConfigSavingsDaily() {
        const company = app.company!;
        const date = this.router.params.date ? parseDateString(this.router.params.date)! : new Date();
        const month = this._timeEntries.length ? this._timeEntries[0].start.getMonth() : date.getMonth();
        const dates = new Map<string, Map<number, TimeEntry[]>>();
        const today = toDateString(date);

        for (const entry of this._timeEntries) {
            if (entry.date > today) {
                continue;
            }

            if (!dates.has(entry.date)) {
                dates.set(entry.date, new Map<number, TimeEntry[]>());
            }

            const date = dates.get(entry.date)!;

            if (!date.has(entry.employeeId!)) {
                date.set(entry.employeeId!, []);
            }

            date.get(entry.employeeId!)!.push(entry);
        }

        const savings = [...dates.entries()].map(([date, employees]) => ({
            x: date,
            y: [...employees.entries()].reduce((total, [employeeId, entries]) => {
                const employee = app.getEmployee(employeeId);
                const statement = this._statements.find((s) => s.employeeId === employeeId && s.month === month);
                if (!employee || !statement) {
                    return total;
                }
                const regHours = calcTotalHours(
                    calcAllHours(employee, company, entries, statement.previousAverages?.hoursPerWorkDay)
                );
                const maxedHours = calcTotalHours(
                    calcAllHours(employee, company, entries, statement.previousAverages?.hoursPerWorkDay, "max")
                );
                return total + maxedHours.salaryCost - regHours.salaryCost;
            }, 0),
        }));

        const serviceFees = 100;

        for (let i = 1; i < savings.length; i++) {
            savings[i].y = savings[i].y + savings[i - 1].y;
        }

        const totalSavings = savings.length ? savings[savings.length - 1].y : 0;
        const colorStop = totalSavings ? 100 - (serviceFees / totalSavings) * 100 : 0;

        const series = [
            {
                name: "Ersparnis",
                data: savings,
            },
        ];

        const lastDay = new Date(new Date().getFullYear(), month + 1, 0).getTime();

        return {
            chart: {
                type: "area",
                toolbar: {
                    show: false,
                },
                height: 300,
                zoom: { enabled: false },
                selection: {
                    enabled: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            series,
            xaxis: {
                type: "datetime",
                max: lastDay,
            },
            yaxis: {
                min: 0,
                max: Math.max(totalSavings, serviceFees * 3),
                forceNiceScale: true,
                opposite: true,
                title: {
                    text: "Ersparnis (€)",
                },
                labels: {
                    formatter: (val: number) => formatNumber(val),
                },
            },
            tooltip: { enabled: false, y: { formatter: (val: number) => formatNumber(val) + " €" } },
            annotations: {
                yaxis: [
                    {
                        y: serviceFees,
                        borderColor: colors.blue,
                        strokeDashArray: 3,
                        opacity: 1,
                        label: {
                            position: "left",
                            textAnchor: "start",
                            borderColor: colors.blue,
                            style: {
                                color: "#fff",
                                background: colors.blue,
                            },
                            text: "Breakeven",
                            offsetY: -10,
                            offsetX: 10,
                        },
                    },
                ],
            },
            fill: {
                type: "gradient",
                gradient: {
                    type: "vertical",
                    colorStops: [
                        {
                            offset: 0,
                            color: colors.green,
                            opacity: 1,
                        },
                        {
                            offset: colorStop,
                            color: colors.green,
                            opacity: 0.2,
                        },
                        {
                            offset: colorStop,
                            color: colors.red,
                            opacity: 0.2,
                        },
                        {
                            offset: 100,
                            color: colors.red,
                            opacity: 0.5,
                        },
                    ],
                },
            },
        };
    }

    private _getChartConfigSavingsGauge() {
        //@ts-ignore
        const dailySavings = this._chartSavingsDaily.config!.series![0].data;
        const currMonth = dailySavings.length ? dailySavings[dailySavings.length - 1].y : 0;
        const fees = 100;
        const roi = Math.floor((currMonth / fees) * 100);
        return {
            series: [roi],
            colors: [colors.blue],
            chart: {
                height: 300,
                type: "radialBar",
                offsetY: -70,
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "1em",
                            color: undefined,
                            offsetY: 60,
                        },
                        value: {
                            offsetY: 70,
                            fontSize: "1.6em",
                            color: undefined,
                            formatter: (val: number) => {
                                return val + " %";
                            },
                        },
                    },
                    track: {
                        background: "rgba(0, 0, 0, 0.05)",
                    },
                },
            },
            // stroke: {
            //     dashArray: 4
            // },
            labels: ["ROI"],
            title: {
                text: fmtNum(currMonth, 2) + " €",
                align: "center",
                offsetY: 180,
                style: { fontSize: "1.8em", fontWeight: "300" },
            },
        };
    }

    private _updateCharts() {
        // @ts-ignore
        this._chartSavingsMonthly.config = this._getChartConfigSavingsMonthly();
        // @ts-ignore
        this._chartSavingsDaily.config = this._getChartConfigSavingsDaily();
        // @ts-ignore
        this._chartSavingsGauge.config = this._getChartConfigSavingsGauge();
    }

    static styles = [
        shared,
        css`
            :host {
                display: grid;
                grid-template-columns: 200px 1fr;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .main {
                position: relative;
            }

            .scroller {
                overflow: auto;
            }

            .charts {
                position: sticky;
                left: 0;
                padding: 0 1em;
                display: flex;
                align-items: center;
            }

            .charts-savings-daily {
                flex: 1;
                width: 0;
            }

            .charts-savings-monthly {
                flex: 1;
                width: 0;
            }

            .charts-savings-gauge,
            .charts-savings-total {
                width: 265px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .charts-title {
                font-size: var(--font-size-large);
                padding: 0.5em;
                font-weight: 600;
                text-align: center;
            }

            .charts-savings {
                margin-top: 1em;
            }

            .chart-savings-gauge {
                display: block;
                height: 250px;
            }

            .chart-savings-total-euro,
            .chart-savings-total-percent {
                text-align: center;
                font-size: var(--font-size-enormous);
                color: var(--color-highlight);
                font-weight: 300;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        const years: number[] = [];
        for (let year = new Date().getFullYear(); year > 2010; year--) {
            years.push(year);
        }

        const date = this.router.params.date ? parseDateString(this.router.params.date)! : new Date();

        const currMonth = date.getMonth();
        const currYear = date.getFullYear();

        const costTotal = this._statements.reduce((total, s) => total + s.cost.total, 0);
        const savingsTotal = this._statements.reduce((total, s) => total + s.cost.savings, 0);
        const savingsPercent = costTotal ? (savingsTotal / costTotal) * 100 : 0;

        return html`
            <div class="fullbleed vertical layout">
                <div class="scroller stretch">
                    <div class="blue message">
                        <i class="info-circle"></i>
                        Die hier gezeigten Ersparnisse ergeben sich aus den Regeln, die Sie für die Zeiterfassung
                        definiert haben. Es werden je nach Einstellung zu früher Dienstbeginn, Verspätung,
                        Minutenrundung und Raucherpausen Mitarbeiter-genau erhoben und mit dessen Lohndaten berechnet.
                        Dieser Bericht setzt die Nutzung der elektronischen Zeiterfassung von Pentacode voraus.
                    </div>
                    <div class="charts charts-savings">
                        <div class="charts-savings-gauge">
                            <div class="charts-title">${monthNames[currMonth]} ${currYear}</div>
                            <ptc-chart class="chart-savings-gauge"></ptc-chart>
                        </div>
                        <div class="charts-savings-daily">
                            <ptc-chart class="chart-savings-daily"></ptc-chart>
                        </div>
                    </div>

                    <div class="charts">
                        <div class="charts-savings-total vertical center-justifying layout">
                            <div class="charts-title">${currYear} Gesamt</div>
                            <div class="chart-savings-total-euro blue">${formatNumber(savingsTotal)} €</div>
                            <div class="chart-savings-total-percent green">${fmtNum(savingsPercent, 1)} %</div>
                        </div>

                        <div class="charts-savings-monthly">
                            <ptc-chart class="chart-savings-monthly"></ptc-chart>
                        </div>
                    </div>
                </div>

                <div
                    class="fullbleed center-aligning center-justifying vertical layout scrim"
                    ?hidden=${!this._loading}
                >
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            </div>
        `;
    }
}
