import "./scroller";
import "./avatar";
import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { TimeSettings } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import "./progress";
import { TimeSettingsForm } from "./time-settings-form";
import { app } from "../init";
import { Checkbox } from "./checkbox";
import { alert, confirm } from "./alert-dialog";
import { EntityFiltersEl } from "./entity-filters";

@customElement("ptc-time-settings-dialog")
export class TimeSettingsDialog extends Dialog<TimeSettings, boolean> {
    readonly preventDismiss = true;

    @query("ptc-time-settings-form")
    private _form: TimeSettingsForm;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    @query("ptc-entity-filters")
    private _entityFiltersEl: EntityFiltersEl;

    @state()
    private _settings: TimeSettings;

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        css`
            .inner {
                max-width: 50em;
            }

            .assignments ptc-checkbox-button ptc-checkbox {
                font-size: 0.7em;
            }

            .assignments ptc-checkbox-button button {
                padding: 0.5em;
            }

            .assignments ptc-avatar {
                margin: -0.2em 0.5em -0.2em 0;
            }

            .assignments .filter-input {
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                width: 10em;
            }

            .assignments-popover {
                padding: 0;
            }

            .assignments-popover .scroller {
                max-height: 30em;
                border-radius: 1em;
            }
        `,
    ];

    async show(timeSettings: TimeSettings) {
        this._settings = timeSettings;
        const promise = super.show();
        await this.updateComplete;
        this._entityFiltersEl.filters = timeSettings.filters;
        this._form.init(timeSettings);
        this._nameInput.value = timeSettings.name || "";
        return promise;
    }

    private async _submit() {
        if (!this._nameInput.reportValidity() || !this._form.reportValidity()) {
            return;
        }

        const orig = this._settings;
        const data = this._form.data;

        if (
            orig.paidBreaksAuto !== data.paidBreaksAuto ||
            orig.paidBreaksManual !== data.paidBreaksManual ||
            orig.breakTiming !== data.breakTiming ||
            orig.bonusNight1Start !== data.bonusNight1Start ||
            orig.bonusNight1End !== data.bonusNight1End ||
            orig.bonusNight2Start !== data.bonusNight2Start ||
            orig.bonusNight2End !== data.bonusNight2End ||
            orig.bonusMinDuration !== data.bonusMinDuration ||
            orig.bonusSundayNextDayUntil !== data.bonusSundayNextDayUntil ||
            orig.bonusHolidayNextDayUntil !== data.bonusHolidayNextDayUntil ||
            orig.bonusChristmasEveFrom !== data.bonusChristmasEveFrom ||
            orig.bonusNewYearsEveFrom !== data.bonusNewYearsEveFrom
        ) {
            this.hide();
            const confirmed = await confirm(
                html`<strong>WARNUNG</strong>: Eine oder mehrere vorgenommene Änderungen können rückwirkend Auswirkungen
                    auf Mitarbeiterkonten und Lohnabrechnungen haben. Wir empfehlen deshalb, vor dem Speichern
                    Arbeitszeiten und Konten festzuschreiben.
                    <ptc-help-anchor
                        page="handbuch/einstellungen/zeiterfassung/#neuberechnung-von-konten--lohnabrechnungen"
                    >
                        <a
                            href="${process.env
                                .PTC_HELP_SITE}/handbuch/einstellungen/zeiterfassung/#neuberechnung-von-konten--lohnabrechnungen"
                        >
                            Mehr Erfahren <i class="arrow-right"></i>
                        </a>
                    </ptc-help-anchor>`,
                "Fortfahren",
                "Abbrechen"
            );
            this._show();
            if (!confirmed) {
                return;
            }
        }

        this.loading = true;
        try {
            await app.api.createOrUpdateTimeSettings(
                new TimeSettings({
                    ...orig,
                    ...data,
                    name: this._nameInput.value,
                    filters: this._entityFiltersEl.filters,
                })
            );
            this.done(true);
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this._show();
        }
        this.loading = false;
    }

    renderContent() {
        if (!app.company) {
            return html``;
        }

        return html`
            <div class="vertical layout fit-vertically">
                <div class="double-padded vertical spacing layout">
                    <div class="small margined subtle blue colored-text">
                        ${this._settings.id
                            ? html`<i class="pencil-alt"></i> Zeiterfassungs-Regelsatz Bearbeiten`
                            : html`<i class="plus"></i> Neuer Zeiterfassungs-Regelsatz`}
                        <ptc-help-anchor page="handbuch/einstellungen/zeiterfassung/#regelsätze-verwalten">
                            <i class="question-circle"></i>
                        </ptc-help-anchor>
                    </div>
                    <input name="name" required placeholder="Name des Regelsatzes" class="fill-horizontally" />
                    <ptc-entity-filters
                        hideFilterIcon
                        .filterTypes=${["venue", "department", "position", "employeeId", "employmentType"]}
                        class="focus-container"
                    ></ptc-entity-filters>
                </div>

                <ptc-scroller class="stretch">
                    <ptc-time-settings-form
                        class="double-padded small"
                        .settings=${this._settings}
                    ></ptc-time-settings-form>
                </ptc-scroller>

                <div class="padded spacing evenly stretching horizontal layout">
                    <button class="primary" @click=${this._submit}>Speichern</button>
                    <button class="transparent" @click=${() => this.done(false)}>Abbrechen</button>
                </div>
            </div>
        `;
    }
}
