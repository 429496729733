import { getMonday, toDateString, parseDateString, dateAdd } from "@pentacode/core/src/util";
import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import { shared } from "../styles";
import { DateString } from "@pentacode/openapi";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

export type CalendarEntry<T = any> = {
    start: string;
    end: string;
    icon: string;
    label: string;
    color: string;
    hoverLabel?: string;
    data?: T;
};

type WeekData = {
    number: number;
    days: {
        date: string;
        sameMonth: boolean;
    }[];
    lanes: CalendarEntry[][];
};

@customElement("ptc-calendar")
export class Calendar extends LitElement {
    @property({ type: Number })
    minYear: number = currentYear - 1;

    @property({ type: Number })
    maxYear: number = currentYear + 1;

    @property({ type: Number })
    year: number = currentYear;

    @property({ type: Number })
    month: number = currentMonth;

    @property({ attribute: false })
    entries: CalendarEntry[] = [];

    @property()
    renderDay: (date: string) => TemplateResult = () => html``;

    private _resizeObserver = new ResizeObserver(() => this._updateEntryStyles());

    private get _weeks(): WeekData[] {
        let date = getMonday(toDateString(new Date(this.year, this.month, 1)));
        const lastMonday = getMonday(toDateString(new Date(this.year, this.month + 1, 0)));
        const weeks: WeekData[] = [];
        let number = 0;

        while (date <= lastMonday) {
            const week: WeekData = {
                number,
                days: [],
                lanes: [],
            };

            for (let i = 0; i < 7; i++) {
                week.days.push({
                    date,
                    sameMonth: parseDateString(date)?.getMonth() === this.month,
                });
                date = dateAdd(date, { days: 1 });
            }

            const monday = week.days[0].date;
            const sunday = week.days[week.days.length - 1].date;

            const entries = this.entries
                .filter((e) => e.start <= sunday && e.end > monday)
                .sort((a, b) => (a.start < b.start ? -1 : 1));

            for (const entry of entries) {
                let lane = week.lanes.find((lane) => !lane.some((e) => e.start < entry.end && e.end > entry.start));
                if (!lane) {
                    lane = [];
                    week.lanes.push(lane);
                }
                lane.push(entry);
            }

            weeks.push(week);
            number++;
        }

        return weeks;
    }

    connectedCallback(): void {
        super.connectedCallback();
        this._resizeObserver.observe(this);
    }

    updated() {
        this._updateEntryStyles();
    }

    private _updateEntryStyles() {
        const entries = this.renderRoot.querySelectorAll(".entry") as NodeListOf<HTMLElement>;
        const days = [...this.renderRoot.querySelectorAll(".day")] as HTMLElement[];
        const totalWidth = this.offsetWidth;
        for (const entry of entries) {
            const inclusiveEnd = dateAdd(entry.dataset.end as DateString, { days: -1 });
            const weekDays = days.filter((day) => day.dataset.week === entry.dataset.week);
            const firstDay = weekDays.find((day) => day.dataset.date === entry.dataset.start) || weekDays[0];
            const lastDay = weekDays.find((day) => day.dataset.date === inclusiveEnd) || weekDays[weekDays.length - 1];
            const left = firstDay.offsetLeft;
            const right = totalWidth - (lastDay.offsetLeft + lastDay.offsetWidth);
            entry.style.left = `${left}px`;
            entry.style.right = `${right}px`;
            entry.classList.toggle("cutoff-left", entry.dataset.start! < weekDays[0].dataset.date!);
            entry.classList.toggle("cutoff-right", inclusiveEnd > weekDays[weekDays.length - 1].dataset.date!);
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
            }

            .week-header {
                font-weight: bold;
                color: var(--color-primary);
                flex: none !important;
                height: auto !important;
                border-bottom: solid 1px var(--shade-1);
            }

            .week-header > :not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .week:not(:last-child) {
                border-bottom: solid 1px var(--shade-1);
            }

            .day:not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .lanes {
                top: 2em;
                pointer-events: none;
            }

            .lane {
                height: 2em;
                margin: 0.25em 0;
            }

            .entry {
                color: var(--color-highlight);
                pointer-events: auto;
            }

            .entry.cutoff-left .entry-inner {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .entry.cutoff-right .entry-inner {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .entry-inner {
                position: absolute;
                left: 0.25em;
                right: 0.25em;
                top: 0;
                bottom: 0;
                border: solid 1px;
                border-radius: 0.5em;
                padding: 0.25em;
                background: var(--color-bg);
            }

            .day:not(:hover) .add-icon-container {
                visibility: hidden;
            }
        `,
    ];

    render() {
        return html`
            <div class="fullbleed month vertical evenly stretching layout">
                <div class="week-header horizontal evenly stretching horizontal layout">
                    ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((d) => html` <div class="padded">${d}</div> `)}
                </div>
                ${this._weeks.map(
                    (week) => html`
                        <div class="week relative">
                            <div class="fullbleed evenly stretching horizontal layout">
                                ${week.days.map(
                                    (day) => html`
                                        <div
                                            class="day relative vertical layout click"
                                            data-date=${day.date}
                                            data-week=${week.number}
                                            @click=${() =>
                                                this.dispatchEvent(
                                                    new CustomEvent("add-entry", {
                                                        detail: { date: day.date },
                                                    })
                                                )}
                                        >
                                            <div class="padded horizontal layout ${!day.sameMonth ? "faded" : ""}">
                                                ${parseDateString(day.date)?.getDate()}
                                            </div>

                                            <div
                                                class="tinier fullbleed double-padded end-justifying center-aligning vertical layout add-icon-container subtle"
                                            >
                                                <i class="plus"></i>
                                            </div>

                                            ${this.renderDay(day.date)}
                                        </div>
                                    `
                                )}
                            </div>

                            <div class="fullbleed lanes">
                                ${week.lanes.map(
                                    (entries) => html`
                                        <div class="relative smaller lane">
                                            ${entries.map(
                                                (entry) => html`
                                                    <div
                                                        class="fullbleed entry"
                                                        style="--color-highlight: ${entry.color}"
                                                        data-start=${entry.start}
                                                        data-end=${entry.end}
                                                        data-week=${week.number}
                                                        title=${entry.hoverLabel || entry.label}
                                                    >
                                                        <div
                                                            class="entry-inner ellipsis click"
                                                            @click=${(e: Event) => {
                                                                e.stopPropagation();
                                                                this.dispatchEvent(
                                                                    new CustomEvent("entry-clicked", {
                                                                        detail: { entry },
                                                                    })
                                                                );
                                                            }}
                                                        >
                                                            <i class="${entry.icon}"></i>
                                                            ${entry.label}
                                                        </div>
                                                    </div>
                                                `
                                            )}
                                        </div>
                                    `
                                )}
                            </div>
                        </div>
                    `
                )}
            </div>
        `;
    }
}
