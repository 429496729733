import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { Position } from "@pentacode/core/src/model";
import { app } from "../init";
import { colors, shared } from "../styles";

@customElement("ptc-positions-form")
export class PositionsForm extends LitElement {
    @property({ attribute: false })
    positions: Position[] = [];

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    private _addPosition(position: Position) {
        this.positions.push(position);
        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("change"));
    }

    private _removePosition(i: number) {
        this.positions.splice(i, 1);
        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("change"));
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
            }
        `,
    ];

    render() {
        const filterString = this._filterInput?.value.toLowerCase();
        const venues = app.accessibleVenues.map((venue) => ({
            name: venue.name,
            departments: venue.departments
                .filter((department) => app.hasAccess({ department }))
                .map((department) => ({
                    name: department.name,
                    color: department.color,
                    positions: department.positions.filter(
                        (position) =>
                            app.hasAccess({ position }) &&
                            !this.positions.some((pos) => pos.id === position.id) &&
                            (!filterString ||
                                `${venue.name} ${department.name} ${position.name}`
                                    .toLowerCase()
                                    .indexOf(filterString) !== -1)
                    ),
                })),
        }));
        return html`
            <div class="box">
                <ptc-sortable-list
                    .items=${this.positions}
                    .renderItem=${(position: Position, i: number) => html`
                        <div
                            class="border-bottom horizontal center-aligning layout"
                            style="--color-highlight: ${app.company?.getPositionColor(position)}"
                        >
                            <div class="double-padded colored-text semibold stretch" style="cursor: grab">
                                <i class="arrows-down-to-people"></i>
                                ${app.company?.getPositionLabel(position)}
                            </div>
                            ${i === 0 ? html` <div class="smaller pill">standard</div> ` : ""}
                            <button class="margined slim transparent" @click=${() => this._removePosition(i)}>
                                <i class="times"></i>
                            </button>
                        </div>
                    `}
                    @item-moved=${() => this.dispatchEvent(new CustomEvent("change"))}
                >
                </ptc-sortable-list>

                <div class="margined vertical layout">
                    <button class="transparent" type="button"><i class="plus"></i> Position Hinzufügen</button>
                    <ptc-popover style="padding: 0; width: 20em;" @popover-show=${() => this._filterInput?.focus()}>
                        <div class="padded">
                            <div class="small left icon input">
                                <i class="search"></i>
                                <input placeholder="Suchen..." @input=${() => this.requestUpdate()} id="filterInput" />
                            </div>
                        </div>
                        <ptc-scroller style="max-height: 50vh">
                            ${venues.map((venue) =>
                                venue.departments.flatMap((d) => d.positions).length
                                    ? html`
                                          <div class="bottom-padded">
                                              <div
                                                  class="bigger horizontally-padded margined"
                                                  ?hidden=${app.venues.length < 2}
                                              >
                                                  <i class="people-roof"></i> ${venue.name}
                                              </div>

                                              ${venue.departments.map((department) =>
                                                  department.positions.length
                                                      ? html`
                                                            <div
                                                                class="margined box"
                                                                style="--color-highlight: ${colors[department.color] ||
                                                                department.color};"
                                                            >
                                                                <div class="larger padded colored-text border-bottom">
                                                                    <i class="people-line"></i> ${department.name}
                                                                </div>
                                                                <div class="half-margined spacing vertical layout">
                                                                    ${department.positions.map(
                                                                        (position) => html`
                                                                            <button
                                                                                class="slim transparent text-left-aligning"
                                                                                style="--color-highlight: ${app.company?.getPositionColor(
                                                                                    position
                                                                                )};"
                                                                                @click=${() =>
                                                                                    this._addPosition(position)}
                                                                            >
                                                                                <i class="arrows-down-to-people"></i>
                                                                                ${position.name}
                                                                            </button>
                                                                        `
                                                                    )}
                                                                </div>
                                                            </div>
                                                        `
                                                      : ""
                                              )}
                                          </div>
                                      `
                                    : ""
                            )}
                        </ptc-scroller>
                    </ptc-popover>
                </div>
            </div>
        `;
    }
}
