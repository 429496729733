import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { shared, mixins } from "../styles";
import { app } from "../init";
import "./scroller";
import "./contract-form";
import "./avatar";
import "./spinner";
import { Checkbox } from "./checkbox";
import { EmployeeList } from "./employee-list";
import "./employees-profile-all";
import "./employees-time-all";
import "./employees-ledgers-all-month";
import "./employees-ledgers-all-year";
import "./employees-positions-all";
import "./employees-permissions-all";
import "./employees-advances-all";
import "./employees-notifications-all";
import "./employees-absences-all";
import "./employees-availabilities-all";
import "./employees-documents-all";
import "./employees-contracts-all";
import "./employees-time-balance-all";
import "./employees-vacation-balance-all";
import "./employees-bonuses-balance-all";
import { SendMessageDialog } from "./send-message-dialog";
import { singleton } from "../lib/singleton";
import { serializeFilters } from "@pentacode/core/src/filters";
import { Permission } from "@pentacode/core/src/permissions";

@customElement("ptc-employees-all")
export class EmployeesAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all(?:\/(?<page>[^/]+))?/;

    static pages = [
        "core_data",
        "time",
        "time_balance",
        "vacation_balance",
        "bonuses_balance",
        "absences",
        "documents",
        "availabilities",
        "advances",
        "positions",
        "permissions",
        "contracts",
        "ledgers-month",
        "ledgers-year",
    ];

    get helpPage() {
        switch (this._page) {
            case "core_data":
                return "/handbuch/mitarbeiter/stammdaten";
            case "ledgers-month":
            case "ledgers-year":
                return "/handbuch/mitarbeiter/konten";
            case "time":
                return "/handbuch/mitarbeiter/arbeitszeiten";
            case "absences":
                return "/handbuch/mitarbeiter/abwesenheiten";
            case "documents":
                return "/handbuch/mitarbeiter/dokumente";
            case "availabilities":
                return "/handbuch/mitarbeiter/verfügbarkeiten";
            case "advances":
                return "/handbuch/mitarbeiter/vorschuesse";
            case "positions":
                return "/handbuch/mitarbeiter/positionen";
            case "permissions":
                return "/handbuch/mitarbeiter/zugaenge-rechte";
            case "contracts":
                return "/handbuch/mitarbeiter/vertrag";
            default:
                return "/handbuch/mitarbeiter";
        }
    }

    @routeProperty({ arg: "page" })
    private _page: string;

    @state()
    private _loading: boolean = false;

    @state()
    private _selectingEmployee: boolean = false;

    @query("ptc-employee-list")
    private _employeeList: EmployeeList;

    @singleton("ptc-send-message-dialog")
    private _sendMessageDialog: SendMessageDialog;

    async handleRoute() {
        if (this._page === "ledgers") {
            this.go(`employees/all/ledgers-month`, undefined, true);
            return;
        }

        if (!EmployeesAll.pages.includes(this._page) || !this._hasPagePermission(this._page)) {
            const page = EmployeesAll.pages.find((p) => this._hasPagePermission(p));
            this.redirect(page ? `employees/all/${page}` : "");
            return;
        }
    }

    private _hasPagePermission(page: string) {
        // The page name mostly maps directly to the permission name, with a few execptions
        const perm = page
            .replace(/time_balance|vacation_balance|bonuses_balance|ledgers-year|ledgers-month/i, "ledgers")
            .replace("contracts", "contract");
        return app.hasPermission(`manage.employees.${perm}` as Permission);
    }

    private async _startSelectEmployee(e: MouseEvent) {
        e.stopPropagation();
        this._selectingEmployee = true;
        await this.updateComplete;
        this._employeeList.clearFilter();
        this._employeeList.focus();
    }

    private _employeeSelected(e: CustomEvent) {
        this.go(`employees/${e.detail.employee.id}/${this._page}`, this.router.params);
        this._selectingEmployee = false;
    }

    private _goToPage(page: string, params?: { [prop: string]: number | string | Date }) {
        this.go(`employees/all/${page}`, params);
    }

    private async _sendMessage() {
        const filteredDepartments =
            app.employeeFilters.filters
                .filter((f) => f.type === "department")
                .map(({ value }) => app.accessibleDepartments.find((d) => d.id === value)!) || [];
        const isFiltered = filteredDepartments.length !== app.accessibleDepartments.length;
        await this._sendMessageDialog.show({
            all: !isFiltered,
            departments: isFiltered ? filteredDepartments : undefined,
        });
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("click", () => (this._selectingEmployee = false));
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 15em;
                padding: 0;
                position: relative;
            }

            .employee-list {
                ${mixins.fullbleed()};
                background: var(--color-bg);
                z-index: 1;
            }

            .employee-avatar {
                font-size: var(--font-size-enormous);
                align-self: center;
                border: solid 0.15em;
                border-radius: 100%;
                text-align: center;
                background: var(--shade-1);
                color: var(--shade-5);
                width: 2.5em;
                height: 2.5em;
                line-height: 2.5em;
                margin: 0.5em;
                margin-top: 0;
            }

            .employee-name {
                font-size: var(--font-size-medium);
                align-self: center;
                margin-bottom: 0.5em;
                padding: 0.3em;
                padding-left: 0.5em;
            }

            .sub-view {
                flex: 1;
                min-width: 0;
                position: relative;
                border-right: solid 1px var(--shade-1);
            }

            .sub-view > * {
                ${mixins.fullbleed()};
            }

            @media print {
                .sub-view > * {
                    position: static;
                }

                .sub-view {
                    border: none;
                }
            }
        `,
    ];

    render() {
        return html`
            <div class="sub-menu noprint vertical layout">
                <ptc-employee-list
                    @change=${this._employeeSelected}
                    class="employee-list"
                    ?hidden=${!this._selectingEmployee}
                >
                </ptc-employee-list>

                <div class="horizontally-margined vertically-padded vertical text-centering layout border-bottom">
                    <div class="horizontal end-justifying layout">
                        <button
                            class="transparent skinny"
                            @click=${this._sendMessage}
                            title="Nachricht Versenden"
                            ?disabled=${!app.hasPermission("manage.employees.messages")}
                        >
                            <i class="envelope"></i>
                        </button>

                        <button
                            class="transparent skinny"
                            @click=${() =>
                                this.go("exports/employee_data_pdf", {
                                    filters: serializeFilters(app.employeeFilters.filters),
                                })}
                            title="Datenblätter Drucken"
                            ?disabled=${!app.hasPermission("manage.employees.contract")}
                        >
                            <i class="address-card"></i>
                        </button>
                    </div>

                    <i class="users employee-avatar"></i>

                    <button
                        class="center-aligning center-justifying horizontal layout employee-name transparent"
                        @click=${this._startSelectEmployee}
                    >
                        <div>Alle Mitarbeiter</div>
                        <i class="caret-down"></i>
                    </button>
                </div>

                <ptc-scroller class="stretch">
                    <div class="horizontally-margined vertically-padded vertical tabs border-bottom">
                        <button
                            ?active=${this._page === "time"}
                            @click=${() => this._goToPage("time")}
                            ?disabled=${!app.hasPermission("manage.employees.time")}
                        >
                            <i class="hourglass-clock"></i>
                            Arbeitszeiten
                        </button>

                        <button
                            ?active=${this._page === "time_balance"}
                            @click=${() => this._goToPage("time_balance")}
                            ?disabled="${!app.hasPermission("manage.employees.ledgers")}"
                        >
                            <i class="scale-unbalanced"></i>
                            Arbeitszeitkonto
                        </button>

                        <button
                            ?active=${this._page === "vacation_balance"}
                            @click=${() => this._goToPage("vacation_balance")}
                            ?disabled=${!app.hasPermission("manage.employees.ledgers")}
                        >
                            <i class="island-tropical"></i>
                            Urlaubskonto
                        </button>

                        <button
                            ?active=${this._page === "bonuses_balance"}
                            @click=${() => this._goToPage("bonuses_balance")}
                            ?disabled=${!app.hasPermission("manage.employees.ledgers")}
                        >
                            <i class="badge-percent"></i>
                            Zuschlagskonto
                        </button>

                        <button
                            ?active=${this._page === "absences"}
                            @click=${() => this._goToPage("absences", { date: new Date() })}
                            ?disabled=${!app.hasPermission("manage.employees.absences")}
                        >
                            <i class="person-to-portal"></i>
                            Abwesenheiten
                        </button>

                        <button
                            ?active=${this._page === "availabilities"}
                            @click=${() => this._goToPage("availabilities", { date: new Date() })}
                            ?disabled=${!app.hasPermission("manage.employees.availabilities")}
                        >
                            <i class="comment-check"></i>
                            Verfügbarkeiten
                        </button>

                        <button
                            ?active=${this._page === "documents"}
                            @click=${() => this._goToPage("documents")}
                            ?disabled=${!app.hasPermission("manage.employees.documents")}
                        >
                            <i class="files"></i>
                            Dokumente & Formulare
                        </button>

                        <button
                            ?active=${this._page === "advances"}
                            @click=${() => this._goToPage("advances")}
                            ?disabled=${!app.hasPermission("manage.employees.advances")}
                        >
                            <i class="hand-holding-dollar"></i>
                            Vorschüsse
                        </button>

                        <button
                            ?active=${this._page === "ledgers-month" || this._page === "ledgers-year"}
                            @click=${() => this._goToPage("ledgers-month")}
                            ?disabled=${!app.hasPermission("manage.employees.ledgers")}
                        >
                            <i class="scale-unbalanced"></i>
                            Konten (Übersicht)
                        </button>
                    </div>
                    <div class="horizontally-margined vertically-padded vertical tabs border-bottom">
                        <button
                            ?active=${this._page === "core_data"}
                            @click=${() => this._goToPage("core_data")}
                            ?disabled=${!app.hasPermission("manage.employees.core_data")}
                        >
                            <i class="address-card"></i>
                            Stammdaten
                        </button>

                        <button
                            ?active=${this._page === "positions"}
                            @click=${() => this._goToPage("positions")}
                            ?disabled=${!app.hasPermission("manage.employees.positions")}
                        >
                            <i class="arrows-down-to-people"></i>
                            Positionen
                        </button>

                        <button
                            ?active=${this._page === "permissions"}
                            @click=${() => this._goToPage("permissions")}
                            ?disabled=${!app.hasPermission("manage.employees.permissions")}
                        >
                            <i class="user-unlock"></i>
                            Zugänge & Rechte
                        </button>

                        <button
                            ?active=${this._page === "contracts"}
                            @click=${() => this._goToPage("contracts")}
                            ?disabled=${!app.hasPermission("manage.employees.contract")}
                        >
                            <i class="file-contract"></i>
                            Verträge
                        </button>
                    </div>
                    <div class="horizontally-margined vertically-padded vertical tabs border-bottom">
                        <button
                            @click=${() => this.dispatchEvent(new CustomEvent("newemployee"))}
                            ?disabled=${!app.hasPermission("manage.employees.create")}
                        >
                            <i class="plus"></i>
                            Neuer Mitarbeiter
                        </button>
                    </div>
                </ptc-scroller>
            </div>

            <div class="sub-view">
                <ptc-employees-profile-all
                    class="fullbleed"
                    ?hidden=${this._page !== "core_data"}
                ></ptc-employees-profile-all>

                <ptc-employees-time-all class="fullbleed" ?hidden=${this._page !== "time"}></ptc-employees-time-all>

                <ptc-employees-ledgers-all-month
                    class="fullbleed"
                    ?hidden=${this._page !== "ledgers-month"}
                ></ptc-employees-ledgers-all-month>

                <ptc-employees-time-balance-all
                    class="fullbleed"
                    ?hidden=${this._page !== "time_balance"}
                ></ptc-employees-time-balance-all>

                <ptc-employees-vacation-balance-all
                    class="fullbleed"
                    ?hidden=${this._page !== "vacation_balance"}
                ></ptc-employees-vacation-balance-all>

                <ptc-employees-bonuses-balance-all
                    class="fullbleed"
                    ?hidden=${this._page !== "bonuses_balance"}
                ></ptc-employees-bonuses-balance-all>

                <ptc-employees-ledgers-all-year
                    class="fullbleed"
                    ?hidden=${this._page !== "ledgers-year"}
                ></ptc-employees-ledgers-all-year>

                <ptc-employees-advances-all
                    class="fullbleed"
                    ?hidden=${this._page !== "advances"}
                ></ptc-employees-advances-all>

                <ptc-employees-positions-all
                    class="fullbleed"
                    ?hidden=${this._page !== "positions"}
                ></ptc-employees-positions-all>

                <ptc-employees-permissions-all
                    class="fullbleed"
                    ?hidden=${this._page !== "permissions"}
                ></ptc-employees-permissions-all>

                <ptc-employees-notifications-all
                    class="fullbleed"
                    ?hidden=${this._page !== "notifications"}
                ></ptc-employees-notifications-all>

                <ptc-employees-absences-all
                    class="fullbleed"
                    ?hidden=${this._page !== "absences"}
                ></ptc-employees-absences-all>

                <ptc-employees-documents-all
                    class="fullbleed"
                    ?hidden=${this._page !== "documents"}
                ></ptc-employees-documents-all>

                <ptc-employees-availabilities-all
                    class="fullbleed"
                    ?hidden=${this._page !== "availabilities"}
                ></ptc-employees-availabilities-all>

                <ptc-employees-contracts-all
                    class="fullbleed"
                    ?hidden=${this._page !== "contracts"}
                ></ptc-employees-contracts-all>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
