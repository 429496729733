import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { formatNumber } from "@pentacode/core/src/util";
import { CountingLog } from "@pentacode/core/src/model";
import { shared } from "../styles";
import { app } from "../init";
import { Euros } from "@pentacode/openapi";

const denominations = [500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01] as Euros[];

@customElement("ptc-counting-log-form")
export class CountingLogForm extends LitElement {
    @property()
    readonly: boolean = false;

    @query("form")
    private _form: HTMLFormElement;

    get data(): CountingLog {
        const data = new FormData(this._form);

        const count: { value: Euros; amount: Euros }[] = [];

        for (const value of denominations) {
            const amount = (parseInt(data.get(value.toString()) as string) || 0) as Euros;
            if (amount) {
                count.push({ value, amount });
            }
        }

        return {
            account: app.account!.id,
            countedBy: data.get("countedBy") as string,
            count,
        };
    }

    checkValidity() {
        return this._form.checkValidity();
    }

    reportValidity() {
        return this._form.reportValidity();
    }

    reset(log: CountingLog | null) {
        (this.renderRoot.querySelector("input[name='countedBy']") as HTMLInputElement).value =
            (log && log.countedBy) || app.account!.name;
        for (const value of denominations) {
            const input = this.renderRoot.querySelector(`input[name="${value}"]`) as HTMLInputElement;
            const count = log && log.count.find((c) => c.value === value);
            input.value = (count && count.amount.toString()) || "0";
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
            }

            .count {
                font-size: var(--font-size-small);
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(5em, 1fr));
                grid-gap: 0.5em;
            }

            .count .field > label {
                text-align: center;
                padding: 0.5em;
            }

            .count input {
                text-align: right;
            }
        `,
    ];

    render() {
        return html`
            <form @change=${() => this.dispatchEvent(new CustomEvent("change"))}>
                <div class="field">
                    <label>Zählung durchgeführt von:</label>
                    <input name="countedBy" required ?readonly=${this.readonly} />
                </div>

                <div class="count">
                    ${denominations.map((value) => {
                        return html`
                            <div class="field">
                                <label>${formatNumber(value)} €</label>
                                <input name="${value}" type="number" step="1" min="0" ?readonly=${this.readonly} />
                            </div>
                        `;
                    })}
                </div>
            </form>
        `;
    }
}
