import { html, css, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { getSingleton } from "../lib/singleton";
import { Dialog } from "./dialog";
import { AlertType } from "./alert-dialog";

export interface PromptOptions {
    message?: string | TemplateResult;
    title?: string;
    type?: AlertType;
    icon?: string;
    preventDismiss?: boolean;
    hideIcon?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    placeholder?: string;
}

@customElement("pl-prompt-dialog")
export class PromptDialog extends Dialog<PromptOptions, string> {
    @property()
    dialogTitle: string = "";
    @property()
    message: string | TemplateResult = "";
    @property()
    confirmLabel: string;
    @property()
    cancelLabel: string;
    @property({ reflect: true, attribute: "type" })
    type: AlertType = "info";
    @property()
    icon = "";
    @property({ attribute: "hide-icon", reflect: true, type: Boolean })
    hideIcon: boolean = false;
    @property({ reflect: true })
    horizontal: boolean = false;
    @property()
    placeholder: string;

    @query("form")
    private _form: HTMLFormElement;

    static styles = [
        ...Dialog.styles,
        css`
            :host([hide-icon]) {
                text-align: center;
            }

            .inner {
                max-width: 28em;
                padding: 0.5em;
            }

            i {
                font-size: 3rem;
                margin: 0.1em;
            }

            .title {
                font-weight: bold;
                font-size: var(--font-size-large);
                margin: 0.5em;
            }

            .text {
                margin: 0.5em;
            }
        `,
    ];

    renderContent() {
        const { message, dialogTitle, confirmLabel, cancelLabel, icon } = this;
        return html`
            <form @submit=${this._submit} autocomplete="off">
                <div class="start-aligning horizontal layout" ?hidden=${!dialogTitle && !message}>
                    <i class="${icon}" ?hidden=${this.hideIcon}></i>
                    <div class="stretch">
                        <div class="title" ?hidden=${!dialogTitle}>${dialogTitle}</div>
                        <div class="text">${message}</div>
                    </div>
                </div>

                <div class="vertically-margined vertical layout">
                    <textarea name="_text" placeholder=${this.placeholder} autocomplete="nah"></textarea>
                </div>

                <div class="spacing evenly stretching horizontal layout">
                    <button class="${this._buttonClass()}">${confirmLabel}</button>
                    ${cancelLabel
                        ? html`
                              <button class="transparent" type="button" @click=${() => this.done()}>
                                  ${cancelLabel}
                              </button>
                          `
                        : ""}
                </div>
            </form>
        `;
    }

    show({
        message = "",
        title = "",
        type = "info",
        preventDismiss = true,
        icon,
        hideIcon = false,
        confirmLabel = "Absenden",
        cancelLabel = "Abbrechen",
        placeholder = "",
    }: PromptOptions = {}) {
        this.message = message;
        this.dialogTitle = title;
        this.type = type;
        this.preventDismiss = preventDismiss;
        this.icon = icon || this._icon(type);
        this.hideIcon = hideIcon;
        this.confirmLabel = confirmLabel;
        this.cancelLabel = cancelLabel;
        this.placeholder = placeholder;

        return super.show();
    }

    private _submit(e: Event) {
        e.preventDefault();
        const data = new FormData(this._form);
        this.done(data.get("_text") as string);
    }

    private _icon(type: string) {
        switch (type) {
            case "info":
                return "info-circle";
            case "warning":
                return "exclamation-triangle";
            case "success":
                return "check";
            case "question":
            case "destructive":
                return "question-circle";
            default:
                return "";
        }
    }

    private _buttonClass() {
        return this.type === "destructive" ? "negative" : "primary";
    }
}

export async function prompt(
    message: string | TemplateResult,
    confirmLabel?: string,
    cancelLabel?: string,
    opts: PromptOptions = {}
) {
    const dialog = getSingleton("pl-prompt-dialog") as PromptDialog;
    if (dialog.promise) {
        await dialog.promise;
    }
    return dialog.show({ ...opts, message, confirmLabel, cancelLabel });
}
