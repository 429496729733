import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { parseDurationString, toDurationString } from "@pentacode/core/src/util";
import "./scroller";
import { BonusCompType, BonusType, BonusTypeMode, BonusTypeOverride } from "@pentacode/core/src/model";
import { Checkbox } from "./checkbox";
import { TimeInput } from "./time-input";
import { live } from "lit/directives/live.js";
import { app } from "../init";
import "./popover";
import { Dialog } from "./dialog";
import "./bonus-type-item";
import { HOLIDAYS } from "@pentacode/core/src/holidays";
import { Euros, Hours, Percent, Rate } from "@pentacode/openapi";

@customElement("ptc-bonus-type-dialog")
export class BonusTypeDialog extends Dialog<BonusType, boolean> {
    @state()
    private _bonusType: BonusType;

    @query("form")
    private _form: HTMLFormElement;

    private get _intervals(): [number, number][] {
        return (
            this._bonusType?.intervals.map(([start, end]) => [parseDurationString(start), parseDurationString(end)]) ||
            []
        );
    }

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    private _draggingInterval: { index: number; edit: "start" | "end" | "both"; original: [number, number] } | null =
        null;

    private _dragstartPosition: number | null = null;

    private _draggingStartBefore: string | null = null;

    private _hourWidth: number = 0;

    readonly preventDismiss = true;

    async show(type: BonusType) {
        this._bonusType = type;
        const promise = super.show();

        await this.updateComplete;

        if (!this._nameInput.value) {
            this._nameInput.focus();
        }

        return promise;
    }

    async updated() {
        const hourElements = this.renderRoot.querySelectorAll(".time-span-line-hour") as NodeListOf<HTMLDivElement>;
        this._hourWidth = hourElements[0].offsetWidth;
        const hourPositions = [...hourElements].map((el) => el.offsetLeft);
        for (const [i, [start, end]] of this._intervals.entries()) {
            const intervalEl = this.renderRoot.querySelector(
                `.time-span-interval[data-interval-index="${i}"]`
            ) as HTMLDivElement;
            if (!intervalEl) {
                continue;
            }
            const startPos = hourPositions[Math.floor(start)] + this._hourWidth * (start % 1);
            const endPos = hourPositions[Math.floor(end)] + this._hourWidth * (end % 1);
            intervalEl.style.left = `${startPos}px`;
            intervalEl.style.width = `${endPos - startPos}px`;
        }

        if (this._bonusType.shiftMustStartBefore) {
            const el = this.renderRoot.querySelector(".time-span-interval.start-before") as HTMLDivElement;
            const hours = parseDurationString(this._bonusType.shiftMustStartBefore);
            el.style.left = `${hourPositions[Math.floor(hours)] + this._hourWidth * (hours % 1)}px`;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.renderRoot.addEventListener("mousedown", (e: MouseEvent) => this._mousedown(e));
        document.addEventListener("mouseup", () => this._mouseup());
        document.addEventListener("mousemove", (e: MouseEvent) => this._mousemove(e));
    }

    private _formInput() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);

        this._bonusType.name = data.get("name") as string;
        this._bonusType.mode = data.get("mode") as BonusTypeMode;

        this._bonusType.compType = data.get("compType") as BonusCompType;

        this._bonusType.minDuration = data.get("minDuration")
            ? parseDurationString(data.get("minDuration") as string)
            : null;
        this._bonusType.minDurationPercent = data.get("minDurationPercent")
            ? (Number(data.get("minDurationPercent")) as Percent)
            : null;
        this._bonusType.shiftMustStartBefore = (data.get("shiftMustStartBefore") as string) || null;
        this._bonusType.taxFree = data.has("taxFree");
        this._bonusType.taxFreeUpToRate = (
            this._bonusType.taxFree && this._bonusType.compType === BonusCompType.ModifiedHourlyRate
                ? Number(data.get("taxFreeUpToRate") || "0")
                : 0
        ) as Rate<Euros, Hours>;
        this._bonusType.continuedPay = data.has("continuedPay");

        switch (this._bonusType.mode) {
            case BonusTypeMode.Date:
                this._bonusType.date = data.get("date") as string;
                break;
            case BonusTypeMode.WeekDays:
                this._bonusType.weekDays = (data.getAll("weekDays") as string[]).map((day) => Number(day));
                break;
            case BonusTypeMode.Holidays:
                this._bonusType.holidays = data.getAll("holidays") as string[];
                break;
        }

        switch (this._bonusType.compType) {
            case BonusCompType.ModifiedHourlyRate:
                this._bonusType.maxPercent = (
                    data.get("maxPercent") ? Number(data.get("maxPercent") as string) : 0
                ) as Percent;
                this._bonusType.defaultPercent = (
                    data.get("defaultPercent") ? Number(data.get("defaultPercent") as string) : 0
                ) as Percent;
                break;
            case BonusCompType.FixedHourlyRate:
                this._bonusType.fixedHourlyRateMax = (
                    data.get("fixedHourlyRateMax") ? Number(data.get("fixedHourlyRateMax") as string) : 0
                ) as Rate<Euros, Hours>;
                this._bonusType.fixedHourlyRateDefault = (
                    data.get("fixedHourlyRateDefault") ? Number(data.get("fixedHourlyRateDefault") as string) : 0
                ) as Rate<Euros, Hours>;
                break;
            case BonusCompType.FixedAmount:
                this._bonusType.fixedAmountMax = (
                    data.get("fixedAmountMax") ? Number(data.get("fixedAmountMax") as string) : 0
                ) as Euros;
                this._bonusType.fixedAmountDefault = (
                    data.get("fixedAmountDefault") ? Number(data.get("fixedAmountDefault") as string) : 0
                ) as Euros;
                break;
        }

        this.requestUpdate();
    }

    private _mousedown(e: MouseEvent) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        const target = e.target as HTMLDivElement;

        if (target.classList.contains("start-before") || target.parentElement?.classList.contains("start-before")) {
            this._draggingInterval = null;
            this._draggingStartBefore = this._bonusType.shiftMustStartBefore;
            this._dragstartPosition = e.clientX;
            return;
        } else {
            this._draggingStartBefore = null;
        }

        let intervalEl: HTMLDivElement | null = null;
        let action: "start" | "end" | "both" = "both";

        if (target.classList.contains("time-span-interval")) {
            intervalEl = target;
        } else if (target.classList.contains("time-span-interval-knob")) {
            intervalEl = target.parentElement as HTMLDivElement;
            if (target.classList.contains("start")) {
                action = "start";
            } else if (target.classList.contains("end")) {
                action = "end";
            }
        }

        if (!intervalEl) {
            return;
        }

        const intervalIndex = Number(intervalEl.dataset.intervalIndex);

        if (typeof intervalIndex === "number") {
            this._draggingInterval = {
                index: intervalIndex,
                edit: action,
                original: [...this._intervals[intervalIndex]],
            };
            this._dragstartPosition = e.clientX;
        }
    }

    private _mouseup() {
        this._draggingInterval = null;
        this._dragstartPosition = null;
        this._draggingStartBefore = null;
    }

    private _mousemove(e: MouseEvent) {
        if (this._draggingStartBefore && this._dragstartPosition) {
            const dx = e.clientX - this._dragstartPosition;
            const dt = Math.round((dx / this._hourWidth) * 2) / 2;
            const newValue = toDurationString(parseDurationString(this._draggingStartBefore) + dt);
            if (newValue > "00:00" && newValue < "24:00") {
                this._bonusType.shiftMustStartBefore = newValue;
                this.requestUpdate("_bonusType");
            }
        }

        if (this._draggingInterval && this._dragstartPosition) {
            const dx = e.clientX - this._dragstartPosition;
            const dt = Math.round((dx / this._hourWidth) * 2) / 2;

            const currInterval = this._intervals[this._draggingInterval.index];
            const newInterval = [...this._draggingInterval.original];

            switch (this._draggingInterval.edit) {
                case "start":
                    newInterval[0] += dt;
                    break;
                case "end":
                    newInterval[1] += dt;
                    break;
                case "both":
                    newInterval[0] += dt;
                    newInterval[1] += dt;
                    break;
            }

            if (
                (currInterval[0] !== newInterval[0] || currInterval[1] !== newInterval[1]) &&
                newInterval[0] >= 0 &&
                newInterval[1] <= 36 &&
                newInterval[1] > newInterval[0] &&
                !this._intervals.some(
                    ([start, end], i) =>
                        (i > this._draggingInterval!.index && start <= newInterval[1]) ||
                        (i < this._draggingInterval!.index && end >= newInterval[0])
                )
            ) {
                this._intervals[this._draggingInterval.index] = newInterval as [number, number];
                this._bonusType.intervals[this._draggingInterval.index] = [
                    toDurationString(newInterval[0]),
                    toDurationString(newInterval[1]),
                ];
                this.requestUpdate();
            }
        }
    }

    private _addInterval() {
        switch (this._intervals.length) {
            case 0:
                this._bonusType.intervals = [["00:00", "24:00"]];
                break;
            case 1:
                this._bonusType.intervals = [
                    ["00:00", "11:00"],
                    ["13:00", "24:00"],
                ];
                break;
            case 2:
                this._bonusType.intervals = [
                    ["00:00", "10:00"],
                    ["12:00", "22:00"],
                    ["24:00", "34:00"],
                ];
                break;
        }

        this.requestUpdate();
    }

    private _removeInterval() {
        this._bonusType.intervals = this._bonusType.intervals.slice(0, -1);
        this.requestUpdate();
    }

    private _addOverride(type: BonusType) {
        if (!this._bonusType.overrides) {
            this._bonusType.overrides = [];
        }
        this._bonusType.overrides.push(
            new BonusTypeOverride({
                objectId: type.id,
            })
        );
        this.requestUpdate();
    }

    private _removeOverride(type: BonusType) {
        this._bonusType.overrides = this._bonusType.overrides.filter((o) => o.objectId !== type.id);
        this.requestUpdate();
    }

    static styles = [
        ...Dialog.styles,
        Checkbox.styles,
        TimeInput.styles,
        css`
            :host {
                display: block;
            }

            .inner {
                max-width: 50em;
            }

            .time-span {
                position: relative;
                padding: 1em;
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
            }

            .time-span-line {
                height: 4em;
                font-size: var(--font-size-small);
                position: relative;
                opacity: 0.8;
            }

            .time-span-line::after {
                content: "";
                display: block;
                border-top: dashed 1px;
                position: absolute;
                width: 100%;
                height: 0;
                top: 1em;
            }

            .time-span-line-hour {
                position: relative;
            }

            .time-span-line-hour::before {
                content: "";
                display: block;
                border-left: solid 1px;
                position: absolute;
                width: 0;
                left: 0;
                height: 1.4em;
                top: 0.3em;
            }

            .time-span-line-hour:nth-child(2n)::before {
                height: 1em;
                top: 0.5em;
            }

            .time-span-line-hour::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 1.5em;
                transform: translate(-50%, 0);
            }

            .time-span-line-hour:nth-child(2n + 1)::after {
                content: attr(data-hour);
                font-size: var(--font-size-larger);
                margin-top: 0.2em;
            }

            .time-span-intervals {
                position: relative;
            }

            .time-span-interval {
                height: 1em;
                position: absolute;
                z-index: 1;
                top: 0.45em;
                cursor: move;
            }

            .time-span-interval::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 0.2em;
                bottom: 0.2em;
                background: var(--color-primary);
                border-radius: 0.6em;
                opacity: 0.3;
            }

            .time-span-interval-knob {
                display: block;
                width: 1.6em;
                height: 1.6em;
                border-radius: 100%;
                background: var(--color-highlight, var(--color-primary));
                position: absolute;
                top: -0.4em;
                transition: box-shadow;
                z-index: 2;
                box-shadow: rgba(0, 0, 0, 0.3) 0 0 0.2em;
                border: solid 0.3em var(--color-bg);
                box-sizing: border-box;
                cursor: col-resize;
            }

            .time-span-interval-knob:hover {
                opacity: 0.5;
            }

            .time-span-interval-knob.smaller {
                top: -0.3em;
            }

            .time-span-interval-knob.start {
                left: -0.8em;
            }

            .time-span-interval-knob.end {
                right: -0.85em;
            }
        `,
    ];

    renderContent() {
        const hours = [];
        for (let i = 0; i < 36; i++) {
            hours.push(i);
        }

        const months: [string, number][] = [
            ["Januar", 31],
            ["Februar", 28],
            ["März", 31],
            ["April", 30],
            ["Mai", 31],
            ["Juni", 30],
            ["Juli", 31],
            ["August", 31],
            ["September", 30],
            ["Oktober", 31],
            ["November", 30],
            ["Dezember", 31],
        ];

        const days: [string, string][] = [];

        for (const [i, [month, numberOfDays]] of months.entries()) {
            for (let j = 1; j <= numberOfDays; j++) {
                days.push([
                    `${j}. ${month}`,
                    `${(i + 1).toString().padStart(2, "0")}-${j.toString().padStart(2, "0")}`,
                ]);
            }
        }

        const overrideable =
            app.company?.bonusTypes?.filter(
                (t) =>
                    t.id !== this._bonusType.id &&
                    !this._bonusType.overrides?.some((o) => o.objectId === t.id) &&
                    !t.overrides?.some((o) => o.objectId === this._bonusType.id)
            ) || [];

        return html`
            <form
                @change=${this._formInput}
                @submit=${(e: Event) => {
                    e.preventDefault();
                    this.done(true);
                }}
                autocomplete="off"
                class="vertical layout fit-vertically"
            >
                <div class="double-padded vertical spacing layout">
                    <div class="small margined subtle blue colored-text">
                        ${this._bonusType.id
                            ? html`<i class="pencil-alt"></i> Zuschlagsart Bearbeiten`
                            : html`<i class="plus"></i> Neue Zuschlagsart`}
                    </div>
                    <input
                        name="name"
                        required
                        placeholder="Name der Zuschlagsart"
                        class="fill-horizontally"
                        .value=${live(this._bonusType.name)}
                    />
                </div>

                <ptc-scroller class="stretch">
                    <div class="horizontally-padded horizontally-margined">
                        <div class="horizontal evenly stretching spacing layout">
                            <div class="field">
                                <label>Gültig An</label>
                                <select name="mode" required .value=${live(this._bonusType.mode)}>
                                    <option .value=${BonusTypeMode.Daily}>Täglich</option>
                                    <option .value=${BonusTypeMode.WeekDays}>Bestimmte Wochentage</option>
                                    <option .value=${BonusTypeMode.Holidays}>Feiertage</option>
                                    <option .value=${BonusTypeMode.Date}>Datum</option>
                                </select>
                            </div>
                            ${this._bonusType.mode === BonusTypeMode.Date
                                ? html`
                                      <div class="field">
                                          <label>Datum</label>
                                          <select name="date" required .value=${live(this._bonusType.date || "")}>
                                              ${days.map(
                                                  ([label, value]) => html` <option .value=${value}>${label}</option> `
                                              )}
                                          </select>
                                      </div>
                                  `
                                : ""}
                            ${this._bonusType.mode === BonusTypeMode.Holidays
                                ? html`
                                      <div class="field">
                                          <label>Feiertage</label>
                                          <button class="slim ghost" type="button">
                                              <div
                                                  class="normal-weight text-left-aligning horizontal center-aligning layout"
                                              >
                                                  <div class="stretch">
                                                      ${this._bonusType.holidays?.length
                                                          ? html`<span class="bold"
                                                                    >${this._bonusType.holidays.length}</span
                                                                >
                                                                Feiertage gewählt`
                                                          : html`<span class="subtle">Feiertage Wählen</span>`}
                                                  </div>
                                                  <i class="angle-down"></i>
                                              </div>
                                          </button>
                                          <ptc-popover style="padding: 0">
                                              <ptc-scroller style="max-height: 40em">
                                                  <div class="padded spacing vertical layout">
                                                      ${HOLIDAYS[app.company?.country || "DE"]
                                                          .filter((hol) => !hol.noRealHoliday)
                                                          .map(
                                                              (holiday) => html`
                                                                  <ptc-checkbox-button
                                                                      name="holidays"
                                                                      buttonClass="small slim ghost"
                                                                      .value=${holiday.name}
                                                                      .label=${holiday.name}
                                                                      .checked=${this._bonusType.holidays?.includes(
                                                                          holiday.name
                                                                      )}
                                                                  ></ptc-checkbox-button>
                                                              `
                                                          )}
                                                  </div>
                                              </ptc-scroller>
                                          </ptc-popover>
                                      </div>
                                  `
                                : ""}
                        </div>

                        ${this._bonusType.mode === BonusTypeMode.WeekDays
                            ? html`
                                  <div class="field">
                                      <label>Wochentage</label>
                                      <div class="horizontal spacing wrapping layout">
                                          ${[
                                              ["Mo", 1],
                                              ["Di", 2],
                                              ["Mi", 3],
                                              ["Do", 4],
                                              ["Fr", 5],
                                              ["Sa", 6],
                                              ["So", 0],
                                          ].map(
                                              ([label, value]) => html`
                                                  <ptc-checkbox-button
                                                      name="weekDays"
                                                      .label=${label}
                                                      .value="${value}"
                                                      .buttonClass=${"small slim ghost"}
                                                      .checked=${live(
                                                          this._bonusType.weekDays?.includes(value as number)
                                                      )}
                                                  ></ptc-checkbox-button>
                                              `
                                          )}
                                      </div>
                                  </div>
                              `
                            : ""}

                        <div class="horizontal evenly stretching spacing layout">
                            <div class="field">
                                <label>Schichtbeginn Vor</label>
                                <ptc-time-input
                                    name="shiftMustStartBefore"
                                    .value=${this._bonusType.shiftMustStartBefore || ""}
                                    min="00:30"
                                    max="23:30"
                                ></ptc-time-input>
                            </div>
                            <div class="field">
                                <label>Mindestdauer (Std:Min)</label>
                                <ptc-time-input
                                    name="minDuration"
                                    .value=${this._bonusType.minDuration
                                        ? live(toDurationString(this._bonusType.minDuration))
                                        : ""}
                                ></ptc-time-input>
                            </div>
                            <div class="field">
                                <label>Mindestdauer (%)</label>
                                <div class="right icon input">
                                    <input
                                        type="number"
                                        inputmode="numeric"
                                        class="text-right-aligning"
                                        name="minDurationPercent"
                                        .value=${live(this._bonusType.minDurationPercent?.toString() || "")}
                                        min="1"
                                        max="100"
                                        step="1"
                                    />
                                    <i class="percent"></i>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <div class="center-aligning spacing horizontal layout">
                                <label>Zeiträume</label>
                                <button
                                    type="button"
                                    class="smaller skinny subtle"
                                    ?disabled=${!this._intervals.length}
                                    @click=${this._removeInterval}
                                >
                                    <i class="minus"></i>
                                </button>
                                <button
                                    type="button"
                                    class="smaller skinny subtle"
                                    ?disabled=${this._intervals.length > 2}
                                    @click=${this._addInterval}
                                >
                                    <i class="plus"></i>
                                </button>
                            </div>
                            <div class="time-span">
                                <div class="time-span-intervals">
                                    ${this._intervals.map(
                                        (interval, index) => html`
                                            <div class="time-span-interval" data-interval-index=${index}>
                                                <div class="time-span-interval-knob start"></div>
                                                <div class="time-span-interval-knob end"></div>
                                            </div>
                                            <ptc-popover
                                                class="non-interactive tooltip"
                                                trigger="hover"
                                                alignment="top"
                                            >
                                                Zeitraum ${index + 1}:
                                                <div class="larger bold">
                                                    ${interval[0] >= 24
                                                        ? `${toDurationString(interval[0] - 24)} (Folgetag)`
                                                        : toDurationString(interval[0])}
                                                    -
                                                    ${interval[1] > 24
                                                        ? `${toDurationString(interval[1] - 24)} (Folgetag)`
                                                        : toDurationString(interval[1])}
                                                </div>
                                            </ptc-popover>
                                        `
                                    )}
                                    ${this._bonusType.shiftMustStartBefore
                                        ? html`
                                              <div class="time-span-interval start-before">
                                                  <div class="smaller grey time-span-interval-knob start"></div>
                                                  <ptc-popover
                                                      class="non-interactive tooltip"
                                                      trigger="hover"
                                                      alignment="top"
                                                  >
                                                      Schichtbeginn vor:
                                                      <div class="larger bold">
                                                          ${this._bonusType.shiftMustStartBefore.slice(0, 5)}
                                                      </div>
                                                  </ptc-popover>
                                              </div>
                                          `
                                        : ""}
                                </div>
                                <div class="time-span-line horizontal evenly stretching layout">
                                    ${hours.map(
                                        (h) => html`
                                            <div
                                                class="time-span-line-hour"
                                                data-hour="${(h % 24).toString().padStart(2, "0")}"
                                            ></div>
                                        `
                                    )}
                                </div>
                                <div class="subtle horizontal layout">
                                    <div class="text-right-aligning" style="flex: 2">
                                        Tag des Schichtbeginns <i class="arrow-left-from-line"></i>
                                    </div>
                                    <div class="text-left-aligning" style="flex: 1">
                                        <i class="arrow-right-from-line"></i> Folgetag
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="horizontal evenly stretching spacing layout">
                            <div class="field">
                                <label>Art der Vergütung</label>
                                <select name="compType" .value=${live(this._bonusType.compType)}>
                                    <option .value=${BonusCompType.ModifiedHourlyRate}>Prozent des Basislohns</option>
                                    <option .value=${BonusCompType.FixedHourlyRate}>Fester Stundensatz</option>
                                    <option .value=${BonusCompType.FixedAmount}>Fester Betrag</option>
                                </select>
                            </div>

                            ${this._bonusType.compType === BonusCompType.ModifiedHourlyRate
                                ? html`
                                      <div class="field">
                                          <label>Prozent Maximal</label>
                                          <div class="right icon input">
                                              <input
                                                  type="number"
                                                  ?required=${this._bonusType.compType ===
                                                  BonusCompType.ModifiedHourlyRate}
                                                  inputmode="numeric"
                                                  class="text-right-aligning"
                                                  name="maxPercent"
                                                  .value=${live(this._bonusType.maxPercent?.toString() || "")}
                                                  min="0"
                                                  max="200"
                                                  step="1"
                                              />
                                              <i class="percent"></i>
                                          </div>
                                      </div>

                                      <div class="field">
                                          <label>Prozent Standardwert</label>
                                          <div class="right icon input">
                                              <input
                                                  type="number"
                                                  ?required=${this._bonusType.compType ===
                                                  BonusCompType.ModifiedHourlyRate}
                                                  inputmode="numeric"
                                                  name="defaultPercent"
                                                  class="text-right-aligning"
                                                  .value=${live(this._bonusType.defaultPercent?.toString() || "")}
                                                  max="${this._bonusType.maxPercent}"
                                                  step="1"
                                              />
                                              <i class="percent"></i>
                                          </div>
                                      </div>
                                  `
                                : ""}
                            ${this._bonusType.compType === BonusCompType.FixedHourlyRate
                                ? html`
                                      <div class="field">
                                          <label>Stundensatz Maximal</label>
                                          <div class="right icon input">
                                              <input
                                                  type="number"
                                                  ?required=${this._bonusType.compType ===
                                                  BonusCompType.FixedHourlyRate}
                                                  inputmode="numeric"
                                                  class="text-right-aligning"
                                                  name="fixedHourlyRateMax"
                                                  .value=${live(this._bonusType.fixedHourlyRateMax?.toString() || "")}
                                                  min="0"
                                                  max="1000"
                                                  step="0.01"
                                              />
                                              <i class="euro-sign"></i>
                                          </div>
                                      </div>

                                      <div class="field">
                                          <label>Stundensatz Standardwert</label>
                                          <div class="right icon input">
                                              <input
                                                  type="number"
                                                  ?required=${this._bonusType.compType ===
                                                  BonusCompType.FixedHourlyRate}
                                                  inputmode="numeric"
                                                  class="text-right-aligning"
                                                  name="fixedHourlyRateDefault"
                                                  .value=${live(
                                                      this._bonusType.fixedHourlyRateDefault?.toString() || ""
                                                  )}
                                                  min="0"
                                                  max="1000"
                                                  step="0.01"
                                                  max="${this._bonusType.fixedHourlyRateMax}"
                                              />
                                              <i class="euro-sign"></i>
                                          </div>
                                      </div>
                                  `
                                : ""}
                            ${this._bonusType.compType === BonusCompType.FixedAmount
                                ? html`
                                      <div class="field">
                                          <label>Betrag Maximal</label>
                                          <div class="right icon input">
                                              <input
                                                  type="number"
                                                  ?required=${this._bonusType.compType === BonusCompType.FixedAmount}
                                                  inputmode="numeric"
                                                  class="text-right-aligning"
                                                  name="fixedAmountMax"
                                                  .value=${live(this._bonusType.fixedAmountMax?.toString() || "")}
                                                  min="0"
                                                  max="1000"
                                                  step="0.01"
                                              />
                                              <i class="euro-sign"></i>
                                          </div>
                                      </div>

                                      <div class="field">
                                          <label>Betrag Standardwert</label>
                                          <div class="right icon input">
                                              <input
                                                  type="number"
                                                  ?required=${this._bonusType.compType === BonusCompType.FixedAmount}
                                                  inputmode="numeric"
                                                  class="text-right-aligning"
                                                  name="fixedAmountDefault"
                                                  .value=${live(this._bonusType.fixedAmountDefault?.toString() || "")}
                                                  min="0"
                                                  max="1000"
                                                  step="0.01"
                                                  max="${this._bonusType.fixedAmountMax}"
                                              />
                                              <i class="euro-sign"></i>
                                          </div>
                                      </div>
                                  `
                                : ""}
                        </div>

                        <div class="horizontal evenly stretching spacing layout">
                            <div class="field">
                                <label>Beitragsfrei</label>
                                <div class="relative input">
                                    <ptc-checkbox-button
                                        name="taxFree"
                                        label="Ja"
                                        buttonClass="slim ghost"
                                        .checked=${live(this._bonusType.taxFree)}
                                    ></ptc-checkbox-button>
                                </div>
                            </div>

                            <div
                                class="field"
                                ?hidden=${!this._bonusType.taxFree ||
                                this._bonusType.compType !== BonusCompType.ModifiedHourlyRate}
                            >
                                <label>Beitragsfrei bis zu</label>
                                <div class="relative input">
                                    <input
                                        name="taxFreeUpToRate"
                                        style="padding-right: 2.5em; text-align: right;"
                                        .value=${live(this._bonusType.taxFreeUpToRate?.toFixed(2) || "")}
                                        type="number"
                                        min="0"
                                        max="100"
                                        step="0.01"
                                    />
                                    <div style="position: absolute; right: 0.5em; top: 0.4em;">
                                        <sup>€</sup>&frasl;<sub>St</sub>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <label>Bei Ausfall (Urlaub / Krank)</label>
                                <div class="horizontal evenly stretching spacing layout">
                                    <ptc-checkbox-button
                                        name="continuedPay"
                                        label="Beitragspflichtig Fortzahlen"
                                        buttonClass="slim ghost"
                                        .checked=${live(this._bonusType.continuedPay)}
                                    ></ptc-checkbox-button>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label>Hat Vorrang Vor...</label>
                            <div class="box">
                                ${this._bonusType.overrides?.map(({ objectId }) => {
                                    const bonusType = app.company?.bonusTypes.find((t) => t.id === objectId);
                                    if (!bonusType) {
                                        return;
                                    }

                                    return html`
                                        <div class="horizontal start-aligning layout border-bottom">
                                            <div class="stretch">
                                                <ptc-bonus-type-item
                                                    class="small"
                                                    .bonusType=${bonusType}
                                                ></ptc-bonus-type-item>
                                            </div>
                                            <button
                                                type="button"
                                                class="small double-margined slim transparent"
                                                @click=${() => this._removeOverride(bonusType)}
                                            >
                                                <i class="trash"></i>
                                            </button>
                                        </div>
                                    `;
                                })}
                                <div class="small padded vertical layout">
                                    <button type="button" class="transparent">
                                        <i class="plus"></i> Zuschlagsart Hinzufügen...
                                    </button>
                                    <ptc-popover class="popover-menu" hide-on-click alignment="top">
                                        <ptc-scroller style="max-height: 50vh">
                                            ${overrideable.length
                                                ? overrideable.map(
                                                      (type) => html`
                                                          <button
                                                              style="padding: 0;"
                                                              type="button"
                                                              @click=${() => this._addOverride(type)}
                                                          >
                                                              <ptc-bonus-type-item
                                                                  class="smaller"
                                                                  .bonusType=${type}
                                                              ></ptc-bonus-type-item>
                                                          </button>
                                                      `
                                                  )
                                                : html`
                                                      <div class="double-padded small subtle">
                                                          Keine passenden Zuschlagsarten verfügbar.
                                                      </div>
                                                  `}
                                        </ptc-scroller>
                                    </ptc-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </ptc-scroller>

                <div class="padded horizontal spacing evenly stretching layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
