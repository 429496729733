import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./spinner";
import { Checkbox } from "./checkbox";
import "./venue-departments";
import "./popover";
import { TimeInput } from "./time-input";
import { DateInput } from "./date-input";
import "./settings-billing";
import "./settings-time";
import "./settings-documents";
import "./settings-core-data";
import "./scroller";
import "./help";
import "./settings-accounting";
import "./settings-bonuses";
import "./settings-benefits";
import "./settings-company";
import "./settings-venues";
import "./settings-account";
import { Permission } from "@pentacode/core/src/permissions";

@customElement("ptc-settings")
export class Settings extends Routing(StateMixin(LitElement)) {
    static pages = [
        "company",
        "venues",
        "time",
        "documents",
        "core_data",
        "accounting",
        "bonuses",
        "benefits",
        "account",
        "billing",
    ];

    routePattern = /^settings(?:\/(?<page>\w+))?/;

    @state()
    private _loading: boolean = false;

    @routeProperty({ arg: "page" })
    private _page: string;

    handleRoute() {
        if (
            !Settings.pages.includes(this._page) ||
            (this._page !== "account" && !app.hasPermission(`manage.settings.${this._page}` as Permission))
        ) {
            const page =
                Settings.pages.find((p) => app.hasPermission(`manage.settings.${p}` as Permission)) || "account";
            this.go(`settings/${page}`, undefined, true);
            return;
        }
    }

    static styles = [
        shared,
        Checkbox.styles,
        TimeInput.styles,
        DateInput.styles,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 15em;
            }

            .main {
                flex: 1;
                position: relative;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }

        return html`
            <div class="sub-menu">
                <div class="vertical tabs">
                    <button ?active=${this._page === "account"} @click=${() => this.go("settings/account")}>
                        <i class="user"></i>
                        Benutzer
                    </button>

                    <button
                        ?active=${this._page === "company"}
                        @click=${() => this.go("settings/company")}
                        ?disabled=${!app.hasPermission("manage.settings.company")}
                    >
                        <i class="building"></i>
                        Unternehmen
                    </button>

                    <button
                        ?active=${this._page === "venues"}
                        @click=${() => this.go("settings/venues")}
                        ?disabled=${!app.hasPermission("manage.settings.venues")}
                    >
                        <i class="sitemap"></i>
                        Arbeitsbereiche
                    </button>

                    <button
                        ?active=${this._page === "time"}
                        @click=${() => this.go("settings/time")}
                        ?disabled=${!app.hasPermission("manage.settings.time")}
                    >
                        <i class="stopwatch"></i>
                        Zeiterfassung
                    </button>

                    <button
                        ?active=${this._page === "core_data"}
                        @click=${() => this.go("settings/core_data")}
                        ?disabled=${!app.hasPermission("manage.settings.core_data")}
                    >
                        <i class="contact-card"></i>
                        Stammdaten
                    </button>

                    <button
                        ?active=${this._page === "documents"}
                        @click=${() => this.go("settings/documents")}
                        ?disabled=${!app.hasPermission("manage.settings.documents")}
                    >
                        <i class="files"></i>
                        Dokumente
                    </button>

                    <button
                        ?active=${this._page === "bonuses"}
                        @click=${() => this.go("settings/bonuses")}
                        ?disabled=${!app.hasPermission("manage.settings.bonuses")}
                    >
                        <i class="badge-percent"></i>
                        Zuschläge
                    </button>

                    <button
                        ?active=${this._page === "benefits"}
                        @click=${() => this.go("settings/benefits")}
                        ?disabled=${!app.hasPermission("manage.settings.benefits")}
                    >
                        <i class="hand-holding-box"></i>
                        Sonderleistungen
                    </button>

                    <button
                        ?active=${this._page === "accounting"}
                        @click=${() => this.go("settings/accounting")}
                        ?disabled=${!app.hasPermission("manage.settings.accounting")}
                    >
                        <i class="abacus"></i>
                        Buchhaltung
                    </button>

                    <button
                        ?active=${this._page === "billing"}
                        @click=${() => this.go("settings/billing")}
                        ?disabled=${!app.hasPermission("manage.settings.billing")}
                        ?hidden=${!app.company || !app.company.billing}
                    >
                        <i class="file-invoice-dollar"></i>
                        Rechnungsstellung
                    </button>
                </div>
            </div>

            <div class="main">
                <ptc-settings-company ?hidden=${this._page !== "company"}></ptc-settings-company>

                <ptc-settings-venues class="fullbleed " ?hidden=${this._page !== "venues"}></ptc-settings-venues>

                <ptc-settings-time class="fullbleed time" ?hidden=${this._page !== "time"}></ptc-settings-time>

                <ptc-settings-account ?hidden=${this._page !== "account"}> </ptc-settings-account>

                <ptc-settings-billing class="fullbleed" ?hidden=${this._page !== "billing"}></ptc-settings-billing>

                <ptc-settings-core-data
                    class="fullbleed"
                    ?hidden=${this._page !== "core_data"}
                ></ptc-settings-core-data>

                <ptc-settings-documents
                    class="fullbleed"
                    ?hidden=${this._page !== "documents"}
                ></ptc-settings-documents>

                <ptc-settings-accounting
                    class="fullbleed"
                    ?hidden=${this._page !== "accounting"}
                ></ptc-settings-accounting>

                <ptc-settings-bonuses class="fullbleed" ?hidden=${this._page !== "bonuses"}></ptc-settings-bonuses>

                <ptc-settings-benefits class="fullbleed" ?hidden=${this._page !== "benefits"}></ptc-settings-benefits>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
