import "./spinner";
import { BonusesBalance, TimeBalance, VacationBalance } from "@pentacode/core/src/model";
import {
    formatNumber,
    formatDate,
    toDateString,
    getRange,
    dateAdd,
    parseDateString,
    formatRange,
    getCurrentMonthRange,
} from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import { Balance } from "./balance";
import { DateString } from "@pentacode/openapi";
import { GetBalancesParams } from "@pentacode/core/src/api";

interface YearData {
    year: number;
    balances: {
        from: DateString;
        to: DateString;
        time: TimeBalance;
        vacation: VacationBalance;
        bonuses: BonusesBalance;
    }[];
}

@customElement("ptc-employees-ledgers-single")
export class EmployeesLedgersSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<id>\d+)\/ledgers/;

    @routeProperty({ arg: "id", type: Number })
    employeeId: number;

    get routeTitle() {
        return `Konten: ${this._employee && this._employee.name}`;
    }

    handleRoute() {
        this.synchronize();
    }

    @state()
    private _loading = false;

    @state()
    private _years: YearData[] = [];

    @state()
    private _printingYear: number | null = null;

    private get _employee() {
        return (this.employeeId && app.getEmployee(this.employeeId)) || null;
    }

    async synchronize() {
        if (!this._employee) {
            return;
        }

        this._loading = true;

        const latestContract = this._employee.latestContract;
        const firstContract = this._employee.firstContract;

        if (!firstContract || !latestContract) {
            return;
        }

        const endOfCurrentMonth = getCurrentMonthRange().to;
        const from = getRange(firstContract.start, "month").from;
        const to =
            latestContract.end && latestContract.end < endOfCurrentMonth
                ? getRange(dateAdd(latestContract.end, { days: -1 }), "month").to
                : endOfCurrentMonth;

        try {
            const params = new GetBalancesParams({
                filters: [{ type: "employeeId", value: this._employee.id }],
                from,
                to,
                resolution: "month",
            });
            const [timeBalances, vacationBalance, bonusesBalances] = await Promise.all([
                app.api.getTimeBalances(params),
                app.api.getVacationBalances(params),
                app.api.getBonusesBalances(params),
            ]);

            const years: YearData[] = [];
            let monthStart = from;

            do {
                const year = parseDateString(monthStart)!.getFullYear();
                const currYear = years[years.length - 1];
                const time = timeBalances.find((b) => b.from === monthStart);
                const vacation = vacationBalance.find((b) => b.from === monthStart);
                const bonuses = bonusesBalances.find((b) => b.from === monthStart);
                if (time && vacation && bonuses) {
                    const balances = {
                        from: monthStart,
                        to: getRange(monthStart, "month").to,
                        time: time!,
                        vacation: vacation!,
                        bonuses: bonuses!,
                    };
                    if (currYear && currYear.year === year) {
                        currYear.balances.push(balances);
                    } else {
                        years.push({
                            year,
                            balances: [balances],
                        });
                    }
                }
                monthStart = dateAdd(monthStart, { months: 1 });
            } while (monthStart < to);

            this._years = years;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._years.reverse();
        this._years.forEach((y) => y.balances.reverse());

        this._loading = false;
    }

    private async _printYear(year: number) {
        this._printingYear = year;
        await this.updateComplete;
        print();
        this._printingYear = null;
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                overflow: auto !important;
            }

            .inner {
                min-width: 76em;
                border-right: solid 1px var(--shade-1);
            }

            .row {
                display: grid;
                grid-template-columns: 11em 4fr 4fr 5fr;
                line-height: 3em;
                border-bottom: solid 1px var(--shade-2);
                break-inside: avoid;
            }

            .row > :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .row-header {
                text-align: right;
                position: sticky;
                left: 0;
                background: var(--color-bg);
                padding: 0 0.5em;
                font-weight: 600;
                z-index: 1;
            }

            .month-name {
                padding: 0 0.5em;
            }

            .row-header button {
                line-height: normal;
            }

            .row:not(:hover) .reset-button {
                visibility: hidden;
            }

            .row-ledger {
                text-align: center;
                display: flex;
                align-items: center;
            }

            .header-row {
                position: sticky;
                top: 0;
                background: var(--color-bg);
                z-index: 9;
            }

            .header-row > .row-ledger {
                display: block;
                font-weight: 600;
            }

            :not(.header-row) > .row-ledger .difference {
                font-weight: 600;
            }

            .row-ledger .balance {
                font-weight: bold;
            }

            :not(.header-row) > .row-ledger.bonus .non-free {
                opacity: 0.8;
                font-weight: 300;
            }

            .row-ledger > * {
                flex: 1;
            }

            .row-ledger > :not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .row-ledger.time {
                --color-highlight: var(--teal);
            }

            .row-ledger.vacation {
                --color-highlight: var(--blue);
            }

            .row-ledger.bonus {
                --color-highlight: var(--purple);
            }

            .row-ledger > .balance-total {
                font-size: var(--font-size-large);
            }

            .balance-reset {
                height: 3em;
            }

            .balance-reset .balance {
                position: relative;
                display: flex;
                justify-content: center;
            }

            .balance-reset .balance button {
                position: absolute;
                right: 0;
                top: 0.2em;
                margin: auto;
                line-height: normal;
            }

            .balance-reset .balance:not(:hover) button {
                display: none;
            }

            .balance-reset.editing > :not(:first-child) {
                border: none;
            }

            .balance-reset input,
            .balance-reset button:not(.icon) {
                font-size: var(--font-size-default);
                line-height: normal;
                padding: 0.5em;
                text-align: center;
                max-width: 7em;
            }

            .row-ledger-header {
                font-weight: 600;
                font-size: var(--font-size-medium);
                display: block;
                color: var(--color-highlight);
                margin-bottom: -0.7em;
                border: none !important;
            }

            .row-ledger-subheader {
                font-weight: bold;
                display: flex;
                align-items: center;
            }

            .row-ledger-subheader > * {
                flex: 1;
            }

            .total-row {
                font-weight: bold;
            }

            .year-header {
                font-size: var(--font-size-huge);
                font-weight: 300;
                padding: 0.3em;
                text-align: center;
                border-bottom: solid 1px var(--shade-2);
                background: var(--color-bg);
                position: sticky;
                top: 3.06em;
                z-index: 8;
                color: var(--color-primary);
                padding-left: 5.5em;
            }

            .print-button {
                position: absolute;
                right: 0.4em;
                top: 0.4em;
                line-height: normal;
            }

            .year:not(:hover) .print-button {
                display: none;
            }

            .print-header {
                border-bottom: solid 1px var(--shade-2);
            }

            @media print {
                :host {
                    display: block;
                    position: static !important;
                }

                .header-row,
                .row-header {
                    position: static;
                }
            }
        `,
    ];

    private _renderReset(time: TimeBalance, vacation: VacationBalance, bonuses: BonusesBalance) {
        if (typeof time.reset !== "number" && typeof vacation.reset !== "number" && typeof bonuses.reset !== "number") {
            return;
        }
        return html`
            <div class="row balance-row balance-reset">
                <div class="row-header horizontal center-aligning layout">
                    <div class="month-name stretch">Übertrag</div>
                </div>

                <div class="row-ledger time">
                    <div class="balance">
                        ${typeof time.reset === "number"
                            ? html` <ptc-balance .value=${time.reset}></ptc-balance> `
                            : ""}
                    </div>
                </div>

                <div class="row-ledger vacation">
                    <div class="balance">
                        ${typeof vacation.reset === "number"
                            ? html` <ptc-balance .value=${vacation.reset}></ptc-balance> `
                            : ""}
                    </div>
                </div>

                <div class="row-ledger bonus">
                    <div class="balance">
                        ${typeof bonuses.reset === "number"
                            ? html` <ptc-balance .value=${bonuses.reset}></ptc-balance> `
                            : ""}
                    </div>
                </div>
            </div>
        `;
    }

    private _renderRow(
        label: string,
        time: TimeBalance,
        vacation: VacationBalance,
        bonus: BonusesBalance,
        sfnLedgerEnabled?: boolean,
        date?: DateString
    ) {
        const range = date ? getRange(date, "month") : null;
        return html`
            <div
                class="row ${date ? "click" : ""}"
                @click=${!date
                    ? null
                    : () =>
                          this.go(`employees/${this._employee!.id}/time`, {
                              from: range!.from,
                              to: range!.to,
                          })}
            >
                <div class="row-header horizontal center-aligning layout">
                    <div class="month-name stretch">${label}</div>
                </div>

                <div class="row-ledger time">
                    <div class="nominal">${formatNumber(time.nominal, 2)}</div>

                    <div class="actual">${formatNumber(time.actual, 2)}</div>

                    <div class="difference">
                        <ptc-balance .value=${time.difference}></ptc-balance>
                    </div>

                    <div class="balance">
                        <ptc-balance .value=${time.balance}></ptc-balance>
                    </div>
                </div>

                <div class="row-ledger vacation">
                    <div class="nominal">${formatNumber(vacation.nominal, 2)}</div>

                    <div class="actual">${formatNumber(vacation.actual, 2)}</div>

                    <div class="difference">
                        <ptc-balance .value=${vacation.difference}></ptc-balance>
                    </div>

                    <div class="balance">
                        <ptc-balance .value=${vacation.balance}></ptc-balance>
                    </div>
                </div>

                <div class="row-ledger bonus">
                    <div class="nominal">
                        ${sfnLedgerEnabled && typeof bonus.nominal === "number"
                            ? formatNumber(bonus.nominal, 2)
                            : "N/A"}
                    </div>

                    <div class="actual">${formatNumber(bonus.actual, 2)}</div>

                    ${bonus.untaxed === bonus.actual
                        ? html` <div class="non-free">(${formatNumber(bonus.taxed, 2)})</div> `
                        : html` <div class="actual">${formatNumber(bonus.taxed, 2)}</div> `}
                    ${typeof bonus.balance === "number" && typeof bonus.difference === "number"
                        ? html`
                              <div class="difference">
                                  <ptc-balance .value=${bonus.difference}></ptc-balance>
                              </div>

                              <div class="balance">
                                  <ptc-balance .value=${bonus.balance}></ptc-balance>
                              </div>
                          `
                        : html`
                              <div class="difference">N/A</div>

                              <div class="balance">N/A</div>
                          `}
                </div>
            </div>

            ${this._renderReset(time, vacation, bonus)}
        `;
    }

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }

        const latestContract = emp.latestContract;

        return html`
            <div class="horizontal center-aligned layout padded small-caps bottom-margined printonly print-header">
                <div>
                    <strong>${emp.lastName}, ${emp.firstName}</strong>${emp.staffNumber
                        ? `, Pnr.: ${emp.staffNumber}`
                        : ""}
                </div>
                <div class="stretch"></div>
                <div>
                    <strong>${this._printingYear}</strong>
                </div>
                <div class="stretch"></div>
                <div class="subtle small-caps">${app.company!.name}</div>
            </div>
            <div class="inner">
                <div class="row header-row">
                    <div class="row-header"></div>

                    <div class="row-ledger time">
                        <div class="row-ledger-header">Stunden</div>

                        <div class="row-ledger-subheader">
                            <div class="nominal">Soll</div>

                            <div class="actual">Ist</div>

                            <div class="difference">Diff</div>

                            <div class="balance">Saldo</div>
                        </div>
                    </div>

                    <div class="row-ledger vacation">
                        <div class="row-ledger-header">Urlaub</div>

                        <div class="row-ledger-subheader">
                            <div class="nominal">Anspr.</div>

                            <div class="actual">Gen.</div>

                            <div class="difference">Diff</div>

                            <div class="balance">Saldo</div>
                        </div>
                    </div>

                    <div class="row-ledger bonus">
                        <div class="row-ledger-header">SFN-Zuschläge (€)</div>

                        <div class="row-ledger-subheader">
                            <div class="nominal">Pausch.</div>

                            <div class="actual">B.frei</div>

                            <div class="non-free">B.pfl.</div>

                            <div class="difference">Diff</div>

                            <div class="balance">Saldo</div>
                        </div>
                    </div>
                </div>

                ${latestContract && latestContract.end && latestContract.end < toDateString(new Date())
                    ? html`
                          <div class="subtle double-padded text-centering border-bottom">
                              Ausgeschieden am <strong>${formatDate(latestContract.end)}</strong>
                          </div>
                      `
                    : ""}
                ${this._years.map(({ year, balances }) => {
                    const last = balances[0];

                    return html`
                        <div class="year ${this._printingYear && this._printingYear !== year ? "noprint" : ""}">
                            <div class="year-header ${this._printingYear ? "noprint" : ""}">
                                ${year}
                                <button
                                    class="medium transparent icon print-button noprint"
                                    @click=${() => this._printYear(year)}
                                >
                                    <i class="print"></i>
                                </button>
                            </div>

                            <div class="row balance-row">
                                <div class="row-header"><div class="month-name">Saldo</div></div>

                                <div class="row-ledger time">
                                    <div class="balance-total">
                                        <ptc-balance .value=${last?.time?.balance}></ptc-balance>
                                    </div>
                                </div>

                                <div class="row-ledger vacation">
                                    <div class="balance-total">
                                        <ptc-balance .value=${last?.vacation?.balance}></ptc-balance>
                                    </div>
                                </div>

                                <div class="row-ledger bonus">
                                    <div class="balance-total">
                                        ${typeof last?.bonuses?.balance === "number"
                                            ? html` <ptc-balance .value=${last?.bonuses?.balance}></ptc-balance> `
                                            : "N/A"}
                                    </div>
                                </div>
                            </div>

                            ${balances.map((s) => {
                                const { time, vacation, bonuses, from, to } = s;
                                const contracts = this._employee?.getAllContractsForRange({ from, to });
                                const sfnLedgerEnabled = contracts?.some((c) => c.enableSFNLedger);
                                return this._renderRow(
                                    formatRange({ from, to }),
                                    time,
                                    vacation,
                                    bonuses,
                                    sfnLedgerEnabled,
                                    from
                                );
                            })}
                        </div>
                    `;
                })}
                ${app.company && app.company.settings.startLedgers
                    ? html`
                          <div
                              class="subtle double-padded text-centering click"
                              @click=${() => this.go("settings/other")}
                          >
                              Beginn der Kontoführung: <strong>${formatDate(app.company.settings.startLedgers)}</strong>
                          </div>
                      `
                    : ""}
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
