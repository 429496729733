import "./scroller";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { app } from "../init";
import { StateMixin } from "../mixins/state";
import { RouteArgs, Routing } from "../mixins/routing";
import { shared } from "../styles";
import { singleton } from "../lib/singleton";
import { TimeSettingsDialog } from "./time-settings-dialog";
import { MealsMode, TimeSettings, breakModeLabel } from "@pentacode/core/src/model";
import { alert, confirm } from "./alert-dialog";
import "./entity-filters";

@customElement("ptc-settings-time")
export class SettingsTime extends Routing(StateMixin(LitElement)) {
    routePattern = /settings\/time/;

    get routeTitle() {
        return "Zeiterfassung | Einstellungen";
    }

    get helpPage() {
        return "handbuch/einstellungen/rechnungsstellung/";
    }

    handleRoute(_args: RouteArgs, { sid, ...rest }: { [params: string]: string }) {
        if (sid) {
            const settings = app.availableTimeSettings.find((s) => s.id === Number(sid));
            if (settings) {
                this._editTimeSettings(settings);
            }
            this.router.params = rest;
        }
    }

    @state()
    private _loading = false;

    @singleton("ptc-time-settings-dialog")
    private _timeSettingsDialog: TimeSettingsDialog;

    private async _editTimeSettings(settings: TimeSettings) {
        const changes = await this._timeSettingsDialog.show(settings);
        if (changes) {
            app.fetchCompany();
        }
    }

    private async _newTimeSettings({ id, name, ...rest }: Partial<TimeSettings> = {}) {
        const added = await this._timeSettingsDialog.show(
            new TimeSettings({ ...rest, name: name ? name + " (Kopie)" : "" })
        );
        if (added) {
            app.fetchCompany();
        }
    }

    private async _deleteTimeSettings(s: TimeSettings) {
        if (
            await confirm(
                "Sind Sie sicher dass Sie diesen Zeiterfassungs-Regelsatz löschen möchten?",
                "Löschen",
                "Abbrechen",
                { title: "Regelsatz Löschen", type: "destructive", icon: "trash-alt" }
            )
        ) {
            this._loading = true;
            try {
                await app.api.deleteTimeSettings(s.id);
                await app.fetchCompany();
            } catch (e) {
                void alert(e.message, { type: "warning" });
            }
            this._loading = false;
        }
    }

    private async _itemMoved({ detail: { item, toIndex } }: CustomEvent<{ item: TimeSettings; toIndex: number }>) {
        this._loading = true;
        item.order = toIndex;
        try {
            await app.api.createOrUpdateTimeSettings(item);
            await app.fetchCompany();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    static styles = [
        shared,
        css`
            .settings-item {
                padding: 0.75em 0.75em 0.4em 0.75em;
            }

            .settings-item .box {
                padding: 0.1em 0.3em;
                font-weight: 600;
                position: relative;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return;
        }
        return html`
            <div class="fullbleed vertical layout">
                <ptc-scroller class="stretch">
                    <div class="double-padded" style="max-width: 50em; margin: 0 auto;">
                        <h2>Zeiterfassungs-Regelsätze</h2>
                        <div class="box">
                            <ptc-sortable-list
                                .items=${app.availableTimeSettings}
                                @item-moved=${this._itemMoved}
                                .renderItem=${(s: TimeSettings) => {
                                    return html`
                                        <div
                                            class="click spacing vertical layout settings-item border-bottom relative"
                                            @click=${() => this._editTimeSettings(s)}
                                        >
                                            <div class="larger">${s.name}</div>
                                            <div class="spacing wrapping smaller horizontal layout">
                                                <ptc-entity-filters
                                                    readonly
                                                    .filters=${s.filters}
                                                    hideFilterIcon
                                                    style="padding: 0; max-width: 100%;"
                                                    hideEmployeeCount
                                                ></ptc-entity-filters>
                                                <div
                                                    class="padded box centering spacing horizontal layout"
                                                    title="Pausenmodus"
                                                >
                                                    <i class="coffee"></i>
                                                    <div>${breakModeLabel(s.breakMode)}</div>
                                                </div>

                                                <div
                                                    class="padded box centering horizontal layout"
                                                    title="Mitarbeiteressen"
                                                >
                                                    <i class="utensils"></i>
                                                    <div>
                                                        ${s.mealsMode === MealsMode.Auto
                                                            ? "Automatisch"
                                                            : s.mealsMode === MealsMode.Employee
                                                              ? "Durch Mitarbeiter"
                                                              : "Manuell"}
                                                    </div>
                                                </div>

                                                <div
                                                    class="padded box centering horizontal layout ${s.trackingEnabled
                                                        ? ""
                                                        : "strike-through"}"
                                                    title="Zeiterfassung per Stempeluhr"
                                                >
                                                    <i class="larger stopwatch"></i>
                                                </div>

                                                ${s.trackingEnabled
                                                    ? html`
                                                          <div
                                                              class="padded box centering horizontal layout ${s.unplannedCheckins
                                                                  ? ""
                                                                  : "strike-through"}"
                                                              title="Ungeplante/spontane Schichten Erlauben"
                                                          >
                                                              <i class="larger running"></i>
                                                          </div>

                                                          <div
                                                              class="padded box centering horizontal layout ${s.trackingViaAppEnabled
                                                                  ? ""
                                                                  : "strike-through"}"
                                                              title="Zeiterfassung per Mitarbeiter-App"
                                                          >
                                                              <i class="larger mobile-alt"></i>
                                                          </div>

                                                          <div
                                                              ?hidden=${!s.trackingViaAppEnabled}
                                                              class="padded box centering horizontal layout ${s.trackingViaAppRequiresToken
                                                                  ? ""
                                                                  : "strike-through"}"
                                                              title="Stempeln per MA-App erfordert QR-Code"
                                                          >
                                                              <i class="larger qrcode"></i>
                                                          </div>
                                                      `
                                                    : ""}
                                            </div>

                                            <button
                                                class="slim margined transparent absolute top right"
                                                @click=${(e: Event) => e.stopPropagation()}
                                            >
                                                <i class="ellipsis-h"></i>
                                            </button>

                                            <ptc-popover hide-on-leave hide-on-click>
                                                <div class="spacing vertical layout">
                                                    <button
                                                        class="slim transparent text-left-aligning"
                                                        @click=${(e: Event) => {
                                                            e.stopPropagation();
                                                            this._editTimeSettings(s);
                                                        }}
                                                    >
                                                        <i class="pencil-alt"></i> Bearbeiten
                                                    </button>
                                                    <button
                                                        class="slim transparent text-left-aligning"
                                                        @click=${(e: Event) => {
                                                            e.stopPropagation();
                                                            this._newTimeSettings(s);
                                                        }}
                                                    >
                                                        <i class="clone"></i> Duplizieren
                                                    </button>
                                                    <button
                                                        class="slim transparent text-left-aligning"
                                                        @click=${(e: Event) => {
                                                            e.stopPropagation();
                                                            this._deleteTimeSettings(s);
                                                        }}
                                                    >
                                                        <i class="trash-alt"></i> Löschen
                                                    </button>
                                                </div>
                                            </ptc-popover>
                                        </div>
                                    `;
                                }}
                            ></ptc-sortable-list>

                            <div class="padded vertical layout">
                                <button class="transparent" @click=${this._newTimeSettings}>
                                    <i class="plus"></i> Neuer Regelsatz
                                </button>
                            </div>
                        </div>
                    </div>
                </ptc-scroller>
            </div>
            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
