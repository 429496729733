import { Venue } from "@pentacode/core/src/model";
import { html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";
import { VenueForm } from "./venue-form";
import "./scroller";

@customElement("ptc-venue-dialog")
export class VenueDialog extends Dialog<Venue, void> {
    readonly preventDismiss = true;

    @query("ptc-venue-form")
    private _form: VenueForm;

    @property()
    private _venueId: number | null = null;

    private get _isNew() {
        return this._venueId === null;
    }

    async show(venue?: Venue): Promise<void> {
        const promise = super.show();
        this._venueId = venue && typeof venue.id === "number" ? venue.id : null;
        if (venue) {
            await this.updateComplete;
            setTimeout(() => this._form.init(venue), 50);
        }
        return promise;
    }

    private async _submit() {
        this.loading = true;

        const data = this._form.data;

        if (!data) {
            return;
        }

        if (!this._form.validateVenueNumber()) {
            this.loading = false;
            return;
        }

        try {
            if (!this._isNew) {
                await app.updateVenue({ ...data, id: this._venueId! });
            } else {
                await app.createVenue(data);
            }

            this.done();
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            void this.show();
        }

        this.loading = false;
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                display: flex;
                flex-direction: column;
                max-width: 500px;
            }

            h1 {
                margin: 0;
                text-align: center;
            }

            .header,
            .footer {
                padding: 0.7em;
            }

            .footer {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0.7em;
            }

            ptc-scroller {
                flex: 1;
            }

            ptc-venue-form {
                display: block;
                margin: 0 0.7em;
            }
        `,
    ];

    renderContent() {
        return html`
            <div class="header">
                <h1>${this._venueId !== null ? "Standort Bearbeiten" : "Neuer Standort"}</h1>
            </div>

            <ptc-scroller>
                <ptc-venue-form
                    .usedVenueNumbers=${this._getUsedVenueNumbers()}
                    @submit=${this._submit}
                    @cancel=${() => this.done()}
                ></ptc-venue-form>
            </ptc-scroller>

            <div class="footer">
                <button class="primary stretch" @click=${this._submit}>Speichern</button>

                <button class="transparent stretch" @click=${this.done}>Abbrechen</button>
            </div>
        `;
    }

    private _getUsedVenueNumbers() {
        return app.venues
            .filter((v) => v.id !== this._venueId)
            .map((v) => v.venueNumber)
            .filter((n) => !!n) as number[];
    }
}
