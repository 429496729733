import { Feature } from "@pentacode/core/src/model";
import { toDateString, parseDateString } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { shared, mixins } from "../styles";
import { contactSupport } from "../lib/help";
import { app } from "../init";
import "./planning-revenue";
import "./planning-roster";
import { DatePicker } from "./date-picker";
import "./spinner";

@customElement("ptc-planning")
export class Planning extends Routing(StateMixin(LitElement)) {
    static pages = ["revenue", "roster"] as const;

    routePattern = /^planning(?:\/(?<page>\w+))?/;

    get helpPage() {
        switch (this._page) {
            case "revenue":
                return "/handbuch/planung/umsatzplanung";
            case "roster":
                return "/handbuch/planung/stundenvorgaben";
            default:
                return "/handbuch/planung";
        }
    }

    @routeProperty({ arg: "page" })
    private _page: "revenue" | "roster";

    @state()
    private _loading = false;

    @query("select")
    private _venueSelect: HTMLSelectElement;

    @query("ptc-date-picker")
    private _datePicker: DatePicker;

    private _selectVenue() {
        this.go(null, { venue: this._venueSelect.value, date: this.date });
    }

    handleRoute() {
        if (!this.venue || !app.hasAccess({ venue: this.venue })) {
            const venue = app.venues.find((venue) => app.hasAccess({ venue }));
            if (venue) {
                this.go(null, { ...this.router.params, venue: venue.id.toString() }, true);
            }
            return;
        }

        if (!Planning.pages.includes(this._page) || !app.hasPermission(`manage.planning.${this._page}`)) {
            const page = Planning.pages.find((page) => app.hasPermission(`manage.planning.${page}`));
            this.go(page ? `planning/${page}` : "", undefined, true);
            return;
        }

        const today = toDateString(new Date());

        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { ...this.router.params, date: today }, true);
            return;
        }

        this._datePicker && (this._datePicker.value = this.date);
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                position: relative;
                width: 220px;
                box-sizing: border-box;
            }

            .sub-menu select {
                width: 100%;
                margin-bottom: 1em;
            }

            .main {
                flex: 1;
                min-width: 0;
                position: relative;
            }

            .main > * {
                ${mixins.fullbleed()};
            }

            @media print {
                .main > * {
                    position: static;
                }
            }
        `,
    ];

    render() {
        if (!app.company || !app.company.features.includes(Feature.Planning)) {
            return html`
                <div class="poster center-aligning center-justifying vertical layout fullbleed">
                    <i class="calendar-alt"></i>

                    <div>
                        Dieser Bereich ist für Ihr Unternehmen noch nicht verfügbar! Bitte kontaktieren sie unser
                        Supportteam um zu erfahren, wie Sie dieses Modul freizuschalten können!
                    </div>

                    <button @click=${() => contactSupport("Planungsmodul Freischalten")}>
                        <i class="user-headset"></i>
                        Support Kontaktieren
                    </button>
                </div>
            `;
        }

        return html`
            <div class="sub-menu noprint">
                <div class="vertical tabs">
                    <button
                        ?active=${this._page === "revenue"}
                        @click=${() => this.go("planning/revenue")}
                        ?disabled=${!app.hasPermission(`manage.planning.revenue`)}
                    >
                        <i class="euro-sign"></i>
                        Umsätze
                    </button>

                    <button
                        ?active=${this._page === "roster"}
                        @click=${() => this.go("planning/roster")}
                        ?disabled=${!app.hasPermission(`manage.planning.roster`)}
                    >
                        <i class="bars-progress"></i>
                        Stundenvorgaben
                    </button>
                </div>

                <div class="divider"></div>

                <select @change=${this._selectVenue}>
                    ${app.accessibleVenues.map(
                        (venue) => html`
                            <option
                                .value=${venue.id.toString()}
                                ?selected=${venue.id === this.venueId}
                                ?disabled=${!app.hasAccess({ venue })}
                            >
                                ${venue.name}
                            </option>
                        `
                    )}
                </select>

                <ptc-date-picker
                    class="small"
                    mode="week"
                    @change=${(e: CustomEvent) =>
                        this.go(null, { venue: this._venueSelect.value, date: e.detail.date })}
                ></ptc-date-picker>
            </div>

            <div class="main">
                <ptc-planning-revenue ?hidden=${this._page !== "revenue"}></ptc-planning-revenue>

                <ptc-planning-roster ?hidden=${this._page !== "roster"}></ptc-planning-roster>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
