import { classMap } from "lit/directives/class-map.js";
import {
    BonusesBalance,
    EmployeeStatus,
    EntityFilter,
    TimeBalance,
    VacationBalance,
    employmentTypeLabel,
} from "@pentacode/core/src/model";
import { getRange, toDateString, parseDateString, formatNumber, monthNames } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import "./employees-filter";
import "./month-picker";
import { Balance } from "./balance";
import "./avatar";
import "./spinner";
import { GetBalancesParams } from "@pentacode/core/src/api";
import { EmployeeSortProperty } from "@pentacode/core";

@customElement("ptc-employees-ledgers-all-month")
export class EmployeesLedgersAllMonth extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/ledgers-month/;

    get routeTitle() {
        return "Konten: Alle Mitarbeiter";
    }

    @state()
    private _loading = false;

    @state()
    private _timeBalances: TimeBalance[] = [];

    @state()
    private _vacationBalances: VacationBalance[] = [];

    @state()
    private _bonusesBalances: BonusesBalance[] = [];

    private get _filteredBalances() {
        const employees = app.getFilteredEmployees(getRange(this.date, "month"));

        return employees.map((employee) => ({
            employee,
            time: this._timeBalances.find((tb) => tb.employeeId === employee.id),
            vacation: this._vacationBalances.find((vb) => vb.employeeId === employee.id),
            bonus: this._bonusesBalances.find((bb) => bb.employeeId === employee.id),
        }));
    }

    async handleRoute() {
        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { date: toDateString(new Date()) }, true);
            return;
        }
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("date")) {
            this.load();
        }
    }

    async load() {
        if (!this.date) {
            return;
        }

        const { from, to } = getRange(this.date, "month");

        this._loading = true;

        try {
            const filters = app.accessibleEmployees
                .filter((e) => e.status !== EmployeeStatus.Probation && !!e.getContractForRange({ from, to }))
                .map((e) => ({ type: "employeeId", value: e.id }) as EntityFilter);
            const params = new GetBalancesParams({
                filters,
                from,
                to,
                resolution: "month",
            });
            const [timeBalances, vacationBalances, bonusesBalances] = await Promise.all([
                await app.api.getTimeBalances(params),
                await app.api.getVacationBalances(params),
                await app.api.getBonusesBalances(params),
            ]);
            this._timeBalances = timeBalances;
            this._vacationBalances = vacationBalances;
            this._bonusesBalances = bonusesBalances;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .header {
                border-bottom: solid 1px var(--shade-2);
            }

            .month-picker {
                max-width: 600px;
                padding-left: 0;
                padding-right: 0;
            }

            .range-input-wrapper button {
                padding: 0.4em;
            }

            .scroller {
                overflow: auto;
                flex: 1;
                min-height: 0;
            }

            .row {
                display: grid;
                grid-template-columns: 14em 4fr 4fr 5fr;
                border-bottom: solid 1px var(--shade-2);
                break-inside: avoid;
                min-width: 80em;
            }

            .row > :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .difference ptc-balance.positive,
            .difference ptc-balance.negative {
                color: var(--color-fg);
            }

            .difference.warn ptc-balance {
                color: var(--orange) !important;
            }

            .difference.error {
                background: var(--color-negative);
            }

            .difference.error ptc-balance {
                color: var(--color-bg) !important;
            }

            .row-ledger {
                text-align: center;
                display: flex;
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 9;
                background: var(--color-bg);
            }

            .row-header {
                position: sticky;
                left: 0;
                z-index: 8;
                background: var(--color-bg);
            }

            .header-row > .row-ledger {
                display: block;
                font-weight: 600;
            }

            .row-ledger > :not(:last-child) {
                border-right: solid 1px var(--shade-1);
            }

            .row-ledger > * {
                font-size: var(--font-size-small);
                padding: 0.3em;
                flex: 1;
                min-width: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .row-ledger .balance {
                font-weight: bold;
            }

            .row-ledger.time {
                --color-highlight: var(--teal);
            }

            .row-ledger.vacation {
                --color-highlight: var(--blue);
            }

            .row-ledger.bonus {
                --color-highlight: var(--purple);
            }

            .row-ledger-header {
                font-weight: 600;
                font-size: var(--font-size-default);
                display: block;
                margin: 0.3em;
                margin-bottom: 0;
                color: var(--color-highlight);
                border: none !important;
            }

            .row-ledger-subheader {
                font-weight: bold;
                margin: 0.5em 0;
                display: flex;
                align-items: center;
            }

            .row-ledger-subheader > * {
                flex: 1;
            }

            .no-data {
                padding: 2em;
                text-align: center;
            }

            .employee-header {
                padding: 0.2em 0.5em;
                flex: none;
            }

            .employee-header ptc-avatar {
                margin-right: 0.5em;
            }

            .employee-name {
                margin-bottom: 0.1em;
            }

            .issue-popover {
                color: var(--color-negative);
                --color-highlight: var(--color-negative);
                padding: 0.5em;
                text-align: center;
                max-width: 100%;
                display: block;
            }

            .issue-message {
                font-weight: bold;
                font-size: 1.2em;
                margin: 0.1em 0 0.3em 0;
            }

            .issue-detail-value {
                font-size: 1.5em;
            }

            :not(.header-row) > .row-ledger.bonus .non-free {
                opacity: 0.8;
                font-weight: 300;
            }
        `,
    ];

    render() {
        const date = parseDateString(this.date)!;
        const { from, to } = getRange(date, "month");

        if (!date) {
            return html``;
        }

        return html`
            <div class="header center-aligning horizontal layout padded-medium noprint">
                <ptc-month-picker
                    @change=${({ detail: { date } }: CustomEvent<{ date: string }>) => this.go(null, { date })}
                    .date=${this.date}
                ></ptc-month-picker>

                <div class="stretch"></div>

                <div class="tabs">
                    <button active>Monat</button>
                    <button @click=${() => this.go("employees/all/ledgers-year")}>Jahr</button>
                </div>

                <div class="stretch"></div>

                <button class="transparent slim right-margined" @click=${() => print()}>
                    <i class="print"></i>
                </button>
            </div>

            <ptc-employees-filter
                class="noprint border-bottom relative"
                style="z-index: 10"
                .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
            ></ptc-employees-filter>

            <div class="header center-aligning horizontal layout padded small-caps bottom-margined printonly">
                <div>Kontenübersicht <strong>${monthNames[date.getMonth()]} ${date.getFullYear()}</strong></div>
                <div class="stretch"></div>
                <div class="subtle">${app.company!.name}</div>
            </div>

            <div class="scroller">
                <div class="row header-row">
                    <div class="horizontal end-aligning layout row-header"></div>

                    <div class="row-ledger time">
                        <div class="row-ledger-header">Stunden</div>

                        <div class="row-ledger-subheader">
                            <div class="nominal">Soll</div>

                            <div class="actual">Ist</div>

                            <div class="difference">Diff</div>

                            <div class="balance">Saldo</div>
                        </div>
                    </div>

                    <div class="row-ledger vacation">
                        <div class="row-ledger-header">Urlaub</div>

                        <div class="row-ledger-subheader">
                            <div class="nominal">Zuw.</div>

                            <div class="actual">Gen.</div>

                            <div class="difference">Diff</div>

                            <div class="balance">Saldo</div>
                        </div>
                    </div>

                    <div class="row-ledger bonus">
                        <div class="row-ledger-header">SFN-Zuschläge (€)</div>

                        <div class="row-ledger-subheader">
                            <div class="nominal">Pausch.</div>

                            <div class="actual">B.frei</div>

                            <div class="non-free">B.pfl.</div>

                            <div class="difference">Diff</div>

                            <div class="balance">Saldo</div>
                        </div>
                    </div>
                </div>

                ${this._filteredBalances.map((statement) => {
                    const { employee, time, vacation, bonus } = statement;
                    const contract = employee.getContractForRange({ from, to });
                    if (!contract || !time || !vacation || !bonus) {
                        return;
                    }
                    const employmentType = employmentTypeLabel(contract.employmentType);

                    return html`
                        <div
                            class="row click"
                            @click=${() => this.go(`employees/${employee.id}/time`, { date_from: from, date_to: to })}
                        >
                            <div class="employee-header horizontal center-aligning layout row-header">
                                <ptc-avatar class="small noprint" .employee=${employee}></ptc-avatar>
                                <div class="stretch collapse">
                                    <div class="ellipsis employee-name">
                                        ${employee.lastName}, ${employee.firstName}
                                    </div>
                                    <div class="tiny pills">
                                        <div class="pill">${employmentType}</div>
                                        ${employee.staffNumber
                                            ? html`
                                                  <div class="pill">Pnr: <strong>${employee.staffNumber}</strong></div>
                                              `
                                            : ""}
                                    </div>
                                </div>
                            </div>

                            <div class="row-ledger time">
                                <div class="nominal">${formatNumber(time.nominal, 2)}</div>

                                <div class="actual">${formatNumber(time.actual, 2)}</div>

                                <div class="difference">
                                    <ptc-balance .value=${time.difference}></ptc-balance>
                                </div>

                                <div class="balance">
                                    <ptc-balance .value=${time.balance}></ptc-balance>
                                </div>
                            </div>

                            <div class="row-ledger vacation">
                                <div class="nominal">${formatNumber(vacation.nominal, 2)}</div>

                                <div class="actual">${formatNumber(vacation.actual, 2)}</div>

                                <div class="difference">
                                    <ptc-balance .value=${vacation.difference}></ptc-balance>
                                </div>

                                <div class="balance">
                                    <ptc-balance .value=${vacation.balance}></ptc-balance>
                                </div>
                            </div>

                            <div class="row-ledger bonus">
                                <div class="nominal">
                                    ${contract.enableSFNLedger && typeof bonus.nominal === "number"
                                        ? formatNumber(bonus.nominal, 2)
                                        : "N/A"}
                                </div>

                                <div class="actual">${formatNumber(bonus.untaxed, 2)}</div>

                                ${bonus.taxed === bonus.actual
                                    ? html` <div class="non-free">(${formatNumber(bonus.taxed, 2)})</div> `
                                    : html` <div class="actual">${formatNumber(bonus.taxed, 2)}</div> `}

                                <div
                                    class=${classMap({
                                        difference: true,
                                        warn: !!bonus.difference && Math.abs(bonus.difference) > 0.2 * bonus.nominal!,
                                    })}
                                >
                                    ${bonus.difference
                                        ? html`<ptc-balance .value=${bonus.difference}></ptc-balance>`
                                        : "N/A"}
                                </div>

                                <div class="balance">
                                    ${contract.enableSFNLedger && typeof bonus.balance === "number"
                                        ? html`<ptc-balance .value=${bonus.balance}></ptc-balance>`
                                        : "N/A"}
                                </div>
                            </div>
                        </div>
                    `;
                })}
                <div>
                    <div
                        class="fullbleed center-aligning center-justifying vertical layout scrim"
                        ?hidden=${!this._loading}
                    >
                        <ptc-spinner ?active=${this._loading}></ptc-spinner>
                    </div>
                </div>
            </div>
        `;
    }
}
