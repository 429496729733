import { Access, Employee, EmployeeStatus, InviteStatus, Role } from "@pentacode/core/src/model";
import { UpdateEmployeesParams } from "@pentacode/core/src/api";
import { debounce, intersectArrays } from "@pentacode/core/src/util";
import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { shared } from "../styles";
import { app } from "../init";
import "./avatar";
import { alert } from "./alert-dialog";
import "./spinner";
import { singleton } from "../lib/singleton";
import { InviteDialog } from "./invite-dialog";
import { repeat } from "lit/directives/repeat.js";
import { cache } from "lit/directives/cache.js";
import { live } from "lit/directives/live.js";
import { PERMISSIONS, Permission, PermissionInfo } from "@pentacode/core/src/permissions";
import { popover } from "../directives/popover";
import "./entity-filters";
import "./employees-filter";

function countSelected(perm: PermissionInfo, permissions: string[]): [number, number] {
    return perm.children
        ? perm.children.reduce(
              ([n, N], p) => {
                  const [_n, _N] = countSelected(p, permissions);
                  return [n + _n, N + _N];
              },
              [0, 0]
          )
        : perm.key
          ? [permissions.includes(perm.key) ? 1 : 0, 1]
          : [0, 0];
}

@customElement("ptc-employee-permissions-row")
export class PermissionsRow extends LitElement {
    @property({ attribute: false })
    employee: Employee | null;

    @property({ attribute: false })
    permissionsRole!: Role;

    @property({ attribute: false })
    permissions!: Permission[];

    @property({ attribute: false })
    expanded!: Permission[];

    @property({ type: Boolean })
    isVisible = false;

    // private get _data() {
    //     if (!this._form) {
    //         return {
    //             role: this.employee.role,
    //             permissions: this.employee.permissions,
    //         };
    //     }

    //     const formData = new FormData(this._form);

    //     return {
    //         role: formData.get("role"),
    //         permissions: formData.getAll("permissions"),
    //     };
    // }

    createRenderRoot() {
        return this;
    }

    shouldUpdate(changes: Map<string, any>) {
        return changes.has("isVisible") || this.isVisible;
    }

    private _permissionToggled(e: Event) {
        const input = e.target as HTMLInputElement;
        const scope = input.value as Permission;
        const checked = input.checked;

        this.dispatchEvent(
            new CustomEvent("permission-toggled", { detail: { employeeId: this.employee?.id, scope, checked } })
        );
    }

    // private async _roleSelected(e: Event) {
    //     const select = e.target as HTMLSelectElement;
    //     const role = Number(select.value) as Role;

    //     if (
    //         role === Role.Owner &&
    //         !(await confirm(
    //             "Wollen Sie diesen Mitarbeiter wirklich zum Besitzer dieses Unternehmens machen? Der aktuelle Besitzer wird dann zum Manager degradiert.",
    //             "Bestätigen",
    //             "Abbrechen",
    //             { title: "Besitzer Wechseln", icon: "user-crown" }
    //         ))
    //     ) {
    //         select.value = this.employee?.role.toString() || "";
    //         return;
    //     }

    //     this.dispatchEvent(
    //         new CustomEvent("role-selected", {
    //             detail: { employeeId: this.employee!.id, role },
    //         })
    //     );
    // }

    private _renderCheckboxes(permission: PermissionInfo, parentDisabled = false): TemplateResult | undefined {
        if (!app.company || (permission.feature && !app.company.features.includes(permission.feature))) {
            return;
        }
        const emp = this.employee;
        const permissions = this.permissions;
        const role = this.permissionsRole;
        const disabled =
            role <= Role.Owner ||
            parentDisabled ||
            permission.readonly ||
            (permission.requiresRole && permission.requiresRole < role) ||
            (permission.requires && permission.requires.some((p) => !permissions.includes(p as Permission)));

        const [selected, total] = countSelected(permission, permissions);
        const key = permission.key as Permission;
        const checked = role <= Role.Owner || permissions.includes(key as Permission);

        return html`
            <div class="permission-wrapper horizontal layout ${permission.hide ? "hide-permission" : ""}">
                ${key
                    ? html`
                          <div
                              class="permission-checkbox"
                              ?disabled=${disabled}
                              title="${permission.title}"
                              ?hidden=${permission.hide}
                              data-selected-count=${role > Role.Owner && checked && selected !== total
                                  ? `${selected}/${total}`
                                  : ""}
                          >
                              <input
                                  type="checkbox"
                                  name="permissions"
                                  .value=${key}
                                  id="checkbox_${emp?.id || "all"}.${key}"
                                  data-permission=${key}
                                  .checked=${live(checked)}
                                  @change=${this._permissionToggled}
                              />
                              <label for="checkbox_${emp?.id || "all"}.${key}">
                                  <i class="${permission.children ? "check-double" : "check"}"></i>
                              </label>
                          </div>
                      `
                    : ""}
                ${permission.children && (!key || this.expanded.includes(key) || permission.hide)
                    ? html`
                          <div
                              class="permission-children"
                              ?hidden=${!!key && !permission.hide && !this.expanded.includes(key)}
                          >
                              ${permission.children.map((p) =>
                                  this._renderCheckboxes(p, disabled || (!!key && !permissions.includes(key)))
                              )}
                          </div>
                      `
                    : ""}
            </div>
        `;
    }

    private _renderHeaderAll() {
        return html`
            <div class="row-header horizontal center-aligning layout">
                <i class="users all-employees-icon"></i>
                <div class="employee-name stretch ellipsis bold">Alle Mitarbeiter</div>
            </div>
        `;
    }

    private _renderHeaderEmployee() {
        const emp = this.employee!;
        const role = this.permissionsRole;
        return html`
            <div
                class="row-header employee-header horizontal spacing center-aligning layout"
                @click=${() => this.dispatchEvent(new CustomEvent("header-clicked"))}
            >
                <div class="stretch collapse center-aligning horizontal layout employee-header">
                    <ptc-avatar .employee=${emp}></ptc-avatar>
                    <div class="employee-name stretch ellipsis">${emp.lastName}, ${emp.firstName}</div>
                </div>
                ${emp.accountId
                    ? html`
                          ${emp.role <= Role.Manager && emp.access
                              ? html`
                                    <div
                                        class="tiny"
                                        ${popover(
                                            html`
                                                <div class="small padded semibold">
                                                    <i class="person-harassing"></i> Zuständigkeitsbereiche
                                                </div>
                                                <ptc-entity-filters
                                                    .filters=${emp.role > Role.Owner ? emp.access.filters : []}
                                                    readonly
                                                    hideFilterIcon
                                                    hideEmployeeCount
                                                ></ptc-entity-filters>
                                            `,
                                            {
                                                trigger: "hover",
                                                class: "non-interactive text-left-aligning tooltip",
                                                alignment: "right",
                                            }
                                        )}
                                    >
                                        ${app.employees.filter((employee) => app.company?.hasAccess(emp, { employee }))
                                            .length}
                                        <i class="people-group"></i>
                                    </div>
                                `
                              : ""}
                          <div
                              class="tiny ${role === Role.Owner
                                  ? "purple"
                                  : role === Role.Manager
                                    ? "orange"
                                    : "grey"} colored-text"
                          >
                              ${emp.role === Role.Owner
                                  ? html` <i class="user-crown" title="Besitzer"></i>`
                                  : emp.role === Role.Manager
                                    ? html` <i class="user-tie" title="Manager"></i>`
                                    : html`<i class="user-hard-hat" title="Mitarbeiter"></i>`}
                          </div>
                      `
                    : emp.invite?.status === InviteStatus.Sent || emp.invite?.status === InviteStatus.Created
                      ? html`<div class="smaller faded orange pill"><i class="envelope"></i> Einl. Versandt</div>`
                      : emp.invite?.status === InviteStatus.DeliveryFailed
                        ? html`<div class="smaller red pill">
                              <i class="exclamation-triangle"></i> Einl. Fehlgeschl.
                          </div>`
                        : emp.status === EmployeeStatus.Active
                          ? html`
                                <button
                                    type="button"
                                    class="smaller skinny subtle"
                                    @click=${(e: Event) => {
                                        e.stopPropagation();
                                        this.dispatchEvent(new CustomEvent("send-invite"));
                                    }}
                                >
                                    <i class="envelope"></i> Einladung Versenden
                                </button>
                            `
                          : ""}
            </div>
        `;
    }

    private _render() {
        return html`
            ${this.employee ? this._renderHeaderEmployee() : this._renderHeaderAll()}

            <div class="row-section horizontal layout">
                ${PERMISSIONS.children
                    .filter((perm: PermissionInfo) => !perm.requiresRole || perm.requiresRole > Role.Manager)
                    .map((perm) => this._renderCheckboxes(perm, false))}
            </div>

            <div class="row-section horizontal layout">
                ${PERMISSIONS.children
                    .filter((perm: PermissionInfo) => perm.requiresRole && perm.requiresRole <= Role.Manager)
                    .map((perm) => this._renderCheckboxes(perm, false))}
            </div>
        `;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }
}

export interface PermissionData {
    employee: Employee;
    role: Role;
    permissions: Permission[];
}

@customElement("ptc-employees-permissions-all")
export class EmployeesPermissionsAll extends Routing(StateMixin(LitElement)) {
    routePattern = /employees\/all\/permissions/;

    get routeTitle() {
        return "Berechtigungen: Alle Mitarbeiter";
    }

    @state()
    private _loading: boolean = false;

    @state()
    private _data: PermissionData[] = [];

    @state()
    private _dataAll: {
        permissions: Permission[];
    } = { permissions: [] };

    @singleton("ptc-invite-dialog")
    private _inviteDialog: InviteDialog;

    @query(".inner")
    private _inner: HTMLDivElement;

    @query(".header.row")
    private _headerRow: HTMLDivElement;

    private _expanded = new Set<Permission>();

    private async _load() {
        this._loading = true;
        await app.fetchCompany();
        this._refresh();
        this._loading = false;
    }

    private _refresh() {
        this._data = app
            .getFilteredEmployees()
            .sort(
                (a, b) =>
                    Number(!!b.accountId) - Number(!!a.accountId) ||
                    a.role - b.role ||
                    Number(!!b.invite) - Number(!!a.invite) ||
                    (a.lastName < b.lastName ? -1 : 1)
            )
            .map((employee) => ({
                employee,
                role: employee.role,
                permissions: employee.access?.permissions || [],
            }));

        this._dataAll = {
            permissions: intersectArrays(...this._data.map((d) => d.permissions)),
        };
    }

    async handleRoute() {
        void this._load();
    }

    private _intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => this._intersectionHandler(entries),
        { root: this, rootMargin: "50%" }
    );

    private _intersectionHandler(entries: IntersectionObserverEntry[]) {
        entries.forEach((e) => ((e.target as PermissionsRow).isVisible = e.isIntersecting));
    }

    updated() {
        this._inner.style.width = `${this._headerRow.scrollWidth}px`;
        const elements = this.renderRoot.querySelectorAll("ptc-employee-permissions-row");
        for (const el of elements) {
            this._intersectionObserver.observe(el);
        }
    }

    private _permissionToggled({
        detail: { employeeId, scope, checked },
    }: CustomEvent<{ employeeId?: number; scope: Permission; checked: boolean }>) {
        const dataArray = employeeId
            ? this._data.filter((d) => d.employee.id === employeeId)
            : [
                  this._dataAll,
                  ...(scope.startsWith("manage") ? this._data.filter((d) => d.role <= Role.Manager) : this._data),
              ];

        const { sub, requires, bound } = app.getRelatedPermissions(scope);

        for (const data of dataArray) {
            if (checked) {
                data.permissions = [...new Set([...data.permissions, ...sub, ...bound])];
            } else {
                data.permissions = data.permissions.filter((p) => ![...sub, ...requires, ...bound].includes(p));
            }
        }

        // if (!!employeeId) {
        //     (target as PermissionsRow).requestUpdate();
        // } else {
        this.requestUpdate();
        // }

        this._update();
    }

    private _roleSelected({ detail: { role, employeeId } }: CustomEvent<{ employeeId: number; role: Role }>) {
        const data = this._data.find((d) => d.employee.id === employeeId)!;
        data.role = role;

        if (data.role > Role.Manager) {
            data.permissions = data.permissions.filter((p) => !p.startsWith("manage"));
        }

        // (target as PermissionsRow).requestUpdate();
        this.requestUpdate();
        this._update();
    }

    private async _sendInvites(emp?: Employee) {
        await this._inviteDialog.show(emp);
        void this._load();
    }

    private _getUpdates({ role, permissions, employee }: PermissionData) {
        if (
            role !== employee.role ||
            permissions.length !== employee.access?.permissions.length ||
            permissions.some((val) => !employee.access?.permissions.includes(val))
        ) {
            return { id: employee.id, access: { permissions, filters: employee.access?.filters || [] }, role };
        } else {
            return null;
        }
    }

    private _getAllUpdates() {
        const updates: { id: number; access: Access; role: Role }[] = [];

        for (const emp of this._data) {
            const upd = this._getUpdates(emp);
            if (upd) {
                updates.push(upd);
            }
        }

        return updates;
    }

    private _update = debounce(async () => {
        const update = this._getAllUpdates();

        if (!update.length) {
            return;
        }

        try {
            await app.api.updateEmployees(new UpdateEmployeesParams({ update }));
            await app.fetchCompany();
            this._refresh();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.requestUpdate();
    }, 2000);

    private _toggleCollapsed(perm: PermissionInfo) {
        if (!perm.children) {
            return;
        }

        if (this._expanded.has(perm.key as Permission)) {
            this._expanded.delete(perm.key as Permission);
        } else {
            this._expanded.add(perm.key! as Permission);
        }

        this.requestUpdate();
    }

    static styles = [
        shared,
        css`
            :host {
                overflow: auto !important;
            }

            .row {
                display: flex;
                height: 2.6em;
                contain: size style;
            }

            .row > * {
                border-bottom: solid 1px var(--shade-2);
            }

            .row.accountless:not(:hover) {
                opacity: 0.5;
            }

            .row.accountless:not(:hover) button {
                display: none;
            }

            .header.row {
                position: sticky;
                top: 0;
                background: var(--color-bg);
                z-index: 2;
                height: 18em;
            }

            .header.row > * {
                background: var(--color-bg);
            }

            .header.row .row-header {
                height: auto;
            }

            .row-header {
                width: 20em;
                flex: none;
                min-width: 20em;
                position: sticky;
                left: 0;
                background: var(--color-bg);
                padding: 0 0.3em;
                z-index: 1;
                border-right: solid 1px var(--shade-2);
            }

            .row-header ptc-avatar {
                font-size: var(--font-size-tiny);
                margin-right: 0.8em;
            }

            .row-section {
                margin-left: 1em;
                border-left: solid 1px var(--shade-2);
                border-right: solid 1px var(--shade-2);
            }

            .employee-header {
                cursor: pointer;
            }

            .employee-header:hover {
                color: var(--color-primary);
            }

            .permission-header {
                background: var(--color-bg);
                overflow: hidden;
                position: relative;
                flex: none;
            }

            .permission-header:not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .permission-header-title {
                width: 2.5em;
                line-height: 2.5em;
                padding: 0.5em 0;
            }

            .permission-header-title-inner {
                writing-mode: vertical-rl;
                text-orientation: mixed;
                padding-top: 0.5em;
            }

            .permission-header-title-floating {
                line-height: 2.5em;
                text-align: center;
                box-sizing: border-box;
                position: absolute;
                left: 4em;
                right: 1em;
                top: 0;
            }

            .not-selectable > .permission-header-title-floating {
                left: 1em;
            }

            .permission-header,
            .permission-header-children {
                display: flex;
                box-sizing: border-box;
            }

            .permission-header-children {
                margin-top: 2.5em;
                position: relative;
                border-left: solid 1px var(--shade-2);
                border-top: solid 1px var(--shade-2);
            }

            .permission-header-children::before {
                content: "";
                display: block;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-bottom: solid 1px var(--shade-2);
            }

            .permission-header.not-selectable > .permission-header-title {
                display: none;
            }

            .permission-header.not-selectable > .permission-header-title-floating {
                padding-left: 0;
            }

            .permission-header.not-selectable > .permission-header-children {
                border-left: none;
            }

            .permission-wrapper:not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .permission-children {
                display: flex;
            }

            .permission-children:not(:first-child) {
                border-left: solid 1px var(--shade-2);
            }

            .permission-checkbox {
                width: 2.5em;
                flex: none;
                text-align: center;
                position: relative;
                cursor: pointer;
            }

            .permission-checkbox:not(:first-child) {
                border-left: solid 1px var(--shade-2);
            }

            .permission-checkbox:hover {
                background: var(--blue-bg);
            }

            .permission-checkbox[disabled] {
                background: var(--shade-1);
                opacity: 1;
            }

            .permission-checkbox input {
                position: absolute;
                opacity: 0;
            }

            .permission-checkbox label {
                display: block;
                text-align: center;
                width: 100%;
                line-height: 2.5em;
                cursor: pointer;
                padding: 0;
                color: var(--color-positive);
            }

            .permission-checkbox input:not(:checked) + label {
                opacity: 0;
            }

            .hide-permission > .permission-header-children {
                margin-top: 0;
            }

            .hide-permission > .permission-header-children,
            .hide-permission > .permission-children {
                border: none;
            }

            .all-employees-icon {
                border-radius: 100%;
                background: var(--shade-1);
                border: solid 0.1em var(--shade-2);
                padding: 0.1em;
                margin-right: 0.6em;
            }

            .permission-checkbox[data-selected-count]:not([data-selected-count=""]) i {
                opacity: 0.5;
            }

            .permission-checkbox[data-selected-count]:not([data-selected-count=""])::after {
                content: attr(data-selected-count);
                font-size: 0.6em;
                position: absolute;
                right: 0;
                bottom: 0;
                font-weight: bold;
                padding: 0.2em;
                border-radius: 0.5em;
                background: rgba(255, 255, 255, 0.8);
                color: var(--orange);
                pointer-events: none;
            }
        `,
    ];

    private _renderHeader(perm: PermissionInfo): TemplateResult | undefined {
        if (!app.company || (perm.feature && !app.company.features.includes(perm.feature))) {
            return;
        }
        const key = perm.key as Permission;
        return html`
            <div class="permission-header ${perm.key ? "" : "not-selectable"} ${perm.hide ? "hide-permission" : ""}">
                <div
                    class="permission-header-title vertical center-aligning layout ${perm.children ? "click" : ""}"
                    @click=${() => this._toggleCollapsed(perm)}
                    ?hidden=${perm.hide}
                >
                    ${perm.icon ? html`<i class="${perm.icon}"></i>` : ""}
                    <div class="permission-header-title-inner ellipsis stretch">${perm.title}</div>
                    ${perm.children
                        ? html` <i class="small ${this._expanded.has(key) ? "chevron-right" : "chevron-down"}"></i> `
                        : ""}
                </div>
                <div class="permission-header-title-floating ellipsis" ?hidden=${perm.hide}>${perm.title}</div>
                ${perm.children &&
                html`
                    <div
                        class="permission-header-children"
                        ?hidden=${!!perm.key && !perm.hide && !this._expanded.has(key)}
                    >
                        ${perm.children.map((child) => this._renderHeader(child))}
                    </div>
                `}
            </div>
        `;
    }

    render() {
        return html`
            <div class="fullbleed vertical layout">
                <ptc-employees-filter
                    class="border-bottom"
                    @change=${() => this._refresh()}
                    disableSorting
                ></ptc-employees-filter>
                <div class="stretch collapse scrolling">
                    <div class="inner">
                        <div class="header row">
                            <div class="row-header vertical end-justifying layout">
                                <button
                                    type="button"
                                    class="double-margined subtle transparent"
                                    @click=${() => this._sendInvites()}
                                >
                                    <i class="envelope"></i>
                                    Einladungen Versenden
                                </button>
                            </div>

                            <div class="row-section vertical layout">
                                <div class="padded text-centering bold">Mitarbeiterzugang</div>
                                <div class="horizontal layout stretch collapse">
                                    ${PERMISSIONS.children
                                        .filter(
                                            (perm: PermissionInfo) =>
                                                !perm.requiresRole || perm.requiresRole > Role.Manager
                                        )
                                        .map((perm) => this._renderHeader(perm))}
                                </div>
                            </div>

                            <div class="row-section vertical layout">
                                <div class="padded text-centering bold">Verwaltung</div>
                                <div class="horizontal layout stretch collapse">
                                    ${PERMISSIONS.children
                                        .filter(
                                            (perm: PermissionInfo) =>
                                                perm.requiresRole && perm.requiresRole >= Role.Manager
                                        )
                                        .map((perm) => this._renderHeader(perm))}
                                </div>
                            </div>

                            <div></div>
                        </div>

                        <ptc-employee-permissions-row
                            class="row"
                            .employee=${null}
                            .permissions=${this._dataAll?.permissions}
                            .expanded=${[...this._expanded]}
                            @permission-toggled=${this._permissionToggled}
                            @role-selected=${this._roleSelected}
                        >
                        </ptc-employee-permissions-row>

                        ${repeat(
                            this._data,
                            (e) => e.employee.id,
                            ({ employee, role, permissions }) =>
                                html`<ptc-employee-permissions-row
                                    class="row ${!employee.accountId && employee.invite?.status !== InviteStatus.Sent
                                        ? "accountless"
                                        : ""}"
                                    .employee=${employee}
                                    .permissionsRole=${role}
                                    .permissions=${permissions}
                                    .expanded=${[...this._expanded]}
                                    @permission-toggled=${this._permissionToggled}
                                    @role-selected=${this._roleSelected}
                                    @header-clicked=${() => this.go(`employees/${employee.id}/permissions`)}
                                    @send-invite=${() => this._sendInvites(employee)}
                                ></ptc-employee-permissions-row>`
                        )}
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
