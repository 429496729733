import { RevenueEntry, RevenueType } from "@pentacode/core/src/model";
import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { Dialog } from "./dialog";
import { CountingLogForm } from "./counting-log-form";
import { DateInput } from "./date-input";
import { DateString, Euros } from "@pentacode/openapi";

interface Input {
    otherEntries: RevenueEntry[];
    entry: RevenueEntry;
}

@customElement("ptc-counting-log-dialog")
export class CountingLogDialog extends Dialog<Input, RevenueEntry> {
    readonly preventDismiss = true;

    @state()
    private _otherEntries: RevenueEntry[] = [];

    @state()
    private _entry: RevenueEntry;

    @query("ptc-counting-log-form")
    private _countingLogForm: CountingLogForm;

    @query("form")
    private _form: HTMLFormElement;

    @query("ptc-date-input[name='date']")
    private _dateInput: HTMLInputElement;

    @query("input[name='amount']")
    private _amountInput: HTMLInputElement;

    @query("input[name='balance']")
    private _balanceInput: HTMLInputElement;

    private get _previousEntry() {
        return [...this._otherEntries]
            .reverse()
            .find((e) => e.date <= this._dateInput.value && !!e.sequence && e.type !== RevenueType.CashCount);
    }

    private get _nominalCount() {
        const previous = this._previousEntry;
        return (previous && previous.cashBalance) || 0;
    }

    async show({ otherEntries, entry }: Input) {
        this._entry = entry;
        this._otherEntries = otherEntries;
        await this.updateComplete;
        this._dateInput.value = entry.date;
        this._dateChanged();
        this._countingLogForm.reset(entry.countingLog);
        this._countingLogChanged();
        return super.show();
    }

    private _countingLogChanged() {
        const countingLog = this._countingLogForm.data;
        const cashCount = countingLog.count.reduce((total, { amount, value }) => total + amount * value, 0);
        this._amountInput.value = cashCount.toFixed(2);
    }

    private _dateChanged() {
        this._balanceInput.value = this._nominalCount.toFixed(2);
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const date = new FormData(this._form).get("date") as DateString;
        const countingLog = this._countingLogForm.data;
        const amount = countingLog.count.reduce((total, { amount, value }) => total + amount * value, 0);

        this._entry.amount = Number(amount.toFixed(2)) as Euros;
        this._entry.date = date;
        this._entry.countingLog = countingLog;
        this._entry.cashBalance = this._nominalCount as Euros;

        this.done(this._entry);
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        css`
            h1 {
                margin: 0.7em 0 0 0;
                text-align: center;
            }

            .fields,
            ptc-counting-log-form {
                margin: 0 0.7em;
            }

            input[type="number"] {
                text-align: right;
            }

            .row input[readonly] {
                border-color: transparent;
            }
        `,
    ];

    renderContent() {
        const earliestEditable = this._otherEntries.find((e) => !e.readonly);
        return html`
            <div class="big text-centering margined top-padded"><i class="coins"></i> Neues Zählprotokoll</div>

            <form @submit=${this._submit}>
                <div class="horizontal spacing layout fields">
                    <div class="field stretch">
                        <label>Datum</label>
                        <ptc-date-input
                            name="date"
                            .min=${earliestEditable && earliestEditable.date}
                            @change=${() => this._dateChanged()}
                        ></ptc-date-input>
                    </div>

                    <div class="field stretch">
                        <label>Kassenstand</label>
                        <div class="right icon input">
                            <input readonly type="number" step="0.01" name="balance" />
                            <i class="euro-sign"></i>
                        </div>
                    </div>

                    <div class="field stretch">
                        <label>Gezählt</label>
                        <div class="right icon input">
                            <input readonly type="number" step="0.01" name="amount" />
                            <i class="euro-sign"></i>
                        </div>
                    </div>
                </div>

                <ptc-counting-log-form @change=${this._countingLogChanged}></ptc-counting-log-form>

                <div class="horizontal padded evenly stretching spacing layout">
                    <button class="primary">Speichern</button>
                    <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
