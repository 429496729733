import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { BenefitType, CompanySettings } from "@pentacode/core/src/model";
import { app } from "../init";
import { Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import { shared } from "../styles";
import "./scroller";
import "./bonus-type-item";
import { alert, confirm } from "./alert-dialog";
import { clone } from "@pentacode/core/src/encoding";
import { singleton } from "../lib/singleton";
import "./sortable-list";
import { BenefitTypeDialog } from "./benefit-type-dialog";
import { TimeInput } from "./time-input";
import { Rate, Euros, Meals } from "packages/openapi/src/units";

@customElement("ptc-settings-benefits")
export class SettingsBenefits extends Routing(StateMixin(LitElement)) {
    routePattern = /settings\/benefits/;

    @state()
    private _loading = false;

    @state()
    private _benefitTypes: BenefitType[] = [];

    @singleton("ptc-benefit-type-dialog")
    private _benefitTypeDialog: BenefitTypeDialog;

    @query("#mealsSettingsForm")
    private _mealsSettingsForm: HTMLFormElement;

    private get _mealsSettingsData(): Pick<CompanySettings, `mealValue${"Breakfast" | "Lunch" | "Dinner"}`> | null {
        if (!this._mealsSettingsForm) {
            return null;
        }
        const data = new FormData(this._mealsSettingsForm);
        return {
            mealValueBreakfast: data.get("mealValueBreakfast")
                ? (Number(data.get("mealValueBreakfast") as string) as Rate<Euros, Meals>)
                : null,
            mealValueLunch: data.get("mealValueLunch")
                ? (Number(data.get("mealValueLunch") as string) as Rate<Euros, Meals>)
                : null,
            mealValueDinner: data.get("mealValueDinner")
                ? (Number(data.get("mealValueDinner") as string) as Rate<Euros, Meals>)
                : null,
        };
    }

    private get _hasMealsSettingsChanged() {
        if (!app.company || !this._mealsSettingsData) {
            return false;
        }
        for (const [prop, value] of Object.entries(this._mealsSettingsData) as [keyof CompanySettings, any][]) {
            if (app.company.settings[prop] !== value) {
                return true;
            }
        }
    }

    private _reset() {
        if (!app.company) {
            return;
        }
        this._benefitTypes = app.company.benefitTypes?.map(clone) || [];
        for (const [prop, value] of Object.entries(app.company.settings)) {
            const input = (this.renderRoot.querySelector(`[name="${prop}"]`) as HTMLInputElement) || null;
            if (input) {
                input.value = typeof value === "number" ? value.toFixed(2) : value;
            }
        }
        this.requestUpdate();
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("active") && this.active) {
            this._reset();
        }
    }

    private async _addBenefitType(config: BenefitType | undefined = new BenefitType()) {
        const submitted = await this._benefitTypeDialog.show(config);

        if (submitted) {
            this._benefitTypes.push(config);
            await this._submitBenefitTypes();
        }
    }

    private async _editBenefitType(config: BenefitType) {
        const updated = await this._benefitTypeDialog.show(config);
        if (updated) {
            Object.assign(config, updated);
            await this._submitBenefitTypes();
        }
    }

    private async _removeBenefitType(index: number) {
        if (
            await confirm("Sind Sie sicher dass Sie diese Sonderleistung löschen möchten?", "Löschen", "Abbrechen", {
                type: "destructive",
            })
        ) {
            this._benefitTypes.splice(index, 1);
            await this._submitBenefitTypes();
        }
    }

    private async _orderChanged() {
        this._benefitTypes.forEach((t, i) => (t.order = i));
        this._submitBenefitTypes();
    }

    private async _submitBenefitTypes() {
        if (!app.company) {
            return;
        }
        this._loading = true;
        try {
            await app.updateCompany({ benefitTypes: this._benefitTypes });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._reset();
        this._loading = false;
    }

    private async _submitMealsSettings(e: Event) {
        e.preventDefault();
        if (!app.company) {
            return;
        }
        this._loading = true;
        try {
            await app.updateCompany({
                settings: new CompanySettings({
                    ...app.company.settings,
                    ...this._mealsSettingsData,
                }),
            });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._reset();
        this._loading = false;
    }

    static styles = [
        shared,
        TimeInput.styles,
        css`
            .meals-row {
                display: grid;
                grid-template-columns: 1fr 7em;
                gap: 0.5em;
                align-items: center;
            }
        `,
    ];

    render() {
        return html`
            <ptc-scroller class="fullbleed">
                <div style="max-width: 50em; margin: 0 auto;">
                    <div class="padded">
                        <h2>Mitarbeiteressen</h2>

                        <form
                            id="mealsSettingsForm"
                            @input=${() => this.requestUpdate()}
                            @submit=${this._submitMealsSettings}
                        >
                            <div class="box">
                                <div class="half-padded meals-row border-bottom">
                                    <div class="padded stretch"><i class="pancakes"></i> Frühstück</div>
                                    <div class="right icon input">
                                        <input
                                            name="mealValueBreakfast"
                                            type="number"
                                            step="0.01"
                                            class="text-right-aligning"
                                        />
                                        <i class="euro-sign"></i>
                                    </div>
                                </div>
                                <div class="half-padded meals-row border-bottom">
                                    <div class="padded stretch"><i class="burger-fries"></i> Mittagessen</div>
                                    <div class="right icon input">
                                        <input
                                            name="mealValueLunch"
                                            type="number"
                                            step="0.01"
                                            class="text-right-aligning"
                                            name="mealValueLunch"
                                        />
                                        <i class="euro-sign"></i>
                                    </div>
                                </div>
                                <div class="half-padded meals-row">
                                    <div class="padded stretch"><i class="plate-utensils"></i> Abendessen</div>
                                    <div class="right icon input">
                                        <input
                                            name="mealValueDinner"
                                            type="number"
                                            step="0.01"
                                            class="text-right-aligning"
                                            name="mealValueDinner"
                                        />
                                        <i class="euro-sign"></i>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="padded evenly stretching spacing horizontal layout"
                                ?hidden=${!this._hasMealsSettingsChanged}
                            >
                                <button class="primary">Speichern</button>
                                <button class="transparent" type="button" @click=${() => this._reset()}>
                                    Abbrechen
                                </button>
                            </div>
                        </form>

                        <h2>Weitere Sonderleistungen</h2>
                        <div class="box">
                            <ptc-sortable-list
                                .items=${this._benefitTypes}
                                .renderItem=${(type: BenefitType, i: number) => html`
                                    <div class="relative border-bottom">
                                        <div class="double-padded click" @click=${() => this._editBenefitType(type)}>
                                            ${type.name}
                                        </div>

                                        <button class="transparent slim margined absolute top right" type="button">
                                            <i class="ellipsis-h"></i>
                                        </button>

                                        <ptc-popover class="popover-menu" hide-on-click>
                                            <button @click=${() => this._editBenefitType(type)} type="button">
                                                <i class="pencil-alt"></i>
                                                Bearbeiten
                                            </button>

                                            <button @click=${() => this._removeBenefitType(i)} type="button">
                                                <i class="trash"></i>
                                                Entfernen
                                            </button>
                                        </ptc-popover>
                                    </div>
                                `}
                                @item-moved=${this._orderChanged}
                            ></ptc-sortable-list>

                            <div class="margined vertical layout" @click=${() => this._addBenefitType()}>
                                <button class="transparent"><i class="plus"></i> Neue Sonderleistung</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
