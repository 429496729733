import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import "./popover";
import { Checkbox } from "./checkbox";
import { wait } from "@pentacode/core/src/util";

@customElement("ptc-meals-input")
export class MealsInput extends LitElement {
    createRenderRoot() {
        return this;
    }

    @property({ type: Boolean })
    readonly = false;

    @query(".focus-container")
    private _focusContainer: HTMLDivElement;

    @query("#breakfast")
    private _breakfastInput: Checkbox;

    @query("#lunch")
    private _lunchInput: Checkbox;

    @query("#dinner")
    private _dinnerInput: Checkbox;

    @property({ type: Boolean })
    get breakfast() {
        return this._breakfastInput?.checked || false;
    }
    set breakfast(val: boolean) {
        (async () => {
            if (!this._breakfastInput) {
                await this.updateComplete;
            }

            this._breakfastInput.checked = val;
            await wait(100);
            this.requestUpdate();
        })();
    }

    @property({ type: Boolean })
    get lunch() {
        return this._lunchInput?.checked || false;
    }
    set lunch(val: boolean) {
        (async () => {
            if (!this._lunchInput) {
                await this.updateComplete;
            }

            this._lunchInput.checked = val;
            await wait(100);
            this.requestUpdate();
        })();
    }

    @property({ type: Boolean })
    get dinner() {
        return this._dinnerInput?.checked || false;
    }
    set dinner(val: boolean) {
        (async () => {
            if (!this._dinnerInput) {
                await this.updateComplete;
            }

            this._dinnerInput.checked = val;
            await wait(100);
            this.requestUpdate();
        })();
    }

    focus() {
        this._focusContainer?.focus();
    }

    render() {
        return html`
            <div class="focus-container" tabindex="0" style="padding: 0.39em">
                ${this.breakfast ? html`<i class="smaller pancakes"></i> 1` : ""}
                ${this.lunch ? html`<i class="smaller burger-fries"></i> 1` : ""}
                ${this.dinner ? html`<i class="smaller plate-utensils"></i> 1` : ""}
                ${!this.breakfast && !this.lunch && !this.dinner ? html`<i class="smaller utensils"></i> 0` : ""}
            </div>
            <ptc-popover trigger="focus" class="small" style="padding: 0.5em; min-width: 14em;">
                <div class="vertical layout">
                    <ptc-checkbox-button
                        buttonClass="slim transparent"
                        id="breakfast"
                        .label=${html` <i class="pancakes"></i> Frühstück `}
                        @change=${() => this.requestUpdate()}
                    ></ptc-checkbox-button>
                    <ptc-checkbox-button
                        buttonClass="slim transparent"
                        id="lunch"
                        .label=${html` <i class="burger-fries"></i> Mittagessen `}
                        @change=${() => this.requestUpdate()}
                    ></ptc-checkbox-button>
                    <ptc-checkbox-button
                        buttonClass="slim transparent"
                        id="dinner"
                        .label=${html` <i class="plate-utensils"></i> Abendessen `}
                        @change=${() => this.requestUpdate()}
                    ></ptc-checkbox-button>
                </div>
            </ptc-popover>
        `;
    }
}
