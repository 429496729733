import { LitElement, html, css, nothing } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import {
    BreakMode,
    MealsMode,
    Position,
    TimeEntry,
    TimeEntryType,
    TimeLogEvent,
    breakModeLabel,
    timeLogActionIcon,
    timeLogActionLabel,
} from "@pentacode/core/src/model";
import {
    toDurationString,
    parseDurationString,
    toDateString,
    toTimeString,
    parseTimes,
    formatDateShort,
} from "@pentacode/core/src/util";
import { app, router } from "../init";
import { shared } from "../styles";
import { TimeInput } from "./time-input";
import { DateInput } from "./date-input";
import { Textarea } from "./textarea";
import { popover } from "../directives/popover";
import "./time-log-event-details";
import { DateString, Euros, Meals } from "@pentacode/openapi";
import { MealsInput } from "./meals-input";
import { Checkbox } from "./checkbox";

@customElement("ptc-time-entry-form")
export class TimeEntryForm extends LitElement {
    @property({ attribute: false })
    entry: TimeEntry;

    @property({ attribute: false })
    positions?: Position[];

    @property({ type: Boolean })
    hideMealsAndRevenue = false;

    @property({ type: Boolean })
    hideLogged = false;

    @property({ type: Boolean })
    readonly = false;

    @property({ attribute: false })
    timeLogEvents: TimeLogEvent[] = [];

    @query("ptc-date-input[name='date']")
    private _dateInput: HTMLInputElement;

    @query("ptc-time-input[name='startPlanned']")
    private _startPlannedInput: HTMLInputElement;

    @query("ptc-time-input[name='endPlanned']")
    private _endPlannedInput: HTMLInputElement;

    @query("ptc-time-input[name='startLogged']")
    private _startLoggedInput: HTMLInputElement;

    @query("ptc-time-input[name='endLogged']")
    private _endLoggedInput: HTMLInputElement;

    @query("ptc-time-input[name='startFinal']")
    private _startFinalInput: HTMLInputElement;

    @query("ptc-time-input[name='endFinal']")
    private _endFinalInput: HTMLInputElement;

    @query("ptc-meals-input")
    private _mealsInput: MealsInput;

    @query("input[name='revenue']")
    private _revenueInput: HTMLInputElement;

    @query("ptc-time-input[name='break']")
    private _breakInput: HTMLInputElement;

    @query("ptc-time-input[name='breakPlanned']")
    private _breakPlannedInput: HTMLInputElement;

    @query("ptc-time-input[name='breakLogged']")
    private _breakLoggedInput: HTMLInputElement;

    @query("select[name='position']")
    private _positionSelect: HTMLSelectElement;

    @query("ptc-textarea[name='comment']")
    private _commentInput: Textarea;

    @query("form")
    private _form: HTMLFormElement;

    private get _employee() {
        return this.entry.employeeId ? app.getEmployee(this.entry.employeeId) : null;
    }

    private get _editMode() {
        const { trackingEnabled } = app.getTimeSettings({ timeEntry: this.entry });
        const isPast = this.entry.start < new Date();
        return !trackingEnabled || isPast || !!this.entry.startFinal || !!this.entry.endFinal ? "final" : "planned";
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("entry")) {
            this.updateFields();
        }
    }

    focusField(field: string) {
        if (["start", "end"].includes(field)) {
            const mode = this._editMode as string;
            field = field + mode[0].toUpperCase() + mode.slice(1);
        }
        if (field === "break" && this._editMode === "planned") {
            field = "breakPlanned";
        }
        const el = this.renderRoot!.querySelector(`[name="${field}"]`) as HTMLElement;
        el && el.focus();
    }

    updateFields() {
        if (!this.entry) {
            return;
        }
        const position = this.entry.position || this._employee?.positions[0];
        this._positionSelect.value = position?.id.toString() || "";
        this._startPlannedInput.value = toTimeString(this.entry.startPlanned);
        this._endPlannedInput.value = toTimeString(this.entry.endPlanned);
        this._startLoggedInput.value = toTimeString(this.entry.startLogged);
        this._endLoggedInput.value = toTimeString(this.entry.endLogged);
        this._startFinalInput.value = toTimeString(this.entry.startFinal);
        this._endFinalInput.value = toTimeString(this.entry.endFinal);
        this._dateInput.value = this.entry.date;
        this._breakInput.value = typeof this.entry.break === "number" ? toDurationString(this.entry.break) : "";
        this._breakPlannedInput.value =
            typeof this.entry.breakPlanned === "number" ? toDurationString(this.entry.breakPlanned) : "";
        this._breakLoggedInput.value =
            typeof this.entry.breakLogged === "number" ? toDurationString(this.entry.breakLogged) : "";
        this._mealsInput.breakfast = !!this.entry.mealsBreakfast;
        this._mealsInput.lunch = !!this.entry.mealsLunch;
        this._mealsInput.dinner = !!this.entry.mealsDinner;
        this._revenueInput.value = this.entry.revenue.toString();
        this._commentInput.value = this.entry.comment || "";
    }

    private async _updateEntry() {
        const entry = this.entry;
        const formData = new FormData(this._form);

        entry.date = formData.get("date") as DateString;
        entry.comment = formData.get("comment") as string;
        if (entry.type === TimeEntryType.Work) {
            const [startPlanned, endPlanned] = parseTimes(
                entry.date,
                formData.get("startPlanned") as string,
                formData.get("endPlanned") as string
            );
            const [startFinal, endFinal] = parseTimes(
                entry.date,
                formData.get("startFinal") as string,
                formData.get("endFinal") as string
            );
            entry.startPlanned = startPlanned;
            entry.endPlanned = endPlanned;
            entry.startFinal = startFinal;
            entry.endFinal = endFinal;
            entry.break = formData.get("break") ? parseDurationString(formData.get("break") as string) : null;
            entry.breakPlanned = formData.get("breakPlanned")
                ? parseDurationString(formData.get("breakPlanned") as string)
                : null;
            entry.mealsBreakfast = this._mealsInput.breakfast ? (1 as Meals) : (0 as Meals);
            entry.mealsLunch = this._mealsInput.lunch ? (1 as Meals) : (0 as Meals);
            entry.mealsDinner = this._mealsInput.dinner ? (1 as Meals) : (0 as Meals);
            entry.revenue = (formData.get("revenue") ? Number(formData.get("revenue") as string) : 0) as Euros;
            entry.position = app.getPosition(parseInt(formData.get("position") as string))?.position || null;
            entry.positionId = entry.position?.id || null;
        }
    }

    private _validateDate(e: Event) {
        if (!this.entry.employeeId) {
            return;
        }
        const input = e.target as HTMLInputElement;
        const employee = app.getEmployee(this.entry.employeeId);
        const contract = employee && employee.getContractForDate(input.value);
        input.setCustomValidity(!contract ? "Es existiert kein Vertrag für diesen Zeitraum!" : "");
    }

    private async _commitChanges() {
        this.dispatchEvent(new CustomEvent("updateentry", { detail: this.entry, composed: true, bubbles: true }));
    }

    static styles = [
        shared,
        TimeInput.styles,
        DateInput.styles,
        Textarea.styles,
        Checkbox.styles,
        css`
            :host {
                display: block;
                font-size: inherit;
                color: inherit;
            }

            form {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
            }

            .field > label {
                padding: 0.6em 0.4em;
            }

            .field > label i {
                font-size: 0.9em;
                margin: -0.2em 0.2em -0.2em 0;
            }

            input[name="revenue"],
            select {
                font-size: var(--font-size-larger);
                padding: 0.3em 0.5em;
                font-weight: 600;
            }

            ptc-time-input {
                min-width: 4em;
            }

            ptc-time-input input {
                padding: 0.3em 0.4em;
                font-weight: 600;
            }

            ptc-time-input input[disabled] {
                opacity: 1;
            }

            .dash {
                font-weight: bold;
                margin: 0 0.2em;
            }

            ptc-time-input.break {
                margin-left: 0.2em;
            }

            ptc-time-input.break input {
                padding-left: 1.7em;
            }

            ptc-time-input.break i {
                font-size: 0.8em;
            }

            input[type="number"] {
                text-align: right;
            }

            .time-settings .box {
                border-color: var(--shade-2);
            }

            .time-log-events {
                position: relative;
                margin-top: -0.3em;
            }

            .time-log-events::after {
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 100%;
                left: 1em;
                top: 0;
                border-left: dashed 1px;
                opacity: 0.5;
            }

            .time-log-events i {
                background: var(--color-bg);
                position: relative;
                z-index: 1;
                border-radius: 100%;
                width: 1.3em;
                height: 1.3em;
                line-height: 1.3em;
            }
        `,
    ];

    render() {
        if (!this.entry) {
            return html``;
        }

        const contract = this._employee?.getContractForDate(this.entry.date);
        const salary = contract && contract.getSalary(this.entry);
        const showRevenue = salary && salary.commission;
        const s = app.getTimeSettings({ timeEntry: this.entry });
        const positions = this.positions || this._employee?.positions || [];
        const isPast = this.entry.date < toDateString(new Date());
        const canEditFinal =
            app.hasPermission("manage.employees.time") &&
            this._employee &&
            app.hasPermissionForEmployee(this._employee);

        return html`
            <form
                @submit=${(e: Event) => e.preventDefault()}
                @input=${() => this._updateEntry()}
                @change=${() => this._commitChanges()}
            >
                <div class="vertical layout" hidden>
                    <label><i class="calendar-alt"></i>Datum</label>
                    <ptc-date-input
                        name="date"
                        required
                        @change=${(e: Event) => this._validateDate(e)}
                    ></ptc-date-input>
                </div>

                <div class="vertical layout" ?hidden=${this.entry.type !== TimeEntryType.Work}>
                    <div class="horizontal spacing center-aligning layout">
                        <label ?disabled=${this.readonly} class="stretch"><i class="user"></i>Position </label>
                        <label class="smaller orange colored-text" ?hidden=${this.entry.position?.active}
                            ><i class="exclamation-triangle"></i>Archivierte Position</label
                        >
                    </div>
                    <select name="position" required ?disabled=${this.readonly}>
                        ${positions.map((position) => html` <option value=${position.id}>${position.name}</option> `)}
                    </select>
                </div>

                <div ?hidden=${this.entry.type !== TimeEntryType.Work}>
                    <label class="padded"> <i class="calendar"></i> Geplant </label>
                    <div class="horizontal center-aligning layout larger">
                        <ptc-time-input
                            class="stretch"
                            name="startPlanned"
                            ?disabled=${this.readonly || isPast || !!this.entry.startFinal}
                        ></ptc-time-input>
                        <div class="dash" ?disabled=${this._editMode === "final" || this.readonly}>-</div>
                        <ptc-time-input
                            class="stretch"
                            name="endPlanned"
                            ?disabled=${this.readonly || isPast || !!this.entry.endFinal}
                        ></ptc-time-input>
                        <ptc-time-input
                            class="stretch break"
                            name="breakPlanned"
                            icon="coffee"
                            .hourDigits=${1}
                            ?disabled=${![
                                BreakMode.Planned,
                                BreakMode.PlannedPlusManual,
                                BreakMode.PlannedOrManual,
                            ].includes(s.breakMode) ||
                            this.readonly ||
                            isPast ||
                            !!this.entry.endFinal}
                        ></ptc-time-input>
                    </div>
                </div>

                <div ?hidden=${this.entry.type !== TimeEntryType.Work || this.hideLogged}>
                    <label class="padded" disabled><i class="stopwatch"></i> Gestempelt</label>
                    <div class="horizontal center-aligning layout larger">
                        <ptc-time-input class="stretch" name="startLogged" disabled></ptc-time-input>
                        <div class="dash" disabled>-</div>
                        <ptc-time-input class="stretch" name="endLogged" disabled></ptc-time-input>
                        <ptc-time-input
                            class="stretch break"
                            name="breakLogged"
                            icon="coffee"
                            .hourDigits=${1}
                            disabled
                        ></ptc-time-input>
                    </div>
                </div>

                <div ?hidden=${this.entry.type !== TimeEntryType.Work}>
                    <label class="padded"> <i class="check"></i> Erfasst </label>
                    <div class="horizontal center-aligning layout larger">
                        <ptc-time-input
                            class="stretch"
                            name="startFinal"
                            ?disabled=${this._editMode !== "final" || this.readonly || !canEditFinal}
                        ></ptc-time-input>
                        <div class="dash" ?disabled=${this._editMode !== "final" || this.readonly}>-</div>
                        <ptc-time-input
                            class="stretch"
                            name="endFinal"
                            ?disabled=${this._editMode !== "final" || this.readonly || !canEditFinal}
                        ></ptc-time-input>
                        <ptc-time-input
                            class="stretch break"
                            name="break"
                            icon="coffee"
                            .hourDigits=${1}
                            ?disabled=${this._editMode !== "final" || this.readonly || !canEditFinal}
                        ></ptc-time-input>
                    </div>
                </div>

                <div
                    class="horizontal evenly stretching spacing layout"
                    ?hidden=${this.entry.type !== TimeEntryType.Work || this.hideMealsAndRevenue}
                >
                    <div class="vertical layout">
                        <label ?disabled=${this.readonly}><i class="utensils"></i>Essen</label>
                        <ptc-meals-input
                            ?disabled=${this.readonly}
                            @change=${async () => {
                                await this._updateEntry();
                                void this._commitChanges();
                            }}
                        ></ptc-meals-input>
                    </div>

                    <div class="vertical layout" style="flex: 2;" ?disabled=${!showRevenue}>
                        <label ?disabled=${this.readonly}><i class="euro-sign"></i>Umsatz</label>
                        <div class="right icon input">
                            <input
                                type="number"
                                min="0"
                                step="0.01"
                                name="revenue"
                                required
                                ?disabled=${this.readonly}
                            />
                            <i class="euro-sign"></i>
                        </div>
                    </div>
                </div>

                <div class="vertical layout">
                    <label><i class="comment" ?disabled=${this.readonly}></i>Bemerkungen</label>
                    <ptc-textarea
                        name="comment"
                        @keydown=${(e: KeyboardEvent) => e.key !== "Escape" && e.stopPropagation()}
                        ?disabled=${this.readonly}
                    ></ptc-textarea>
                </div>

                <div class="vertical layout">
                    <label class="center-aligning horizontal layout">
                        <div class="stretch"><i class="sliders-h"></i>Regeln</div>
                        <div
                            class="smaller link ellipsis subtle"
                            style="max-width: 10em;"
                            @click=${() => router.go("settings/time", { sid: s.id.toString() })}
                            ?disabled=${!app.hasPermission("manage.settings.time")}
                        >
                            ${s.name}
                        </div>
                    </label>
                    <div class="tiny semibold horizontally-padded horizontal spacing wrapping layout time-settings">
                        <div class="half-padded box centering spacing horizontal layout" title="Pausenmodus">
                            <i class="coffee"></i>
                            <div>${breakModeLabel(s.breakMode)}</div>
                        </div>

                        <div class="padded box centering horizontal layout" title="Mitarbeiteressen">
                            <i class="utensils"></i>
                            <div>
                                ${s.mealsMode === MealsMode.Auto
                                    ? "Auto"
                                    : s.mealsMode === MealsMode.Employee
                                      ? "Durch MA"
                                      : "Manuell"}
                            </div>
                        </div>

                        <div
                            class="half-padded box centering horizontal layout ${s.trackingEnabled
                                ? ""
                                : "strike-through"}"
                            title="Zeiterfassung per Stempeluhr"
                        >
                            <i class="larger stopwatch"></i>
                        </div>

                        ${s.trackingEnabled
                            ? html`
                                  <div
                                      class="half-padded box centering horizontal layout ${s.trackingViaAppEnabled
                                          ? ""
                                          : "strike-through"}"
                                      title="Zeiterfassung per Mitarbeiter-App"
                                  >
                                      <i class="larger mobile-alt"></i>
                                  </div>

                                  <div
                                      ?hidden=${!s.trackingViaAppEnabled}
                                      class="half-padded box centering horizontal layout ${s.trackingViaAppRequiresToken
                                          ? ""
                                          : "strike-through"}"
                                      title="Stempeln per MA-App erfordert QR-Code"
                                  >
                                      <i class="larger qrcode"></i>
                                  </div>
                              `
                            : ""}
                    </div>
                </div>

                ${this.timeLogEvents?.length
                    ? html`
                          <div class="vertical layout">
                              <label><i class="timeline-arrow"></i> Zeiterfassungsprotokoll</label>
                              <div class="time-log-events">
                                  ${this.timeLogEvents
                                      .sort((a, b) => a.time.getTime() - b.time.getTime())
                                      .map(
                                          (event) => html`
                                              <div
                                                  class="smaller center-aligning spacing horizontal layout time-log-event margined"
                                                  style="padding-left: 0.18em; margin-bottom: 0;"
                                                  ${event.image ||
                                                  event.comment ||
                                                  event.location ||
                                                  event.rejectedReason
                                                      ? popover(
                                                            html`
                                                                <ptc-time-log-event-details
                                                                    .event=${event}
                                                                ></ptc-time-log-event-details>
                                                            `,
                                                            {
                                                                trigger: "hover",
                                                                preferAlignment: ["left", "left-bottom", "left-top"],
                                                            }
                                                        )
                                                      : nothing}
                                              >
                                                  <i class="semibold ${timeLogActionIcon(event.action)}"></i>

                                                  <div
                                                      class="stretch ellipsis ${event.status === "rejected"
                                                          ? "red colored-text line-through"
                                                          : ""}"
                                                  >
                                                      ${formatDateShort(event.time, true)}

                                                      <strong>${toTimeString(event.time)}</strong>

                                                      ${timeLogActionLabel(event.action)}
                                                  </div>

                                                  <div>
                                                      ${event.status === "rejected"
                                                          ? html`<i class="exclamation-triangle red colored-text"></i>`
                                                          : ""}
                                                      ${event.location ? html`<i class="location-dot"></i>` : ""}
                                                      ${event.image ? html`<i class="image-polaroid-user"></i>` : ""}
                                                      ${event.comment ? html`<i class="comment"></i>` : ""}
                                                      ${event.source === "employee_app"
                                                          ? html`<i
                                                                class="mobile"
                                                                title="Über Mitarbeiter-App erfasst"
                                                            ></i>`
                                                          : ""}
                                                  </div>
                                              </div>
                                          `
                                      )}
                              </div>
                          </div>
                      `
                    : ""}
            </form>
        `;
    }
}
