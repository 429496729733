import { LitElement, html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty, RouteArgs } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./spinner";
import type { Scroller } from "./scroller";
import "./scroller";
import { DateInput } from "./date-input";
import { Checkbox } from "./checkbox";
import { dateAdd, formatMonthShort, getRange, toDateString } from "@pentacode/core/src/util";
import { ExportFormat, ExportParams, ExportResponse } from "@pentacode/core/src/api";
import { alert } from "./alert-dialog";
import { fileIcon, MimeType } from "@pentacode/core/src/model";
import { asSinglePDF, downloadFromUrl, formatFileSize, unzip } from "../lib/util";
import { EntityFiltersEl } from "./entity-filters";
import { EmployeeSortDirection, EmployeeSortProperty, deserializeFilters } from "@pentacode/core/src/filters";
import { print } from "../lib/print";
import { DocumentTagsInput } from "./document-tags-input";
import "./popover";
import { DateRangePicker } from "./date-range-picker";
import { DateRange } from "packages/core/src/time";

@customElement("ptc-exports")
export class Exports extends Routing(StateMixin(LitElement)) {
    static pages = Object.values(ExportFormat);

    routePattern = /^exports(?:\/(?<page>\w+))?/;

    defaultRange = () => {
        return getRange(new Date(), this._page === "balances_excel" ? "year" : "month");
    };

    @state()
    private _loading: string | boolean = false;

    @state()
    private _exportResponse: ExportResponse | null = null;

    @state()
    private _displayInfoMessages = false;

    @state()
    private _displayWarningMessages = true;

    @state()
    private _displayErrorMessages = true;

    @routeProperty({ arg: "page" })
    private _page: ExportFormat;

    @query("ptc-entity-filters")
    private _filtersEl: EntityFiltersEl;

    @query("ptc-document-tags-input")
    private _tagsInput: DocumentTagsInput;

    @query("ptc-scroller")
    private _scroller: Scroller;

    @query("ptc-date-range-picker")
    private _dateRangePicker: DateRangePicker;

    @query("form")
    private _exportForm: HTMLFormElement;

    private get _exportFormData(): Partial<ExportParams> {
        const data = this._exportForm && new FormData(this._exportForm);

        if (!data) {
            return {};
        }

        return {
            ...this._dateRangePicker?.range,
            resolution: (data.get("resolution") || undefined) as ExportParams["resolution"],
            filters: this._filtersEl?.filters || [],
            sendAsEmail: data.get("sendAsEmail") ? [data.get("sendAsEmail") as string] : undefined,
            saveToDocuments: data.has("saveToDocuments"),
            requireEmployeeSignature: data.has("requireEmployeeSignature"),
            venueId: data.get("venueId") ? Number(data.get("venueId") as string) : undefined,
            tags: this._tagsInput?.tags,
            sortProperty: data.get("sortProperty") as EmployeeSortProperty,
            sortDirection: data.get("sortDirection") as EmployeeSortDirection,
        };
    }

    private get _openFileUrl() {
        const res = this._exportResponse;
        if (!res) {
            return "";
        }

        switch (res.type) {
            case MimeType.MSExcel:
            case MimeType.MSExcelLegacy:
            case MimeType.MSWord:
            case MimeType.MSWordLegacy:
                return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(res.url)}`;
            default:
                return res.url;
        }
    }

    async handleRoute(_args: RouteArgs, params: { filters: string }) {
        if (!Exports.pages.includes(this._page) || !this._hasPermission(this._page)) {
            const page = Exports.pages.find((p) => this._hasPermission(p));
            this.go(`exports/${page}`, undefined, true);
            return;
        }

        this._exportResponse = null;

        await this.requestUpdate();

        let filters = params.filters && deserializeFilters(params.filters);

        if (filters) {
            if (this._page.startsWith("payroll") || this._page.startsWith("time")) {
                filters = filters.filter((f) => f.type !== "employeeStatus");
            }
            this._filtersEl.filters = filters;
            this.go(null, { ...this.router.params, filters: undefined }, true);
            return;
        }

        if (this._filtersEl && !this._filtersEl.filters.length) {
            this._filtersEl.filters = [];
        }
    }

    private _hasPermission(page: ExportFormat) {
        if (page.startsWith("payroll")) {
            return app.hasPermission("manage.exports.payroll");
        } else if (page.startsWith("time")) {
            return app.hasPermission("manage.exports.time");
        } else if (page.startsWith("cashbook")) {
            return app.hasPermission("manage.exports.cashbook");
        } else if (page.startsWith("employee_data")) {
            return app.hasPermission("manage.exports.employee_data");
        } else if (page.startsWith("balances")) {
            return app.hasPermission("manage.exports.ledgers");
        } else if (page.startsWith("roster")) {
            return app.hasPermission("manage.exports.roster");
        } else {
            return false;
        }
    }

    private async _submit(e: Event) {
        e.preventDefault();

        this._loading = "Ihr Export wird vorbereitet. Bitte haben Sie etwas Geduld...";

        await this.updateComplete;

        this._scroller.scrollTop = (this.renderRoot.querySelector("#responseAnchor") as HTMLDivElement)?.offsetTop;

        try {
            this._exportResponse = await app.api.export(
                new ExportParams({ ...this._exportFormData, format: this._page })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._displayInfoMessages = false;
        this._displayWarningMessages = !!this._exportResponse?.messages?.some((m) => m.type === "warning");
        this._displayErrorMessages = !!this._exportResponse?.messages?.some((m) => m.type === "error");

        this._loading = false;
    }

    private async _printResponse() {
        if (!this._exportResponse) {
            return;
        }

        this._loading = "Druck wird vorbereitet...";

        try {
            if (this._exportResponse.type === MimeType.Zip) {
                const file = await fetch(this._exportResponse.url);
                const files = await unzip(await file.arrayBuffer());
                const pdf = await asSinglePDF(files);
                const url = await URL.createObjectURL(new Blob([new Uint8Array(pdf)], { type: MimeType.PDF }));
                print(url, false);
            } else {
                const file = await fetch(this._exportResponse.url);
                const url = await URL.createObjectURL(await file.blob());
                print(url, false);
            }
        } finally {
            this._loading = false;
        }
    }

    private async _downloadResponse() {
        if (!this._exportResponse) {
            return;
        }

        this._loading = "Download wird vorbereitet...";

        try {
            if (this._exportResponse.type === MimeType.Zip) {
                await downloadFromUrl(this._exportResponse.url, this._exportResponse.fileName);
            } else {
                const file = await fetch(this._exportResponse.url);
                const url = await URL.createObjectURL(await file.blob());
                await downloadFromUrl(url, this._exportResponse.fileName);
            }
        } finally {
            this._loading = false;
        }
    }

    static styles = [
        shared,
        DateInput.styles,
        Checkbox.styles,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 20em;
            }

            .main {
                flex: 1;
                position: relative;
            }
        `,
    ];

    private _renderHeader() {
        switch (this._page) {
            case ExportFormat.PayrollReportDetailed:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohnbericht (Detailliert)</h2>
                        <div class="subtle margined">
                            Dieser Bericht kommt in PDF-Form und enthält <strong>Arbeitszeiten</strong>,
                            <strong>Abwesenheiten</strong>, <strong>Zuschläge</strong>,
                            <strong>Vertragsänderungen</strong> und alle anderen lohn-relevanten Informationen innerhalb
                            des gewählten Zeitraums inklusive einer <strong>Auswertung</strong>.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollReportOverview:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohnbericht (Übersicht)</h2>
                        <div class="subtle margined">
                            Dieser Bericht kommt in PDF-Form und enthält <strong>Arbeitszeiten</strong>,
                            <strong>Abwesenheiten</strong>, <strong>Zuschläge</strong>,
                            <strong>Vertragsänderungen</strong> und alle anderen lohn-relevanten Informationen innerhalb
                            des gewählten Zeitraums inklusive einer <strong>Auswertung</strong>.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollDatevLG:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Datev Lohn & Gehalt</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte
                            <strong>ASCII-Schnittstelle</strong> von
                            <a
                                href="https://www.datev.de/web/de/datev-shop/personalwirtschaft/lohn-und-gehalt-comfort/"
                                target="_blank"
                            >
                                Datev Lohn & Gehalt
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollDatevLodas:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Datev Lodas</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a
                                href="https://www.datev.de/web/de/datev-shop/personalwirtschaft/lodas-comfort/"
                                target="_blank"
                            >
                                Datev Lodas
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollAgenda:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Agenda</h2>
                        <div class="subtle margined">
                            Eine Textdatei über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a href="https://www.agenda-software.de/unternehmen/lohn-software.php" target="_blank">
                                Agenda
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollLexware:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Lexware</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a href="https://shop.lexware.de/lohn" target="_blank">Lexware Lohn und Gehalt</a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollAddison:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport Addison</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a
                                href="https://www.wolterskluwer.com/de-de/solutions/addison-komplettloesung-mittelstand/addison-lohn-und-gehaltsabrechnung-sme"
                                target="_blank"
                            >
                                Addison
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollLohnAG:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport LohnAG</h2>
                        <div class="subtle margined">
                            Eine Textdatei, die über die sogannte <strong>ASCII-Schnittstelle</strong> von
                            <a href="https://www.lohn-ag.de/" target="_blank"> LohnAG </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollBMDWages:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> Lohndatenexport BMD</h2>
                        <div class="subtle margined">
                            Eine CSV-Datei mit Lohndaten, die über die <strong>Schnittstelle</strong> von
                            <a
                                href="https://www.bmd.at/Portaldata/1/Resources/help/27.25/OES/Documents/1165317561018012300.html#Textmarke_4"
                                target="_blank"
                            >
                                BMD
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.PayrollBMDAbsences:
                return html`
                    <div class="text-centering">
                        <h2><i class="hand-holding-dollar"></i> BMD Nichtleistungszeiten</h2>
                        <div class="subtle margined">
                            Eine CSV-Datei mit angefallenen Nichtleistungszeiten, die über die
                            <strong>Schnittstelle</strong> von
                            <a
                                href="hhttps://www.bmd.at/Portaldata/1/Resources/help/27.01/OES/Documents/1173103919002700700.html"
                                target="_blank"
                            >
                                BMD
                            </a>
                            eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.TimeSheet:
                return html`
                    <div class="text-centering">
                        <h2><i class="list-timeline"></i> Arbeitszeit-Nachweis</h2>
                        <div class="subtle margined">
                            Eine Auflistung aller <strong>Arbeitszeiten</strong> und <strong>Fehltage</strong> zur
                            Aushändigung an und Unterschrift durch den Mitarbeiter.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookDatev:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export DATEV Buchungsstapel</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            im <strong>DATEV Buchungstapel</strong> Format, welches direkt nach DATEV importiert werden
                            kann.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookDatevOnline:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export DATEV Online</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            in einem Format, welches direkt nach <strong>DATEV Online</strong> importiert werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookLexware:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export Lexware</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            im ein Format, welches direkt in das Buchhaltungs Lexware eingelesen werden kann.
                        </div>
                    </div>
                `;
            case ExportFormat.CashbookGeneric:
                return html`
                    <div class="text-centering">
                        <h2><i class="book"></i> Kassenbuch-Export Generisch</h2>
                        <div class="subtle margined">
                            Exportiert alle <strong>Kassenbucheinträge</strong> für den gewählten Standort und Zeitraum
                            als generisches CSV-Format.
                        </div>
                    </div>
                `;
            case ExportFormat.EmployeeDataPDF:
                return html`
                    <div class="text-centering">
                        <h2><i class="address-card"></i> Mitarbeiter Datenblatt</h2>
                        <div class="subtle margined">
                            Die Stammdaten Ihrer Mitarbeiter auf einer übersichtlichen PDF-Seite.
                        </div>
                    </div>
                `;
            case ExportFormat.EmployeeDataCSV:
                return html`
                    <div class="text-centering">
                        <h2><i class="address-card"></i> Mitarbeiter Stammdaten</h2>
                        <div class="subtle margined">
                            Die Stammdaten Ihrer Mitarbeiter als tabellarischer CSV-Export.
                        </div>
                    </div>
                `;
            case ExportFormat.BalancesTimeExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="scale-balanced"></i> Arbeitszeitkonto</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine tabellarische Darstellung der
                            <strong>Arbeitszeitkonten</strong> für den gewählten Zeitraum im Microsoft Excel Format.
                            Über die Option <strong>Auflösung</strong> können Sie außerdem bestimmen, ob die Konten im
                            Wochen-, Monats- oder Jahresrhythmus saldiert werden sollen.
                        </div>
                    </div>
                `;
            case ExportFormat.BalancesVacationExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="scale-balanced"></i> Urlaubskonto</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine tabellarische Darstellung der
                            <strong>Urlaubskonten</strong> für den gewählten Zeitraum im Microsoft Excel Format. Über
                            die Option <strong>Auflösung</strong> können Sie außerdem bestimmen, ob die Konten im
                            Wochen-, Monats- oder Jahresrhythmus saldiert werden sollen.
                        </div>
                    </div>
                `;
            case ExportFormat.BalancesBonusesExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="scale-balanced"></i> Zuschlagskonto</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine tabellarische Darstellung der
                            <strong>Zuschlagskonten</strong> für den gewählten Zeitraum im Microsoft Excel Format. Über
                            die Option <strong>Auflösung</strong> können Sie außerdem bestimmen, ob die Konten im
                            Wochen-, Monats- oder Jahresrhythmus saldiert werden sollen.
                        </div>
                    </div>
                `;
            case ExportFormat.RosterExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Dienstplan Excel</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine Darstellung des Dienstplans für den gewählten Zeitraum im
                            Microsoft Excel Format.
                        </div>
                    </div>
                `;
            case ExportFormat.OvertimeExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Überstunden Excel</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine Auflistung der angefallenen Überstunden für den gewählten
                            Zeitraum im Microsoft Excel Format.
                        </div>
                    </div>
                `;
            case ExportFormat.AverageWorkTimeExcel:
                return html`
                    <div class="text-centering">
                        <h2><i class="table"></i> Durchschnittliche Arbeitszeit Excel</h2>
                        <div class="subtle margined">
                            Dieser Export enthält eine Auflistung der durchschnittlichen Arbeitszeit pro Interval im
                            gewählten Zeitraum im Microsoft Excel Format.
                        </div>
                    </div>
                `;
        }
    }

    private _renderMenu() {
        return html`
            <div class="vertical tabs">
                <div class="subtle margined">
                    <i class="hand-holding-dollar"></i>
                    Lohnbuchhaltung
                </div>
                <button
                    ?active=${this._page === ExportFormat.PayrollReportDetailed}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollReportDetailed}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollReportDetailed)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lohnbericht (Detailliert)</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollReportOverview}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollReportOverview}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollReportOverview)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lohnbericht (Übersicht)</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollDatevLG}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollDatevLG}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollDatevLG)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">DATEV Lohn & Gehalt</div>
                        <i class="subtle file-text"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollDatevLodas}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollDatevLodas}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollDatevLodas)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">DATEV Lodas</div>
                        <i class="subtle file-text"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollAgenda}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollAgenda}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollAgenda)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Agenda</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollLexware}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollLexware}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollLexware)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lexware</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollAddison}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollAddison}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollAddison)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Addison</div>
                        <i class="subtle file-text"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.PayrollLohnAG}
                    @click=${() => this.go(`exports/${ExportFormat.PayrollLohnAG}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.PayrollLohnAG)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lohn-AG</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                ${app.company?.country === "AT"
                    ? html`
                          <button
                              ?active=${this._page === ExportFormat.PayrollBMDWages}
                              @click=${() => this.go(`exports/${ExportFormat.PayrollBMDWages}`, this.dateRange!)}
                              ?disabled=${!this._hasPermission(ExportFormat.PayrollBMDWages)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">BMD Lohndaten</div>
                                  <i class="subtle file-csv"></i>
                              </div>
                          </button>
                          <button
                              ?active=${this._page === ExportFormat.PayrollBMDAbsences}
                              @click=${() => this.go(`exports/${ExportFormat.PayrollBMDAbsences}`, this.dateRange!)}
                              ?disabled=${!this._hasPermission(ExportFormat.PayrollBMDAbsences)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">BMD Nichtleistungszeiten</div>
                                  <i class="subtle file-csv"></i>
                              </div>
                          </button>
                      `
                    : ""}

                <div class="subtle margined">
                    <i class="list-timeline"></i>
                    Arbeitszeiten
                </div>
                <button
                    ?active=${this._page === ExportFormat.TimeSheet}
                    @click=${() => this.go(`exports/${ExportFormat.TimeSheet}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.TimeSheet)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Arbeitszeit-Nachweis</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button @click=${() => this.go("exports/time_entries_generic")} hidden>
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Arbeitszeiten Tabellarisch</div>
                        <i class="subtle file-csv"></i>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <div class="subtle margined">
                    <i class="table"></i>
                    Dienstplan
                </div>
                <button
                    ?active=${this._page === ExportFormat.RosterExcel}
                    @click=${() => this.go(`exports/${ExportFormat.RosterExcel}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.RosterExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Dienstplan</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <div class="subtle margined">
                    <i class="scale-balanced"></i>
                    Konten
                </div>

                <button
                    ?active=${this._page === ExportFormat.BalancesTimeExcel}
                    @click=${() => this.go(`exports/${ExportFormat.BalancesTimeExcel}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.BalancesTimeExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Arbeitszeitkonto</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <button
                    ?active=${this._page === ExportFormat.BalancesVacationExcel}
                    @click=${() => this.go(`exports/${ExportFormat.BalancesVacationExcel}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.BalancesVacationExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Urlaubskonto</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                <button
                    ?active=${this._page === ExportFormat.BalancesBonusesExcel}
                    @click=${() => this.go(`exports/${ExportFormat.BalancesBonusesExcel}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.BalancesBonusesExcel)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Zuschlagskonto</div>
                        <i class="subtle file-excel"></i>
                    </div>
                </button>

                ${app.company?.country === "AT"
                    ? html`
                          <button
                              ?active=${this._page === ExportFormat.OvertimeExcel}
                              @click=${() => this.go(`exports/${ExportFormat.OvertimeExcel}`, this.dateRange!)}
                              ?disabled=${!this._hasPermission(ExportFormat.OvertimeExcel)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">Überstunden</div>
                                  <i class="subtle file-excel"></i>
                              </div>
                          </button>

                          <button
                              ?active=${this._page === ExportFormat.AverageWorkTimeExcel}
                              @click=${() => this.go(`exports/${ExportFormat.AverageWorkTimeExcel}`, this.dateRange!)}
                              ?disabled=${!this._hasPermission(ExportFormat.AverageWorkTimeExcel)}
                          >
                              <div class="horizontal center-aligning layout">
                                  <div class="stretch">Durchschnittliche Arbeitszeit</div>
                                  <i class="subtle file-excel"></i>
                              </div>
                          </button>
                      `
                    : ""}

                <div class="subtle margined">
                    <i class="address-card"></i>
                    Stammdaten
                </div>
                <button @click=${() => this.go("exports/master_data_generic")} hidden>
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Tabellarisch</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.EmployeeDataPDF}
                    @click=${() => this.go(`exports/${ExportFormat.EmployeeDataPDF}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.EmployeeDataPDF)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Datenblatt</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.EmployeeDataCSV}
                    @click=${() => this.go(`exports/${ExportFormat.EmployeeDataCSV}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.EmployeeDataCSV)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">CSV-Export</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>

                <div class="subtle margined">
                    <i class="book"></i>
                    Kassenbuch
                </div>
                <button
                    ?active=${this._page === ExportFormat.CashbookPDF}
                    @click=${() => this.go(`exports/${ExportFormat.CashbookPDF}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.CashbookPDF)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Ausdruck / PDF</div>
                        <i class="subtle file-pdf"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.CashbookDatev}
                    @click=${() => this.go(`exports/${ExportFormat.CashbookDatev}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.CashbookDatev)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">DATEV Buchungsstapel</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.CashbookDatevOnline}
                    @click=${() => this.go(`exports/${ExportFormat.CashbookDatevOnline}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.CashbookDatevOnline)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Datev Online</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.CashbookLexware}
                    @click=${() => this.go(`exports/${ExportFormat.CashbookLexware}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.CashbookLexware)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Lexware</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
                <button
                    ?active=${this._page === ExportFormat.CashbookGeneric}
                    @click=${() => this.go(`exports/${ExportFormat.CashbookGeneric}`, this.dateRange!)}
                    ?disabled=${!this._hasPermission(ExportFormat.CashbookGeneric)}
                >
                    <div class="horizontal center-aligning layout">
                        <div class="stretch">Generisch</div>
                        <i class="subtle file-csv"></i>
                    </div>
                </button>
            </div>
        `;
    }

    render() {
        if (!app.company) {
            return html``;
        }

        const rangeSuggestions: { from: string; to: string; label: string }[] = [];

        let date = toDateString(new Date());

        for (let i = 0; i < 12; i++) {
            rangeSuggestions.push({
                label: formatMonthShort(date),
                ...getRange(date, "month"),
            });
            date = dateAdd(date, { months: -1 });
        }

        return html`
            <div class="sub-menu scrolling">${this._renderMenu()}</div>

            <div class="main">
                <ptc-scroller class="fullbleed">
                    <div class="double-padded">
                        <div style="max-width: 36em; margin: auto;">
                            ${this._renderHeader()}

                            <form
                                @submit=${this._submit}
                                @change=${() => this.requestUpdate()}
                                ?disabled=${this._loading || !!this._exportResponse}
                            >
                                <div class="horizontal spacing layout">
                                    ${!this._page.startsWith("employee_data")
                                        ? html`
                                              <div class="field stretch">
                                                  <label>Zeitraum</label>
                                                  <ptc-date-range-picker
                                                      @range-selected=${(e: CustomEvent<DateRange>) =>
                                                          this.go(null, e.detail)}
                                                      .range=${this.dateRange}
                                                      class="box"
                                                  ></ptc-date-range-picker>
                                              </div>
                                          `
                                        : ""}
                                    ${this._page.startsWith("balances")
                                        ? html`
                                              <div class="field">
                                                  <label>Auflösung</label>
                                                  <select class="slim" name="resolution">
                                                      <option .value=${"week"}>Woche</option>
                                                      <option .value=${"month"} selected>Monat</option>
                                                      <option .value=${"year"}>Jahr</option>
                                                  </select>
                                              </div>
                                          `
                                        : ""}
                                </div>
                                ${this._page.startsWith("payroll") ||
                                this._page.startsWith("employee_data") ||
                                this._page.startsWith("balances") ||
                                this._page === ExportFormat.TimeSheet ||
                                this._page === ExportFormat.RosterExcel
                                    ? html`
                                          <div class="field">
                                              <label>Mitarbeiter</label>
                                              <div class="focus-container horizontal center-aligning layout">
                                                  ${this._page.startsWith("employee_data")
                                                      ? html`
                                                            <ptc-entity-filters
                                                                hideFilterIcon
                                                                .filterTypes=${[
                                                                    "employmentType",
                                                                    "venue",
                                                                    "department",
                                                                    "position",
                                                                    "employeeId",
                                                                    "employeeStatus",
                                                                ] as const}
                                                                class="stretch"
                                                            ></ptc-entity-filters>
                                                        `
                                                      : html`
                                                            <ptc-entity-filters
                                                                hideFilterIcon
                                                                .filterTypes=${[
                                                                    "employmentType",
                                                                    "venue",
                                                                    "department",
                                                                    "position",
                                                                    "employeeId",
                                                                    "costCenter",
                                                                ] as const}
                                                                class="stretch"
                                                            ></ptc-entity-filters>
                                                        `}
                                                  <button
                                                      class="skinny transparent"
                                                      title="Sortierung"
                                                      style="margin-right: 0.25em;"
                                                      type="button"
                                                  >
                                                      <i class="arrow-down-wide-short"></i>
                                                  </button>
                                                  <ptc-popover>
                                                      <div class="horizontal spacing center-aligning layout">
                                                          <div>
                                                              <label>Sortieren nach...</label>
                                                              <select class="semibold small slim" name="sortProperty">
                                                                  <option value="lastName" selected>Nachname</option>
                                                                  <option value="firstName">Vorname</option>
                                                                  <option value="staffNumber">Personalnummer</option>
                                                                  <option value="birthday">Geburtstag</option>
                                                                  <option value="birthdayDate">
                                                                      Geburtstag (Datum)
                                                                  </option>
                                                                  <option value="email">Email</option>
                                                              </select>
                                                          </div>
                                                          <div>
                                                              <label>&nbsp;</label>
                                                              <select class="semibold small slim" name="sortDirection">
                                                                  <option value="ascending" selected>
                                                                      Aufsteigend
                                                                  </option>
                                                                  <option value="descending">Absteigend</option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                  </ptc-popover>
                                              </div>
                                          </div>
                                      `
                                    : ""}
                                ${this._page.startsWith("cashbook")
                                    ? html`
                                          <div class="field">
                                              <label>Standort</label>
                                              <select name="venueId">
                                                  ${app.accessibleVenues.map(
                                                      (venue) => html`
                                                          <option .value=${venue.id.toString()}>${venue.name}</option>
                                                      `
                                                  )}
                                              </select>
                                          </div>
                                      `
                                    : ""}

                                <div class="field">
                                    <label>Versenden An</label>
                                    <input type="email" placeholder="Emailaddresse" name="sendAsEmail" />
                                </div>

                                ${[ExportFormat.PayrollReportDetailed, ExportFormat.TimeSheet].includes(this._page) &&
                                app.hasPermission("manage.employees.documents")
                                    ? html`
                                          <div class="field">
                                              <ptc-checkbox-button
                                                  label="Im Dokumentenarchiv ablegen"
                                                  buttonClass="semislim ghost"
                                                  name="saveToDocuments"
                                              ></ptc-checkbox-button>
                                          </div>

                                          ${this._page === ExportFormat.TimeSheet
                                              ? html`
                                                    <div class="field" ?hidden=${!this._exportFormData.saveToDocuments}>
                                                        <ptc-checkbox-button
                                                            label="Unterschrift Anfordern"
                                                            buttonClass="semislim ghost"
                                                            name="requireEmployeeSignature"
                                                        ></ptc-checkbox-button>
                                                    </div>
                                                `
                                              : ""}

                                          <div class="field" ?hidden=${!this._exportFormData.saveToDocuments}>
                                              <label>Kategorien</label>
                                              <ptc-document-tags-input></ptc-document-tags-input>
                                          </div>
                                      `
                                    : ""}

                                <div class="double-margined vertical layout">
                                    <button class="primary"><i class="download"></i> Export Starten</button>
                                </div>
                            </form>

                            ${this._exportResponse?.messages?.length
                                ? html`
                                      <div class="double-margined box">
                                          <div class="half-padded centering spacing horizontal layout">
                                              <button
                                                  class="transparent skinny"
                                                  ?active=${this._displayInfoMessages}
                                                  @click=${() =>
                                                      (this._displayInfoMessages = !this._displayInfoMessages)}
                                              >
                                                  <i class="info-circle"></i>
                                                  ${this._exportResponse.messages.filter((m) => m.type === "info")
                                                      .length}
                                              </button>
                                              <button
                                                  class="transparent skinny orange"
                                                  ?active=${this._displayWarningMessages}
                                                  @click=${() =>
                                                      (this._displayWarningMessages = !this._displayWarningMessages)}
                                              >
                                                  <i class="exclamation-triangle"></i>
                                                  ${this._exportResponse.messages.filter((m) => m.type === "warning")
                                                      .length}
                                              </button>
                                              <button
                                                  class="transparent skinny red"
                                                  ?active=${this._displayErrorMessages}
                                                  @click=${() =>
                                                      (this._displayErrorMessages = !this._displayErrorMessages)}
                                              >
                                                  <i class="hexagon-exclamation"></i>
                                                  ${this._exportResponse.messages.filter((m) => m.type === "error")
                                                      .length}
                                              </button>
                                          </div>
                                          <ptc-scroller style="max-height: 20em">
                                              <div class="double-padded">
                                                  <ul class="smaller">
                                                      ${this._exportResponse.messages
                                                          .filter(
                                                              (m) =>
                                                                  (m.type === "info" && this._displayInfoMessages) ||
                                                                  (m.type === "warning" &&
                                                                      this._displayWarningMessages) ||
                                                                  (m.type === "error" && this._displayErrorMessages)
                                                          )
                                                          .map(
                                                              ({ type, content }) =>
                                                                  html`<li
                                                                      class="${type === "error"
                                                                          ? "red colored-text"
                                                                          : type === "warning"
                                                                            ? "orange colored-text"
                                                                            : ""}"
                                                                      style="text-indent: -1.3em; margin-left: 1.3em; margin-bottom: 0.2em;"
                                                                  >
                                                                      <i
                                                                          class="bold inline ${type === "info"
                                                                              ? "info-circle"
                                                                              : type === "warning"
                                                                                ? "triangle-exclamation"
                                                                                : "hexagon-exclamation"}"
                                                                      ></i>
                                                                      ${content}
                                                                  </li>`
                                                          )}
                                                  </ul>
                                              </div>
                                          </ptc-scroller>
                                      </div>
                                  `
                                : ""}
                            ${this._loading || this._exportResponse
                                ? html`
                                      <div class="double-margined centering vertical layout">
                                          <i class="arrow-down"></i>
                                      </div>
                                  `
                                : ""}

                            <div id="responseAnchor"></div>

                            <div
                                class="double-margined double-padded centering text-centering horizontal layout box"
                                ?hidden=${!this._loading}
                            >
                                <ptc-spinner ?active=${!!this._loading} class="tiny"></ptc-spinner>
                                <div class="subtle left-margined">${this._loading}</div>
                            </div>

                            ${this._exportResponse && !this._loading
                                ? html`
                                      <div class="margined text-centering">
                                          <a
                                              href="${this._exportResponse.url}"
                                              target="_blank"
                                              class="margined block"
                                              download
                                          >
                                              <i class="${fileIcon(this._exportResponse.type)}"></i> ${this
                                                  ._exportResponse.fileName}
                                              <span class="small subtle"
                                                  >${formatFileSize(this._exportResponse.size)}</span
                                              >
                                          </a>

                                          <div class="small margined spacing centering horizontal layout">
                                              <button
                                                  @click=${() => window.open(this._openFileUrl, "_blank")}
                                                  ?hidden=${this._exportResponse!.type === MimeType.Zip}
                                                  class="slim ghost"
                                              >
                                                  <i class="eye"></i> Öffnen
                                              </button>
                                              <button @click=${this._downloadResponse} class="slim ghost">
                                                  <i class="download"></i> Downloaden
                                              </button>
                                              <button
                                                  @click=${this._printResponse}
                                                  class="slim ghost"
                                                  ?hidden=${![MimeType.PDF, MimeType.Zip].includes(
                                                      this._exportResponse.type
                                                  )}
                                              >
                                                  <i class="print"></i> Drucken
                                              </button>
                                          </div>
                                      </div>

                                      <div class="double-margined vertical layout">
                                          <button class="transparent" @click=${() => (this._exportResponse = null)}>
                                              Neuer Export...
                                          </button>
                                      </div>
                                  `
                                : ""}
                        </div>
                    </div>
                </ptc-scroller>
            </div>
        `;
    }
}
