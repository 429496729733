import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { RevenueEntry, RevenueType, RevenueGroup, RevenuePrediction } from "@pentacode/core/src/model";
import {
    GetRevenueEntriesParams,
    GetRevenuePredictionsParams,
    UpdateRevenuePredictionsParams,
    GetRevenueGroupsParams,
} from "@pentacode/core/src/api";
import {
    parseDateString,
    toDateString,
    getRange,
    dateAdd,
    formatDate,
    formatWeekDay,
    formatNumber,
} from "@pentacode/core/src/util";
import { getHolidayForDate } from "@pentacode/core/src/holidays";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared, colors } from "../styles";
import { alert } from "./alert-dialog";
import "./scroller";
import "./popover";
import { Balance } from "./balance";
import "./spinner";
import { DateString, Euros } from "@pentacode/openapi";

@customElement("ptc-planning-revenue")
export class RevenuesPlanning extends Routing(StateMixin(LitElement)) {
    routePattern = /^planning\/revenue/;

    get routeTitle() {
        return this.venue ? `Umsatzplanung: ${this.venue.name}` : undefined;
    }

    @state()
    private _loading = false;

    @state()
    private _predictions: RevenuePrediction[] = [];

    @state()
    private _prevEntries: RevenueEntry[] = [];

    @state()
    private _currPredictions: RevenuePrediction[] = [];

    @state()
    private _groups: RevenueGroup[] = [];

    @state()
    private _calcAverage = false;

    @query(".calc-average-form")
    private _calcAverageForm: HTMLFormElement;

    private get _dates() {
        const { from, to } = getRange(this.date, "week");
        let d = from;
        const dates: DateString[] = [];
        while (d < to) {
            dates.push(d);
            d = dateAdd(d, { days: 1 });
        }
        return dates;
    }

    private get _calcAverageSettings() {
        const dates = this._dates;

        if (!this._calcAverageForm) {
            return {
                weeks: 4,
                adjustments: dates.map((date) => ({
                    date,
                    factor: 0,
                })),
            };
        }

        const data = new FormData(this._calcAverageForm);

        return {
            weeks: Number(data.get("weeks") as string),
            adjustments: dates.map((date) => ({
                date,
                factor: Number(data.get("adjustment-" + date) as string),
            })),
        };
    }

    private get _departmentData() {
        const data = new Map<
            string,
            {
                total: number;
                distinct: Map<
                    number,
                    {
                        name: string;
                        ratio: number;
                        amount: number;
                    }
                >;
            }
        >();

        for (const entry of this._predictions) {
            for (const att of entry.attributions) {
                const amount = (entry.amount * att.ratio) / 100;
                const depKey = `${att.department}_${entry.date}`;

                if (!data.has(depKey)) {
                    data.set(depKey, {
                        total: 0,
                        distinct: new Map<
                            number,
                            {
                                name: string;
                                ratio: number;
                                amount: number;
                            }
                        >(),
                    });
                }

                const depData = data.get(depKey)!;

                depData.total += amount;

                if (!depData.distinct.has(entry.groupId)) {
                    depData.distinct.set(entry.groupId, {
                        name: entry.group.name,
                        amount: 0,
                        ratio: att!.ratio,
                    });
                }

                depData.distinct.get(entry.groupId)!.amount += amount;
            }
        }

        return data;
    }

    async handleRoute() {
        //Invalid date
        if (!this.date || !parseDateString(this.date)) {
            this.go(null, { ...this.router.params, date: toDateString(new Date()) }, true);
            return;
        }

        await this._load();
    }

    private async _load() {
        this._loading = true;

        try {
            const currWeek = getRange(this.date, "week");
            const firstWeek = getRange(dateAdd(this.date, { days: -13 * 7 }), "week");

            const [groups, prev, existing] = await Promise.all([
                app.api.getRevenueGroups(
                    new GetRevenueGroupsParams({
                        venue: this.venueId,
                        type: [RevenueType.Sales],
                        reporting: true,
                        excludeSuggestions: true,
                    })
                ),
                app.api.getRevenueEntries(
                    new GetRevenueEntriesParams({
                        venue: this.venueId,
                        type: [RevenueType.Sales],
                        reporting: true,
                        from: firstWeek.from,
                        to: currWeek.from,
                    })
                ),
                app.api.getRevenuePredictions(
                    new GetRevenuePredictionsParams({
                        venue: this.venueId,
                        ...currWeek,
                    })
                ),
            ]);

            this._groups = groups;
            this._prevEntries = prev;
            this._currPredictions = existing;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;

        this._calcAverage = !this._currPredictions.length;
        await this.updateComplete;
        this._resetCalcAverageSettings();
        this._loadData();
    }

    private async _loadData() {
        this._predictions = this._calcAverage ? await this._loadAverage() : await this._loadExisting();
        await this.updateComplete;
        this._fill();
    }

    private async _loadExisting() {
        const existing = this._currPredictions;

        const predictions: RevenuePrediction[] = [];

        for (const group of this._groups) {
            for (const date of this._dates) {
                const entry =
                    existing.find((e) => e.groupId === group.id && e.date === date) ||
                    new RevenuePrediction({
                        groupId: group.id,
                    });
                entry.group = group;
                entry.date = date;
                predictions.push(entry);
            }
        }

        return predictions;
    }

    private async _loadAverage() {
        const settings = this._calcAverageSettings;
        const { from } = getRange(dateAdd(this.date, { days: -7 * settings.weeks }), "week");

        const previous = this._prevEntries.filter((e) => e.date >= from);

        const predictions: RevenuePrediction[] = [];

        for (const group of this._groups) {
            for (const date of this._dates) {
                const weekday = parseDateString(date)!.getDay();
                const prev = previous.filter(
                    (e) => e.groupId === group.id && parseDateString(e.date)!.getDay() === weekday
                );
                const entry =
                    this._predictions.find((e) => e.groupId === group.id && e.date === date) ||
                    new RevenuePrediction({
                        groupId: group.id,
                    });
                entry.group = group;
                const adjustment = settings.adjustments.find((a) => a.date === date) || { factor: 0 };
                const average = prev.length ? prev.reduce((total, e) => total + e.amount, 0) / prev.length : 0;
                entry.amount = (average + (average * adjustment.factor) / 100) as Euros;
                entry.date = date;
                predictions.push(entry);
            }
        }

        return predictions;
    }

    private _fill() {
        for (const entry of this._predictions) {
            const input = this.renderRoot!.querySelector(
                `input[data-group="${entry.groupId}"][data-date="${entry.date}"]`
            ) as HTMLInputElement;
            input && (input.value = entry.amount.toFixed(2));
        }
    }

    private _toggleCalcAverage() {
        this._calcAverage = !this._calcAverage;
        this._loadData();
    }

    private _resetCalcAverageSettings() {
        const weekSelector = this.renderRoot!.querySelector("select[name='weeks']") as HTMLSelectElement;
        weekSelector && (weekSelector.value = "4");

        const adjustmentInputs = this.renderRoot!.querySelectorAll(
            "input[name^='adjustment-']"
        ) as NodeListOf<HTMLInputElement>;
        for (const inp of adjustmentInputs) {
            inp.value = "0";
        }
    }

    private async _save() {
        this._loading = true;

        try {
            await app.api.updateRevenuePredictions(
                new UpdateRevenuePredictionsParams({
                    updated: this._predictions,
                })
            );
            await this._load();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            .inner {
                max-width: 80em;
                display: flex;
                flex-direction: column;
            }

            .scroller {
                flex: 1;
                overflow: auto;
                padding: 1em;
            }

            .scroller-inner {
                margin-bottom: 2em;
            }

            .row {
                display: flex;
                min-width: 60em;
            }

            .row:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }

            .header-row {
                position: sticky;
                top: 0;
                z-index: 2;
                background: var(--color-bg);
            }

            .row > * {
                flex: 1;
                min-width: 0;
            }

            .row > :not(:last-child) {
                border-right: solid 1px var(--shade-2);
            }

            .row-header {
                font-weight: 600;
                color: var(--color-highlight);
                padding: 0.2em;
                box-sizing: border-box;
                position: sticky;
                left: 0;
                z-index: 1;
                background: var(--color-bg);
                padding: 0.5em 1em;
                min-width: 12em;
            }

            .day-header {
                font-weight: bold;
                text-align: center;
            }

            .day-header.holiday {
                color: var(--violet);
            }

            .day-subheader {
                font-size: 80%;
                opacity: 0.7;
                margin: 0.1em 0.5em 0.5em 0.5em;
            }

            .attribution-popover {
                max-width: 20em;
                padding: 0;
            }

            .attribution {
                border-radius: var(--border-radius);
                padding: 0.5em;
                margin: 0.5em;
                color: var(--color-highlight);
                border: solid 1px;
            }

            .attribution-department {
                font-weight: 600;
            }

            .attribution-ratio {
                opacity: 0.7;
                margin: 0 0.5em;
                flex: none;
            }

            .attribution-amount {
                font-weight: bold;
                flex: none;
                margin-left: 0.5em;
            }

            .attribution-header {
                text-align: center;
                margin: 0.7em;
                font-weight: 600;
            }

            .attribution-empty {
                margin: 0.5em;
            }

            .attribution-empty div {
                padding: 0.5em 0.8em;
                opacity: 0.7;
            }

            .attribution-empty button {
                width: 100%;
                margin-top: 0.5em;
            }

            .data-row input {
                text-align: right;
                border: none;
                border-radius: 0;
            }

            .data-row input:focus {
                background: var(--blue-bg);
            }

            .row.adjustments-row,
            .row.adjustments-row > * {
                border: none;
            }

            .calc-average-form {
                margin-bottom: 2em;
            }

            .calc-average-weeks {
                margin-bottom: 0.5em;
                font-size: var(--font-size-medium);
                font-weight: 600;
            }

            .calc-average-weeks select {
                padding-top: 0.3em;
                padding-bottom: 0.3em;
                margin: 0 0.5em;
            }

            .calc-average-adjustment {
                padding: 0.5em;
            }

            .calc-average-adjustment input {
                width: 100%;
            }

            .calc-average-adjustment label {
                font-size: var(--font-size-large);
                margin-bottom: 0.5em;
            }

            .department-row {
                color: var(--color-highlight);
            }

            .no-groups {
                margin: 4em 2em;
                text-align: center;
            }

            .no-groups > button {
                margin-top: 1em;
            }
        `,
    ];

    render() {
        if (!this.venue || !this.date) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        const dates = this._dates;
        const depData = this._departmentData;

        if (!this._groups.length) {
            return html`
                <div class="no-groups">
                    <div>Sie haben noch keine Umsatzgruppen definiert.</div>

                    <button @click=${() => this.go("revenues/groups")}>
                        Jetzt Anlegen <i class="arrow-right"></i>
                    </button>
                </div>
            `;
        }

        const departments = this.venue.departments.filter((dep) =>
            dates.some((date) => depData.has(`${dep.id}_${date}`))
        );

        return html`
            <div class="fullbleed vertical layout">
                <div class="scroller stretch">
                    <div class="scroller-inner">
                        <form class="calc-average-form" @input=${this._loadData} ?hidden=${!this._calcAverage}>
                            <div class="horizontal center-aligning center-justifying layout calc-average-weeks">
                                <div>Durchschnitt der letzten</div>
                                <select name="weeks">
                                    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                                        (val) => html` <option .value=${val.toString()}>${val}</option> `
                                    )}
                                </select>
                                <div>Wochen</div>
                            </div>

                            <div class="row adjustments-row">
                                <div class="row-header"></div>
                                ${dates.map((date) => {
                                    const adjustment = this._calcAverageSettings.adjustments.find(
                                        (s) => s.date === date
                                    )!;

                                    return html`
                                        <div
                                            class="vertical center-aligning center-justifying layout calc-average-adjustment"
                                        >
                                            <label>
                                                <ptc-balance
                                                    .value=${adjustment.factor}
                                                    .decimals=${0}
                                                    icon="percent"
                                                ></ptc-balance>
                                            </label>

                                            <input
                                                type="range"
                                                min="-100"
                                                max="100"
                                                step="1"
                                                name="adjustment-${date}"
                                                .value=${"0"}
                                            />
                                        </div>
                                    `;
                                })}
                            </div>

                            <div class="medium horizontal center-aligning center-justifying layout padded-full" hidden>
                                <button class="transparent">
                                    <i class="arrow-down"></i>
                                    Berechnen
                                    <i class="arrow-down"></i>
                                </button>
                            </div>
                        </form>

                        <div>
                            <div class="row header-row">
                                <div class="row-header"></div>
                                ${dates.map((date) => {
                                    const holiday = getHolidayForDate(date, {
                                        holidays: app.venues[0]?.enabledHolidays,
                                        country: app.company!.country,
                                    });
                                    return html`
                                        <div
                                            class=${classMap({
                                                "day-header": true,
                                                holiday: !!holiday,
                                            })}
                                        >
                                            ${formatWeekDay(date)}
                                            <div class="day-subheader ellipsis">
                                                ${holiday ? holiday.name : formatDate(date)}
                                            </div>
                                        </div>
                                    `;
                                })}
                            </div>

                            ${this._groups.map(
                                ({ name, id }) => html`
                                    <div class="row data-row">
                                        <div class="row-header ellipsis">${name}</div>

                                        ${dates.map((date) => {
                                            const entry = this._predictions.find(
                                                (e) => e.groupId === id && e.date === date
                                            );
                                            if (!entry) {
                                                return;
                                            }
                                            return html`
                                                <div>
                                                    <form>
                                                        <div class="input">
                                                            <input
                                                                data-group=${entry.groupId}
                                                                data-date=${date}
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                                name="amount"
                                                                autocomplete="off"
                                                                @input=${(e: Event) => {
                                                                    entry.amount = Number(
                                                                        (e.target as HTMLInputElement).value
                                                                    ) as Euros;
                                                                    this.requestUpdate();
                                                                }}
                                                            />
                                                        </div>
                                                    </form>

                                                    <ptc-popover
                                                        trigger="focus"
                                                        class="attribution-popover"
                                                        .preferAlignment=${["right"]}
                                                    >
                                                        <div class="attribution-header">Zuordnung</div>

                                                        ${entry.attributions.length
                                                            ? entry.attributions.map(({ department: depId, ratio }) => {
                                                                  const { department } = app.getDepartment(depId);
                                                                  if (!department) {
                                                                      return;
                                                                  }
                                                                  return html`
                                                                      <div
                                                                          class="attribution horizontal center-aligning layout"
                                                                          style="--color-highlight: ${colors[
                                                                              department.color
                                                                          ] || department.color}"
                                                                      >
                                                                          <div
                                                                              class="horizontal center-aligning layout"
                                                                          >
                                                                              <div
                                                                                  class="stretch ellipsis attribution-department"
                                                                              >
                                                                                  ${department.name}
                                                                              </div>
                                                                              <div class="attribution-ratio">
                                                                                  ${ratio} %
                                                                              </div>
                                                                          </div>
                                                                          <div class="stretch"></div>
                                                                          <div class="attribution-amount">
                                                                              ${formatNumber(
                                                                                  (ratio / 100) * entry.amount,
                                                                                  2
                                                                              )}
                                                                              €
                                                                          </div>
                                                                      </div>
                                                                  `;
                                                              })
                                                            : html`
                                                                  <div class="attribution-empty">
                                                                      <div>
                                                                          Für diese Umsatzart wurde noch keine
                                                                          Abteilungszuordnung definiert.
                                                                      </div>

                                                                      <button
                                                                          @click=${() =>
                                                                              this.go("revenues/attribution")}
                                                                      >
                                                                          Zuordnung Definieren
                                                                      </button>
                                                                  </div>
                                                              `}
                                                    </ptc-popover>
                                                </div>
                                            `;
                                        })}
                                    </div>
                                `
                            )}
                        </div>

                        <div class="medium padded-full pad-children divider">
                            <i class="arrow-down"></i>
                            <div>Umsätze pro Abteilung</div>
                            <i class="arrow-down"></i>
                        </div>

                        <div>
                            ${departments.length
                                ? departments.map((dep) => {
                                      if (!dates.some((date) => depData.has(`${dep.id}_${date}`))) {
                                          return;
                                      }
                                      return html`
                                          <div
                                              class="row department-row data-row"
                                              style="--color-highlight: ${colors[dep.color] || dep.color}"
                                          >
                                              <div class="row-header ellipsis">${dep.name}</div>

                                              ${dates.map((date) => {
                                                  const data = depData.get(`${dep.id}_${date}`) || {
                                                      total: 0,
                                                      distinct: [],
                                                  };
                                                  return html`
                                                      <div>
                                                          <div class="input">
                                                              <input
                                                                  type="number"
                                                                  .value=${data.total.toFixed(2)}
                                                                  step="0.01"
                                                                  readonly
                                                              />
                                                          </div>

                                                          <ptc-popover
                                                              class="attribution-popover"
                                                              trigger="hover"
                                                              .preferAlignment=${["right"]}
                                                          >
                                                              ${[...data.distinct.values()].map(({ name, amount }) => {
                                                                  return html`
                                                                      <div
                                                                          class="attribution horizontal center-aligning layout"
                                                                      >
                                                                          <div
                                                                              class="stretch ellipsis attribution-department"
                                                                          >
                                                                              ${name}
                                                                          </div>
                                                                          <div class="stretch"></div>
                                                                          <div class="attribution-amount">
                                                                              ${formatNumber(amount)} €
                                                                          </div>
                                                                      </div>
                                                                  `;
                                                              })}
                                                          </ptc-popover>
                                                      </div>
                                                  `;
                                              })}
                                          </div>
                                      `;
                                  })
                                : html`
                                      <div class="no-groups">
                                          <div>Sie haben noch keine Erlöszuordnung definiert.</div>

                                          <button @click=${() => this.go("revenues/attribution")}>
                                              Jetzt Definieren <i class="arrow-right"></i>
                                          </button>
                                      </div>
                                  `}
                        </div>
                    </div>
                </div>

                <div class="horizontal center-aligning layout padded-medium pad-children noprint border-top">
                    <button class=${this._calcAverage ? "primary" : "transparent"} @click=${this._toggleCalcAverage}>
                        <i class="empty-set"></i>
                    </button>
                    <div class="stretch"></div>
                    <button class="transparent" @click=${this._load}>Zurücksetzen</button>
                    <button class="primary" @click=${this._save}>Speichern</button>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
