import { Textarea } from "./textarea";
import "./scroller";
import { html, css } from "lit";
import { customElement, property, state, query, queryAll } from "lit/decorators.js";
import { RosterNote } from "@pentacode/core/src/model";
import { DateInput } from "./date-input";
import { dateAdd } from "@pentacode/core/src/util";
import { app } from "../init";
import { alert, confirm } from "./alert-dialog";
import { ColorInput } from "./color-input";
import { colors } from "../styles";
import { Checkbox } from "./checkbox";
import autosize from "autosize";
import { Popover } from "./popover";
import "./spinner";
import { DateString } from "@pentacode/openapi";

@customElement("ptc-roster-note-popover")
export class RosterNotePopover extends Popover {
    @property({ attribute: false })
    rosterNote: RosterNote | null = null;

    @state()
    private _original?: Partial<RosterNote>;

    @state()
    private _loading = false;

    @query("form")
    private _form: HTMLFormElement;

    @query("[name='start']")
    private _startInput: DateInput;

    @query("[name='end']")
    private _endInput: DateInput;

    @query("[name='text']")
    private _textInput: HTMLTextAreaElement;

    @query("[name='color']")
    private _colorInput: ColorInput;

    @queryAll("[name='departments']")
    private _departmentCheckboxes: Checkbox[];

    private get _data() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);
        return {
            start: data.get("start") as string,
            end: dateAdd(data.get("end") as DateString, { days: 1 }),
            text: data.get("text") as string,
            color: (data.get("color") as string) || null,
            departments: data.getAll("departments") as string[],
        };
    }

    updated(changes: Map<string, unknown>) {
        if (changes.has("rosterNote")) {
            this._original = { ...this.rosterNote };
            this._fill();
            this._textInput?.focus();
        }
    }

    hide(deleted?: boolean) {
        if (this.rosterNote && this._original) {
            Object.assign(this.rosterNote, this._original);
        }
        this.dispatchEvent(new CustomEvent("change"));
        this.dispatchEvent(new CustomEvent("done", { detail: { deleted: deleted || !this.rosterNote?.id } }));
        super.hide();
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.updateComplete;
        autosize(this._textInput);
    }

    private _fill() {
        if (!this.rosterNote) {
            return;
        }
        this._startInput.value = this.rosterNote.start;
        this._endInput.value = this.rosterNote.end ? dateAdd(this.rosterNote.end, { days: -1 }) : null;
        this._textInput.value = this.rosterNote.text || "";
        this._colorInput.value = this.rosterNote.color || "";
        for (const checkbox of this._departmentCheckboxes) {
            checkbox.checked = !this.rosterNote.departments || this.rosterNote.departments.includes(checkbox.value);
        }
        autosize.update(this._textInput);
    }

    private async _submit(e: Event) {
        e.preventDefault();

        if (!this.rosterNote) {
            return;
        }

        this._loading = true;

        try {
            const note = await app.api.createOrUpdateRosterNote(this.rosterNote);
            Object.assign(this.rosterNote, note);
            this._original = undefined;
            this.hide();
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _deleteRosterNote() {
        setTimeout(() => (this._ignoreNextClick = true), 50);
        if (
            !(await confirm("Wollen Sie diese Dienstplannotiz wirklich entfernen?", "Entfernen", "Abbrechen", {
                title: "Dienstplannotiz Entfernen",
                type: "destructive",
                icon: "sticky-note",
            }))
        ) {
            return;
        }

        this._loading = true;

        try {
            await app.api.deleteRosterNote(this.rosterNote!.id);
            this.hide(true);
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _applyChanges() {
        Object.assign(this.rosterNote!, this._data);
        this._endInput.min = this._startInput.value || undefined;
        const firstDepCheckbox = this._departmentCheckboxes[0];
        if (firstDepCheckbox && this.rosterNote) {
            firstDepCheckbox.setCustomValidity(
                this.rosterNote.departments && !this.rosterNote.departments.length
                    ? "Es muss mindestens eine Abteilung gewählt sein!"
                    : ""
            );
        }
        if (this._form.checkValidity()) {
            this.dispatchEvent(new CustomEvent("change"));
        }
    }

    static styles = [
        ...Popover.styles,
        DateInput.styles,
        ColorInput.styles,
        Checkbox.styles,
        Textarea.styles,
        css`
            :host {
                display: block;
                width: 20em;
                --hover-buffer: 0;
            }

            .checkbox-grid {
                display: grid;
                grid-gap: 0.5em;
                grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
            }
        `,
    ];

    renderContent() {
        if (!this.rosterNote) {
            return html``;
        }
        const editing = !!this.rosterNote.id;
        const venue = app.getVenue(this.rosterNote.venueId)!;

        const departments = venue.departments.filter((department) => app.hasAccess({ department }));

        return html`
            <form @submit=${this._submit} class="vertical spacing layout fit-vertically">
                <div class="horizontal start-aligning layout">
                    <div class="horizontally margined stretch subtle">
                        <i class="sticky-note"></i> ${editing ? "Dienstplannotiz Bearbeiten" : "Neue Dienstplannotiz"}
                    </div>
                    <div ?hidden=${!editing}>
                        <button type="button" class="smaller subtle icon" @click=${this._deleteRosterNote}>
                            <i class="trash-alt"></i>
                        </button>
                    </div>
                </div>

                <div class="vertical layout relative">
                    <ptc-textarea
                        name="text"
                        @input=${this._applyChanges}
                        placeholder="Geben Sie hier Ihre Notiz ein..."
                    ></ptc-textarea>
                </div>

                <div class="horizontal spacing layout">
                    <div class="vertical layout stretch">
                        <label class="smaller">Von</label>
                        <ptc-date-input
                            name="start"
                            required
                            @input=${this._applyChanges}
                            datePicker="popover"
                        ></ptc-date-input>
                    </div>
                    <div class="vertical layout stretch">
                        <label class="smaller">Bis</label>
                        <ptc-date-input
                            name="end"
                            required
                            @input=${this._applyChanges}
                            datePicker="popover"
                        ></ptc-date-input>
                    </div>
                    <div class="vertical layout">
                        <label class="smaller">Farbe</label>
                        <ptc-color-input class="huger" name="color" @change=${this._applyChanges}></ptc-color-input>
                    </div>
                </div>

                <div>
                    <label class="smaller">Abteilungen</label>

                    <ptc-scroller style="max-height: 20em">
                        <div class="smaller checkbox-grid">
                            ${departments.map(
                                (dep) => html`
                                    <ptc-checkbox-button
                                        .label=${dep.name}
                                        buttonClass="transparent slim"
                                        name="departments"
                                        .value=${dep.id.toString()}
                                        class="box"
                                        style="--color-highlight: ${colors[dep.color] || dep.color}"
                                        @change=${this._applyChanges}
                                    ></ptc-checkbox-button>
                                `
                            )}
                        </div>
                    </ptc-scroller>
                </div>

                <div class="evenly stretching spacing horizontal layout smaller top-margined">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.hide()}>Abbrechen</button>
                </div>
            </form>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
