import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { ContractChange } from "@pentacode/core/src/contract";
import { formatDate } from "@pentacode/core/src/util";
import { shared } from "../styles";

@customElement("ptc-contract-change")
export class DisplayContractChange extends LitElement {
    @property({ attribute: false })
    change: ContractChange;

    static styles = [
        shared,
        css`
            :host {
                display: block;
            }

            .table {
                display: inline-grid;
                grid-template-columns: auto auto auto;
                grid-gap: 0.5em;
            }

            .line {
                border-right: solid 1px var(--shade-2);
                transform: translate(-50%, 0);
            }
        `,
    ];

    render() {
        return html`
            <div class="horizontal layout left-padded">
                <div class="vertical layout">
                    <div class="line" style="height: 1em"></div>
                    <i
                        class="${this.change.type === "change"
                            ? "pen-circle"
                            : this.change.type === "pause"
                              ? "circle-pause"
                              : this.change.type === "resume"
                                ? "circle-play"
                                : this.change.type === "start"
                                  ? "circle-play"
                                  : "circle-stop"}"
                        style="position: sticky; top: 1em;"
                    ></i>
                    <div class="line stretch"></div>
                </div>

                <div style="width: 14em; padding: 1em 0.5em; position: sticky; top: 0; align-self: start;">
                    <div>${formatDate(this.change.date)}</div>
                    <div class="bold">
                        ${this.change.type === "change"
                            ? "Vertragsänderung"
                            : this.change.type === "pause"
                              ? "Vertragspause"
                              : this.change.type === "resume"
                                ? "Pause beendet"
                                : this.change.type === "start"
                                  ? "Vertragsbeginn"
                                  : "Vertragsende"}
                    </div>
                    ${this.change.contractB?.comment
                        ? html`
                              <div class="small subtle">
                                  <i class="smaller comment"></i> ${this.change.contractB.comment}
                              </div>
                          `
                        : ""}
                </div>

                <div class="double-padded spacing grid stretch" style="--grid-column-width: 20em;">
                    ${this.change.type !== "end"
                        ? html`
                              ${this.change.fields.map(
                                  ({ label, before, after }) => html`
                                      <div>
                                          <div class="blue colored-text">${label}:</div>
                                          <div class="center-aligning spacing wrapping horizontal layout relative">
                                              ${["change", "pause", "resume"].includes(this.change.type)
                                                  ? html`
                                                        <div class="fit-horizontally">${unsafeHTML(before)}</div>
                                                        <div class="bold">
                                                            <i class="arrow-right"></i>
                                                        </div>
                                                    `
                                                  : ""}
                                              ${after
                                                  ? html` <div class="fit-horizontally">${unsafeHTML(after)}</div> `
                                                  : ""}
                                          </div>
                                      </div>
                                  `
                              )}
                          `
                        : ""}
                </div>
            </div>
        `;
    }
}
