import logo from "../img/logo.svg";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { shared, mixins } from "../styles";
import { app, router } from "../init";
import { singleton } from "../lib/singleton";
import { ForgotPasswordDialog } from "./forgot-password-dialog";
import "./spinner";

@customElement("ptc-login")
export class Login extends LitElement {
    @state()
    private _loading = false;

    @state()
    private _error = "";

    get _admin() {
        return typeof router.params.admin !== "undefined";
    }

    @query("#company")
    private _companyInput: HTMLInputElement;

    @query("#email")
    private _emailInput: HTMLInputElement;

    @query("#password")
    private _passwordInput: HTMLInputElement;

    @singleton("ptc-forgot-password-dialog")
    private _forgotPasswordDialog: ForgotPasswordDialog;

    private async _submit(e: FocusEvent) {
        e.preventDefault();
        this._loading = true;
        this._error = "";

        const companyId = parseInt(this._companyInput.value);

        try {
            await app.login({
                email: this._emailInput.value,
                password: this._passwordInput.value,
                scope: "manage",
                company: isNaN(companyId) ? undefined : companyId,
            });
            const { next, ...params } = router.params;
            router.go(next || "", params, true);
        } catch (e) {
            this._error = e.message || "Anmeldung fehlgeschlagen. Bitte versuchen Sie es sp\u00e4ter noch einmal!";
            this._passwordInput.focus();
        }

        this._loading = false;

        await this.updateComplete;
        this._passwordInput.value = "";
    }

    private _forgotPassword() {
        void this._forgotPasswordDialog.show({ email: this._emailInput.value });
    }

    static styles = [
        shared,
        css`
            :host {
                ${mixins.fullbleed()};
                ${mixins.scroll()};
                z-index: 100;
                padding: 2em;
                background: #fafafa;
            }

            .container {
                min-height: 100%;
                text-align: center;
            }

            .card {
                width: 300px;
                background: var(--color-bg);
                border-radius: calc(2 * var(--border-radius));
                padding: 1em;
                color: var(--color-fg);
                margin: 2em;
                box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px -5px;
                position: relative;
                overflow: hidden;
            }

            .logo {
                height: 50px;
            }

            .greeting {
                margin-top: 1em;
                width: 350px;
                text-align: center;
            }

            input {
                text-align: inherit;
            }

            .signup-note {
                font-weight: 300;
                margin: 0.2em;
            }

            .message {
                margin: 0 0 0.8em 0;
            }

            .forgot-password {
                margin: 0.5em 0 -0.5em 0;
                padding: 0.4em;
            }
        `,
    ];

    render() {
        const { _error } = this;

        return html`
            <div class="container vertical center-aligning center-justifying layout">
                <img src="${logo}" class="logo" />

                <div class="greeting">Willkommen bei Pentacode! Bitte melden Sie sich an.</div>

                <div class="card">
                    <form class="vertical layout" @submit=${this._submit}>
                        <div class="field" ?hidden=${!this._admin}>
                            <input
                                type="number"
                                id="company"
                                placeholder="Unternehmens-ID"
                                .value=${router.params.c || router.params.company || ""}
                            />
                        </div>

                        <div class="field">
                            <input
                                type="email"
                                id="email"
                                placeholder="E-mail Adresse"
                                required
                                .value=${router.params.email || ""}
                            />
                        </div>

                        <div class="field">
                            <input type="password" id="password" placeholder="Passwort" required />
                        </div>

                        <div class="negative message" ?hidden=${!_error}>${_error}</div>

                        <button type="submit" class="primary">Login</button>
                    </form>
                </div>

                <button class="small transparent forgot-password" @click=${this._forgotPassword}>
                    Passwort Vergessen?
                </button>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
