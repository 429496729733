import { LitElement, html, css, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { formatDate, formatNumber, formatRange, getRange } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import { alert } from "./alert-dialog";
import "./date-range-picker";
import "./avatar";
import "./employees-filter";
import "./spinner";
import "./popover";
import { BonusesBalance } from "@pentacode/core/src/model";
import { virtualize } from "@lit-labs/virtualizer/virtualize.js";
import { EmployeeSortProperty, serializeFilters } from "@pentacode/core/src/filters";
import { GetBalancesParams } from "@pentacode/core/src/api";
import { Balance } from "./balance";
import { DateRange, sumBonusesBalances } from "@pentacode/core/src/time";
import { popover } from "../directives/popover";
import { singleton } from "../lib/singleton";
import { ResetBalancesDialog } from "./reset-balances-dialog";
import { RecalculateResultsDialog } from "./recalcuate-results-dialog";

@customElement("ptc-employees-bonuses-balance-all")
export class EmployeesBonusesBalanceAll extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/all\/bonuses_balance/;

    get routeTitle() {
        return "Zuschlagskonten: Alle Mitarbeiter";
    }

    @state()
    private _balances: BonusesBalance[] = [];

    @state()
    private _loading = false;

    @singleton("ptc-reset-balances-dialog")
    private _resetBalancesDialog: ResetBalancesDialog;

    @singleton("ptc-recalculate-results-dialog")
    private _recalculateResultsDialog: RecalculateResultsDialog;

    defaultRange = () => getRange(new Date(), "month");

    rangeChanged = () => this.load();

    async load() {
        if (this._loading || !this.dateRange) {
            return;
        }

        this._loading = true;

        try {
            this._balances = await app.api.getBonusesBalances(
                new GetBalancesParams({
                    from: this.dateRange.from,
                    to: this.dateRange.to,
                    resolution: "none",
                })
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _resetBalances() {
        const didChange = await this._resetBalancesDialog.show({
            date: this.dateFrom,
            filters: app.employeeFilters.filters,
        });
        if (didChange) {
            this.load();
        }
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                display: flex;
                flex-direction: column;
            }

            .scroller {
                flex: 1;
                overflow: auto;
            }

            table {
                border-collapse: separate;
                display: block;
            }

            thead {
                display: block;
                position: sticky;
                top: 0;
                z-index: 2;
            }

            tbody {
                display: block;
                margin-bottom: 1em;
                box-sizing: border-box;
            }

            tr {
                display: grid;
                grid-template-columns: 1fr 10em repeat(4, 8em);
                align-items: center;
                width: 100%;
            }

            tbody > tr:not(:last-of-type) {
                border-bottom: solid 1px var(--shade-2);
            }

            th,
            td {
                padding: 0.5em;
                vertical-align: middle;
            }

            td {
                text-align: right;
            }

            thead {
                font-weight: bold;
                background: var(--color-bg);
                text-align: center;
            }

            tbody.total {
                font-weight: bold;
                box-shadow: rgb(0 0 0 / 10%) 0 1px 0;
                position: sticky;
                top: 2.3em;
                z-index: 2;
                background: var(--color-bg);
            }
        `,
    ];

    private _renderBalances() {
        const filteredEmployees = app.getFilteredEmployees();
        const total = sumBonusesBalances(
            this._balances.filter((b) => filteredEmployees.some((e) => e.id === b.employeeId))
        );
        const carry = total.reset ?? total.carry;

        return html`
            <table class="fill-horizontally">
                <thead>
                    <tr>
                        <th></th>
                        <th scope="col">Übertrag</th>
                        <th scope="col">Pauschale</th>
                        <th scope="col">Verdient</th>
                        <th scope="col">Differenz</th>
                        <th scope="col">Saldo</th>
                    </tr>
                </thead>

                <tbody class="total">
                    <tr>
                        <th scope="row"><i class="people-group"></i> Gesamt</th>
                        <td>
                            ${typeof carry === "undefined"
                                ? html`<span class="faded">N/A</span>`
                                : html` <ptc-balance .value=${carry}></ptc-balance> `}
                        </td>
                        <td>
                            ${typeof total.nominal === "undefined"
                                ? html`<span class="faded">N/A</span>`
                                : formatNumber(total.nominal)}
                        </td>
                        <td>${formatNumber(total.actual)}</td>
                        <td>
                            ${typeof total.difference === "undefined"
                                ? html`<span class="faded">N/A</span>`
                                : html`
                                      <ptc-balance
                                          .value=${total.difference}
                                          .icons=${"up-down"}
                                          class="semibold"
                                      ></ptc-balance>
                                  `}
                        </td>
                        <td>
                            ${typeof total.balance === "undefined"
                                ? html`<span class="faded">N/A</span>`
                                : html` <ptc-balance class="bold" .value=${total.balance}></ptc-balance> `}
                        </td>
                    </tr>
                </tbody>

                <tbody>
                    ${virtualize({
                        items: filteredEmployees,
                        renderItem: (employee) => {
                            const balance = this._balances.find((s) => s.employeeId === employee.id);

                            if (!balance) {
                                return html``;
                            }

                            return html`
                                <tr class="click" @click=${() => this.go(`employees/${employee.id}/bonuses_balance`)}>
                                    <th>
                                        <div class="horizontal center-aligning spacing layout employee-header">
                                            <ptc-avatar class="tinier" .employee=${employee}></ptc-avatar>
                                            <div class="stretch collapse ellispis">
                                                <span class="semibold">
                                                    ${employee.lastName}, ${employee.firstName}
                                                </span>
                                                ${employee.staffNumber
                                                    ? html` <span class="subtle">#${employee.staffNumber}</span> `
                                                    : ""}
                                            </div>
                                        </div>
                                    </th>
                                    <td
                                        ${typeof balance.reset === "number"
                                            ? popover(html`Konto zurückgesetzt am ${formatDate(balance.from)}`, {
                                                  class: "tooltip",
                                                  trigger: "hover",
                                              })
                                            : nothing}
                                    >
                                        ${typeof balance.carry === "number"
                                            ? html` <ptc-balance .value=${balance.carry}></ptc-balance> `
                                            : html`<span class="subtle">N/A</span>`}
                                        ${typeof balance.reset === "number"
                                            ? html`
                                                  <i class="smaller arrow-right"></i>
                                                  <ptc-balance class="bold" .value=${balance.reset}></ptc-balance>
                                              `
                                            : ""}
                                    </td>
                                    <td>
                                        ${typeof balance.nominal === "undefined"
                                            ? html`<span class="subtle">N/A</span>`
                                            : formatNumber(balance.nominal)}
                                    </td>
                                    <td>
                                        ${balance.actual !== balance.untaxed
                                            ? html`<sup
                                                  class="tinier"
                                                  ${popover(
                                                      html`
                                                          <table class="simple">
                                                              <thead>
                                                                  <tr>
                                                                      <th style="text-align: left;">Ursache</th>
                                                                      <th>Betrag (€)</th>
                                                                  </tr>
                                                              </thead>
                                                              <tbody>
                                                                  <tr>
                                                                      <th>
                                                                          <i class="badge-percent"></i>
                                                                          Beitragsfrei
                                                                      </th>
                                                                      <td>${formatNumber(balance.untaxed)}</td>
                                                                  </tr>
                                                                  <tr>
                                                                      <th>
                                                                          <i class="badge-dollar"></i>
                                                                          Beitragspflichtig
                                                                      </th>
                                                                      <td>${formatNumber(balance.taxed)}</td>
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      `,
                                                      {
                                                          trigger: "hover",
                                                          class: "text-left-aligning tooltip non-interactive",
                                                      }
                                                  )}
                                              >
                                                  <i class="badge-dollar"></i>
                                              </sup> `
                                            : ""}
                                        ${formatNumber(balance.actual)}
                                    </td>
                                    <td>
                                        ${typeof balance.difference === "undefined"
                                            ? html`<span class="subtle">N/A</span>`
                                            : html`
                                                  <ptc-balance
                                                      .value=${balance.difference}
                                                      .icons=${"up-down"}
                                                      class="semibold"
                                                  ></ptc-balance>
                                              `}
                                    </td>
                                    <td>
                                        ${balance.subBalances
                                            ? html`<sup
                                                  class="tinier bold"
                                                  ${popover(
                                                      html`
                                                          <table class="simple">
                                                              <thead>
                                                                  <tr>
                                                                      <th></th>
                                                                      <th scope="col">Übertrag</th>
                                                                      <th scope="col">Pauschale</th>
                                                                      <th scope="col">Verdient</th>
                                                                      <th scope="col">Differenz</th>
                                                                      <th scope="col">Saldo</th>
                                                                  </tr>
                                                              </thead>

                                                              <tbody class="total">
                                                                  ${balance.subBalances.map(
                                                                      (subBalance) => html`
                                                                          <tr>
                                                                              <th>${formatRange(subBalance)}</th>
                                                                              <td
                                                                                  class="center-aligning end-justifying horizontal layout"
                                                                              >
                                                                                  ${typeof subBalance.carry === "number"
                                                                                      ? html`
                                                                                            <ptc-balance
                                                                                                .value=${subBalance.carry}
                                                                                            ></ptc-balance>
                                                                                        `
                                                                                      : html`<span class="subtle"
                                                                                            >N/A</span
                                                                                        >`}
                                                                                  ${typeof subBalance.reset === "number"
                                                                                      ? html`
                                                                                            <i
                                                                                                class="smaller arrow-right"
                                                                                            ></i>
                                                                                            <ptc-balance
                                                                                                class="bold"
                                                                                                .value=${subBalance.reset}
                                                                                            ></ptc-balance>
                                                                                        `
                                                                                      : ""}
                                                                              </td>
                                                                              <td>
                                                                                  ${typeof subBalance.nominal ===
                                                                                  "number"
                                                                                      ? formatNumber(subBalance.nominal)
                                                                                      : html`<span class="subtle"
                                                                                            >N/A</span
                                                                                        >`}
                                                                              </td>
                                                                              <td>
                                                                                  ${formatNumber(subBalance.actual)}
                                                                              </td>
                                                                              <td>
                                                                                  ${typeof subBalance.difference ===
                                                                                  "number"
                                                                                      ? html`
                                                                                            <ptc-balance
                                                                                                .value=${subBalance.difference}
                                                                                                .icons=${"up-down"}
                                                                                                class="semibold"
                                                                                            ></ptc-balance>
                                                                                        `
                                                                                      : html`<span class="subtle"
                                                                                            >N/A</span
                                                                                        >`}
                                                                              </td>
                                                                              <td>
                                                                                  ${typeof subBalance.balance ===
                                                                                  "number"
                                                                                      ? html`
                                                                                            <ptc-balance
                                                                                                .value=${subBalance.balance}
                                                                                                .icons=${"up-down"}
                                                                                                class="semibold"
                                                                                            ></ptc-balance>
                                                                                        `
                                                                                      : html`<span class="subtle"
                                                                                            >N/A</span
                                                                                        >`}
                                                                              </td>
                                                                          </tr>
                                                                      `
                                                                  )}
                                                              </tbody>
                                                          </table>
                                                      `,
                                                      {
                                                          trigger: "hover",
                                                          class: "non-interactive",
                                                      }
                                                  )}
                                              >
                                                  <i class="list"></i>
                                              </sup> `
                                            : ""}
                                        ${typeof balance.balance === "number"
                                            ? html`
                                                  <ptc-balance
                                                      .value=${balance.balance}
                                                      .icons=${"up-down"}
                                                      class="semibold"
                                                  ></ptc-balance>
                                              `
                                            : html`<span class="subtle">N/A</span>`}
                                    </td>
                                </tr>
                            `;
                        },
                    })}
                </tbody>
            </table>
        `;
    }

    render() {
        return html`
            <div class="center-aligning horizontal layout padded">
                <ptc-date-range-picker
                    @range-selected=${(e: CustomEvent<DateRange>) => this.go(null, e.detail)}
                    .range=${this.dateRange}
                    maxDays=${366}
                ></ptc-date-range-picker>

                <div class="stretch collapse end-justifying horizontal layout">
                    <button
                        class="transparent slim right-margined"
                        title="Arbeitszeiten Exportieren"
                        @click=${() =>
                            this.go("exports/balances_excel", {
                                filters: serializeFilters(app.employeeFilters.filters),
                            })}
                    >
                        <i class="download"></i>
                    </button>

                    <button class="slim transparent">
                        <i class="ellipsis-h"></i>
                    </button>

                    <ptc-popover class="popover-menu">
                        <button @click=${this._resetBalances}><i class="undo"></i> Konten Zurücksetzen</button>
                        <button
                            @click=${async () => {
                                const recalculated = await this._recalculateResultsDialog.show({
                                    filters: app.employeeFilters.filters,
                                });
                                if (recalculated) {
                                    this.load();
                                }
                            }}
                        >
                            <i class="calculator-simple"></i> Konten Neu Berechnen
                        </button>
                    </ptc-popover>
                </div>
            </div>

            <ptc-employees-filter
                class="border-top border-bottom"
                .sortableProps=${["firstName", "lastName", "staffNumber"] as EmployeeSortProperty[]}
            ></ptc-employees-filter>

            <div class="scroller">
                <div class="double-padded">${this._renderBalances()}</div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
