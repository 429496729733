import { html, LitElement, TemplateResult } from "lit";
import { customElement, property, queryAll, state } from "lit/decorators.js";
import { triggerReflow } from "../lib/util";

@customElement("ptc-sortable-list")
export class SortableList<T = any> extends LitElement {
    @property({ attribute: false })
    items: T[] = [];

    @property()
    layout: "vertical" | "horizontal" = "vertical";

    @property({ type: Boolean })
    locked = false;

    @state()
    private _draggingIndex?: number;

    @state()
    private _draggingElDimensions: { height: number; width: number } = { height: 0, width: 0 };

    @queryAll("li .item-inner")
    private _itemEls: NodeListOf<HTMLLIElement>;

    @property({ attribute: false })
    renderItem: (item: T, index: number) => TemplateResult;

    createRenderRoot() {
        return this;
    }

    private _dragStart(i: number) {
        this._draggingIndex = i;
        const draggedEl = this._itemEls[i];
        this._draggingElDimensions = {
            height: draggedEl.offsetHeight,
            width: draggedEl.offsetWidth,
        };
        setTimeout(() => (draggedEl.style.opacity = "0"), 50);
        for (const el of this._itemEls) {
            el.style.transition = "transform 0.3s";
            el.style.pointerEvents = "none";
            triggerReflow(el);
        }
    }

    private _dragEnter(i: number) {
        if (this._draggingIndex === undefined) {
            return;
        }

        const translateDirection = this.layout === "horizontal" ? "X" : "Y";
        const translateDistance =
            this.layout === "horizontal" ? this._draggingElDimensions.width : this._draggingElDimensions.height;

        for (const [j, el] of this._itemEls.entries()) {
            if (j > this._draggingIndex && j <= i) {
                el.style.transform = `translate${translateDirection}(-${translateDistance}px)`;
            } else if (j < this._draggingIndex && j >= i) {
                el.style.transform = `translate${translateDirection}(${translateDistance}px)`;
            } else {
                el.style.transform = "";
            }
        }

        // if (i > this._draggingIndex) {
        //     for (let j = i; j > this._draggingIndex; j--) {
        //         console.log("moving up item", j);
        //         this._itemEls[j].style.transform = "translateY(-100%)";
        //     }
        // }

        // if (this._draggingIndex === undefined || !this._updatedOrder) {
        //     return;
        // }
        // const updated = [...this.items];

        // const removed = updated.splice(this._draggingIndex, 1);
        // updated?.splice(i, 0, ...removed);
        // this._updatedOrder = updated;
    }

    private _dragEnd() {
        for (const item of this._itemEls) {
            item.style.transition = "";
            triggerReflow(item);
            item.style.transform = "";
            item.style.pointerEvents = "";
            item.style.opacity = "";
        }
        this._draggingIndex = undefined;
    }

    private _drop(toIndex: number) {
        if (this._draggingIndex === undefined) {
            return;
        }

        const fromIndex = this._draggingIndex;

        if (fromIndex !== toIndex) {
            const [item] = this.items.splice(this._draggingIndex, 1);
            this.items.splice(toIndex, 0, item);
            this.dispatchEvent(new CustomEvent("item-moved", { detail: { item, fromIndex, toIndex } }));
        }

        this._dragEnd();

        this.requestUpdate("items");
    }

    render() {
        if (!this.renderItem) {
            return;
        }

        return html`<ol @dragend=${this._dragEnd} class=${this.layout === "horizontal" ? "horizontal layout" : ""}>
            ${this.items.map(
                (item, i) => html`
                    <li
                        @dragstart=${(e: DragEvent) => {
                            this._dragStart(i);
                            e.dataTransfer?.setData("text/plain", "asdf");
                        }}
                        @dragenter=${(e: DragEvent) => {
                            e.preventDefault();
                            this._dragEnter(i);
                        }}
                        @dragover=${(e: DragEvent) => e.preventDefault()}
                        @drop=${(e: DragEvent) => {
                            e.preventDefault();
                            this._drop(i);
                        }}
                        draggable="${this.locked ? "false" : "true"}"
                    >
                        <div class="item-inner">${this.renderItem(item, i)}</div>
                    </li>
                `
            )}
        </ol>`;
    }
}
