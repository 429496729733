import { Company, CompanyListing } from "@pentacode/core/src/model";
import { GetCompanyParams } from "@pentacode/core/src/api";
import { html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";

type TransferVenueDialogParams = { venueName: string };

@customElement("ptc-transfer-venue-dialog")
export class TransferVenueDialog extends Dialog<TransferVenueDialogParams, Company> {
    readonly preventDismiss = true;

    @query("form")
    private _form: HTMLFormElement;

    @state()
    private _params: TransferVenueDialogParams;

    @state()
    private _companies: CompanyListing[] = [];

    @state()
    private _selectedCompanyId: number | null = null;

    @state()
    private _filterString: string = "";

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    @query("#companyId")
    private _companyIdSelect: HTMLSelectElement;

    async show(params: TransferVenueDialogParams) {
        this._params = { ...params };

        this._companies = await app.api.listCompanies();

        return super.show();
    }

    private _getFilteredCompanies() {
        return this._companies.filter((c) => `${c.name} ${c.id}`.toLowerCase().includes(this._filterString));
    }

    private async _submit(e: Event) {
        e.preventDefault();

        if (!this._form.checkValidity()) {
            this._form.reportValidity();
            return;
        }

        this.loading = true;
        try {
            if (!this._selectedCompanyId) {
                throw new Error("Bitte wählen Sie ein Unternehmen aus!");
            }
            const company = await app.api.getCompany(
                new GetCompanyParams({
                    id: this._selectedCompanyId,
                })
            );
            this.done(company);
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            void this.show(this._params);
        }

        this.loading = false;
    }

    static styles = [...Dialog.styles];

    renderContent() {
        return html`
            <form @submit=${this._submit}>
                <div class="vertical layout">
                    <div class="padded center-aligning horizontal layout border-bottom">
                        <div class="half-padded semibold stretch">
                            <i class="truck-moving"></i> Standort Umziehen: ${this._params.venueName}
                        </div>
                        <button class="slim transparent" @click=${() => this.done()}>
                            <i class="times"></i>
                        </button>
                    </div>
                    <div class="padded spacing vertical layout">
                        <div class="filter-input right icon input">
                            <input
                                id="filterInput"
                                type="text"
                                placeholder="Filtern..."
                                @input=${this._updateFilters}
                                autocomplete="off"
                            />
                            <i
                                class="${this._filterString ? "times click" : "search"} icon"
                                @click=${this._clearFilter}
                            ></i>
                        </div>
                        <select
                            id="companyId"
                            required
                            @change=${() => this._selectCompany()}
                            .value=${this._selectedCompanyId?.toString() || ""}
                        >
                            <option value="">Unternehmen wählen...</option>
                            ${this._getFilteredCompanies().map(
                                (company) =>
                                    html`<option
                                        ?selected=${company.id === this._selectedCompanyId}
                                        value=${company.id}
                                    >
                                        ${company.name}
                                    </option>`
                            )}
                        </select>
                    </div>
                </div>

                <div class="padded spacing horizontal layout">
                    <button class="primary stretch" @click=${this._submit} ?disabled=${!this._selectedCompanyId}>
                        Umziehen
                    </button>

                    <button class="stretch transparent" @click=${() => this.done()} type="button">Abbrechen</button>
                </div>
            </form>
        `;
    }
    private _selectCompany() {
        console.log(this._companyIdSelect.value);
        this._selectedCompanyId = Number(this._companyIdSelect.value) || null;
    }

    private _updateFilters() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private async _clearFilter() {
        const selectedCompanyId = this._selectedCompanyId;
        //this causes the <select> to change, which triggers and resets the selection
        this._filterString = this._filterInput.value = "";
        await this.updateComplete;

        //set the saved companyId again
        this._selectedCompanyId = selectedCompanyId;
    }
}
