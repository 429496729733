import { clone } from "@pentacode/core/src/encoding";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import { alert, confirm } from "./alert-dialog";
import "./spinner";
import { Checkbox } from "./checkbox";
import "./popover";
import { TimeInput } from "./time-input";
import { DateInput } from "./date-input";
import { dateAdd, monthNames, parseDateString } from "@pentacode/core/src/util";
import { CommitTimesDialog } from "./commit-times-dialog";
import "./scroller";
import "./help";
import { Company, CompanySettings } from "@pentacode/core/src/model";

@customElement("ptc-settings-company")
export class SettingsCompany extends Routing(StateMixin(LitElement)) {
    routePattern = /^settings\/company/;

    get routeTitle() {
        return "Unternehmen | Einstellungen";
    }

    get helpPage() {
        return "handbuch/einstellungen/unternehmen/";
    }

    @state()
    private _loading: boolean = false;

    @singleton("ptc-commit-times-dialog")
    private _commitTimesDialog: CommitTimesDialog;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='weekFactor']")
    private _weekFactorInput: HTMLInputElement;

    private get _data() {
        if (!this._form) {
            return null;
        }

        const data = new FormData(this._form);

        return {
            name: data.get("name") as string,
            address: data.get("address") as string,
            postalCode: data.get("postalCode") as string,
            city: data.get("city") as string,
            phone: data.get("phone") as string,
            email: data.get("email") as string,
            settings: {
                startLedgers: (data.get("startLedgers") as string) || null,
                startPayrollPeriod: Number(data.get("startPayrollPeriod")),
                iterativeBreaks: JSON.parse(data.get("iterativeBreaks") as string),
                includeTaxedBonusesInBalance: JSON.parse(data.get("includeTaxedBonusesInBalance") as string),
                weekFactor: Number(data.get("weekFactor") as string),
            },
        };
    }

    get hasChanges() {
        if (!app.company || !this._data) {
            return false;
        }

        const { settings, ...companyInfo } = this._data;

        const hasOtherSettingsChanged = Object.entries(settings).some(
            ([key, value]) => app.company!.settings[key as keyof CompanySettings] !== value
        );

        const hasCompanyInfoChanged = Object.entries(companyInfo).some(
            ([key, value]) => app.company![key as keyof Company] !== value
        );

        const hasChanges = hasCompanyInfoChanged || hasOtherSettingsChanged;

        return hasChanges;
    }

    private async _submit(e?: FocusEvent) {
        e && e.preventDefault();

        if (!app.company || !this._data) {
            return;
        }

        const { settings: updatedSettings, ...companyInfo } = this._data;

        const settings = clone(app.company.settings);

        Object.assign(settings, updatedSettings);

        this._loading = true;
        try {
            await app.updateCompany({ settings, ...companyInfo });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _clearCache() {
        if (
            !(await confirm(
                "Möchten Sie den Zwischenspeicher für Ihr Unternehmen zurücksetzen und Arbeitszeiten, Zuschläge und Konten neu berechnen?",
                "Ja",
                "Nein"
            ))
        ) {
            return;
        }
        this._loading = true;
        try {
            await app.updateCompany({
                clearCache: true,
            });

            void alert(
                "Zwischenspeicher erfolgreich zurückgesetzt. Bitte beachten Sie dass vorübergehend zu längeren Ladezeiten kommen kann."
            );
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    static styles = [
        shared,
        Checkbox.styles,
        TimeInput.styles,
        DateInput.styles,
        css`
            ptc-time-input {
                width: 5em;
            }
        `,
    ];

    render() {
        if (!app.company) {
            return html``;
        }
        const company = app.company;
        const settings = app.company.settings;
        const commitBefore = app.company.settings.commitTimeEntriesBefore;
        const commitBeforeDate = commitBefore && parseDateString(dateAdd(commitBefore, { days: -1 }));
        const weekFactor = this._data ? this._data.settings.weekFactor : settings.weekFactor;
        // feb has only 28 days, therefore last possible payroll period start is 28th
        const lastPossiblePayrollPeriodStart = 28;

        return html`
            <form
                @input=${() => this.requestUpdate("hasChanges")}
                @change=${() => this.requestUpdate("hasChanges")}
                @submit=${this._submit}
                class="fullbleed vertical layout"
            >
                <ptc-scroller class="stretch">
                    <div class="double-padded" style="max-width: 50em; margin: 0 auto;">
                        <h2>Unternehmensdaten</h2>

                        <div class="field">
                            <label>Unternehmensname</label>
                            <input name="name" placeholder="Unternehmensname" .value=${company.name} />
                        </div>

                        <div class="field">
                            <label>Adresse</label>
                            <input
                                type="text"
                                name="address"
                                placeholder="Strasse / Hausnummer"
                                .value=${company.address}
                            />
                        </div>

                        <div class="fields horizontal spacing layout">
                            <div class="field stretch">
                                <label>Postleitzahl</label>
                                <input
                                    type="number"
                                    pattern="d*"
                                    name="postalCode"
                                    placeholder="PLZ"
                                    .value=${company.postalCode}
                                />
                            </div>

                            <div class="field stretch">
                                <label>Stadt</label>
                                <input type="text" name="city" placeholder="Stadt" .value=${company.city} />
                            </div>

                            <div class="field">
                                <label>Land</label>
                                <select name="country" disabled .value=${company.country}>
                                    <option value="DE">Deutschland</option>
                                    <option value="AT">Österreich</option>
                                </select>
                            </div>
                        </div>

                        <div class="fields horizontal spacing layout">
                            <div class="field stretch">
                                <label>Telefon</label>
                                <input type="text" name="phone" placeholder="Telefon" .value=${company.phone} />
                            </div>

                            <div class="field stretch">
                                <label>Emailadresse</label>
                                <input type="email" name="email" placeholder="Email" .value=${company.email} />
                            </div>
                        </div>

                        <h2>Konten</h2>

                        <div class="horizontal spacing fields layout">
                            <div class="field">
                                <label>
                                    Kontoführung Ab
                                    <ptc-help-anchor page="handbuch/einstellungen/unternehmen/#beginn-der-kontoführung">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>
                                <ptc-date-input
                                    name="startLedgers"
                                    .value=${settings.startLedgers}
                                    @change=${() => this.requestUpdate("hasChanges")}
                                ></ptc-date-input>
                            </div>

                            <div class="field">
                                <label>
                                    Beginn des Abrechnungszeitraums
                                    <ptc-help-anchor
                                        page="handbuch/einstellungen/unternehmen/#beginn-des-abrechnungszeitraums"
                                    >
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>

                                <select name="startPayrollPeriod" .value=${settings.startPayrollPeriod.toString()}>
                                    <option value="1">1. (empfohlen)</option>
                                    ${Array.from({ length: lastPossiblePayrollPeriodStart - 1 }, (_, i) => i + 2).map(
                                        (el) => html`<option value="${el}">${el}.</option>`
                                    )}
                                </select>
                            </div>
                        </div>

                        <div class="field">
                            <label>
                                Saldierung SFN-Konten
                                <ptc-help-anchor page="handbuch/einstellungen/unternehmen/#saldierung-sfn-konten">
                                    <i class="faded question-circle"></i>
                                </ptc-help-anchor>
                            </label>

                            <select
                                name="includeTaxedBonusesInBalance"
                                .value=${settings.includeTaxedBonusesInBalance.toString()}
                            >
                                <option value="false">Exklusive Steuerpflichtige Zuschläge</option>
                                <option value="true">Inklusive Steuerpflichtige Zuschläge</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>
                                Arbeitszeiten & Konten festgeschrieben...
                                <ptc-help-anchor
                                    page="handbuch/einstellungen/unternehmen/#arbeitszeiten-%26-konten-festschreiben"
                                >
                                    <i class="faded question-circle"></i>
                                </ptc-help-anchor>
                            </label>
                            <div class="padded">
                                ...bis einschließlich:
                                ${commitBeforeDate
                                    ? html`<strong>
                                          ${monthNames[commitBeforeDate.getMonth()]} ${commitBeforeDate.getFullYear()}
                                      </strong>`
                                    : ""}

                                <button
                                    class="small subtle left-margined"
                                    type="button"
                                    @click=${() => this._commitTimesDialog.show()}
                                >
                                    <i class="pencil-alt"></i> Bearbeiten...
                                </button>
                            </div>
                        </div>

                        <h2>Sonstiges</h2>

                        <div class="field">
                            <label>
                                Berechnung Gesetzliche Pause
                                <ptc-help-anchor
                                    page="handbuch/einstellungen/unternehmen/#berechnung-gesetzliche-pause"
                                >
                                    <i class="faded question-circle"></i>
                                </ptc-help-anchor>
                            </label>

                            <select name="iterativeBreaks" .value=${settings.iterativeBreaks.toString()}>
                                <option value="false">Standard</option>
                                <option value="true">Iterativ</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>
                                Wochenfaktor
                                <ptc-help-anchor page="handbuch/einstellungen/unternehmen/#wochenfaktor">
                                    <i class="faded question-circle"></i>
                                </ptc-help-anchor>
                            </label>

                            <div class="horizontal spacing evenly stretching layout">
                                <select
                                    name="weekFactorSelect"
                                    @change=${(e: Event) => {
                                        this._weekFactorInput.value = (e.target as HTMLInputElement).value;
                                        this._weekFactorInput.focus();
                                    }}
                                >
                                    <option ?selected=${weekFactor === 4.35} value="4.35">4,35 (empfohlen)</option>
                                    <option ?selected=${weekFactor === 4.33} value="4.33">4,33</option>
                                    <option value="" ?selected=${weekFactor !== 4.33 && weekFactor !== 4.35}>
                                        Benutzerdefiniert
                                    </option>
                                </select>

                                <input
                                    type="number"
                                    name="weekFactor"
                                    step="0.0001"
                                    min="4.0000"
                                    max="5.0000"
                                    required
                                    .value=${settings.weekFactor.toString()}
                                    ?invisible=${weekFactor === 4.33 || weekFactor === 4.35}
                                />
                            </div>
                        </div>

                        ${app.account?.admin
                            ? html` <div class="double-margined border-bottom"></div>
                                  <button type="button" class="ghost" @click=${this._clearCache}>
                                      Zwischenspeicher Zurücksetzen
                                  </button>`
                            : ""}
                    </div>
                </ptc-scroller>

                <div
                    class="horizontal padded spacing evenly stretching spacing layout"
                    ?hidden=${!this.hasChanges}
                    style="width: 100%; max-width: 50em; margin: 0 auto;"
                >
                    <button class="primary">Speichern</button>
                    <div></div>
                    <!-- <button class="transparent" type="button" ?hidden>Abbrechen</button> -->
                </div>
            </form>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
