// import { TimeEntry } from "@pentacode/core/src/model";
// import { MonthlyStatement } from "@pentacode/core/src/statement";
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./reports-overview";
import "./reports-costs";
import "./reports-savings";
import "./reports-time";
import "./reports-revenue";
import { Permission } from "@pentacode/core/src/permissions";

@customElement("ptc-reports")
export class Reports extends Routing(StateMixin(LitElement)) {
    static pages = ["overview", "savings", "costs", "time", "revenue"];

    routePattern = /^reports(?:\/(?<page>\w+))?/;

    get helpPage() {
        switch (this._page) {
            case "overview":
                return "/handbuch/berichte/ubersicht";
            case "savings":
                return "/handbuch/berichte/ersparnis";
            case "time":
                return "/handbuch/berichte/arbeitszeit";
            case "costs":
                return "/handbuch/berichte/personalkosten";
            case "revenue":
                return "/handbuch/berichte/umsaetze";
            default:
                return "/handbuch/berichte";
        }
    }

    @routeProperty({ arg: "page" })
    private _page: string;

    handleRoute() {
        if (
            !Reports.pages.includes(this._page) ||
            (this._page !== "overview" && !app.hasPermission(`manage.reports.${this._page}` as Permission))
        ) {
            this.go(`reports/overview`, undefined, true);
            return;
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                width: 10em;
            }

            .main {
                flex: 1;
                position: relative;
            }
        `,
    ];

    render() {
        return html`
            <div class="sub-menu">
                <div class="vertical tabs">
                    <button ?active=${this._page === "overview"} @click=${() => this.go("reports/overview")}>
                        <i class="chart-line"></i>
                        Übersicht
                    </button>

                    <button
                        ?active=${this._page === "savings"}
                        @click=${() => this.go("reports/savings")}
                        ?disabled=${!app.hasPermission("manage.reports.savings")}
                    >
                        <i class="piggy-bank"></i>
                        Ersparnis
                    </button>

                    <button
                        ?active=${this._page === "costs"}
                        @click=${() => this.go("reports/costs")}
                        ?disabled=${!app.hasPermission("manage.reports.costs")}
                    >
                        <i class="hand-holding-dollar"></i>
                        Personalkosten
                    </button>

                    <button
                        ?active=${this._page === "time"}
                        @click=${() => this.go("reports/time")}
                        ?disabled=${!app.hasPermission("manage.reports.time")}
                    >
                        <i class="hourglass-clock"></i>
                        Arbeitszeit
                    </button>

                    <button
                        ?active=${this._page === "revenue"}
                        @click=${() => this.go("reports/revenue")}
                        ?disabled=${!app.hasPermission("manage.reports.revenue")}
                    >
                        <i class="sack-dollar"></i>
                        Umsätze
                    </button>
                </div>
            </div>

            <div class="main">
                <ptc-reports-overview ?hidden=${this._page !== "overview"}></ptc-reports-overview>
                <ptc-reports-costs ?hidden=${this._page !== "costs"}></ptc-reports-costs>
                <ptc-reports-savings ?hidden=${this._page !== "savings"}></ptc-reports-savings>
                <ptc-reports-time ?hidden=${this._page !== "time"}></ptc-reports-time>
                <ptc-reports-revenue ?hidden=${this._page !== "revenue"}></ptc-reports-revenue>
            </div>
        `;
    }
}
