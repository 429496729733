import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { RevenueEntry, RevenueType } from "@pentacode/core/src/model";
import { Dialog } from "./dialog";
import { EmployeeSelect } from "./employee-select";
import { DateInput } from "./date-input";
import { alert } from "./alert-dialog";
import { app } from "../init";
import { UpdateRevenueEntriesParams } from "@pentacode/core/src/api";
import { DateString, Euros } from "@pentacode/openapi";

@customElement("ptc-pay-advance-dialog")
export class PayAdvanceDialog extends Dialog<RevenueEntry, boolean> {
    readonly preventDismiss = true;

    @state()
    private _entry: RevenueEntry;

    @query("form")
    private _form: HTMLFormElement;

    @query("ptc-employee-select")
    private _employeeSelect: EmployeeSelect;

    @query("ptc-date-input")
    private _dateInput: DateInput;

    @query("input[name='amount']")
    private _amountInput: HTMLInputElement;

    async show(entry: RevenueEntry = new RevenueEntry()) {
        this._entry = entry;
        const promise = super.show();

        await this.updateComplete;

        this._employeeSelect.employeeId = entry.employeeId;
        this._dateInput.value = entry.date;
        this._amountInput.value = Math.abs(entry.amount).toFixed(2);

        return promise;
    }

    private get _data() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);

        return {
            employeeId: this._employeeSelect.employee?.id,
            amount: (-1 * Number(data.get("amount") as string)) as Euros,
            date: data.get("date") as DateString,
        };
    }

    private async _submit(e: Event) {
        e.preventDefault();

        if (!app.venues.length) {
            return;
        }

        this.loading = true;

        try {
            await app.api.updateRevenueEntries(
                new UpdateRevenueEntriesParams({
                    updated: [
                        new RevenueEntry({
                            id: this._entry.id,
                            type: RevenueType.PayAdvance,
                            cashbook: false,
                            venueId: app.venues[0]?.id,
                            name: "Vorschuss",
                            ...this._data,
                        }),
                    ],
                })
            );
            this.done(true);
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    static styles = [...Dialog.styles, DateInput.styles, css``];

    renderContent() {
        return html`
            <form @submit=${this._submit} autocomplete="off" class="double-padded spacing vertical layout">
                <div class="small margined subtle blue colored-text">
                    <i class="hand-holding-dollar"></i>
                    ${this._entry.id ? html`Vorschuss Bearbeiten` : html`Neuer Vorschuss`}
                </div>

                <div class="field">
                    <label>Mitarbeiter</label>
                    <ptc-employee-select required></ptc-employee-select>
                </div>

                <div class="spacing evenly stretching horizontal layout">
                    <div class="field">
                        <label>Datum</label>
                        <ptc-date-input name="date" required datePicker="popover"></ptc-date-input>
                    </div>

                    <div class="field">
                        <label>Betrag</label>
                        <div class="right icon input">
                            <input name="amount" type="number" step="0.01" required class="text-right-aligning" />
                            <i class="euro-sign"></i>
                        </div>
                    </div>
                </div>

                <div class="horizontal top-padded spacing evenly stretching layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
