import { Employee } from "@pentacode/core/src/model";
import { html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { EmployeesNew } from "./employees-new";
import { alert } from "./alert-dialog";
import { mixins } from "../styles";

@customElement("ptc-new-employee-dialog")
export class NewEmployeeDialog extends Dialog<Employee, Employee> {
    readonly preventDismiss = true;

    @query("ptc-employees-new")
    private _newEmployeeForm: EmployeesNew;

    async show(employee: Employee): Promise<Employee | undefined> {
        const promise = super.show();
        await this.updateComplete;
        if (employee) {
            await this._newEmployeeForm.init(employee);
        }
        return promise;
    }

    private async _submit() {
        const data = this._newEmployeeForm.data;
        this.loading = true;

        try {
            const employee = await app.createEmployee(data);
            this.done(employee);
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this._show();
        }

        this.loading = false;
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                width: calc(100% - 2em);
                height: calc(100% - 5em);
                max-width: 1000px;
                max-height: 700px;
                overflow: visible;
            }

            ptc-employees-new {
                ${mixins.fullbleed()};
            }
        `,
    ];

    renderContent() {
        return html`
            <button
                type="button"
                class="small subtle absolute top right"
                style="top: -0.5em; right: 0.5em; transform: translateY(-100%); background: var(--color-bg);"
                @click=${() => this.done()}
            >
                <i class="times"></i>
                Abbrechen
            </button>
            <ptc-employees-new @submit=${this._submit}></ptc-employees-new>
        `;
    }
}
