import "../styles/print.css";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import {
    Department,
    EmployeeShortInfo,
    GetPublishedRosterParams,
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
    Venue,
} from "@pentacode/core";
import {
    parseDateString,
    dateAdd,
    dateSub,
    debounce,
    formatDate,
    formatDateShort,
    toTimeString,
} from "@pentacode/core/src/util";
import { getHolidayForDate } from "@pentacode/core/src/holidays";
import { app } from "../init";
import { shared, config, mixins, colors } from "../styles";
import { ServiceWorker } from "../mixins/service-worker";
import { alert } from "./alert-dialog";
import "./spinner";
import "./scroller";
import { ErrorCode } from "@pentacode/core/src/error";
import { DateString } from "@pentacode/openapi";

@customElement("ptc-published-roster")
export class PublishedRoster extends ServiceWorker(LitElement) {
    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @state()
    private _venue: Venue;

    @state()
    private _entries: TimeEntry[];

    @state()
    private _employees: EmployeeShortInfo[];

    @state()
    private _departments?: number[];

    @state()
    private _employee?: number;

    @state()
    private _from: DateString;

    @state()
    private _to: DateString;

    @state()
    private _filterString = "";

    @state()
    private _selectedEmployee?: number;

    @state()
    private _loading = true;

    @state()
    private _mirrorDepartments = false;

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    private get _dates() {
        const dates = [];
        let date = this._from;
        while (date < this._to) {
            dates.push(date);
            date = dateAdd(date, { days: 1 });
        }
        return dates;
    }

    private get _departmentHeaderWidth() {
        const scroller = this.renderRoot.querySelector(".scroller") as HTMLDivElement;
        return (scroller && `${scroller.offsetWidth - 50}px`) || "auto";
    }

    constructor() {
        super();
        this.load();
        window.addEventListener(
            "resize",
            debounce(() => this.requestUpdate(), 50)
        );
    }

    async load() {
        const params = new URLSearchParams(location.search);
        const id = params.get("id") || undefined;
        const token = params.get("token") || undefined;
        const from = (params.get("from") as DateString) || undefined;
        const to = (params.get("to") as DateString) || undefined;
        const venue = (params.get("venue") && Number(params.get("venue"))) || undefined;
        const departments =
            (params.get("d") &&
                params
                    .get("d")!
                    .split(",")
                    .map((d) => parseInt(d))) ||
            undefined;
        const employee = (params.get("e") && parseInt(params.get("e")!)) || undefined;
        this._mirrorDepartments = !!params.get("md");

        await app.loaded;

        try {
            if (id || token) {
                const { timeEntries, roster, employees } = await app.api.getPublishedRoster(
                    new GetPublishedRosterParams({
                        id,
                        departments,
                        employee,
                        venue,
                        from,
                        to,
                        token,
                    })
                );
                this._entries = timeEntries;
                this._venue = roster.venue;
                this._employees = employees;
                this._employee = employee;
                this._from = roster.from;
                this._to = roster.to;
            } else {
                if (!from || !to || !venue) {
                    return;
                }

                const entries = await app.getTimeEntries({
                    from,
                    to,
                    venue,
                    employee,
                    type: [
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.Free,
                        TimeEntryType.CompDay,
                        TimeEntryType.ChildSick,
                        TimeEntryType.SickInKUG,
                    ],
                    includeDeleted: true,
                });

                this._employees = app.accessibleEmployees.filter((emp) => entries.some((e) => e.employeeId === emp.id));
                this._employee = employee;
                this._entries = entries;
                this._venue = app.getVenue(venue)!;
                this._from = from;
                this._to = to;
            }

            this._departments = departments;
            this._selectedEmployee = this._employees.length && this._employees[0].id;
        } catch (e) {
            if (e.code === ErrorCode.INVALID_SESSION || e.code === ErrorCode.SESSION_EXPIRED) {
                await app.logout();
                window.location.reload();
            } else {
                void alert(
                    e.message || "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
                    { type: "warning" }
                );
            }
        }

        this._loading = false;

        await this.updateComplete;
        window.dispatchEvent(new CustomEvent("print-ready"));

        this.requestUpdate();
    }

    private _getEmployee(id: number | null) {
        return this._employees.find((e) => e.id === id);
    }

    private _belongsToDepartment(emp: EmployeeShortInfo, dep: Department) {
        return emp.positions.some((p) => p.departmentId === dep.id);
    }

    private _showInDepartment(e: TimeEntry, dep: Department) {
        if (e.position && !this._mirrorDepartments) {
            return e.position.departmentId === dep.id;
        } else {
            const emp = this._getEmployee(e.isPast ? e.employeeId : e.published!.employeeId);
            return emp && this._belongsToDepartment(emp, dep);
        }
    }

    private _getEntries({
        employee,
        date,
        department,
    }: {
        employee?: EmployeeShortInfo;
        date?: string;
        department?: Department;
    }) {
        return this._entries
            .filter((e) => {
                const published = e.isPast ? e : e.published;
                return (
                    published &&
                    !published.deleted &&
                    (!date || published.date === date) &&
                    (!employee || published.employeeId === employee.id) &&
                    (!department || this._showInDepartment(e, department))
                );
            })
            .sort((a, b) => a.start.getTime() - b.start.getTime());
    }

    private _getEmployeesForDepartment(dep: Department): EmployeeShortInfo[] {
        function getOrder(emp: EmployeeShortInfo) {
            const posOrder = Math.min(...emp.positions.filter((p) => p.departmentId === dep.id).map((p) => p.order));
            const order = dep.rosterOrder.indexOf(emp.id.toString());
            return order === -1 ? dep.rosterOrder.length + posOrder : order;
        }

        return this._employees
            .filter((emp) => this._belongsToDepartment(emp, dep))
            .sort((a, b) => getOrder(a) - getOrder(b));
    }

    private _updateFilter() {
        this._filterString = this._filterInput.value;
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    private _goToRange(from: string, to: string) {
        let url = `/dp/?venue=${this._venue.id}&from=${from}&to=${to}`;
        if (this._departments) {
            url += `&d=${this._departments.join(",")}`;
        }
        if (this._employee) {
            url += `&e=${this._employee}`;
        }
        window.location.href = url;
    }

    static styles = [
        config,
        shared,
        css`
            :host {
                ${mixins.fullbleed()};
                display: flex;
                flex-direction: column;
            }

            .header {
                text-align: center;
                padding-bottom: 0;
                width: 100%;
                max-width: 59em;
                box-sizing: border-box;
            }

            .header button {
                padding: 0.5em;
            }

            .wide {
                padding: 0 0.5em;
            }

            .narrow {
                max-width: 30em;
                margin: 0 auto;
            }

            .scroller {
                max-width: 100%;
                overflow: auto;
            }

            .venue-name {
                font-size: 1.5em;
                font-weight: 300;
            }

            .date-range {
                font-size: 0.8em;
                opacity: 0.7;
                margin-bottom: -4px;
                display: none;
            }

            .employee-selector {
                width: 100%;
                margin: 0.5em 0;
                text-align: center;
                font-size: 1.1em;
            }

            .single-employee {
                font-size: 1.2em;
                color: var(--color-primary);
                margin: 0.5em 0;
                font-weight: 600;
            }

            .department {
                color: var(--color-highlight);
                display: table;
            }

            .department-header {
                text-align: center;
                padding: 0.3em;
                margin: 0.5em;
                position: sticky;
                top: 4em;
                left: 25px;
                background: white;
                color: var(--color-highlight);
                border: solid 1px var(--color-highlight);
                border-radius: var(--border-radius);
                box-sizing: border-box;
                z-index: 3;
            }

            .row {
                display: flex;
            }

            .header-wrapper {
                display: table;
                position: sticky;
                top: 0;
                z-index: 4;
                background: var(--color-bg);
            }

            .row .row-header {
                position: sticky;
                left: 0;
                z-index: 2;
                background: var(--color-bg);
            }

            .row > * {
                flex: none;
                width: 7em;
                box-sizing: border-box;
            }

            .row > :first-child {
                width: 120px;
                flex: none;
            }

            .time {
                font-size: 1.15em;
            }

            .employee.row > :not(:last-child) {
                border-right: dashed 1px rgba(0, 0, 0, 0.3);
            }

            .row:not(:last-child) > *,
            .header-wrapper .row > * {
                border-bottom: dashed 1px rgba(0, 0, 0, 0.3);
            }

            .filter-wrapper {
                display: flex;
                align-items: center;
            }

            .filter-input {
                position: relative;
                flex: 1;
            }

            .day-header {
                font-weight: bold;
                text-align: center;
                padding: 0.5em;
            }

            .day-subheader {
                font-size: 0.8em;
                opacity: 0.7;
                margin-top: 2px;
            }

            .day-header.holiday {
                color: var(--violet);
            }

            .employee {
                page-break-inside: avoid;
            }

            .employee-header {
                font-weight: 600;
                padding: 0.5em;
                font-size: 0.8em;
            }

            .employee-day > * {
                margin: 0.2rem;
            }

            .entry {
                padding-top: 0.2em;
                padding-bottom: 0;
            }

            .entry.mirrored {
                border-color: transparent;
            }

            .narrow .day-header {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .narrow .day-subheader {
                margin: 0 0 0 0.5em;
                font-weight: normal;
                font-size: 0.9em;
            }

            .narrow .entry-times {
                font-size: 1.1em;
            }

            .narrow .entry-position {
                font-size: 1em;
            }

            .narrow .employee-day {
                margin: 0 0.5em;
            }

            .narrow .employee-day:last-child {
                margin-bottom: 1em;
            }

            @media print {
                * {
                    color: black !important;
                }

                :host,
                .wrapper {
                    position: static;
                    padding: 0;
                    font-size: 0.9em;
                }

                .scroller {
                    flex: none;
                    margin: 0 auto;
                }

                .filter-input {
                    display: none;
                }

                .header {
                    max-width: none;
                }

                .department,
                .header-wrapper,
                .row-header {
                    display: block;
                    position: static;
                }

                .department-header {
                    width: auto !important;
                }

                .row > * {
                    flex: none;
                    box-sizing: border-box;
                }
            }
        `,
    ];

    render() {
        if (this._loading) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner active></ptc-spinner>
                </div>
            `;
        }

        return this._employee ? this._renderNarrow() : this._renderWide();
    }

    private _renderEntry(entry: TimeEntry, inDepartment?: Department) {
        if (entry.position) {
            const department = this._venue.departments.find((d) => d.id === entry.position!.departmentId);
            if (!department) {
                return;
            }
            const posName =
                inDepartment && inDepartment.id !== department.id && department.name !== entry.position.name
                    ? `${department.name} / ${entry.position.name}`
                    : entry.position.name;
            const published = entry.isPast ? entry : entry.published!;
            const startPlanned = toTimeString(published.startPlanned);
            const endPlanned = toTimeString(published.endPlanned);
            return html`
                <div
                    class="text-centering smaller half-padded box entry ${inDepartment &&
                    inDepartment.id !== department.id
                        ? "mirrored"
                        : ""}"
                    style="--color-highlight: ${entry.position.color || colors[department.color] || department.color}"
                >
                    <div class="ellipsis horizontally-padded">${posName}</div>
                    <div class="time semibold">
                        ${startPlanned ? startPlanned.slice(0, 5) : "offen"} -
                        ${endPlanned ? endPlanned.slice(0, 5) : "offen"}
                    </div>
                </div>
            `;
        } else {
            return html`<div
                class="text-centering padded entry"
                style="--color-highlight: ${timeEntryTypeColor(entry.type)}"
            >
                ${app.localized.timeEntryTypeLabel(entry.type)}
            </div>`;
        }
    }

    private _renderWide() {
        const dates = this._dates.map((str) => parseDateString(str)!);

        const days = dateSub(this._from, this._to);
        const prevTo = this._from;
        const prevFrom = dateAdd(prevTo, { days: -days });
        const nextFrom = this._to;
        const nextTo = dateAdd(nextFrom, { days });

        return html`
            <div class="wide vertical fullbleed center-aligning layout wrapper">
                <div class="header horizontal center-aligning layout pad-children padded-medium">
                    <button
                        class="transparent noprint"
                        @click=${() => this._goToRange(prevFrom, prevTo)}
                        ?hidden=${!app.account}
                    >
                        <i class="angle-left"></i> ${formatDateShort(prevFrom)} -
                        ${formatDateShort(dateAdd(prevTo, { days: -1 }))}
                    </button>
                    <div class="venue-name stretch ellipsis">${this._venue.name}</div>
                    <button
                        class="transparent noprint"
                        @click=${() => this._goToRange(nextFrom, nextTo)}
                        ?hidden=${!app.account}
                    >
                        ${formatDateShort(nextFrom)} - ${formatDateShort(dateAdd(nextTo, { days: -1 }))}
                        <i class="angle-right"></i>
                    </button>
                </div>

                <div class="scroller stretch">
                    <div class="header-wrapper">
                        <div class="row">
                            <div class="filter-wrapper row-header">
                                <div class="small filter-input right icon input">
                                    <input
                                        id="filterInput"
                                        type="text"
                                        placeholder="Suchen..."
                                        @input=${this._updateFilter}
                                    />
                                    <i
                                        class="${this._filterString ? "times click" : "search"} icon"
                                        @click=${this._clearFilter}
                                    ></i>
                                </div>
                            </div>

                            ${dates.map((date) => {
                                const holiday = getHolidayForDate(date, {
                                    holidays: this._venue.enabledHolidays,
                                    country: app.company?.country,
                                });
                                return html`
                                    <div class="day-header ${holiday ? "holiday" : ""}">
                                        ${[
                                            "Sonntag",
                                            "Montag",
                                            "Dienstag",
                                            "Mittwoch",
                                            "Donnerstag",
                                            "Freitag",
                                            "Samstag",
                                        ][date.getDay()]}
                                        <div class="day-subheader ellipsis">
                                            ${holiday ? holiday.name : formatDate(date)}
                                        </div>
                                    </div>
                                `;
                            })}
                        </div>
                    </div>

                    ${this._venue.departments
                        .sort((a, b) => a.order - b.order)
                        .map((department) => {
                            if (
                                department.archived ||
                                (this._departments && !this._departments.includes(department.id)) ||
                                !this._getEntries({ department }).length
                            ) {
                                return null;
                            }
                            const employees = this._getEmployeesForDepartment(department).filter((employee) =>
                                employee.name.toLowerCase().includes(this._filterString.toLowerCase())
                            );
                            return html`
                                <div
                                    class="department"
                                    style="--color-highlight: ${colors[department.color] || department.color}"
                                >
                                    <div class="department-header" style="width: ${this._departmentHeaderWidth}">
                                        ${department.name}
                                    </div>
                                    ${employees.map((employee) =>
                                        this._getEntries({ department, employee }).length
                                            ? html`
                                                  <div class="employee row">
                                                      <div class="employee-header row-header">${employee.name}</div>

                                                      ${this._dates.map(
                                                          (date) => html`
                                                              <div class="employee-day">
                                                                  ${this._getEntries({
                                                                      employee,
                                                                      date,
                                                                      department,
                                                                  }).map((entry) =>
                                                                      this._renderEntry(entry, department)
                                                                  )}
                                                              </div>
                                                          `
                                                      )}
                                                  </div>
                                              `
                                            : ""
                                    )}
                                </div>
                            `;
                        })}
                </div>
            </div>
        `;
    }

    private _renderNarrow() {
        const employee = this._employees.find((e) => e.id === this._selectedEmployee);

        if (!employee) {
            return html``;
        }

        const from = parseDateString(this._from)!;
        const to = parseDateString(this._to)!;

        return html`
            <div class="narrow vertical fullbleed layout">
                <div class="padded header">
                    <div class="venue-name">${this._venue.name}</div>
                    <div class="date-range">${formatDate(from)} - ${formatDate(to)}</div>
                    ${this._employees.length > 1
                        ? html`
                              <select
                                  class="employee-selector"
                                  .value=${employee.id.toString()}
                                  @change=${(e: Event) =>
                                      (this._selectedEmployee = parseInt((e.target as HTMLSelectElement).value))}
                              >
                                  ${this._employees.map(
                                      (e) => html` <option .value=${e.id.toString()}>${e.name}</option> `
                                  )}
                              </select>
                          `
                        : html` <div class="single-employee">${employee.name}</div> `}
                </div>

                <ptc-scroller class="stretch">
                    ${this._dates.map((date) => {
                        const entries = this._getEntries({ employee, date });
                        const holiday = getHolidayForDate(date, {
                            holidays: this._venue?.enabledHolidays,
                            country: app.company?.country,
                        });
                        return html`
                            <div class="employee-day">
                                <div class="day-header ${holiday ? "holiday" : ""}">
                                    ${["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"][
                                        new Date(date).getDay()
                                    ]}
                                    <div class="day-subheader ellipsis">
                                        ${holiday ? holiday.name : formatDate(date)}
                                    </div>
                                </div>
                                ${entries.length
                                    ? entries.map(
                                          (entry) => html`<div class="bigger">${this._renderEntry(entry)}</div>`
                                      )
                                    : html` <div class="subtle padded text-centering box">Keine Einträge</div> `}
                            </div>
                        `;
                    })}
                </ptc-scroller>
            </div>
        `;
    }
}
