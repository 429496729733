import { formatNumber } from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { shared } from "../styles";

@customElement("ptc-progress")
export class Progress extends LitElement {
    @property({ type: Number })
    actual: number;

    @property({ type: Number })
    nominal: number;

    @property()
    format: (val: number) => string = formatNumber;

    @property({ type: Number })
    threshold = 0.1;

    @query(".progress-inner")
    _progressInner: HTMLElement;

    updated() {
        this._progressInner.style.width = this.offsetWidth + "px";
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                background: #fafafa;
                border-radius: var(--border-radius);
                overflow: hidden;
                position: relative;
                text-align: center;
                font-weight: bold;
                font-size: inherit;
                border: solid 1px var(--shade-1);
            }

            :host(.vacation) {
                --progress-bg-under: var(--blue-bg);
                --progress-fg-under: var(--blue);
                --progress-bg-neutral: var(--blue-bg);
                --progress-fg-neutral: var(--blue);
            }

            .numbers {
                padding: 0.2em;
                box-sizing: border-box;
            }

            .progress {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
            }

            .progress-inner.under {
                background: var(--progress-bg-under, var(--orange-bg));
                color: var(--progress-fg-under, var(--orange));
            }

            .progress-inner.neutral {
                background: var(--progress-bg-neutral, var(--green-bg));
                color: var(--progress-fg-neutral, var(--green));
            }

            .progress-inner.over {
                background: var(--progress-bg-over, var(--red-bg));
                color: var(--progress-fg-over, var(--red));
            }
        `,
    ];

    render() {
        const format = this.format;
        const { actual, nominal } = this;
        const diff = nominal ? (actual - nominal) / nominal : 0;

        return html`
            <div class="progress" style="width: ${Math.min(1, nominal ? actual / nominal : 1) * 100}%">
                <div
                    class="numbers progress-inner ${diff < -this.threshold
                        ? "under"
                        : diff > this.threshold
                          ? "over"
                          : "neutral"}"
                >
                    ${format(actual)} / ${format(nominal)}
                </div>
            </div>

            <div class="numbers">${format(actual)} / ${format(nominal)}</div>
        `;
    }
}
