let iframe: HTMLIFrameElement | undefined = undefined;

export function print(url: string, waitForPrintReady = true) {
    if (!iframe) {
        iframe = document.createElement("iframe");
        Object.assign(iframe.style, {
            position: "fixed",
            width: "100%",
            height: "100%",
            visibility: "hidden",
        });
        document.body.appendChild(iframe);
    }
    const promise = new Promise<void>((resolve) => {
        const doPrint = () => {
            iframe!.contentWindow!.print();
            window.focus();
            resolve();
        };

        iframe!.onload = () => {
            iframe!.focus();
            if (!waitForPrintReady) {
                doPrint();
            } else if (iframe!.contentWindow!.printReady) {
                doPrint();
            } else {
                iframe!.contentWindow!.addEventListener("print-ready", doPrint);
            }
        };
    });
    iframe.src = url;
    return promise;
}
