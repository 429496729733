import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { TimeLogEvent } from "@pentacode/core/src/model";
import { shared } from "../styles";

@customElement("ptc-time-log-event-details")
export class TimeLogEventDetails extends LitElement {
    @property({ attribute: false })
    event: TimeLogEvent;

    static styles = [shared];

    render() {
        const event = this.event;
        return html`
            <div class="spacing vertical layout">
                ${event.rejectedReason
                    ? html`
                          <div class="padded red box" style="width: 280px; box-sizing: border-box;">
                              <strong><i class="exclamation-triangle"></i> Aktion wurde abgelehnt.</strong>
                              Grund: ${event.rejectedReason}
                          </div>
                      `
                    : ""}
                ${event.image && event.location
                    ? html`
                          <div class="relative">
                              <a
                                  href="https://www.google.com/maps/place/${event.location.latitude},${event.location
                                      .longitude}"
                                  target="_blank"
                              >
                                  <img
                                      src="https://maps.googleapis.com/maps/api/staticmap?markers=${event.location
                                          .latitude},${event.location
                                          .longitude}&size=560x560&scale=2&key=AIzaSyDce14aKN0ocoT4g9fQTdWD2ib7rtdg648"
                                      style="width: 280px; height: 280px; border-radius: 0.5em;"
                                  />
                              </a>

                              <a href="${event.image}" target="_blank" class="margined absolute bottom right">
                                  <img
                                      style="width: 100px; height: 100px; border-radius: 0.5em; object-fit: cover"
                                      src=${event.image}
                                  />
                              </a>
                          </div>
                      `
                    : event.image
                      ? html`
                            <div>
                                <a href="${event.image}" target="_blank">
                                    <img
                                        style="width: 280px; height: 280px; border-radius: 0.5em; object-fit: cover"
                                        src=${event.image}
                                    />
                                </a>
                            </div>
                        `
                      : event.location
                        ? html`
                              <div>
                                  <a
                                      href="https://www.google.com/maps/place/${event.location.latitude},${event
                                          .location.longitude}"
                                      target="_blank"
                                  >
                                      <img
                                          src="https://maps.googleapis.com/maps/api/staticmap?markers=${event.location
                                              .latitude},${event.location
                                              .longitude}&size=560x560&scale=2&key=AIzaSyDce14aKN0ocoT4g9fQTdWD2ib7rtdg648"
                                          style="width: 280px; height: 280px; border-radius: 0.5em;"
                                      />
                                  </a>
                              </div>
                          `
                        : ""}
                ${event.comment
                    ? html`
                          <div style="width: 280px; box-sizing: border-box;">
                              <i class="comment"></i>
                              ${event.comment}
                          </div>
                      `
                    : ""}
            </div>
        `;
    }
}
