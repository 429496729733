import { Department, Employee, Venue } from "@pentacode/core/src/model";
import { html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { Checkbox } from "./checkbox";
import "./scroller";
import { alert } from "./alert-dialog";
import { Textarea } from "./textarea";
import "./avatar";
import { SendMessageParams } from "@pentacode/core/src/api";
import "./virtual-list";
import { SelectAudience } from "./select-audience";

type Input = { message?: string; employees?: Employee[]; venues?: Venue[]; departments?: Department[]; all?: boolean };

@customElement("ptc-send-message-dialog")
export class SendMessageDialog extends Dialog<Input | undefined, void> {
    @state()
    private get _recipients() {
        return this._selectAudience?.selected.map((e) => e.id) || [];
    }

    @query(`[name="message"]`)
    private _messageInput: Textarea;

    @query(`ptc-select-audience`)
    private _selectAudience: SelectAudience;

    async show({
        message = "",
        employees = [],
        venues = [],
        departments = [],
        all = false,
    }: Input = {}): Promise<void> {
        const promise = super.show();
        await this.updateComplete;

        this._selectAudience.employees = employees;
        this._selectAudience.departments = departments;
        this._selectAudience.venues = venues;
        this._selectAudience.all = all;
        this._messageInput.value = message;

        return promise;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const employees = this._recipients;

        this.loading = true;
        try {
            await app.api.sendMessage(
                new SendMessageParams({
                    message: this._messageInput.value,
                    employees,
                })
            );
            this.done();
            void alert(`Ihre Nachricht wurde erfolgreich and ${employees.length} Mitarbeiter versand!`, {
                type: "success",
                title: "Nachricht Versand",
            });
        } catch (e) {
            this.done();
            void alert(e.message, { type: "warning" });
        }
        this.loading = false;
    }

    static styles = [...Dialog.styles, Checkbox.styles, Textarea.styles];

    renderContent() {
        return html`
            <form class="padded vertical layout" @submit=${this._submit}>
                <div class="large padded text-centering"><i class="envelope"></i> Nachricht Versenden</div>

                <div>
                    <label>Empfänger</label>
                    <ptc-select-audience @change=${() => this.requestUpdate()}></ptc-select-audience>
                </div>

                <div>
                    <label>Nachricht</label>
                    <ptc-textarea
                        @input=${() => this.requestUpdate()}
                        name="message"
                        placeholder="Tragen Sie hier Ihre Nachricht ein!"
                        required
                    ></ptc-textarea>
                </div>

                <div class="top-margined evenly stretching spacing horizontal layout">
                    <button
                        class="primary"
                        type="submit"
                        ?disabled=${!this._recipients.length || !this._messageInput?.value}
                    >
                        An <strong>${this._recipients.length}</strong> Mitarbeiter Versenden
                    </button>
                    <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
